import {
	PresignedUrlResponse,
	PrivateInvestorDocumentRedirectParams,
	PrivateUserDocumentRedirectParams
} from 'types/investor-documents';
import { apiSecureRequest } from '@utils/request';

export async function privateInvestorDocumentViewRedirect(
	params: PrivateInvestorDocumentRedirectParams
): Promise<PresignedUrlResponse> {
	const response = await apiSecureRequest.get(
		`/private-investor-documents/${params.documentType}/${params.documentId}/view`
	);
	return response.data;
}

export async function privateUserDocumentViewRedirect(
	params: PrivateUserDocumentRedirectParams
): Promise<PresignedUrlResponse> {
	const response = await apiSecureRequest.get(
		`/private-user-documents/${params.documentType}/${params.documentId}/view`
	);
	return response.data;
}
