import { computed, ref } from 'vue';
import {
	EmailEntryUnsubscribeForm,
	EmailGroup,
	EmailGroupEnum,
	IterableUnsubscribeRequest,
	UnsubscribeEmailResponse
} from 'types/email';
import {
	getEmailGroupSubscriptions as getEmailGroupSubscriptionsApi,
	unsubscribeInviteEmail,
	unsubscribeUserEmail,
	unsubscribeUserIterableEmail
} from '@api/email';
import { defineStore } from 'pinia';

const INITIAL_STATE = {
	unsubscribeEmailResponse: {
		recentUnsubscribeGroup: undefined,
		emailGroupSubscriptionDtos: [],
		allEmailOptOut: undefined
	}
};

export const useEmailStore = defineStore('email', () => {
	const emailGroup = ref<EmailGroupEnum | null>(null);
	const invitation = ref(false);
	const messageTypeId = ref('');
	const unsubscribeEmailResponse = ref<UnsubscribeEmailResponse>(INITIAL_STATE.unsubscribeEmailResponse);
	const unsubscribeKey = ref('');

	const recentUnsubscribeGroup = computed((): EmailGroup | undefined => {
		return unsubscribeEmailResponse.value.recentUnsubscribeGroup;
	});

	async function getEmailGroupSubscriptions(unsubscribeKeyParam: string): Promise<UnsubscribeEmailResponse> {
		const unsubscribeEmailApiResponse = await getEmailGroupSubscriptionsApi(unsubscribeKeyParam);
		unsubscribeKey.value = unsubscribeKeyParam;
		unsubscribeEmailResponse.value = unsubscribeEmailApiResponse;

		return unsubscribeEmailApiResponse;
	}

	async function oneClickUnsubscribeIterable(messageTypeIdParam: string): Promise<UnsubscribeEmailResponse> {
		const iterableUnsubscribeRequest: IterableUnsubscribeRequest = {
			unsubscribeKey: unsubscribeKey.value,
			messageTypeId: messageTypeIdParam
		};

		const updatedEmailSubscriptions = await unsubscribeUserIterableEmail(iterableUnsubscribeRequest);
		messageTypeId.value = messageTypeIdParam;
		unsubscribeEmailResponse.value = updatedEmailSubscriptions;

		return updatedEmailSubscriptions;
	}

	async function oneClickUnsubscribeEmailGroup(emailGroupParam: EmailGroupEnum): Promise<UnsubscribeEmailResponse> {
		const form: EmailEntryUnsubscribeForm = {
			unsubscribeKey: unsubscribeKey.value,
			updateEmailGroupSubscriptions: [{ subscribed: false, emailGroup: emailGroupParam }],
			oneClickUnsubscribe: true,
			allEmailOptOut: false
		};

		const updatedEmailSubscriptions = await unsubscribeUserEmail(form);
		emailGroup.value = emailGroupParam;
		unsubscribeEmailResponse.value = updatedEmailSubscriptions;

		return updatedEmailSubscriptions;
	}

	async function oneClickUnsubscribeInvitation(unsubscribeKeyParam: string): Promise<void> {
		await unsubscribeInviteEmail(unsubscribeKeyParam);
		invitation.value = true;
	}

	async function updateEmailGroupSubscriptions(): Promise<UnsubscribeEmailResponse> {
		const updateEmailGroupSubscriptionDtos =
			unsubscribeEmailResponse.value?.emailGroupSubscriptionDtos.map((e) => ({
				emailGroup: e.emailGroup.emailGroupEnum,
				subscribed: e.subscribed
			})) ?? [];

		const form: EmailEntryUnsubscribeForm = {
			unsubscribeKey: unsubscribeKey.value,
			updateEmailGroupSubscriptions: updateEmailGroupSubscriptionDtos,
			oneClickUnsubscribe: false,
			allEmailOptOut: unsubscribeEmailResponse.value?.allEmailOptOut
		};

		return unsubscribeUserEmail(form);
	}

	function toggleAllEmailOptOut(checked: boolean): void {
		const updatedEmailSubscriptionDtos = unsubscribeEmailResponse.value?.emailGroupSubscriptionDtos.map((e) => ({
			...e,
			subscribed: !checked
		}));
		unsubscribeEmailResponse.value.emailGroupSubscriptionDtos = updatedEmailSubscriptionDtos ?? [];
		unsubscribeEmailResponse.value.allEmailOptOut = checked;
	}

	function updateAllEmailOptOut(checked: boolean): void {
		unsubscribeEmailResponse.value.allEmailOptOut = checked;
	}

	function $reset(): void {
		emailGroup.value = null;
		invitation.value = false;
		messageTypeId.value = '';
		unsubscribeEmailResponse.value = INITIAL_STATE.unsubscribeEmailResponse;
		unsubscribeKey.value = '';
	}

	return {
		emailGroup,
		invitation,
		messageTypeId,
		unsubscribeEmailResponse,
		unsubscribeKey,
		recentUnsubscribeGroup,
		oneClickUnsubscribeIterable,
		oneClickUnsubscribeInvitation,
		oneClickUnsubscribeEmailGroup,
		getEmailGroupSubscriptions,
		updateEmailGroupSubscriptions,
		toggleAllEmailOptOut,
		updateAllEmailOptOut,
		$reset
	};
});
