/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Directive, DirectiveBinding } from 'vue';

const HANDLER = '_CLICK_OUTSIDE';

export const ClickAway: Directive = {
	mounted: function (el: HTMLElement, binding: DirectiveBinding) {
		// @ts-ignore
		el[HANDLER] = (event: MouseEvent) => {
			if (!el.contains(event.target as Node)) {
				binding.value();
			}
		};
		// @ts-ignore
		document.addEventListener('click', el[HANDLER]);
	},
	unmounted: function (el: HTMLElement) {
		// @ts-ignore
		document.removeEventListener('click', el[HANDLER]);
	}
};

export default ClickAway;
