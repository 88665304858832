import { InvestMetadata, InvestReviewDetails } from 'types/account/invest';
import { CheckoutOrderGroupDetails } from 'types/checkout';
import { investmentEntityRequest } from '@utils/request';
import { OrderGroupResponse } from 'types/add-funds';

export async function getOrderMetadata(reitOfferingVintageId?: string, cohortId?: string): Promise<InvestMetadata> {
	const response = await investmentEntityRequest.get(`/repeat-investment/metadata`, {
		params: { reitOfferingVintageId, cohortId }
	});

	return response.data;
}

export async function getReviewOrderDetails(orderGroupId: string): Promise<InvestReviewDetails> {
	const response = await investmentEntityRequest.get(`/repeat-investment/order-review/${orderGroupId}`);
	return response.data;
}

export async function updateOrderGroup(
	orderGroupId: string,
	payload: CheckoutOrderGroupDetails
): Promise<OrderGroupResponse> {
	const response = await investmentEntityRequest.put(`/investment/order-group/${orderGroupId}`, payload);
	return response.data;
}
