/**
 * Defines custom Spacetime formatting strings.
 */
export enum CustomSpacetimeFormat {
	/** Jan 1, 2024 */
	MONTH_SHORT_DATE_YEAR = '{month-short} {date}, {year}',
	/** Jan '24 */
	MONTH_SHORT_YEAR_SHORT = '{month-short} {year-short}',
	/** January 1, 2024 */
	MONTH_DATE_YEAR = '{month} {date}, {year}',
	/** January 01, 2024 */
	MONTH_DATE_PAD_YEAR = '{month} {date-pad}, {year}',
	/** Jan 01, 2024 */
	MONTH_SHORT_DATE_PAD_YEAR = '{month-short} {date-pad}, {year}',
	/** Monday, January 1st 2024 */
	DAY_MONTH_DATE_ORDINAL_YEAR = '{day}, {month} {date-ordinal} {year}',
	/** January 2024 */
	MONTH_YEAR = '{month} {year}',
	/** Jan 2024 */
	MONTH_SHORT_YEAR = '{month-short} {year}',
	/** January '24 */
	MONTH_YEAR_SHORT = '{month} {year-short}',
	/** 2024 */
	YEAR = '{year}',
	/** January 1 */
	MONTH_DATE = '{month} {date}',
	/** January 1, 3:45pm */
	MONTH_DATE_TIME_WITH_SUFFIX = '{month} {date}, {hour}:{minute-pad}{ampm}',
	/** 1:00am */
	TIME_WITH_SUFFIX = '{hour}:{minute-pad}{ampm}',
	/** 2024-01-01 */
	ISO_SHORT = 'iso-short',
	/** 01/01/2024 */
	NUMERIC_US = 'numeric-us'
}

/**
 * Defines custom Unix Spacetime formatting strings.
 * These should be used with `spacetime.unixFmt()`
 */
export enum CustomUnixSpacetimeFormat {
	/** 1/1/2024 (use `.unixFmt`) */
	UNIX_SINGLE_DIGIT_FULL_YEAR = 'M/d/yyyy',
	/** 1/1/24 (use `.unixFmt`) */
	UNIX_SINGLE_DIGIT_SHORT_YEAR = 'M/d/yy'
}
