import { AxiosResponse } from 'axios';
import { investmentEntityRequest } from '@utils/request';

export async function getLinkToken(
	redirectUri: string | null = null,
	filterSavings: boolean | null = null
): Promise<string> {
	const params = { webRedirectUri: redirectUri, filterSavings };
	const response = redirectUri
		? await investmentEntityRequest.get('/plaid-link-token', { params })
		: await investmentEntityRequest.get('/plaid-link-token');
	return response.data?.linkToken;
}

export async function getPlaidLayerLinkToken(redirectUri: string | null = null): Promise<string> {
	const params = { webRedirectUri: redirectUri };
	const response = redirectUri
		? await investmentEntityRequest.get('/plaid-layer/link-token', { params })
		: await investmentEntityRequest.get('/plaid-layer/link-token');
	return response.data?.linkToken;
}

export async function submitPlaidLayerEligibility(layerEligible: boolean): Promise<void> {
	await investmentEntityRequest.post('/plaid-layer/eligibility', { layerEligible });
}

export async function submitPlaidLayerPublicToken(payload: { publicToken?: string; skipped?: boolean }): Promise<void> {
	await investmentEntityRequest.post('/plaid-layer/import', payload, {
		suppressToast: (resp: AxiosResponse) => resp.status === 400
	});
}

export async function getLastLinkToken(): Promise<string> {
	const response = await investmentEntityRequest.get('/last-plaid-link-token');
	return response.data;
}
