import { defineStore } from 'pinia';
import { getRiaPlansByEntityV2 } from '@api/ria';
import { ref } from 'vue';
import { RiaPlan } from 'types/plan';

export const usePlansStore = defineStore('plans', () => {
	const selectedPlan = ref<RiaPlan | null>(null);
	const availablePlans = ref<Array<RiaPlan>>([]);
	const isFmpCardFlow = ref(false);

	async function getAvailableRiaPlans(): Promise<void> {
		if (availablePlans.value.length === 0) {
			const availablePlansForEntity = await getRiaPlansByEntityV2();
			availablePlans.value = availablePlansForEntity;
		}
	}

	function $reset(): void {
		selectedPlan.value = null;
		availablePlans.value = [];
		isFmpCardFlow.value = false;
	}

	return {
		selectedPlan,
		availablePlans,
		isFmpCardFlow,
		getAvailableRiaPlans,
		$reset
	};
});
