<template>
	<div v-if="showBanner" class="base-banner bg-color-rust py-100">
		<div class="container">
			<div class="position-relative text-align-center">
				<slot> Override Me </slot>

				<span class="close-button">
					<slot name="close-button">
						<BaseLinkButton data-test="close-banner" classes="modal-close" @click="closeBanner">
							<BaseSvgIcon name="close" />
						</BaseLinkButton>
					</slot>
				</span>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { getItemFromSessionStorageAsBoolean, setItemInSessionStorage } from '@utils/web-storage';
import { app } from '@store/modules/app';
import { computed } from 'vue';
import { isDesktop } from '@utils/composables';
interface Props {
	dismissalKey?: string;
}
const props = withDefaults(defineProps<Props>(), {
	dismissalKey: ''
});

const showBanner = computed((): boolean => {
	return !getItemFromSessionStorageAsBoolean(props.dismissalKey);
});

function closeBanner(): void {
	setItemInSessionStorage(props.dismissalKey, 'true');
	app.UPDATE_CURRENT_BANNER(null);

	// Offset the page "jump" when the added page top padding is removed
	if (isDesktop.value) {
		window.scrollBy(0, -56);
	} else {
		window.scrollBy(0, -80);
	}
}

function setup(): void {
	if (!showBanner.value) {
		app.UPDATE_CURRENT_BANNER(null);
	}
}
setup();
</script>
<style lang="scss">
@use '../../styles/constants/layout';
@use '../../styles/constants/colors.scss' as *;
@use '../../styles/utilities/respond-to.scss' as *;

$desktop-height: 56px;
$mobile-height: 80px;

$desktop-nav-height: 68px;
$mobile-nav-height: 48px;

.base-banner {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	color: white;
	height: $desktop-height;
	z-index: 5000;

	~ .page {
		.jump-link-nav--section {
			scroll-margin-top: 170px;
		}
	}

	a {
		&:hover,
		&:focus {
			color: white;
			text-decoration: underline;
		}
	}

	.close-button {
		a {
			color: white;
			padding: 4px;
			&:hover,
			&:focus {
				color: white;
				background-color: rgba(token('content-primary'), 0.2);
				border-radius: 5px;
			}
		}
	}

	@include respond-to(smo) {
		height: $mobile-height;

		& ~ .toast-container {
			.toast {
				margin-top: $mobile-height;
			}
		}

		& ~ .page {
			padding-top: $mobile-height;

			.main-nav {
				top: $mobile-height;
			}

			.mobile-nav {
				&__top-bar {
					top: $mobile-height;
				}
			}

			.jump-link-nav {
				top: $mobile-nav-height + $mobile-height;
			}
		}
	}

	@include respond-to(md) {
		& ~ .toast-container {
			.toast {
				margin-top: $desktop-height;
			}
		}

		& ~ .page {
			padding-top: $desktop-height;

			.main-nav {
				top: $desktop-height;
			}

			.mobile-nav {
				&__top-bar {
					top: $desktop-height;
				}
			}

			.scrolling-table .table thead {
				top: 7.5rem;
			}

			.jump-link-nav {
				top: $mobile-nav-height + $desktop-height;
			}
		}
	}

	@include respond-to(lg) {
		& ~ .page {
			.jump-link-nav {
				top: $desktop-nav-height + $desktop-height;
			}
		}
	}
}
</style>
