export const isANumber = function (field: string | null): boolean {
	if (field !== null && !isNaN(Number(field))) {
		return true;
	}
	return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function shuffleArray(set: Array<any>): Array<any> {
	for (let i = set.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		const temp = set[i];
		set[i] = set[j];
		set[j] = temp;
	}
	return set;
}
