<template>
	<highcharts :options="chartOptions" :highcharts="localHc" class="highcharts-container" />
</template>
<script setup lang="ts">
import Highcharts, { Options } from 'highcharts';
import BaseChart from '@charts/BaseChartOptions';
import { computed } from 'vue';
import deepMerge from 'deepmerge';
import { Chart as highcharts } from 'highcharts-vue';

interface Props {
	percentage: string;
	chartSize?: string;
	fillColor?: string;
}

const props = withDefaults(defineProps<Props>(), {
	chartSize: '24',
	fillColor: '#517787'
});

const localHc = Highcharts;

const chartOptions = computed((): Options => {
	const percentNum = parseInt(props.percentage);
	const localOptions = {
		chart: {
			type: 'pie',
			spacing: [0, 0, 0, 0],
			height: props.chartSize,
			width: props.chartSize
		},
		plotOptions: {
			series: {
				animation: false
			},
			pie: {
				borderWidth: 0,
				innerSize: '60%',
				size: props.chartSize,
				states: {
					hover: {
						enabled: false
					},
					inactive: {
						enabled: false
					}
				}
			}
		},
		series: [
			{
				type: 'pie',
				data: [
					{
						y: percentNum,
						color: props.fillColor
					},
					{
						y: 100 - percentNum,
						color: '#F2F1ED'
					}
				]
			}
		]
	};

	return deepMerge(new BaseChart().getOptions(), localOptions);
});
</script>
