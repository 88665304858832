import { customerBetaEnrollRouteGuard } from './utils/customer-beta-enroll-route-guard';
import { FundriseRouteConfig } from 'vue-router';

const customerBetaRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/beta-group-enrollment',
		name: 'customer-beta',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		redirect: { name: 'customer-beta-enroll' },
		component: () => import('@views/customer-beta/customer-beta.vue'),
		children: [
			{
				path: 'enroll',
				name: 'customer-beta-enroll',
				meta: { minTokenScopeRequired: 'FULL_ACCESS', step: 1 },
				beforeEnter: customerBetaEnrollRouteGuard,
				component: () => import('@views/customer-beta/customer-beta-enroll.vue')
			},
			{
				path: 'success',
				name: 'customer-beta-success',
				meta: { minTokenScopeRequired: 'FULL_ACCESS', step: 2 },
				component: () => import('@views/customer-beta/customer-beta-success.vue')
			},
			{
				path: 'error',
				name: 'customer-beta-error',
				meta: { minTokenScopeRequired: 'FULL_ACCESS', step: 2 },
				component: () => import('@views/customer-beta/customer-beta-error.vue')
			}
		]
	}
];

export default customerBetaRoutes;
