import { OSType, ScreenSizeType } from 'types/client';
import { appBasePath } from '@constants';

export const Breakpoints = {
	tabletStart: 768,
	desktopStart: 1200
};

export const getScreenSize = (): ScreenSizeType => {
	const screenWidth = window.innerWidth;

	if (screenWidth < Breakpoints.tabletStart) {
		return 'MOBILE';
	} else if (screenWidth >= Breakpoints.desktopStart) {
		return 'DESKTOP';
	} else {
		return 'TABLET';
	}
};

export const getOS = (): OSType | null => {
	const userAgent = window.navigator.userAgent;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

	if (macosPlatforms.some((platform) => userAgent.includes(platform))) {
		if (window.navigator.maxTouchPoints > 1) {
			return 'iOS'; //iPadOS workaround
		}
		return 'MacOS';
	} else if (iosPlatforms.some((platform) => userAgent.includes(platform))) {
		return 'iOS';
	} else if (windowsPlatforms.some((platform) => userAgent.includes(platform))) {
		return 'Windows';
	} else if (/Android/.test(userAgent)) {
		return 'Android';
	} else if (/Linux/.test(userAgent)) {
		return 'Linux';
	} else {
		return null;
	}
};

export const isIphone = (): boolean => {
	const userAgent = window.navigator.userAgent;
	return userAgent.includes('iPhone') && !userAgent.includes('iPad');
};

export const isMobileOS = (): boolean => {
	const userAgent = window.navigator.userAgent;
	if (isIphone() || userAgent.includes('Android')) {
		return true;
	}

	return false;
};

export const redirect = (path: string): void => {
	window.location.href = appBasePath + path;
};

export const replaceRoute = (path: string): void => {
	const url = appBasePath + path;
	window.location.replace(url);
};

export const openNewTab = (path: string): void => {
	window.open(path, '_blank');
};

export const isElementFullyVisible = (element: Element): boolean => {
	const domElement = element.getBoundingClientRect();
	const html = document.documentElement;

	const topVisible = domElement.top >= 0;
	const leftVisible = domElement.left >= 0;
	const bottomVisible = domElement.bottom <= (window.innerHeight || html.clientHeight);
	const rightVisible = domElement.right <= (window.innerWidth || html.clientWidth);

	return topVisible && leftVisible && bottomVisible && rightVisible;
};
