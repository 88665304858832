<template>
	<span :class="badgeClasses"> <BaseSvgIcon v-if="iconName" :name="iconName" /> {{ text }} </span>
</template>

<script setup lang="ts">
import { BadgeBackground, BadgeSize } from 'types/layout';
import { computed } from 'vue';
interface Props {
	text?: string;
	size?: BadgeSize;
	background?: BadgeBackground;
	iconName?: string;
}
const props = withDefaults(defineProps<Props>(), {
	text: undefined,
	size: 'large',
	background: 'default',
	iconName: undefined
});

const badgeClasses = computed((): string => {
	const classes = ['badge', `badge--${props.background}`];

	if (props.iconName) {
		classes.push('badge-has-icon');
	}

	if (!props.text && props.iconName) {
		classes.push('badge-icon-only');
	}

	if (props.size) {
		classes.push(`badge--${props.size}`);
	}

	return classes.join(' ');
});
</script>

<style lang="scss" scoped>
@use '../../styles/constants/colors.scss' as *;

.badge {
	align-items: center;
	border-radius: 35px;
	display: inline-flex;
	// body-md
	font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
	font-size: 1rem;
	line-height: 1.5;
	font-weight: 400;
	height: 24px;
	padding: 0 8px;
	text-transform: none;
	vertical-align: middle;

	&.badge-icon-only {
		padding-right: 8px;
	}

	&.badge-has-icon {
		padding-left: 8px;

		&:not(.badge-icon-only) {
			.svg-icon {
				margin-right: 4px;
			}
		}
	}

	&.badge--xlarge {
		height: 36px;
		padding: 0 16px;
	}

	&.badge--medium {
		font-size: 0.75rem;
		height: 18px;
	}

	&.badge--small {
		font-size: 0.5rem;
		height: 12px;
		line-height: 12px;
		padding: 0 4px;

		.svg-icon {
			height: 11px;
			width: 11px;
		}
	}

	&.badge--default {
		background-color: token('bg-secondary');
		color: token('content-primary');
	}

	&.badge--black {
		background-color: token('content-primary');
		color: map-get($brand-colors, 'white');
	}

	&.badge--white {
		background-color: map-get($brand-colors, 'white');
		color: token('content-primary');
	}

	&.badge--slate {
		background-color: slate(20);
		color: token('content-primary');
	}

	&.badge--pro {
		background-color: token('gold-light');
		color: token('content-primary');
	}
}
</style>
