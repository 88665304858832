import { apiSecureRequest, investmentEntityRequest } from '@utils/request';
import { InfoDetails, InfoViewRequest } from 'types/info-framework';
import { NextQuestionResponse, QuestionAnswer, QuestionResponse } from 'types/question-framework';

export async function getQuestions(questionName?: string): Promise<QuestionResponse> {
	const response = await apiSecureRequest.get('/questions', {
		params: {
			questionName
		}
	});
	return response.data;
}

export async function submitQuestionAnswers(questionAnswers: Array<QuestionAnswer>): Promise<NextQuestionResponse> {
	const response = await apiSecureRequest.post('/answers', questionAnswers);
	return response.data;
}

export async function getInfoDetails(infoId?: string): Promise<InfoDetails> {
	const response = await investmentEntityRequest.get('/info', {
		params: {
			infoId
		}
	});
	return response.data;
}

export async function storeInfoConfirmation(infoId: string): Promise<void> {
	const requestBody: InfoViewRequest = { infoId };
	await apiSecureRequest.post('/info-confirmation', requestBody);
}
