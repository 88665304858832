import { AxiosRequestConfig, AxiosRequestInterceptorParams } from 'axios';
import { logError } from '@utils/error-tracking';

/**
 * If a WAF token isn’t present, runs logic to get the token.
 */
async function WafTokenRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig<unknown>> {
	if (window.AwsWafIntegration) {
		await window.AwsWafIntegration.getToken().catch(logError);
	}

	return config;
}

export const wafTokenRequestInterceptor: AxiosRequestInterceptorParams = [(config) => WafTokenRequest(config)];
