import { PrismicRichText, PrismicSpan } from 'types/prismic';

interface SerializationInfo {
	serializedText: string;
	labelType?: string;
}

export function convertTextToSlug(text: string): string {
	return text
		.toLowerCase()
		.replace(/\s/g, '-')
		.replace(/[^a-z0-9-]/g, '');
}

function handleLabel(spanText: string, label: string): SerializationInfo {
	switch (label) {
		case 'blockquote':
			return { serializedText: `<blockquote class="blockquote"><p>${spanText}</p></blockquote>` };
		case 'pullout-quote':
			return {
				serializedText: `<blockquote class="pullout-quote pl-100 pt-100 pr-100 pb-100 mt-150 mb-150 ml-100-md"><p>${spanText}</p></blockquote>`
			};
		case 'inline-button':
			return {
				serializedText: `<p class="article-inline-button text-align-center">${spanText}</p>`,
				labelType: 'inline-button'
			};
		case 'inline-cta':
			return {
				serializedText: `<aside class="article-cta cta-type--inline-link pl-100 pt-100 pr-100 pb-100 mt-150 mb-150"><p class="article-cta-heading ff-ivar-text delta">${spanText}</p></aside>`
			};
		case 'stats-block':
			return { serializedText: `<aside class="stats-block">${spanText}</aside>` };
		case 'superscript':
			return { serializedText: `<sup>${spanText}</sup>` };
		case 'in-article-nav': {
			const spanSlug = convertTextToSlug(spanText);
			return {
				serializedText:
					`<span id="` + spanSlug + `" class="in-article-nav-heading offset-anchor"></span>` + spanText
			};
		}
		default:
			return { serializedText: spanText };
	}
}

function generateSerializedSpan(spanText: string, span: PrismicSpan): SerializationInfo {
	switch (span.type) {
		case 'strong':
			return { serializedText: `<b>${spanText}</b>` };
		case 'em':
			return { serializedText: `<em>${spanText}</em>` };
		case 'hyperlink':
			if (span.data) {
				const target = span.data.target ? ` target="${span.data.target}"` : '';
				return { serializedText: `<a href="${span.data.url}"${target}>${spanText}</a>` };
			} else {
				return { serializedText: spanText };
			}
		case 'label':
			if (span.data?.label) {
				return handleLabel(spanText, span.data.label);
			}
		default:
			return { serializedText: spanText };
	}
}

function handleNestedSpan(
	serialized: string,
	spanText: string,
	span: PrismicSpan,
	labelType: string | undefined
): string {
	const nestedStart = serialized.indexOf(spanText);
	const nestedEnd = nestedStart + spanText.length;
	const preNest = serialized.slice(0, nestedStart);
	const postNest = serialized.slice(nestedEnd);

	switch (span.type) {
		case 'strong':
			return preNest + `<b>${spanText}</b>` + postNest;
		case 'em':
			return preNest + `<em>${spanText}</em>` + postNest;
		case 'hyperlink':
			if (span.data) {
				const target = span.data.target ? ` target="${span.data.target}"` : '';
				const buttonClasses = labelType === 'inline-button' ? ' class="button button-primary"' : '';
				return preNest + `<a href="${span.data.url}"${target}${buttonClasses}>${spanText}</a>` + postNest;
			} else {
				return serialized;
			}
		default:
			return serialized;
	}
}

function applySpans(text: string, spans: Array<PrismicSpan>): string {
	let result = '';
	let serialized = '';
	let serializationCompleteIndex = 0;
	let labelType: string | undefined;
	spans.forEach((span) => {
		const spanText = text.slice(span.start, span.end);
		if (serializationCompleteIndex <= span.start) {
			serialized += text.slice(serializationCompleteIndex, span.start);
			result += serialized;
			serializationCompleteIndex = span.end;
			const serializationInfo = generateSerializedSpan(spanText, span);
			serialized = serializationInfo.serializedText;
			labelType = serializationInfo.labelType;
		} else {
			serialized = handleNestedSpan(serialized, spanText, span, labelType);
		}
	});

	result += serialized + text.slice(serializationCompleteIndex);

	return result;
}

function startListTag(prev: PrismicRichText | null, type: string): string {
	const isOrdered = type === 'o-list-item';
	if (!prev || (prev && prev.type !== type)) {
		return isOrdered ? '<ol>' : '<ul>';
	} else {
		return '';
	}
}

function endListTag(next: PrismicRichText | null, type: string): string {
	const isOrdered = type === 'o-list-item';
	if (!next || (next && next.type !== type)) {
		return isOrdered ? '</ol>' : '</ul>';
	} else {
		return '';
	}
}

function serializeElement(
	currentElement: PrismicRichText,
	previousElement: PrismicRichText | null,
	nextElement: PrismicRichText | null
): string {
	let serialized: string;
	const text = currentElement.text ?? '';
	if (currentElement.spans && currentElement.spans.length > 0) {
		serialized = applySpans(text, currentElement.spans);
	} else {
		serialized = text;
	}

	switch (currentElement.type) {
		case 'paragraph':
			return `<p>${serialized}</p>`;
		case 'heading1':
			return `<h1>${serialized}</h1>`;
		case 'heading2':
			return `<h2>${serialized}</h2>`;
		case 'heading3':
			return `<h3>${serialized}</h3>`;
		case 'heading4':
			return `<h4>${serialized}</h4>`;
		case 'heading5':
			return `<h5>${serialized}</h5>`;
		case 'heading6':
			return `<h6>${serialized}</h6>`;
		case 'preformatted':
			return `<pre>${serialized}</pre>`;
		case 'strong':
			return `<b>${serialized}</b>`;
		case 'em':
			return `<em>${serialized}</em>`;
		case 'list-item':
			return `${startListTag(previousElement, currentElement.type)}<li>${serialized}</li>${endListTag(
				nextElement,
				currentElement.type
			)}`;
		case 'o-list-item':
			return `${startListTag(previousElement, currentElement.type)}<li>${serialized}</li>${endListTag(
				nextElement,
				currentElement.type
			)}`;
		case 'hyperlink':
			if (currentElement.data) {
				const target = currentElement.data.target ? `target="${currentElement.data.target}"` : '';
				return `<a href="${currentElement.data.url}" ${target}>${serialized}</a>`;
			} else {
				return `<p>${serialized}</p>`;
			}
		case 'image':
			if (currentElement.label === 'floated-image') {
				return currentElement.alt
					? `<img class="floated-image float-right-md col-4-md ml-100-md mb-100-md" src="${currentElement.url}" alt="${currentElement.alt}">`
					: `<img class="floated-image float-right-md col-4-md ml-100-md mb-100-md" src="${currentElement.url}">`;
			} else {
				return currentElement.alt
					? `<img src="${currentElement.url}" alt="${currentElement.alt}">`
					: `<img src="${currentElement.url}">`;
			}
		case 'embed':
			return currentElement.oembed
				? `<div class="responsive-embed" data-oembed="${currentElement.oembed.embed_url}" data-oembed-type="${currentElement.oembed.type}" data-oembed-provider="${currentElement.oembed.provider_name}">${currentElement.oembed.html}</div>`
				: '';
		default:
			return '';
	}
}

function combineListItems(serializedElements: Array<string>): Array<string> {
	const result: Array<string> = [];
	let combinedList = '';

	serializedElements.forEach((elem) => {
		if (elem.startsWith('<ul>')) {
			combinedList += elem;
			if (elem.endsWith('</ul>')) {
				result.push(combinedList);
				combinedList = '';
			}
		} else if (elem.startsWith('<ol>')) {
			combinedList += elem;
			if (elem.endsWith('</ol>')) {
				result.push(combinedList);
				combinedList = '';
			}
		} else if (elem.startsWith('<li>')) {
			combinedList += elem;
			if (elem.endsWith('</ul>') || elem.endsWith('</ol>')) {
				result.push(combinedList);
				combinedList = '';
			}
		} else {
			result.push(elem);
		}
	});

	return result;
}

export function serializePrismicContent(elements: Array<PrismicRichText>): Array<string> {
	const serializedElements: Array<string> = [];

	for (let i = 0; i < elements.length; i++) {
		const currentElement = elements[i];
		const previousElement = i > 0 ? elements[i - 1] : null;
		const nextElement = i < elements.length - 1 ? elements[i + 1] : null;
		const serializedElement = serializeElement(currentElement, previousElement, nextElement);
		serializedElements.push(serializedElement);
	}

	return combineListItems(serializedElements);
}
