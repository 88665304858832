import { DropdownLinks, NavigationLink } from 'types/header';
import { Link } from 'types/layout';

export const learnLinks: Array<DropdownLinks> = [
	{
		dropdown: 'Education',
		links: [
			{ link: { text: 'Help center', router: 'help-center-landing' } },
			{ link: { text: 'Articles', router: 'blog-landing' } },
			{ link: { text: 'Podcast', router: 'blog-category', params: { categoryUrlSlug: 'onward' } } }
		]
	},
	{
		dropdown: 'Getting started',
		links: [
			{
				link: {
					text: 'Why Fundrise',
					router: 'why-fundrise'
				}
			},
			{
				link: {
					text: 'How it works',
					router: 'how-it-works'
				}
			},
			{
				link: {
					text: 'About us',
					router: 'about'
				}
			}
		]
	}
];

export const marketingLinks: Array<NavigationLink> = [
	{
		text: 'Venture',
		router: 'venture-capital',
		badgeText: 'New'
	},
	{
		text: 'Private credit',
		router: 'private-credit'
	},
	{
		text: 'Real estate',
		router: 'real-estate-strategies'
	},
	{
		text: 'Client returns',
		router: 'client-returns'
	}
];

export const settingsLinks: Array<Link> = [
	{
		text: 'Settings',
		router: 'settings-account-information-redirect'
	},
	{
		text: 'Log out',
		router: 'logout-success'
	}
];
