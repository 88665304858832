import { FundriseRouteConfig, RouteLocationRaw } from 'vue-router';
import { app } from '@store/modules/app';
import { checkAndSwitchToIraEntity } from './ira-navigation-guards';
import { investmentEntity } from '@store/modules/investment-entity';
import { ira } from '@store/modules/ira';
import { orderForm } from '@store/modules/order-form';
import { user } from '@store/modules/user';
const getCompletedIraId = (): string | null => {
	return (
		user.user?.entityDetails?.investmentEntities.find(
			(entity) => entity.entityType === 'IRA' && entity.iraEntityMetadata?.isIraOnboardingFinished
		)?.investmentEntityId ?? null
	);
};

const getAnyIncompleteIraId = (): string | null => {
	return (
		user.user?.entityDetails?.investmentEntities.find(
			(entity) => entity.entityType === 'IRA' && !entity.iraEntityMetadata?.isIraOnboardingFinished
		)?.investmentEntityId ?? null
	);
};

const updateIraEntityForRolloverRedirect = async (
	anyCompletedIraId: string | null,
	next?: (to?: RouteLocationRaw) => void
): Promise<void> => {
	const anyIncompleteIraId = getAnyIncompleteIraId();

	const hasCompletedIraNotSelected =
		anyCompletedIraId !== null && investmentEntity.investmentEntityId !== anyCompletedIraId;

	const hasIncompleteIraNotSelected =
		anyIncompleteIraId !== null && investmentEntity.investmentEntityId !== anyIncompleteIraId;

	if (hasCompletedIraNotSelected) {
		await user.updateSelectedEntityWithoutReload({
			investmentEntityId: anyCompletedIraId ?? '',
			nextRoute: {
				route: {
					name: 'ira-transfer-reminder-redirect'
				},
				next
			}
		});
	} else if (anyIncompleteIraId !== null) {
		if (hasIncompleteIraNotSelected) {
			await user.updateSelectedEntity(anyIncompleteIraId);
		}
		await ira.resetIraForSimpleIraReminderRedirect();
	}
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const iraGeneralRoutes: FundriseRouteConfig[] = [
	{
		path: `/ira/rollover/checklist`,
		name: 'ira-rollover-checklist',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: checkAndSwitchToIraEntity,
		component: () => import('@views/ira/general/ira-rollover-checklist.vue')
	},
	{
		path: `/ira/checkout`,
		name: 'ira-checkout-individual-details',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			if (app.isAuthenticated) {
				await orderForm.getUserCheckoutDetailsData();
			}

			if (user.hasIraEntity) {
				next({ name: 'ira-start', replace: true });
			} else if (orderForm.isIraCheckout) {
				next();
			} else {
				next({ name: 'checkout-resume' });
			}
		},
		component: () => import('@views/ira/general/ira-checkout-individual-account-details.vue')
	},
	{
		path: `/ira`,
		name: 'ira-landing',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			await ira.getIraFeesAndContributionLimits();
			next();
		},
		component: () => import('@views/ira/general/ira-landing.vue')
	},
	{
		path: `/mtc`,
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'ira-landing' }
	},
	{
		path: `/IRA`,
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'ira-landing' }
	},
	{
		path: `/ira/signup`,
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'ira-start' }
	},
	{
		path: `/ira/email-saved`,
		name: 'ira-email-saved',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			next({ name: 'account-overview', replace: true });
			app.ADD_TOAST({
				type: 'success',
				message: 'We will email you when your Simple IRA account is eligible for transfer.'
			});
		},
		// revisit using redirect
		component: () => import('@views/ira/general/ira-landing.vue')
	},
	{
		path: `/ira/transfer/reminder/redirect`,
		name: 'ira-transfer-reminder-redirect',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (user.iraEntities.length > 0) {
				const completedIraId = getCompletedIraId();
				await updateIraEntityForRolloverRedirect(completedIraId);
				const redirect = completedIraId ? 'ira-add-funds-start' : 'ira-start';
				next({ name: redirect, replace: true });
			} else {
				next({ name: 'account-overview' });
			}
		},
		// revisit using redirect
		component: () => import('@views/ira/general/ira-landing.vue')
	}
];

export default iraGeneralRoutes;
