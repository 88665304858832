<template>
	<div v-if="showAlert" class="alert__inner body-md" :class="alertClass" role="status" data-test="base-alert">
		<BaseSvgIcon v-if="alertIcon" :name="alertIcon" height="24" width="24" class="alert__icon" />
		<img v-else-if="alertIconUrl" :src="alertIconUrl" alt="" class="alert__inner--img" />
		<div class="alert__content">
			<p
				v-if="props.alert.heading"
				class="alert-heading font-weight-bold"
				data-test="alert-heading"
				v-html="props.alert.heading"
			></p>
			<p v-for="(para, p) in props.alert.message" :key="p" :data-test="`alert-copy-${p}`" v-html="para"></p>
			<slot name="cta">
				<component
					:is="alertLink"
					v-if="alertLink"
					:link="props.alert.cta"
					:mixpanel-custom-properties="mixpanelCustomProperties"
					classes="alert-link body-sm mt-50"
				/>
			</slot>
		</div>
	</div>
</template>

<script setup lang="ts">
import { BaseAlertComponentType, BaseAlertType } from 'types/layout';
import BaseCtaLink from './_base-cta-link.vue';
import BaseLink from './_base-link.vue';
import { computed } from 'vue';
import { MixpanelContext } from 'types/analytics';
import type { Component as ComponentType } from 'vue';

interface Props {
	alert: BaseAlertComponentType;
	mixpanelCustomProperties?: MixpanelContext | null;
}

const props = withDefaults(defineProps<Props>(), {
	mixpanelCustomProperties: null
});

const showAlert = computed((): boolean => !!props.alert.heading || props.alert.message.length > 0);

const alertType = computed((): BaseAlertType => props.alert.type ?? 'message');

const alertClass = computed((): string => `alert__inner--${alertType.value}`);

const alertIcon = computed((): string => {
	switch (alertType.value) {
		case 'info':
			return 'info';
		case 'warning':
			return 'warning';
		case 'error':
			return 'error';
		case 'success':
			return 'success';
		case 'message':
		default:
			return props.alert?.icon ?? '';
	}
});

const alertIconUrl = computed((): string | undefined =>
	alertType.value === 'message' && props.alert?.iconUrl ? props.alert?.iconUrl : undefined
);

const alertLink = computed((): ComponentType | undefined => {
	if (props.alert.cta) {
		return alertType.value === 'message' ? BaseCtaLink : BaseLink;
	}
	return undefined;
});
</script>
