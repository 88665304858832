import { currency, percentage } from '@filters/shared-filters';
import {
	PortfolioBreakdownTableReturnsData,
	Return,
	ReturnsFormat,
	ReturnSummaryByTimePeriod,
	TimePeriodChip,
	ValueFormatType,
	YearlyReturnSummary
} from 'types/performance-portfolio';
import { AssetCard } from 'types/asset-details';
import big from 'big.js';
import { CompanyElement } from 'types/company-element';
import { PERFORMANCE_TIMEZONE } from '@constants/timezones';
import { ReturnsData } from 'types/performance';
import spacetime from 'spacetime';
import { usePerformancePortfolioStore } from '@stores/performance-portfolio';
import { user } from '@store/modules/user';

export function formattedPerformanceValue(
	value: string,
	valueType: ValueFormatType,
	hasSuffix = false,
	displayParensForNegative = false,
	currencyDecimals = 2,
	percentageDecimals = 1
): string {
	if (value !== null && value !== undefined && value !== '' && !isNaN(Number(value))) {
		const absValue = Math.abs(Number(value));

		if (valueType === 'currency') {
			if (absValue === 0 || big(value) > big('0')) {
				return currency(absValue, currencyDecimals);
			}

			return `(${currency(absValue, currencyDecimals)})`;
		}

		if (valueType === 'percentage') {
			if (absValue === 0 || big(value) > big('0')) {
				return `${percentage(absValue, hasSuffix, percentageDecimals)}`;
			}

			if (displayParensForNegative) {
				return `(${percentage(absValue, hasSuffix, percentageDecimals)})`;
			} else {
				return `${percentage(absValue, hasSuffix, percentageDecimals)}`;
			}
		}
	}

	return '';
}

export function getPerformanceReturnIndicator(value: string): string {
	if (value && big(value) > big('0')) {
		return 'performance-portfolio__positive-returns';
	} else if (value && big(value) < big('0')) {
		return 'performance-portfolio__negative-returns';
	} else {
		return 'performance-portfolio__neutral-returns';
	}
}

export function getReturnsColor(value: string): string {
	if (value && big(value).gt(big('0'))) {
		return 'text-color-green';
	} else if (value && big(value).lt(big('0'))) {
		return 'text-color-red';
	} else {
		return 'text-color-content-secondary';
	}
}

export function getFormattedReturnsData(
	data: Return,
	format: ReturnsFormat,
	overwriteNonAnnualizedReturns = false
): PortfolioBreakdownTableReturnsData {
	if (data.annualized === false && overwriteNonAnnualizedReturns && format === '%') {
		return {
			return: '-',
			indicatorClass: ''
		};
	}
	const valueType: ValueFormatType = format === '$' ? 'currency' : 'percentage';
	const returnValue = format === '$' ? data.amount : data.percentage;
	return {
		return: returnValue !== '-' ? formattedPerformanceValue(returnValue, valueType, true, true) : '-',
		indicatorClass: returnValue !== '-' ? getPerformanceReturnIndicator(returnValue) : ''
	};
}

export function generateReturnsData(
	returnSummary: ReturnSummaryByTimePeriod,
	numReturnYears: number,
	hideNonAnnualizedReturns = false
): Array<Return> {
	const performancePortfolioStore = usePerformancePortfolioStore();

	const currentYear = `${spacetime.now(PERFORMANCE_TIMEZONE).year()}`;
	const yearlyReturns = returnSummary.yearlyReturns;
	const allTimeReturns = returnSummary.allTimeReturns;

	if (performancePortfolioStore.showYtdReturns) {
		return generateYearReturns(yearlyReturns, currentYear, true);
	} else if (performancePortfolioStore.showQtdReturns) {
		const currentQuarter = `${spacetime.now(PERFORMANCE_TIMEZONE).quarter()}`;
		return generateQuarterReturns(yearlyReturns, currentYear, currentQuarter);
	} else if (performancePortfolioStore.showCustomYearReturns) {
		const customYear = performancePortfolioStore.selectedCustomYearAndQuarter?.year.toString();
		const isCurrentYear = customYear === currentYear;
		return generateYearReturns(yearlyReturns, customYear, isCurrentYear);
	} else if (performancePortfolioStore.showCustomQuarterReturns) {
		const customYear = performancePortfolioStore.selectedCustomYearAndQuarter?.year.toString();
		const customQuarter = performancePortfolioStore.selectedCustomYearAndQuarter?.quarter.toString();
		return generateQuarterReturns(yearlyReturns, customYear, customQuarter);
	}

	const allYearsReturns = yearlyReturns.map((year) => {
		return year.hadHoldings
			? { amount: year.amount, percentage: year.percentage }
			: { amount: '-', percentage: '-' };
	});

	const placeholderYearReturns: Array<Return> = [];
	const placeholderCount = numReturnYears - yearlyReturns.length;

	for (let i = 0; i < placeholderCount; i++) {
		placeholderYearReturns.push({ amount: '-', percentage: '-' });
	}

	return hideNonAnnualizedReturns
		? [...placeholderYearReturns, ...allYearsReturns]
		: [...placeholderYearReturns, ...allYearsReturns, allTimeReturns];
}

function generateYearReturns(
	yearlyReturns: Array<YearlyReturnSummary>,
	year: string | undefined,
	isCurrent: boolean
): Array<Return> {
	const yearReturns: Array<Return> = [];
	const yearReturnsSummary = yearlyReturns.find((data) => data.year === year);
	if (yearReturnsSummary) {
		const quarterlyReturns = yearReturnsSummary.quarterlyReturns;
		if (quarterlyReturns.firstQuarter) {
			yearReturns.push(buildQuarterReturn(quarterlyReturns.firstQuarter));
		}
		if (quarterlyReturns.secondQuarter) {
			yearReturns.push(buildQuarterReturn(quarterlyReturns.secondQuarter));
		}
		if (quarterlyReturns.thirdQuarter) {
			yearReturns.push(buildQuarterReturn(quarterlyReturns.thirdQuarter));
		}
		if (quarterlyReturns.fourthQuarter) {
			yearReturns.push(buildQuarterReturn(quarterlyReturns.fourthQuarter));
		}

		const yearReturn = yearReturnsSummary.hadHoldings
			? { amount: yearReturnsSummary.amount, percentage: yearReturnsSummary.percentage }
			: { amount: '-', percentage: '-' };
		yearReturns.push(yearReturn);
	} else if (isCurrent) {
		const currentQuarter = spacetime.now(PERFORMANCE_TIMEZONE).quarter();
		for (let i = 0; i < currentQuarter + 1; i++) {
			yearReturns.push({ amount: '-', percentage: '-' });
		}
	} else {
		for (let i = 0; i < 5; i++) {
			yearReturns.push({ amount: '-', percentage: '-' });
		}
	}

	return yearReturns;
}

function generateQuarterReturns(
	yearlyReturns: Array<YearlyReturnSummary>,
	year: string | undefined,
	quarter: string | undefined
): Array<Return> {
	const quarterReturns: Array<Return> = [];
	const yearReturnsSummary = yearlyReturns.find((data) => data.year === year);
	if (yearReturnsSummary) {
		const quarterlyReturns = yearReturnsSummary.quarterlyReturns;
		switch (quarter) {
			case '1':
				quarterReturns.push(buildQuarterReturn(quarterlyReturns.firstQuarter));
				break;
			case '2':
				quarterReturns.push(buildQuarterReturn(quarterlyReturns.secondQuarter));
				break;
			case '3':
				quarterReturns.push(buildQuarterReturn(quarterlyReturns.thirdQuarter));
				break;
			case '4':
				quarterReturns.push(buildQuarterReturn(quarterlyReturns.fourthQuarter));
				break;
			default:
				break;
		}
	} else {
		quarterReturns.push({ amount: '-', percentage: '-' });
	}

	return quarterReturns;
}

function buildQuarterReturn(quarterData: ReturnsData | undefined): Return {
	return {
		amount: quarterData?.hadHoldings ? quarterData?.returnAsCurrency ?? '-' : '-',
		percentage: quarterData?.hadHoldings ? quarterData?.returnAsPercentage ?? '-' : '-'
	};
}

export function filterByStrategy(element: AssetCard, filterMap: Record<string, Array<string>>): boolean {
	if (!filterMap.hasOwnProperty('strategy')) {
		return true;
	} else {
		return filterMap['strategy'].includes(element.strategy?.name ?? '');
	}
}

export function filterByAssetType(element: AssetCard, filterMap: Record<string, Array<string>>): boolean {
	if (!filterMap.hasOwnProperty('assetType')) {
		return true;
	} else {
		return filterMap['assetType'].includes(element.assetType);
	}
}

export function filterByAssetIndustry(element: AssetCard, filterMap: Record<string, Array<string>>): boolean {
	if (!filterMap.hasOwnProperty('industry')) {
		return true;
	} else {
		return filterMap['industry'].includes(element.industry?.name ?? '');
	}
}

export function filterByOfferingName(element: AssetCard, filterMap: Record<string, Array<string>>): boolean {
	if (!filterMap.hasOwnProperty('offeringName')) {
		return true;
	} else {
		return (element.reits ?? []).some((reit) => {
			return filterMap['offeringName'].includes(reit.name);
		});
	}
}

export function filterByIndustry(element: CompanyElement, filterMap: Record<string, Array<string>>): boolean {
	if (!filterMap.hasOwnProperty('industry')) {
		return true;
	} else {
		return filterMap['industry'].includes(element.companyInfo.industry?.name ?? '');
	}
}

export function filterByCompanyAssetType(element: CompanyElement, filterMap: Record<string, Array<string>>): boolean {
	if (!filterMap.hasOwnProperty('assetType')) {
		return true;
	} else {
		return filterMap['assetType'].includes(element.companyInfo.assetType);
	}
}

export function getValidYear(rawYear: string): number | undefined {
	return rawYear !== '' && !isNaN(Number(rawYear)) && !!rawYear.match(/\d{4}/) ? Number(rawYear) : undefined;
}

export function getValidQuarter(rawQuarter: string): number | undefined {
	if (rawQuarter.length === 1 && /[1-4]/.exec(rawQuarter)) {
		return Number(rawQuarter);
	} else if (rawQuarter.length === 2 && /[Qq]?[1-4]/.exec(rawQuarter)) {
		return Number(rawQuarter[1]);
	} else {
		return undefined;
	}
}

export function isCurrentTimePeriod({
	timeSelection,
	year,
	quarter = ''
}: {
	timeSelection: TimePeriodChip;
	year: number | string;
	quarter?: number | string;
}): boolean {
	const currentYear = spacetime.now(PERFORMANCE_TIMEZONE).year().toString();
	const currentQuarter = spacetime.now(PERFORMANCE_TIMEZONE).quarter().toString();

	if (timeSelection.value === 'Annually') {
		return year.toString() === currentYear;
	} else {
		return year.toString() === currentYear && quarter.toString() === currentQuarter;
	}
}

export function showAnnualizedReturn(isAnnualized = false) {
	const performancePortfolioStore = usePerformancePortfolioStore();
	// https://fundrise.atlassian.net/browse/WWW-4050
	const hideAnnualizedReturnDateCutoff = '07/01/2023';
	const firstInvestmentDate = user.user?.entityDetails?.firstInvestmentDate;

	if (performancePortfolioStore.hideAnnualizedReturnFromNewInvestors) {
		return firstInvestmentDate
			? isAnnualized && spacetime(firstInvestmentDate).isBefore(spacetime(hideAnnualizedReturnDateCutoff))
			: false;
	} else {
		return isAnnualized;
	}
}
