<template>
	<router-link v-if="useRouterLink" :to="routerTo" custom>
		<a
			ref="bLink"
			:href="href"
			:class="[props.classes, { 'router-link-active': isActive, 'router-link-exact-active': isExactActive }]"
			:target="target"
			data-test="router-link"
			v-bind="$attrs"
			@click="handleRouterLinkTracking($event, navigate)"
		>
			<slot>{{ props.link.text }}</slot>
		</a>
	</router-link>

	<a
		v-else
		ref="bLink"
		:href="hrefValue"
		:class="props.classes"
		:target="target"
		:rel="props.link.rel || undefined"
		data-test="href-link"
		v-bind="$attrs"
		@click="handleTracking($event)"
		><slot>{{ props.link.text }}</slot></a
	>
</template>

<script lang="ts">
export default {
	name: 'BaseLink'
};
</script>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { isAuthenticated, isWebview } from '@utils/composables';
import { RouteLocationRaw, useLink, useRouter } from 'vue-router';
import { appBasePath } from '@constants';
import { Link } from 'types/layout';
import { MixpanelContext } from 'types/analytics';
import { trackMixpanelClick } from '@utils/analytics';
import { useAnalyticsProperties } from '@utils/composables/use-analytics-properties';

interface Props {
	link: Link;
	classes?: string;
	clickText?: string;
	mixpanelElement?: string;
	mixpanelTarget?: string;
	mixpanelContext?: string;
	mixpanelCustomProperties?: MixpanelContext;
}

interface Emits {
	(e: 'click', value: Event): void;
}

const props = withDefaults(defineProps<Props>(), {
	classes: undefined,
	clickText: undefined,
	mixpanelElement: 'Link',
	mixpanelTarget: undefined,
	mixpanelContext: undefined,
	mixpanelCustomProperties: undefined
});

const emits = defineEmits<Emits>();

const router = useRouter();
const { getActionProperties } = useAnalyticsProperties();
const routerTo = computed((): RouteLocationRaw => {
	return {
		name: props.link.router,
		params: props.link.params,
		query: props.link.query,
		hash: props.link.hash,
		state: props.link.state
	};
});
const { navigate, href, isActive, isExactActive, route } = useLink({
	to: routerTo.value
});

const bLink = ref<HTMLElement | null>(null);

const useRouterLink = computed((): boolean => {
	if (props.link.forceHrefLink) {
		return false;
	} else if (isWebview.value) {
		return isAuthenticated.value && !!props.link.router;
	}
	return !!props.link.router;
});

const hrefValue = computed((): string => {
	if (props.link.router) {
		return route.value.fullPath;
	} else {
		let returnedHref = '';
		if (props.link.href) {
			const uris = ['http://', 'https://', 'mailto:'];
			if (uris.some((uri) => props.link.href?.startsWith(uri))) {
				return props.link.href;
			}

			returnedHref = appBasePath + props.link.href;
		}

		return returnedHref;
	}
});

const target = computed((): string => {
	if (!isWebview.value) {
		return props.link.target || '_self';
	} else {
		return '_self';
	}
});

const isFullPathExactActive = computed((): boolean => {
	return router.currentRoute.value.fullPath === route.value.fullPath;
});

const isHashOrQueryNavigation = computed((): boolean => {
	return isExactActive.value && (!!route.value.hash || !!route.value.query);
});

function handleRouterLinkTracking($event: MouseEvent, navigate: (event: MouseEvent) => void): void {
	if (isFullPathExactActive.value && !isHashOrQueryNavigation.value) {
		$event.preventDefault();
		return;
	}

	emits('click', $event);
	if (bLink.value) {
		trackMixpanelClick(
			getActionProperties({
				'Action Element': props.mixpanelElement ?? '',
				'Action Target': props.mixpanelTarget ?? props.clickText,
				'Action Context': props.mixpanelContext,
				Link: hrefValue.value,
				element: bLink.value,
				customProperties: { ...props.mixpanelCustomProperties }
			})
		);
	}

	if (navigate) {
		navigate($event);
	}
}

function handleTracking($event: Event): void {
	emits('click', $event);

	if (bLink.value) {
		trackMixpanelClick(
			getActionProperties({
				'Action Element': props.mixpanelElement ?? '',
				'Action Target': props.mixpanelTarget ?? props.clickText,
				'Action Context': props.mixpanelContext,
				Link: hrefValue.value,
				element: bLink.value,
				customProperties: { ...props.mixpanelCustomProperties }
			}),
			true
		);
	}
}
</script>
