export const PERFORMANCE_TIMEZONE = 'America/New_York';

export const timezonesInEU: Array<string> = [
	// Western European standard time
	'Europe/Amsterdam',
	'Europe/Berlin',
	'Europe/Busingen',
	'Europe/Luxembourg',
	'Europe/Malta',
	'Europe/Rome',
	'Europe/Vienna',
	'Europe/London',
	'Europe/Belfast',

	// Central European standard time
	'Europe/Bratislava',
	'Europe/Budapest',
	'Europe/Ljubljana',
	'Europe/Prague',
	'Europe/Warsaw',
	'Europe/Zagreb',
	'Europe/Dublin',
	'Europe/Lisbon',
	'Atlantic/Azores',
	'Europe/Stockholm',
	'Europe/Belgrade',

	// Romance standard time
	'Europe/Brussels',
	'Europe/Copenhagen',
	'Europe/Madrid',
	'Europe/Paris',

	// Eastern European standard time
	'Europe/Athens',
	'Europe/Bucharest',
	'Europe/Helsinki',
	'Asia/Nicosia',
	'Europe/Nicosia',
	'Europe/Riga',
	'Europe/Sofia',
	'Europe/Tallinn',
	'Europe/Vilnius',

	// Misc
	'Asia/Famagusta',
	'Atlantic/Madeira',
	'Africa/Ceuta',
	'Atlantic/Canary'
];
