import { AnalyticsReactiveContext, AnalyticsReactiveString } from 'types/analytics';
import type { InjectionKey } from 'vue';

export const ActionElementKey: InjectionKey<AnalyticsReactiveString> = Symbol('Action Element');
export const ActionContextKey: InjectionKey<AnalyticsReactiveString> = Symbol('Action Context');
export const ActionTargetKey: InjectionKey<AnalyticsReactiveString> = Symbol('Action Target');
export const ViewNameKey: InjectionKey<AnalyticsReactiveString> = Symbol('View Name');
export const ViewTitleKey: InjectionKey<AnalyticsReactiveString> = Symbol('View Title');
export const ViewContentKey: InjectionKey<AnalyticsReactiveString> = Symbol('View Content');
export const ViewIdKey: InjectionKey<AnalyticsReactiveString> = Symbol('View ID');
export const CustomPropertiesKey: InjectionKey<AnalyticsReactiveContext> = Symbol('Custom Properties');
