<template>
	<div class="news-feed-card-content-dividend display-flex">
		<div
			data-test="dividend-amount"
			class="news-feed-card-content-dividend-amount pr-100 mr-100"
			:style="displayPreferece ? `border-right: 2px solid rgb(144, 146, 148, ${opacity});` : ''"
		>
			<div class="overline eta">Amount</div>
			<div>
				{{ amount }}
			</div>
		</div>
		<div v-if="displayPreferece" class="pr-100 mr-100 ellipsis" data-test="dividend-preference">
			<div class="overline eta ellipsis">Preference</div>
			<div class="ellipsis">
				{{ preference }}
			</div>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'NewsFeedCardContentDividend'
};
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { NewsFeedItem } from 'types/account';

interface Props {
	item: NewsFeedItem;
}
const props = defineProps<Props>();

const opacity = computed((): string => {
	return props.item.pinned ? '0.5' : '0.2';
});

const amount = computed((): string => {
	return props.item.bodyText ?? '';
});

const displayPreferece = computed((): boolean => {
	return props.item.type !== 'REIT_DIVIDEND';
});

const preference = computed((): string => {
	if (props.item.type === 'DIVIDEND_DISBURSEMENT') {
		return 'Distributed';
	} else {
		return 'Reinvested';
	}
});
</script>

<style lang="scss" scoped>
.news-feed-card-content-dividend {
	margin-top: 1rem;

	&-amount {
		display: none;
	}

	@container (min-width: 550px) {
		margin-top: 1.5rem;

		&-amount {
			display: block;
		}
	}
}
</style>
