<template>
	<div :class="['page', bgColor, 'page-layout-auth']">
		<div class="page__main">
			<slot v-if="!isWebview && !isNestedView" name="header">
				<AppHeader
					class="page__header"
					:transparent-header="false"
					:transparent-header-dark-text="false"
					data-context="header"
				/>
			</slot>

			<main id="main-content" class="page__content" data-context="main">
				<header
					v-if="!hideHero"
					:class="[
						'template-header-hero pt-150 pt-200-md',
						templateHeroColor,
						{ 'template-header-hero__no-overlap': !overlapContent },
						{ 'pt-200-md': !isNestedView, 'pt-150': !isNestedView }
					]"
				>
					<div :class="{ container: !isNestedView }">
						<div
							class="display-flex-md align-items-flex-end justify-content-space-between"
							:class="templateHeaderWidth"
						>
							<div class="template-header-left">
								<slot name="templateHeaderLeft" />
							</div>
							<div v-if="hasHeaderRightSlot" class="template-header-right mt-150-smo text-align-right-md">
								<slot name="templateHeaderRight" />
							</div>
						</div>
					</div>
				</header>
				<div
					:class="[
						'template-auth-content  pb-300',
						{
							'page__content--full-height': true,
							container: !isNestedView,
							'template-auth-content__no-overlap': !overlapContent
						}
					]"
				>
					<div :class="contentWidthClasses"><slot /></div>
				</div>
			</main>
		</div>
		<div v-if="!isWebview && !isNestedView" id="footer">
			<slot name="footer">
				<FooterBasic data-context="footer" />
			</slot>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';
import AppHeader from '@components/header/app-header.vue';
import FooterBasic from '@components/footer/footer-basic.vue';
import { isWebview } from '@utils/composables';

interface Props {
	centeredLayout?: boolean;
	fullWidth?: boolean;
	narrowContent?: boolean;
	isNestedView?: boolean;
	overlapContent?: boolean;
	heroColor?: string;
	bgColor?: string;
	hideHero?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	centeredLayout: false,
	fullWidth: false,
	narrowContent: false,
	isNestedView: false,
	overlapContent: true,
	heroColor: 'bg-color-bg-secondary',
	bgColor: 'bg-color-white',
	hideHero: false
});

const slots = useSlots();

const templateHeroColor = computed((): string => {
	if (props.isNestedView) {
		return '';
	}
	return props.heroColor;
});

const templateHeaderWidth = computed((): string => {
	let classes = 'col-12';

	if (props.centeredLayout && !props.fullWidth) {
		const width = props.narrowContent ? ' col-6-lg' : ' col-8-lg';

		classes += ' ml-auto mr-auto col-10-md';
		classes += width;
	}

	return classes;
});

const contentWidthClasses = computed((): string => {
	if (props.fullWidth) {
		return 'col-12';
	} else {
		let classes = 'col-10-md';

		if (props.narrowContent) {
			classes += ' col-6-lg';
		} else {
			classes += ' col-8-lg';
		}

		if (props.centeredLayout) {
			classes += ' ml-auto mr-auto';
		}

		return classes;
	}
});

const hasHeaderRightSlot = computed((): boolean => !!slots.templateHeaderRight);
</script>

<style lang="scss" scoped>
@use '../styles/utilities/respond-to.scss' as *;

.template-header-hero {
	padding-bottom: 2rem;

	@include respond-to(md) {
		padding-bottom: 6rem;
	}

	@include respond-to(lg) {
		padding-bottom: 7.5rem;
	}

	.container {
		position: relative;
	}

	&__no-overlap {
		padding-bottom: 2rem;
	}
}

.template-auth-content {
	position: relative;
	margin-top: 1.5rem;

	@include respond-to(md) {
		margin-top: -4rem;
	}

	@include respond-to(lg) {
		margin-top: -5.5rem;
	}

	&__no-overlap {
		margin-top: 1.5rem;
	}
}
</style>
