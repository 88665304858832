import { computed, ref } from 'vue';
import { getIpoHypotheticalInvestment, getIpoMetadata } from '@api/ipo';
import { IpoHypotheticalInvestment, IpoMetadata } from 'types/ipo';
import big from 'big.js';
import { CurrentPrincipal } from 'types/portfolio';
import { defineStore } from 'pinia';
import { getCurrentPrincipal } from '@api/investment-entity';

export const useIpoStore = defineStore('ipo', () => {
	const currentPrincipal = ref<CurrentPrincipal | null>(null);
	const ipoHypotheticalInvestment = ref<IpoHypotheticalInvestment | null>(null);
	const ipoMetadata = ref<IpoMetadata | null>(null);

	const isLeadUpEligible = computed((): boolean => {
		return ipoMetadata.value?.ipoEligibility.ipoPhaseAndEligibilityEnum === 'LEAD_UP_ELIGIBLE';
	});

	const hasPendingInvestmentInLeadUp = computed((): boolean => {
		return (
			isLeadUpEligible.value &&
			currentPrincipal.value !== null &&
			big(currentPrincipal.value.pendingPrincipal).gt(0)
		);
	});

	async function fetchAndStoreIpoHypotheticalInvestment(pendingPrincipal: string): Promise<void> {
		ipoHypotheticalInvestment.value = await getIpoHypotheticalInvestment(pendingPrincipal);
	}

	async function fetchAndStoreMetadata(): Promise<void> {
		ipoMetadata.value = await getIpoMetadata();
	}

	async function fetchAndStoreCurrentPrincipal(): Promise<void> {
		currentPrincipal.value = await getCurrentPrincipal();

		if (hasPendingInvestmentInLeadUp.value || isLeadUpEligible.value) {
			await fetchAndStoreIpoHypotheticalInvestment(currentPrincipal.value.pendingPrincipal);
		}
	}

	function $reset(): void {
		currentPrincipal.value = null;
		ipoHypotheticalInvestment.value = null;
		ipoMetadata.value = null;
	}

	return {
		currentPrincipal,
		hasPendingInvestmentInLeadUp,
		ipoHypotheticalInvestment,
		ipoMetadata,
		isLeadUpEligible,
		fetchAndStoreCurrentPrincipal,
		fetchAndStoreIpoHypotheticalInvestment,
		fetchAndStoreMetadata,
		$reset
	};
});
