import { investmentEntityRequest } from '@utils/request';
import { NAVReinvestmentPreference } from 'types/nav-reinvestment';

export const getNAVReinvestmentPreference = async (): Promise<NAVReinvestmentPreference> => {
	const response = await investmentEntityRequest.get(`/nav-reinvestment-preference`);
	return response.data.navReinvestmentPreference;
};

export const updateNAVReinvestmentPreference = async (
	navReinvestmentPreference: NAVReinvestmentPreference
): Promise<void> => {
	await investmentEntityRequest.post(`/nav-reinvestment-preference`, {
		navReinvestmentPreference
	});
};
