<template>
	<div v-if="isSimpleHeaderLink">
		<BaseLink
			:link="{ router: (link as Link).router }"
			classes="display-flex align-items-center mobile-nav__item-link bt"
		>
			<span>
				{{ (link as Link).text }}
			</span>
			<Badge
				v-if="isTaggedLink"
				class="ml-50"
				:text="(link as DropdownLinks).badgeText"
				background="slate"
				size="medium"
			/>
		</BaseLink>
	</div>
	<div v-else>
		<MobileNavAccordion :start-opened="(link as DropdownLinks).startOpened">
			<template #toggle>{{ (link as DropdownLinks).dropdown }}</template>
			<template #drawer>
				<div
					v-for="(dropdownLink, dropdownLinkIndex) in (link as DropdownLinks).links"
					:key="dropdownLinkIndex"
					class="link-container"
				>
					<BaseLink :link="dropdownLink.link" classes="mobile-nav__sub-item-link">
						<p>{{ dropdownLink.link.text }}</p>
					</BaseLink>
				</div>
			</template>
		</MobileNavAccordion>
	</div>
</template>

<script setup lang="ts">
import { DropdownLinks, NavigationLink } from 'types/header';
import Badge from '@components/badges/badge-component.vue';
import { computed } from 'vue';
import { Link } from 'types/layout';
import MobileNavAccordion from '@components/header/navigation/mobile/mobile-nav-accordion.vue';
interface Props {
	link: NavigationLink;
}
const props = defineProps<Props>();

const isSimpleHeaderLink = computed((): boolean => 'text' in props.link);

const isTaggedLink = computed((): boolean => {
	return 'badgeText' in props.link;
});
</script>

<style lang="scss" scoped>
.link-container {
	padding: 0.5rem 1rem;

	&:first-of-type {
		padding-top: 0;
	}
}
</style>
