<template>
	<Badge :text="text" :size="size" background="pro" :icon-name="includeIcon ? 'crown' : ''" />
</template>

<script setup lang="ts">
import Badge from '@components/badges/badge-component.vue';
import { BadgeSize } from 'types/layout';

interface Props {
	text?: string;
	size?: BadgeSize;
	includeIcon?: boolean;
}

withDefaults(defineProps<Props>(), {
	text: undefined,
	size: 'large',
	includeIcon: true
});
</script>
