<template>
	<div ref="target" class="module-md progress-module position-relative">
		<div class="module-section p-100">
			<div v-if="loading || isError">
				<div v-if="isError" class="text-color-content-secondary" v-html="moduleLoadingErrorMessage" />
				<ModularDashboardPlaceholderLines v-else :show-header="true" :line-count="0" :use-animation="loading" />

				<ModularDashboardPlaceholderBlock
					:show-error-icon="isError"
					class="width-100-smo py-100"
					error-icon-size="small"
					:use-animation="loading"
				/>

				<div v-if="isError" class="pt-50 text-align-center">
					<ModularDashboardRetryButton @retry-clicked="loadModule()" />
				</div>
				<ModularDashboardPlaceholderLines v-else :line-count="2" :show-footer="true" :use-animation="true" />
			</div>
			<section v-else class="p-50">
				<div
					v-if="!progressModuleDetails?.dismissible && progressModuleDetails?.iconUrl"
					class="img-container position-absolute"
				>
					<img class="icon-img" :src="progressModuleDetails.iconUrl" alt="" />
				</div>

				<div class="display-flex justify-content-space-between align-items-center">
					<h2 class="body-md-md font-weight-bold-md heading-6-smo text-color-content-primary"
						>{{ progressModuleDetails?.title }}
					</h2>
					<BaseButton
						v-if="progressModuleDetails?.dismissible"
						:classes="`cta-link ${dismissClass}`"
						mixpanel-element="Module"
						mixpanel-target="Dismiss"
						:mixpanel-context="progressModuleDetails?.title"
						@click.prevent="dismiss()"
					>
						<BaseSvgIcon name="close-light" height="16" width="16" />
					</BaseButton>
				</div>
				<p class="mt-50">{{ progressModuleDetails?.subText }}</p>
				<ProgressMeter
					:bar-class="barClass"
					:progress-percent="progressPercentageString"
					:is-animated="true"
					class="mt-100"
					height="8"
				>
					<template #left-side>
						<span class="text-color-content-secondary body-xs">
							{{ progressModuleDetails?.leftProgressText ?? '' }}
						</span>
					</template>
					<template #right-side>
						<span class="text-color-content-secondary body-xs">
							{{ progressModuleDetails?.rightProgressText ?? '' }}
						</span>
					</template>
				</ProgressMeter>
				<BaseButtonGroup>
					<template #primary>
						<BaseLink
							v-if="investLink"
							:mixpanel-context="progressModuleDetails?.title"
							class="button button-mini button-outline"
							:link="investLink"
						/>
					</template>
				</BaseButtonGroup>
			</section>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { getLinkFromRoute } from '@utils/request';
import { Link } from 'types/layout';
import { modularDashboard } from '@store/modules/modular-dashboard';
import { ModularDashboardModule } from 'types/modular-dashboard';
import ModularDashboardPlaceholderBlock from '@components/account/modular-dashboard/modular-dashboard-placeholder-block.vue';
import ModularDashboardPlaceholderLines from '@components/account/modular-dashboard/modular-dashboard-placeholder-lines.vue';
import { ModularDashboardProgressModule } from 'types/modular-dashboard/custom';
import ModularDashboardRetryButton from '@components/account/modular-dashboard/modular-dashboard-retry-button.vue';
import { moduleLoadingErrorMessage } from '@constants/modular-dashboard';
import ProgressMeter from '@components/investment-goals/progress-meter.vue';
import { trackMixpanelScrollIntoView } from '@utils/analytics';
import { useRouter } from 'vue-router';

interface Props {
	module: ModularDashboardModule;
}

interface Emits {
	(e: 'dismiss'): void;
}

const props = defineProps<Props>();

const router = useRouter();

const emits = defineEmits<Emits>();

const isError = ref(false);
const loading = ref(true);
const target = ref<HTMLElement | null>(null);

const investLink = computed((): Link | undefined => {
	if (!progressModuleDetails.value?.cta) {
		return;
	} else {
		return getLinkFromRoute(
			router,
			`${progressModuleDetails.value?.cta?.targetUrl}?useDefaultAmount=true`,
			progressModuleDetails.value?.cta?.text ?? ''
		);
	}
});

const barClass = computed((): string => {
	if (progressPercentage.value >= 100) {
		return 'bg-color-success';
	} else {
		return 'bg-color-data-secondary-dark';
	}
});

const dismissClass = computed((): string => {
	return progressModuleDetails.value?.dismissButtonStyle === 'LIGHT' ? 'text-color-white' : 'dark';
});

const progressModuleDetails = computed((): ModularDashboardProgressModule | null => {
	return modularDashboard.progressModuleDetails;
});

const progressPercentage = computed((): number => Number(progressModuleDetails.value?.percentage) * 100);

const progressPercentageString = computed((): string => progressPercentage.value.toString());

watch(
	() => progressModuleDetails.value,
	(newModuleDetails, oldModuleDetails) => {
		if (oldModuleDetails === null && !!newModuleDetails && target.value) {
			trackMixpanelScrollIntoView(target.value, {
				'View Title': props.module.name,
				'View ID': props.module.name,
				'View Content': newModuleDetails.subText
			});
		}
	},
	{ immediate: true }
);

function dismiss(): void {
	emits('dismiss');
}

async function loadModule(): Promise<void> {
	loading.value = true;
	isError.value = false;

	try {
		await modularDashboard.storeProgressModuleDetails();
	} catch {
		isError.value = true;
	} finally {
		loading.value = false;
	}
}

async function setup(): Promise<void> {
	await loadModule();
}

setup();
</script>
<style lang="scss" scoped>
@use '../../../../../styles/constants/colors' as *;
@use '../../../../../styles/utilities/respond-to.scss' as *;

.progress-module {
	box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);

	@include respond-to(smo) {
		background: #fff;
		border-bottom: 1px solid gray(20);
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
	}

	.button-group {
		margin-top: 1rem;
	}
}

.img-container {
	top: 0;
	right: 0;
	background-color: gold(90);
	border-radius: 0 0 0.25rem 0.25rem;
	padding: 15px 6px 8px 6px;
	margin: 0 16px 16px 7px;

	.icon-img {
		width: 24px;
		height: 24px;
	}
}

.bg-color-success {
	background-color: map-get($validation-colors, 'success');
}

.dismiss-button-container {
	top: 0.8rem;
	right: 1rem;
}
</style>
