<template>
	<div v-if="loading || isError" class="module">
		<div class="display-flex flex-direction-column-smo gap-50">
			<ModularDashboardPlaceholderBlock
				class="flex-1"
				classes="height-100"
				:show-error-icon="isError"
				:use-animation="loading"
			/>
			<div class="px-100 py-150 flex-1">
				<ModularDashboardPlaceholderLines
					:placehold="loading"
					:show-header="true"
					:show-footer="true"
					:use-animation="loading"
				>
					<p class="subheading text-color-content-secondary">Oops! We've hit a snag.</p>
					<p class="text-color-content-secondary">Please try again.</p>
					<ModularDashboardRetryButton class="mt-300" @retry-clicked="loadModule()" />
				</ModularDashboardPlaceholderLines>
			</div>
		</div>
	</div>
	<div v-else class="mx-100-smo">
		<div v-if="newsfeedItems?.length" data-test="newsfeed-items">
			<NewsFeedCard
				v-for="(item, index) in newsfeedItems"
				:key="index"
				:is-pinned-update="index === 0 && item.pinned"
				:item="item"
			/>
		</div>
		<div v-else class="module mt-100" data-test="newsfeed-items-placeholder">
			<div class="module-section">
				<AccountModulePlaceholder
					title="Nothing in your news feed yet"
					message="You can look forward to regular updates about the projects in your portfolio, dividends,
						and more."
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'NewsfeedModule'
};
</script>

<script setup lang="ts">
import { computed, ref } from 'vue';
import AccountModulePlaceholder from '@components/account/account-module-placeholder.vue';
import { modularDashboard } from '@store/modules/modular-dashboard';
import ModularDashboardPlaceholderBlock from '@components/account/modular-dashboard/modular-dashboard-placeholder-block.vue';
import ModularDashboardPlaceholderLines from '@components/account/modular-dashboard/modular-dashboard-placeholder-lines.vue';
import ModularDashboardRetryButton from '@components/account/modular-dashboard/modular-dashboard-retry-button.vue';
import NewsFeedCard from '@components/account/news-feed-card.vue';
import { NewsFeedItem } from 'types/account';

const loading = ref(true);
const isError = ref(false);

const newsfeedItems = computed((): Array<NewsFeedItem> | null => {
	return modularDashboard.newsfeedModule;
});

async function loadModule(): Promise<void> {
	loading.value = true;
	isError.value = false;
	try {
		await modularDashboard.storeNewsfeedModuleData();
	} catch {
		isError.value = true;
	} finally {
		loading.value = false;
	}
}

async function setup(): Promise<void> {
	await loadModule();
}

setup();
</script>
<style lang="scss" scoped>
.module {
	min-width: 0;
	overflow: hidden;
}
</style>
