import { MicroFeedbackFieldData, MicroFeedbackType } from 'types/micro-feedback';

export const microFeedbackData: Record<MicroFeedbackType, MicroFeedbackFieldData> = {
	EMAIL_COMMUNICATION: {
		subHeadline: 'What did you think of this email?',
		backButtonLink: {
			router: 'account-overview',
			text: 'Continue to dashboard'
		},
		redirectRoute: 'account-overview'
	},
	HELP_CENTER_ARTICLE_VOTE: {
		subHeadline: 'What could make it more helpful?',
		backButtonLink: {
			router: 'help-center-landing',
			text: 'Back to help center'
		},
		redirectRoute: 'help-center-landing'
	},
	HISTORICAL_RETURNS_MODULE_MODAL: {
		subHeadline: 'What did you think of this content?',
		backButtonLink: {
			router: 'account-overview',
			text: 'Continue to dashboard'
		},
		redirectRoute: 'account-overview'
	},
	INVESTOR_GOAL_DELETED: {
		subHeadline: 'Why did you delete your goal',
		backButtonLink: {
			router: 'account-overview',
			text: 'Continue to dashboard'
		},
		redirectRoute: 'account-overview'
	},
	INVESTOR_UPDATE: {
		subHeadline: 'What did you think of this investor update?',
		backButtonLink: {
			router: 'account-overview',
			text: 'Continue to dashboard'
		},
		redirectRoute: 'account-overview'
	},
	INVITATION_SENT: {
		subHeadline: 'What was the primary reason you invited someone to join Fundrise?',
		backButtonLink: {
			router: 'account-overview',
			text: 'Continue to dashboard'
		},
		redirectRoute: 'account-overview'
	},
	IRA_INITIAL_INVESTMENT: {
		subHeadline: 'How was your experience opening an IRA with Fundrise?',
		backButtonLink: {
			router: 'account-overview',
			text: 'Continue to dashboard'
		},
		redirectRoute: 'account-overview'
	},
	LIQUIDATION_REQUEST_CANCELLED: {
		subHeadline: 'Why did you cancel your redemption request?',
		backButtonLink: {
			router: 'account-transactions',
			text: 'Continue to transactions'
		},
		redirectRoute: 'account-transactions'
	},
	PERFORMANCE_TRACK_RECORD_MODULE_MODAL: {
		subHeadline: 'What did you think of this content?',
		backButtonLink: {
			router: 'account-overview',
			text: 'Continue to dashboard'
		},
		redirectRoute: 'account-overview'
	},
	RECURRING_INVESTMENT_DISABLED: {
		subHeadline: 'Why did you disable Auto-Invest?',
		backButtonLink: {
			router: 'account-overview',
			text: 'Continue to dashboard'
		},
		redirectRoute: 'account-overview'
	},
	RECURRING_INVESTMENT_PAUSED: {
		subHeadline: 'Why did you pause Auto-Invest?',
		backButtonLink: {
			router: 'account-overview',
			text: 'Continue to dashboard'
		},
		redirectRoute: 'account-overview'
	},
	REIT_INVESTMENT_CANCELLED: {
		subHeadline: 'Why did you cancel your order?',
		backButtonLink: {
			router: 'account-transactions',
			text: 'Continue to transactions'
		},
		redirectRoute: 'account-transactions'
	},
	REPEAT_IRA_INVESTMENT: {
		subHeadline: 'How was your experience investing through your IRA?',
		backButtonLink: {
			router: 'account-overview',
			text: 'Continue to dashboard'
		},
		redirectRoute: 'account-overview'
	},
	REPEAT_REIT_INVESTMENT: {
		subHeadline: 'What prompted you to invest with us today?',
		backButtonLink: {
			router: 'account-overview',
			text: 'Continue to dashboard'
		},
		redirectRoute: 'account-overview'
	},
	SIGNUP_DROPOFF: {
		subHeadline: 'Why did you decide not to invest with us?',
		backButtonLink: {
			router: 'account-overview',
			text: 'Continue to dashboard'
		},
		redirectRoute: 'account-overview'
	},
	EDUCATION_ARTICLE: {
		subHeadline: 'What did you think of this content?',
		backButtonLink: {
			router: 'account-overview',
			text: 'Continue to dashboard'
		},
		redirectRoute: 'account-overview'
	},
	ASSET_DETAIL_PAGE: {
		subHeadline: '',
		backButtonLink: {
			router: '',
			text: ''
		},
		redirectRoute: ''
	}
};
