import { apiRequest, apiSecureRequest } from '@utils/request';
import { app } from '@store/modules/app';
import { MixpanelIdDto } from 'types/analytics';

export async function updateMixpanelId(dto: MixpanelIdDto): Promise<void> {
	if (app.isAuthenticated) {
		await apiSecureRequest.post(`/mixpanel/update-id`, dto);
	} else {
		await apiRequest.post(`/mixpanel/update-id`, dto);
	}
}
