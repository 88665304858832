<template>
	<div>
		<div v-if="props.placehold" class="display-flex flex-direction-column gap-100">
			<div
				v-for="(section, sectionIndex) in sectionArray"
				:key="sectionIndex"
				class="display-flex flex-direction-column gap-50"
			>
				<div v-if="props.showHeader">
					<div
						:class="[
							'placeholder-line placeholder-line__header',
							{
								'placeholder-line__reversed': isDarkTheme,
								'placeholder-line__animated': props.useAnimation
							}
						]"
					/>
				</div>
				<div v-if="lineArray.length" class="display-flex flex-direction-column gap-25">
					<div
						v-for="(line, lineIndex) in lineArray"
						:key="lineIndex"
						:class="[
							'placeholder-line',
							{
								'placeholder-line__reversed': isDarkTheme,
								'placeholder-line__large': props.largeLines,
								'placeholder-line__animated': props.useAnimation
							}
						]"
					/>
				</div>
				<div v-if="props.showFooter" class="display-flex justify-content-flex-end">
					<div
						:class="[
							'placeholder-line placeholder-line__footer',
							{
								'placeholder-line__reversed': isDarkTheme,
								'placeholder-line__animated': props.useAnimation
							}
						]"
					/>
				</div>
			</div>
		</div>
		<slot v-else />
	</div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

interface Props {
	placehold?: boolean;
	theme?: 'light' | 'dark';
	lineCount?: number;
	sectionCount?: number;
	showHeader?: boolean;
	showFooter?: boolean;
	largeLines?: boolean;
	useAnimation?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	placehold: true,
	theme: 'light',
	lineCount: 4,
	sectionCount: 1,
	showHeader: false,
	showFooter: false,
	largeLines: false,
	useAnimation: false
});

const isDarkTheme = computed((): boolean => props.theme === 'dark');

const sectionArray = computed((): Array<number> => new Array(props.sectionCount));

const lineArray = computed((): Array<number> => new Array(props.lineCount));
</script>
<style lang="scss" scoped>
@use '../../../styles/constants/_colors.scss' as *;

.placeholder-line {
	height: 15px;
	background-color: token('bg-tertiary');

	&__reversed {
		background-color: gray(80);
	}

	&__header {
		width: 196px;
		max-width: 100%;
	}

	&__footer {
		width: 130px;
		max-width: 100%;
	}

	&__large {
		height: 30px;
	}

	&__animated {
		animation: placeholder 1.5s infinite linear;
	}
}

@keyframes placeholder {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.25;
	}

	100% {
		opacity: 1;
	}
}
</style>
