import {
	AccountBreakdownPeriod,
	AnnualAccountBreakdown,
	GroupedReturnsByQuarter,
	GroupedReturnsByYear,
	Performance,
	PerformanceAccountValueTableHolder,
	PerformanceDataTableHolder,
	ReitReturnsByQuarter,
	ReitReturnsByYear,
	ReturnsByQuarter,
	ReturnsByYear,
	ReturnsData
} from 'types/performance';
import { currency, percentage } from '@filters/shared-filters';
import { DataTableItem, DataTableItemRow, DataTableItemRowReit } from 'types/data-table';

function phaseSortOrder(phaseLabel: string): number {
	switch (phaseLabel) {
		case 'Operating':
			return 1;
		case 'Stabilizing':
			return 2;
		case 'Ramping up':
			return 3;
		case 'Merged':
			return 4;
		default:
			return 0;
	}
}

function reitRowComparator(a: DataTableItemRowReit, b: DataTableItemRowReit): number {
	return phaseSortOrder(a.phaseLabel) - phaseSortOrder(b.phaseLabel) || a.rowName.localeCompare(b.rowName);
}

export const getBlankReturnsByYear = (returnsByYear: ReturnsByYear[]): ReturnsByYear[] => {
	const blanks: ReturnsByYear[] = returnsByYear.map((yearReturns) => {
		return {
			year: yearReturns.year,
			returns: {
				returnAsCurrency: '',
				returnAsPercentage: '',
				currentPeriod: false
			}
		};
	});

	return blanks;
};

export const buildYearRow = (byYear: GroupedReturnsByYear | undefined, sliceIndex = 0): PerformanceDataTableHolder => {
	const currencyRowItems: Array<DataTableItemRowReit> = [];
	const percentageRowItems: Array<DataTableItemRowReit> = [];
	let currencyTotals: Array<DataTableItem> = [];
	let percentageTotals: Array<DataTableItem> = [];
	const blanks: Array<DataTableItem> = [];

	if (!byYear) {
		return {
			reitRows: {
				currency: currencyRowItems,
				percentage: percentageRowItems
			},
			totalRows: {
				currency: {
					id: 'currencyTotals',
					rowName: 'Subtotal',
					fields: currencyTotals
				},
				percentage: {
					id: 'percentageTotals',
					rowName: 'Weighted average',
					fields: percentageTotals
				}
			}
		};
	}

	byYear.returnsByYear.forEach((returnByYear: ReturnsByYear) => {
		currencyTotals.push({
			columnName: returnByYear.year,
			content: currency(returnByYear.returns.returnAsCurrency ?? '')
		});

		percentageTotals.push({
			columnName: returnByYear.year,
			content: percentage(returnByYear.returns.returnAsPercentage, true, 1, '')
		});

		blanks.push({
			columnName: returnByYear.year,
			content: ''
		});
	});

	currencyTotals = currencyTotals.slice(sliceIndex);
	percentageTotals = percentageTotals.slice(sliceIndex);

	currencyTotals.push({
		columnName: 'allTime',
		content: currency(byYear.allTimeReturns.returnAsCurrency ?? '')
	});

	percentageTotals.push({
		columnName: 'allTime',
		content: percentage(byYear.allTimeReturns.returnAsPercentage, true, 1, '')
	});

	if (byYear.reits) {
		byYear.reits.forEach((reitReturnByYear: ReitReturnsByYear) => {
			let currencyFields: Array<DataTableItem> = [];
			let percentageFields: Array<DataTableItem> = [];
			const yearToContent: Record<string, Record<string, string>> = {};

			reitReturnByYear.returnsByYear.forEach((returnByYear: ReturnsByYear) => {
				yearToContent[returnByYear.year] = {
					currency: currency(returnByYear.returns.returnAsCurrency ?? ''),
					percentage: percentage(returnByYear.returns.returnAsPercentage, true, 1, '')
				};
			});

			blanks.forEach((blankEntry) => {
				let currencyContent = '';
				let percentageContent = '';

				if (yearToContent[blankEntry.columnName]) {
					currencyContent = yearToContent[blankEntry.columnName].currency;
					percentageContent = yearToContent[blankEntry.columnName].percentage;
				}

				currencyFields.push({
					columnName: blankEntry.columnName,
					content: currencyContent
				});

				percentageFields.push({
					columnName: blankEntry.columnName,
					content: percentageContent
				});
			});

			currencyFields = currencyFields.slice(sliceIndex);
			percentageFields = percentageFields.slice(sliceIndex);

			currencyFields.push({
				columnName: 'allTime',
				content: currency(reitReturnByYear.allTimeReturns.returnAsCurrency ?? '')
			});

			percentageFields.push({
				columnName: 'allTime',
				content: percentage(reitReturnByYear.allTimeReturns.returnAsPercentage, true, 1, '')
			});

			currencyRowItems.push({
				id: reitReturnByYear.reitId,
				rowName: reitReturnByYear.title,
				phaseLabel: reitReturnByYear.phaseLabel,
				fields: currencyFields
			});

			percentageRowItems.push({
				id: reitReturnByYear.reitId,
				rowName: reitReturnByYear.title,
				phaseLabel: reitReturnByYear.phaseLabel,
				fields: percentageFields
			});
		});
	}

	currencyRowItems.sort(reitRowComparator);
	percentageRowItems.sort(reitRowComparator);

	return {
		reitRows: {
			currency: currencyRowItems,
			percentage: percentageRowItems
		},
		totalRows: {
			currency: {
				id: 'currencyTotals',
				rowName: 'Subtotal',
				fields: currencyTotals
			},
			percentage: {
				id: 'percentageTotals',
				rowName: 'Weighted average',
				fields: percentageTotals
			}
		}
	};
};

function buildQuarterTotalRow(
	returnsByQuarter: ReturnsByQuarter | undefined,
	yearToDateReturns: ReturnsData | undefined
): {
	currency: DataTableItem[];
	percentage: DataTableItem[];
} {
	const quarterCurrency: Array<DataTableItem> = [];
	const quarterPercentage: Array<DataTableItem> = [];

	if (!returnsByQuarter || !yearToDateReturns) {
		return {
			currency: quarterCurrency,
			percentage: quarterPercentage
		};
	}

	if (returnsByQuarter.firstQuarter) {
		quarterCurrency.push({
			columnName: 'q1',
			content: currency(returnsByQuarter.firstQuarter.returnAsCurrency ?? '')
		});

		quarterPercentage.push({
			columnName: 'q1',
			content: percentage(returnsByQuarter.firstQuarter.returnAsPercentage, true, 1, '')
		});
	}

	if (returnsByQuarter.secondQuarter) {
		quarterCurrency.push({
			columnName: 'q2',
			content: currency(returnsByQuarter.secondQuarter.returnAsCurrency ?? '')
		});

		quarterPercentage.push({
			columnName: 'q2',
			content: percentage(returnsByQuarter.secondQuarter.returnAsPercentage, true, 1, '')
		});
	}

	if (returnsByQuarter.thirdQuarter) {
		quarterCurrency.push({
			columnName: 'q3',
			content: currency(returnsByQuarter.thirdQuarter.returnAsCurrency ?? '')
		});

		quarterPercentage.push({
			columnName: 'q3',
			content: percentage(returnsByQuarter.thirdQuarter.returnAsPercentage, true, 1, '')
		});
	}

	if (returnsByQuarter.fourthQuarter) {
		quarterCurrency.push({
			columnName: 'q4',
			content: currency(returnsByQuarter.fourthQuarter.returnAsCurrency ?? '')
		});

		quarterPercentage.push({
			columnName: 'q4',
			content: percentage(returnsByQuarter.fourthQuarter.returnAsPercentage, true, 1, '')
		});
	}

	if (yearToDateReturns) {
		quarterCurrency.push({
			columnName: 'ytd',
			content: currency(yearToDateReturns.returnAsCurrency ?? '')
		});

		quarterPercentage.push({
			columnName: 'ytd',
			content: percentage(yearToDateReturns.returnAsPercentage, true, 1, '')
		});
	}

	return {
		currency: quarterCurrency,
		percentage: quarterPercentage
	};
}

function buildQuarterReitRow(
	returnsByQuarter: ReturnsByQuarter | undefined,
	yearToDateReturns: ReturnsData | undefined,
	totalColumns: DataTableItem[]
): {
	currency: DataTableItem[];
	percentage: DataTableItem[];
} {
	const quarterCurrency: Array<DataTableItem> = [];
	const quarterPercentage: Array<DataTableItem> = [];

	if (!returnsByQuarter || !yearToDateReturns) {
		return {
			currency: quarterCurrency,
			percentage: quarterPercentage
		};
	}

	const hasFirstQuarter = totalColumns.filter((column) => column.columnName === 'q1').length > 0;
	const hasSecondQuarter = totalColumns.filter((column) => column.columnName === 'q2').length > 0;
	const hasThirdQuarter = totalColumns.filter((column) => column.columnName === 'q3').length > 0;
	const hasForthQuarter = totalColumns.filter((column) => column.columnName === 'q4').length > 0;

	if (returnsByQuarter.firstQuarter) {
		quarterCurrency.push({
			columnName: 'q1',
			content: currency(returnsByQuarter.firstQuarter.returnAsCurrency ?? '')
		});

		quarterPercentage.push({
			columnName: 'q1',
			content: percentage(returnsByQuarter.firstQuarter.returnAsPercentage, true, 1, '')
		});
	} else if (hasFirstQuarter) {
		quarterCurrency.push({
			columnName: 'q1',
			content: ''
		});

		quarterPercentage.push({
			columnName: 'q1',
			content: ''
		});
	}

	if (returnsByQuarter.secondQuarter) {
		quarterCurrency.push({
			columnName: 'q2',
			content: currency(returnsByQuarter.secondQuarter.returnAsCurrency ?? '')
		});

		quarterPercentage.push({
			columnName: 'q2',
			content: percentage(returnsByQuarter.secondQuarter.returnAsPercentage, true, 1, '')
		});
	} else if (hasSecondQuarter) {
		quarterCurrency.push({
			columnName: 'q2',
			content: ''
		});

		quarterPercentage.push({
			columnName: 'q2',
			content: ''
		});
	}

	if (returnsByQuarter.thirdQuarter) {
		quarterCurrency.push({
			columnName: 'q3',
			content: currency(returnsByQuarter.thirdQuarter.returnAsCurrency ?? '')
		});

		quarterPercentage.push({
			columnName: 'q3',
			content: percentage(returnsByQuarter.thirdQuarter.returnAsPercentage, true, 1, '')
		});
	} else if (hasThirdQuarter) {
		quarterCurrency.push({
			columnName: 'q3',
			content: ''
		});

		quarterPercentage.push({
			columnName: 'q3',
			content: ''
		});
	}

	if (returnsByQuarter.fourthQuarter) {
		quarterCurrency.push({
			columnName: 'q4',
			content: currency(returnsByQuarter.fourthQuarter.returnAsCurrency ?? '')
		});

		quarterPercentage.push({
			columnName: 'q4',
			content: percentage(returnsByQuarter.fourthQuarter.returnAsPercentage, true, 1, '')
		});
	} else if (hasForthQuarter) {
		quarterCurrency.push({
			columnName: 'q4',
			content: ''
		});

		quarterPercentage.push({
			columnName: 'q4',
			content: ''
		});
	}

	if (yearToDateReturns) {
		quarterCurrency.push({
			columnName: 'ytd',
			content: currency(yearToDateReturns.returnAsCurrency ?? '')
		});

		quarterPercentage.push({
			columnName: 'ytd',
			content: percentage(yearToDateReturns.returnAsPercentage, true, 1, '')
		});
	}

	return {
		currency: quarterCurrency,
		percentage: quarterPercentage
	};
}

export const quarterToPerformanceDataTableHolder = (
	byQuarter: GroupedReturnsByQuarter | undefined
): PerformanceDataTableHolder => {
	if (!byQuarter) {
		return {
			reitRows: {
				currency: [] as Array<DataTableItemRowReit>,
				percentage: [] as Array<DataTableItemRowReit>
			},
			totalRows: {
				currency: {
					id: 'currencyTotals',
					rowName: 'Subtotal',
					fields: [] as Array<DataTableItem>
				},
				percentage: {
					id: 'percentageTotals',
					rowName: 'Weighted average',
					fields: [] as Array<DataTableItem>
				}
			}
		};
	}

	const currencyRowItems: Array<DataTableItemRowReit> = [];
	const percentageRowItems: Array<DataTableItemRowReit> = [];
	const totalsObject = buildQuarterTotalRow(byQuarter.returnsByQuarter, byQuarter.yearToDateReturns);
	const currencyTotals: Array<DataTableItem> = totalsObject.currency;
	const percentageTotals: Array<DataTableItem> = totalsObject.percentage;

	if (byQuarter.reits) {
		byQuarter.reits.forEach((reitReturnByQuarter: ReitReturnsByQuarter) => {
			const reitReturnsByQuarterFormatted = buildQuarterReitRow(
				reitReturnByQuarter.returnsByQuarter,
				reitReturnByQuarter.yearToDateReturns,
				currencyTotals
			);
			currencyRowItems.push({
				id: reitReturnByQuarter.reitId,
				rowName: reitReturnByQuarter.title,
				phaseLabel: reitReturnByQuarter.phaseLabel,
				fields: reitReturnsByQuarterFormatted.currency
			});

			percentageRowItems.push({
				id: reitReturnByQuarter.reitId,
				rowName: reitReturnByQuarter.title,
				phaseLabel: reitReturnByQuarter.phaseLabel,
				fields: reitReturnsByQuarterFormatted.percentage
			});
		});
	}

	currencyRowItems.sort(reitRowComparator);
	percentageRowItems.sort(reitRowComparator);

	return {
		reitRows: {
			currency: currencyRowItems,
			percentage: percentageRowItems
		},
		totalRows: {
			currency: {
				id: 'currencyTotals',
				rowName: 'Subtotal',
				fields: currencyTotals
			},
			percentage: {
				id: 'percentageTotals',
				rowName: 'Weighted average',
				fields: percentageTotals
			}
		}
	};
};

function buildEmptyPerformanceAccountValueTableHolder(): PerformanceAccountValueTableHolder {
	const netReturns: Array<DataTableItem> = [];
	const netCashInOut: Array<DataTableItem> = [];
	const pendingTransactions: Array<DataTableItem> = [];
	const endOfPeriod: Array<DataTableItem> = [];

	const beginningOfPeriod: DataTableItemRow = {
		id: 'beginningOfPeriod',
		rowName: 'Beginning of period',
		fields: []
	};
	const dividendsEarned: DataTableItemRow = {
		id: 'dividendsEarned',
		rowName: 'Dividends earned',
		fields: []
	};
	const appreciation: DataTableItemRow = {
		id: 'appreciation',
		rowName: 'Appreciation',
		fields: []
	};
	const advisoryFees: DataTableItemRow = {
		id: 'advisoryFees',
		rowName: 'Advisory fees',
		fields: []
	};
	const redemptionDiscounts: DataTableItemRow = {
		id: 'redemptionDiscounts',
		rowName: 'Redemption discounts',
		fields: []
	};
	const principalInvested: DataTableItemRow = {
		id: 'principalInvested',
		rowName: 'Principal invested',
		fields: []
	};
	const dividendsDistributed: DataTableItemRow = {
		id: 'dividendsDistributed',
		rowName: 'Dividends distributed',
		fields: []
	};
	const redemptionsDistributed: DataTableItemRow = {
		id: 'redemptionsDistributed',
		rowName: 'Redemptions distributed',
		fields: []
	};

	const shareTransfers: DataTableItemRow = {
		id: 'shareTransfers',
		rowName: 'Share transfers',
		fields: []
	};

	const pendingRedemptions: DataTableItemRow = {
		id: 'pendingRedemptions',
		rowName: 'Pending Redemptions',
		fields: []
	};

	const pendingOrders: DataTableItemRow = {
		id: 'pendingOrders',
		rowName: 'Pending Orders',
		fields: []
	};

	return {
		netReturns,
		netCashInOut,
		pendingTransactions,
		endOfPeriod,
		beginningOfPeriod,
		dividendsEarned,
		appreciation,
		advisoryFees,
		redemptionDiscounts,
		principalInvested,
		dividendsDistributed,
		redemptionsDistributed,
		shareTransfers,
		pendingRedemptions,
		pendingOrders
	};
}

function populatePerformanceAccountValueTableHolder(
	holder: PerformanceAccountValueTableHolder,
	year: string,
	period: AccountBreakdownPeriod
): void {
	holder.netReturns.push({ columnName: year, content: currency(period.netEarnings) });
	holder.netCashInOut.push({ columnName: year, content: currency(period.netCashInOut) });
	holder.endOfPeriod.push({ columnName: year, content: currency(period.endingValue) });
	holder.pendingTransactions.push({ columnName: year, content: currency(period.netPendingTransactions) });

	holder.beginningOfPeriod.fields.push({ columnName: year, content: currency(period.beginningValue) });
	holder.dividendsEarned.fields.push({ columnName: year, content: currency(period.dividendsEarned) });
	holder.appreciation.fields.push({ columnName: year, content: currency(period.appreciation) });
	holder.advisoryFees.fields.push({ columnName: year, content: currency(period.advisoryFees) });
	holder.redemptionDiscounts.fields.push({ columnName: year, content: currency(period.redemptionFees) });
	holder.principalInvested.fields.push({ columnName: year, content: currency(period.contributions) });
	holder.dividendsDistributed.fields.push({ columnName: year, content: currency(period.dividendsDistributed) });
	holder.redemptionsDistributed.fields.push({ columnName: year, content: currency(period.redemptionsDistributed) });
	holder.shareTransfers.fields.push({ columnName: year, content: currency(period.transferValue) });
	holder.pendingRedemptions.fields.push({ columnName: year, content: currency(period.undistributedRedemptions) });
	holder.pendingOrders.fields.push({ columnName: year, content: currency(period.pendingOrderAmount) });
}

export const buildAccountValueTable = (
	currentYearPerformance: AccountBreakdownPeriod | null | undefined,
	allTimePerformance: AccountBreakdownPeriod,
	pastYearsPerformance: Array<AnnualAccountBreakdown>,
	currentYear: string
): PerformanceAccountValueTableHolder => {
	const holder: PerformanceAccountValueTableHolder = buildEmptyPerformanceAccountValueTableHolder();

	pastYearsPerformance.forEach((annualAccountBreakdown: AnnualAccountBreakdown) => {
		const period: AccountBreakdownPeriod = annualAccountBreakdown.accountValuePeriod;
		const year = annualAccountBreakdown.year;

		populatePerformanceAccountValueTableHolder(holder, year, period);
	});
	if (currentYearPerformance) {
		populatePerformanceAccountValueTableHolder(holder, currentYear, currentYearPerformance);
	}
	populatePerformanceAccountValueTableHolder(holder, 'allTime', allTimePerformance);

	return holder;
};

export const formatPerformanceResponseData = (data: Performance) => {
	if (data?.priorYearReturns?.notReportingNav) {
		const formattedData: ReturnsByYear[] = [];
		const blanks = getBlankReturnsByYear(data.priorYearReturns?.allFunds?.returnsByYear ?? []);

		if (data.priorYearReturns.notReportingNav.reits) {
			data.priorYearReturns.notReportingNav.reits.forEach((reitReturnByYear) => {
				const sliceSize = blanks.length - reitReturnByYear.returnsByYear.length;

				reitReturnByYear.returnsByYear = formattedData.concat(
					blanks.slice(0, sliceSize),
					reitReturnByYear.returnsByYear
				);
			});
		}

		const sliceLength = blanks.length - data.priorYearReturns.notReportingNav.returnsByYear.length;
		data.priorYearReturns.notReportingNav.returnsByYear = formattedData.concat(
			blanks.slice(0, sliceLength),
			data.priorYearReturns.notReportingNav.returnsByYear
		);
	}

	return data;
};
