<template>
	<div>
		<div v-if="props.placehold">
			<div
				v-for="(section, sectionIndex) in sectionArray"
				:key="sectionIndex"
				:class="{ 'mb-300': sectionIndex !== sectionArray.length - 1 }"
			>
				<div class="placeholder-grid__inner">
					<div
						v-for="(line, lineIndex) in lineArray"
						:key="lineIndex"
						:class="{ 'placeholder-grid__line--reversed': props.theme === 'dark' }"
						class="placeholder-grid__line"
					/>
				</div>
			</div>
		</div>
		<slot v-else />
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
	placehold?: boolean;
	theme?: string;
	sectionCount?: number;
	lineCount?: number;
}

const props = withDefaults(defineProps<Props>(), {
	placehold: true,
	theme: 'light',
	sectionCount: 1,
	lineCount: 4
});

const sectionArray = computed((): Array<number> => new Array(props.sectionCount));

const lineArray = computed((): Array<number> => new Array(props.lineCount));
</script>
