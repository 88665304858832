<template>
	<div class="field__inner" :class="{ 'field__inner--error': field.$error }">
		<div>
			<label class="field__label"><slot name="label"> </slot></label>

			<div class="field__input" @keydown="removeServerSide" @mousedown="removeServerSide">
				<slot name="input">
					<input placeholder="Override Input" />
				</slot>
			</div>
		</div>
		<div v-if="field.$error" class="field__error-messages">
			<p v-for="errorMessage in errorMessages" :key="errorMessage.type" class="error-message">
				<slot :name="`${errorMessage.type}-message`">
					{{ errorMessage.message }}
				</slot>
			</p>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	field?: any;
	fieldName?: string;
}
const props = withDefaults(defineProps<Props>(), {
	field: undefined,
	fieldName: 'This field'
});

interface Emits {
	(e: 'clear-server'): void;
}
const emit = defineEmits<Emits>();

const defaultMessage = 'Please check this field';

const requiredMessage = `${props.fieldName} is required`;

const errorMap = computed((): Record<string, string> => {
	return {
		required: requiredMessage,
		requiredUnless: requiredMessage,
		requiredIf: requiredMessage,
		email: `Please enter a valid email address`,
		integer: `Please enter an integer`,
		numeric: `${props.fieldName} must be numeric`,
		minLength: `${props.fieldName} is too short`,
		maxDecimalPlaces: 'Please enter a value with fewer decimals',
		maxLength: `${props.fieldName} is too long`,
		maxValue: `${props.fieldName} is too large`,
		maxAge: `The selected date exceeds the maximum allowed for ${props.fieldName}`,
		minValue: `${props.fieldName} is too small`,
		minAge: `The selected date doesn't meet the minimum required for ${props.fieldName}`,
		multipleOf: `Please enter a valid amount`,
		sameAs: `${props.fieldName} does not match`,
		accepted: `${props.fieldName} must be accepted`,
		selectAll: `Please select all`,
		date: `Please select a valid date`,
		pastDate: `Please select a date in the past`,
		currencyFormat: `Please enter a valid amount`,
		nameRegex: `${props.fieldName} cannot be shorter than 2 letters or longer than 150 letters`,
		passwordRegex: `Password must be at least 8 characters long, and must include one letter and one number`,
		poBox: `Invalid residential address, a PO box may only be used as a mailing address.`,
		isChecked: `Please select this field`
	};
});

const errorMessages = computed((): Array<Record<string, string>> => {
	const errors: Array<Record<string, string>> = [];
	if (props.field.$errors && props.field.$errors.length > 0) {
		/* eslint-disable @typescript-eslint/no-explicit-any */
		const serverError = props.field.$errors.find((error: any) => error.$params?.type === 'serverError');

		if (serverError) {
			errors.push(
				...serverError.$params.serverErrors.map((error: any, index: number) => ({
					type: `serverError+${index}`,
					message: error
				}))
			);
		} else {
			props.field.$errors.forEach((err: any) => {
				const errorType = err.$validator;
				errors.push({
					type: errorType,
					message: errorMap.value[errorType] || defaultMessage
				});
			});
		}
	}

	return errors;
});

function removeServerSide(): void {
	if (!props.field['serverError'] || props.field['serverError']?.$invalid) {
		emit('clear-server');
	}
}
</script>
<style lang="scss">
$margin: 0.25rem;

.field__label {
	+ .field__input {
		margin-top: $margin;
	}
}
</style>
