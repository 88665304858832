<template>
	<div :class="['segmented-control', { 'segmented-control--dark-mode': props.darkMode }]" role="group">
		<slot>
			<BaseButton
				v-for="control in props.controls"
				:key="control.key"
				classes="button"
				:is-active="isSelected(control.key)"
				:aria-label="control.label"
				:data-test="`control-option-${control.key}`"
				:mixpanel-custom-properties="mixpanelCustomProperties"
				@click="handleClick(control.key)"
			>
				<BaseSvgIcon v-if="control.isIcon" :name="control.key" :title="control.label" />
				<span v-else>{{ control.label }}</span>
			</BaseButton>
		</slot>
	</div>
</template>

<script setup lang="ts">
import { MixpanelContext } from 'types/analytics';
import { SegmentedControlButton } from 'types/layout';

interface Props {
	controls: Array<SegmentedControlButton>;
	selected: string;
	darkMode?: boolean;
	mixpanelCustomProperties?: MixpanelContext;
}

interface Emits {
	(e: 'selected-segmented-control-key', key: string): void;
}

const props = withDefaults(defineProps<Props>(), {
	darkMode: false,
	mixpanelCustomProperties: () => ({})
});

const emits = defineEmits<Emits>();

function isSelected(controlKey: string): boolean {
	return props.selected === controlKey;
}

function handleClick(key: string): void {
	emits('selected-segmented-control-key', key);
}
</script>

<style lang="scss" scoped>
@use '../../styles/constants/colors' as *;
@use '../../styles/utilities/respond-to.scss' as *;

.segmented-control {
	background-color: token('bg-tertiary');
	border-radius: 20px;
	color: token('content-primary');
	display: inline-flex;
	flex-wrap: nowrap;
	gap: 0.25rem;
	padding: 0.25rem;
	max-width: 100%;

	@include respond-to(smo) {
		overflow-x: scroll;
		white-space: nowrap;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&.segmented-control--dark-mode {
		background-color: token('content-primary');

		.button {
			color: #fff;

			&:hover,
			&.hover,
			&:focus {
				background-color: token('content-secondary');
			}
		}
	}

	> .button {
		background-color: transparent;
		border-radius: 20px;
		border: none;
		color: token('content-tertiary');
		font-weight: 600;
		padding: 0.125rem 0.75rem;
		height: 1.75rem;
		transition: all 300ms ease-in-out;

		&:hover,
		&.hover,
		&:focus {
			background-color: gray(20);
		}

		&:active,
		&[aria-selected='true'] {
			background-color: #fff;
			color: token('content-primary');
		}
	}
}
</style>
