<template>
	<DesktopNavTemplate :styles="styles">
		<template #left>
			<ul class="main-nav__menu ml-200">
				<li v-for="(link, mainLinkId) in marketingLinks" :key="mainLinkId" class="main-nav__menu-item">
					<NavigationLinkDesktop
						:is-sticky="isSticky"
						:is-transparent-dark-text="isTransparentDarkText"
						:link="link"
					/>
				</li>
				<li class="main-nav__menu-item"> <LearnLinksSubmenu /> </li>
			</ul>
		</template>
		<template #right>
			<div class="main-nav__action-buttons display-flex align-items-center gap-100 eta">
				<BaseLink :classes="loginClasses" :link="{ href: '/login' }"> Log in </BaseLink>
				<BaseLink
					v-if="!hideGetStarted"
					:classes="getStartedClasses"
					data-test="get-started-button"
					:link="getStartedLink"
				/>
			</div>
		</template>
	</DesktopNavTemplate>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import DesktopNavTemplate from '@components/header/navigation/desktop/desktop-nav-template.vue';
import { HeaderStyle } from 'types/header';
import LearnLinksSubmenu from '@components/header/navigation/desktop/learn-links-submenu.vue';
import { Link } from 'types/layout';
import { marketingLinks } from '../links';
import NavigationLinkDesktop from '@components/header/navigation/desktop/navigation-link-desktop.vue';
import { useRoute } from 'vue-router';
interface Props {
	styles: HeaderStyle;
	isSticky?: boolean;
	isTransparentDarkText?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
	isSticky: false,
	isTransparentDarkText: false
});
const route = useRoute();

const getStartedLink: Link = {
	text: 'Get started',
	router: 'checkout-start'
};

const loginClasses = computed((): string => {
	return 'button ' + props.styles.buttonClassLogin;
});

const getStartedClasses = computed((): string => {
	return 'button ' + props.styles.buttonClassGetStarted;
});

const hideGetStarted = computed((): boolean => {
	return route?.name === 'advisor-landing';
});
</script>
