import { FundriseRouteConfig, NavigationGuard } from 'vue-router';
import { app } from '@store/modules/app';
import { logError } from '@utils/error-tracking';
import { user } from '@store/modules/user';
import { verifyEmail } from '@api/login';

const verifyEmailAddress = async (key: string, visitDashOnSuccess = true): Promise<ReturnType<NavigationGuard>> => {
	try {
		const verifyEmailResponse = await verifyEmail(key);
		await app.setUserAuthData(verifyEmailResponse);

		if (verifyEmailResponse.scope === 'ADVISOR_ACCESS') {
			app.ADD_TOAST({
				type: 'success',
				message: 'Your email has been successfully verified.'
			});
			return { name: 'advisor-welcome' };
		} else if (visitDashOnSuccess) {
			app.ADD_TOAST({
				type: 'success',
				message: 'Your email has been successfully verified.'
			});
			return { name: 'home' };
		} else {
			return { name: 'reits-checkout-success' };
		}
	} catch {
		logError('Unable to verify user.');
		return { name: 'verify-email' };
	}
};

const signupRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/signup/verify-signup-checkout`,
		name: 'verify-signup-checkout',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		component: () => import('@views/checkout/checkout-success.vue'),
		beforeEnter: async (to) => {
			if (app.isVerified) {
				return true;
			} else if (to.query.key && app.isAuthenticated) {
				return verifyEmailAddress(`${to.query.key}`, false);
			} else {
				return { name: 'unverified-email' };
			}
		}
	},
	{
		path: `/signup/unverified-email`,
		name: 'unverified-email',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		beforeEnter: async () => {
			if (app.isVerified) {
				return { name: 'account-overview' };
			} else {
				return true;
			}
		},
		component: () => import('@views/email-verification/unverified-email.vue')
	},
	{
		path: `/signup/verify-email`,
		name: 'verify-email',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		component: () => import('@views/email-verification/verification-failed.vue'),
		beforeEnter: async (to) => {
			if (app.isVerified) {
				return { name: 'home' };
			} else if (to.query.key && app.isAuthenticated) {
				const shouldRedirectToDash = !user.hasFirstInvestment;
				return verifyEmailAddress(`${to.query.key}`, shouldRedirectToDash);
			} else {
				return true;
			}
		}
	},
	{
		path: `/signup/unverified-signup-checkout-email`,
		name: 'checkout-verify-email',
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		beforeEnter: async () => {
			if (app.isVerified) {
				return { name: 'home', replace: true };
			} else {
				return true;
			}
		},
		component: () => import('@views/checkout/checkout-verify-email.vue')
	}
];
export default signupRoutes;
