import { growthTechReitId, ocfReitId } from '@constants/reit';
import { app } from '@store/modules/app';
import { FundriseRouteConfig } from 'vue-router';
import { investmentEntity } from '@store/modules/investment-entity';
import { redirect } from '@utils/client';

const investmentsRedirectGuard = () => {
	if (app.isAuthenticated && investmentEntity.isReitInvestor) {
		return { name: 'account-overview', replace: true };
	} else {
		return { name: 'investments', replace: true };
	}
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const redirectRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/account/additional-investment/start',
		name: 'account-additional-investment-redirect',
		redirect: { name: 'add-funds-ria' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/security/manage',
		name: 'account-security-redirect',
		redirect: { name: 'account-overview' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/updates',
		name: 'account-updates-redirect',
		redirect: { name: 'newsfeed' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/beta',
		alias: '/beta/**',
		name: 'beta-redirect',
		redirect: () => {
			if (app.isAuthenticated) {
				return { name: 'account-overview', replace: true };
			} else {
				return { name: 'home', replace: true };
			}
		},
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: `/start-investing`,
		name: 'start-investing',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'get-started' }
	},
	{
		path: `/get-started`,
		name: 'get-started',
		alias: [`/select-goal-help`],
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'checkout-start' }
	},
	{
		path: '/portfolio/:offeringId/documents',
		name: 'portfolio-documents-redirect',
		redirect: { name: 'account-documents' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/portfolio/:offeringId/transactions',
		name: 'portfolio-transactions-redirect',
		redirect: { name: 'account-transactions' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/portfolio/:offeringId/updates',
		name: 'portfolio-updates-redirect',
		redirect: { name: 'newsfeed' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/portfolio/reit-investment/:reitId/updates',
		name: 'reit-updates-redirect',
		redirect: { name: 'newsfeed' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/portfolio/reit-investment/:reitId/documents',
		name: 'reit-documents-redirect',
		redirect: { name: 'account-documents' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/portfolio/reit-investment/:reitId/transactions',
		name: 'reit-transactions-redirect',
		redirect: { name: 'account-transactions' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/portfolio/reit-investment/:reitId/activity',
		alias: '/activity/reit-investment/:reitId/your-investment',
		name: 'reit-activity-redirect',
		redirect: (route) => ({
			name: 'account-portfolio-detail',
			params: {
				reitId: route.params.reitId
			}
		}),
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/personal-information',
		alias: [`/account/investment-accounts`, `/account/investment-accounts/personal-information/manage`],
		name: 'settings-account-information-redirect',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		redirect: () => {
			switch (investmentEntity.entityType) {
				case 'INDIVIDUAL':
					return { name: 'settings-nickname-view' };
				case 'IRA':
					return { name: 'settings-ira-information-view' };
				case 'JOINT_ACCOUNT':
					return { name: 'settings-joint-account-information-view' };
				case 'LLC':
					return {
						name: 'settings-entity-account-information-view'
					};
				case 'TRUST':
					return {
						name: 'settings-trust-information-view'
					};
				default:
					return { name: '404' };
			}
		}
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/personal-information/manage',
		name: 'settings-account-information-with-ie-redirect',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		redirect: () => {
			switch (investmentEntity.entityType) {
				case 'INDIVIDUAL':
					return { name: 'settings-nickname-view' };
				case 'IRA':
					return { name: 'settings-ira-information-view' };
				case 'JOINT_ACCOUNT':
					return { name: 'settings-joint-account-information-view' };
				case 'LLC':
					return {
						name: 'settings-entity-account-information-view'
					};
				case 'TRUST':
					return {
						name: 'settings-trust-information-view'
					};
				default:
					return { name: '404' };
			}
		}
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/accreditation/edit',
		name: 'settings-accreditation-edit-redirect',
		redirect: { name: 'settings-accreditation-edit' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/accreditation/edit',
		name: 'settings-accreditation-edit-with-entity-redirect',
		redirect: { name: 'settings-accreditation-edit' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/advanced-options/view',
		name: 'settings-advanced-options-redirect',
		redirect: { name: 'settings-advanced-options' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/advanced-options/manage',
		name: 'settings-advanced-options-with-entity-redirect',
		redirect: { name: 'settings-advanced-options' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/recurring-investment-settings/manage',
		name: 'settings-auto-invest-form-redirect',
		redirect: { name: 'settings-auto-invest-form' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/recurring-investment-settings/manage',
		name: 'settings-auto-invest-form-with-entity-redirect',
		redirect: { name: 'settings-auto-invest-form' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/recurring-investment-settings/view',
		name: 'auto-invest-view-redirect',
		redirect: { name: 'settings-auto-invest-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId?/:entityType?/payment-methods/manage',
		alias: [
			'/account/investment-accounts/me/individual-account/bank-accounts/manage',
			'/account/investment-accounts/:investmentEntityId?/:entityType?/bank-accounts/manage',
			'/account/investment-accounts/bank-accounts/manage'
		],
		name: 'settings-payment-methods-view-redirect',
		redirect: { name: 'settings-payment-methods-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId?/:entityType?/payment-methods/edit',
		alias: '/account/investment-accounts/:investmentEntityId?/:entityType?/bank-accounts/edit',
		name: 'settings-payment-methods-edit-redirect',
		redirect: { name: 'settings-payment-methods-edit' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:entityType/bank-accounts/new/form',
		name: 'settings-bank-accounts-new-redirect',
		redirect: { name: 'settings-bank-accounts-new' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/bank-accounts/new/form',
		name: 'settings-bank-accounts-new-with-ie-redirect',
		redirect: { name: 'settings-bank-accounts-new' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/ria-dividend-reinvestment/manage',
		alias: '/account/investment-accounts/:investmentEntityId/:entityType/dividend-reinvestment/manage',
		name: 'settings-drip-edit-entityId-entityType-redirect',
		redirect: { name: 'settings-drip-edit' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/ria-dividend-reinvestment/manage',
		alias: '/account/investment-accounts/:investmentEntityId/dividend-reinvestment/manage',
		name: 'settings-drip-edit-entityId-redirect',
		redirect: { name: 'settings-drip-edit' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/ria-dividend-reinvestment/view',
		alias: '/account/investment-accounts/:investmentEntityId/:entityType/dividend-reinvestment/view',
		name: 'settings-reinvestment-view-redirect',
		redirect: { name: 'settings-reinvestment-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/investment-plan/view',
		alias: '/account/investment-accounts/:investmentEntityId/:entityType/investment-goal/manage',
		name: 'settings-investment-plan-redirect',
		redirect: { name: 'settings-investment-plan-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/beneficiaries/view',
		alias: '/account/investment-accounts/:investmentEntityId/:entityType/beneficiaries/manage',
		name: 'settings-beneficiaries-view-redirect',
		redirect: { name: 'settings-beneficiaries-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: `/account/investment-accounts/:investmentEntityId/:entityType/beneficiaries/:beneficiaryId/edit`,
		name: 'settings-beneficiary-edit-redirect',
		redirect: (route) => ({
			name: 'settings-beneficiary-edit',
			params: {
				beneficiaryId: route.params.beneficiaryId
			}
		}),
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/beneficiaries/new/:beneficiaryType',
		name: 'settings-beneficiary-new-redirect',
		redirect: (route) => ({
			name: 'settings-beneficiary-new',
			params: {
				beneficiaryType: route.params.beneficiaryType
			}
		}),
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/notification-recipients/view',
		alias: '/account/investment-accounts/:investmentEntityId/:entityType/notification-recipients/manage',
		name: 'settings-email-recipients-view-redirect',
		redirect: { name: 'settings-email-recipients-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/notification-recipients/add',
		name: 'settings-email-recipients-new-redirect',
		redirect: { name: 'settings-email-recipients-new' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/notification-recipients/:recipientId/edit',
		name: 'settings-email-recipients-edit-redirect',
		redirect: { name: 'settings-email-recipients-edit' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:entityType/document-delivery/edit',
		alias: '/account/investment-accounts/:entityType/document-delivery/manage',
		name: 'settings-document-delivery-edit-redirect',
		redirect: { name: 'settings-document-delivery-edit' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/:entityType/document-delivery/edit',
		alias: '/account/investment-accounts/:investmentEntityId/:entityType/document-delivery/manage',
		name: 'settings-document-delivery-edit-with-ie-redirect',
		redirect: { name: 'settings-document-delivery-edit' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/ira/personal-information',
		alias: '/account/investment-accounts/:investmentEntityId/ira/manage',
		name: 'settings-ira-information-view-redirect',
		redirect: { name: 'settings-ira-information-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: `/account/investment-accounts/:investmentEntityId/:entityType/investment-plan/edit`,
		alias: `/account/investment-accounts/:investmentEntityId/:entityType/investment-goal/edit`,
		name: 'settings-investment-plan-edit-redirect',
		redirect: { name: 'settings-investment-plan-edit' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/joint-account/account-holder-information/edit',
		name: 'settings-joint-account-information-edit-redirect',
		redirect: { name: 'settings-joint-account-information-edit' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/joint-account/personal-information',
		name: 'settings-joint-account-information-view-redirect',
		redirect: { name: 'settings-joint-account-information-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/entity-account/manage',
		alias: '/account/investment-accounts/:investmentEntityId/entity-account/personal-information',
		name: 'settings-manage-entity-account',
		redirect: { name: 'settings-entity-account-information-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/entity-account/entity-information/edit',
		name: 'settings-entity-account-information-edit-redirect',
		redirect: { name: 'settings-entity-account-information-edit' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/individual-account/manage',
		alias: [
			'/account/email/view',
			'/account/profile/mobile-number/view',
			'/account/security/mobile-number/view',
			'/account/profile/personal-information/edit',
			'/account/investment-accounts/individual-account/personal-information/edit',
			'/account/investment-accounts/me/individual-account/manage'
		],
		name: 'settings-manage-individual-account-redirect',
		redirect: { name: 'profile-personal-information-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/individual-account/manage',
		name: 'settings-manage-individual-account-with-ie-redirect',
		redirect: { name: 'profile-personal-information-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/joint-account/manage',
		name: 'settings-manage-joint-account',
		redirect: { name: 'settings-joint-account-information-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/trust/manage',
		alias: '/account/investment-accounts/:investmentEntityId/trust/personal-information',
		name: 'settings-manage-trust',
		redirect: { name: 'settings-trust-information-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/trust/information/edit',
		name: 'settings-trust-information-edit-redirect',
		redirect: { name: 'settings-trust-information-edit' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/individual-account/personal-information/edit',
		name: 'profile-personal-information-edit-redirect',
		redirect: { name: 'profile-address-edit' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/investment-accounts/:investmentEntityId/individual-account/personal-information',
		name: 'settings-personal-information-view-redirect',
		redirect: { name: 'profile-personal-information-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/update-ssn/save/:saveType',
		name: 'update-ssn-save-redirect',
		redirect: { name: 'update-ssn-save' },
		meta: { minTokenScopeRequired: 'FULL_ACCESS' }
	},
	{
		path: '/account/repeat-investment/ria/agreements',
		name: 'add-funds-agreements-redirect',
		redirect: { name: 'add-funds-ria-amount' },
		meta: { minTokenScopeRequired: 'FULL_ACCESS' }
	},
	{
		path: '/account/upgrade/advanced/ira/direct',
		name: 'account-upgrade-advanced-ira-redirect',
		redirect: { name: 'account-overview' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/upgrade/basic-plan',
		name: 'account-upgrade-basic-plan-redirect',
		redirect: { name: 'account-overview' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/upgrade/:serviceLevel/success',
		name: 'account-upgrade-service-level-success-redirect',
		redirect: { name: 'account-overview' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/invest-upgrade/select-starter',
		name: 'account-invest-upgrade-select-starter-redirect',
		redirect: { name: 'add-funds-ria' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/upgrade/premium-welcome',
		name: 'account-upgrade-premium-welcome-redirect',
		redirect: { name: 'account-overview' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/upgrade/:serviceLevel',
		name: 'account-upgrade-service-level-redirect',
		redirect: { name: 'account-overview' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/innovation-fund',
		redirect: { name: 'view-offering-details', params: { offeringId: growthTechReitId } },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/innovation',
		redirect: { name: 'view-offering-details', params: { offeringId: growthTechReitId } },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/opportunistic',
		redirect: { name: 'view-offering-details', params: { offeringId: ocfReitId } },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/security/your-devices/manage',
		alias: '/account/security/view-password',
		name: 'profile-security-view-redirect',
		redirect: { name: 'profile-security-view' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: `/investments/starter-plan`,
		redirect: investmentsRedirectGuard,
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: `/start-investing/starter-plan`,
		redirect: investmentsRedirectGuard,
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: `/start-investing/starter-plan/mint`,
		redirect: investmentsRedirectGuard,
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: `/starter-portfolio/details`,
		redirect: investmentsRedirectGuard,
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: `/starter-portfolio/mint`,
		redirect: investmentsRedirectGuard,
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: `/existing/:slug`,
		redirect: { name: 'ria-upgrade' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: `/select-goal-redirect`,
		redirect: () => {
			if (app.isAuthenticated && investmentEntity.additionalInvestmentEligibility === 'VALID') {
				return { name: 'add-funds-ria' };
			} else if (
				app.isAuthenticated &&
				investmentEntity.additionalInvestmentEligibility === 'ENTITY_NO_PLAN_SELECTION'
			) {
				return { name: 'ria-upgrade' };
			} else {
				return { name: 'start-investing' };
			}
		},
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: `/select-goal`,
		redirect: () => {
			if (app.isAuthenticated && investmentEntity.additionalInvestmentEligibility === 'VALID') {
				return { name: 'add-funds-ria' };
			} else {
				return { name: 'start-investing' };
			}
		},
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/signup/start',
		redirect: () => {
			if (app.isAuthenticated) {
				return { name: 'account-overview', replace: true };
			} else {
				return { name: 'home', replace: true };
			}
		},
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/reits/checkout/ira-profile-form',
		redirect: { name: 'ira-signup-start' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/reits/checkout/select-plan-detail/:planId',
		redirect: {
			name: 'investment-details'
		},
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/reits/checkout/select-plan-list',
		redirect: { name: 'plan-menu' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/reits/checkout/plan-recommendation',
		redirect: { name: 'investment-recommendation' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/reits/checkout/survey',
		name: 'hdyh-redirect',
		redirect: { name: 'checkout-resume' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/allocation-summary',
		redirect: { name: 'checkout-resume' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/direct-investment-redirect',
		redirect: { name: 'checkout-resume' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: `/account/select-investment-entity/:investmentEntityId/redirect/:redirectUrl(.*)`,
		redirect: (route) => ({
			path: route.params.redirectUrl as string,
			query: { investmentEntityId: route.params.investmentEntityId }
		}),
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/your-portfolio',
		redirect: { name: 'start-investing' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/compare-goal-vs-traditional',
		redirect: { name: 'start-investing' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/ways-to-invest',
		redirect: { name: 'start-investing' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/start-investing/advanced-plans',
		redirect: { name: 'start-investing' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/start-investing/advanced-plans/**',
		redirect: { name: 'start-investing' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/smarter-platform',
		redirect: { name: 'how-it-works' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/legal/referral-program',
		redirect: { name: 'referral-terms-conditions' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/real-estate-investing-guide',
		redirect: { name: 'rei-guide' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/invest/dashboard',
		redirect: { name: 'account-overview' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/portfolio/view',
		redirect: { name: 'account-overview' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/portfolio/investments',
		redirect: { name: 'account-overview' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/portfolio/tax-center',
		redirect: { name: 'account-overview' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/reits/checkout-resume',
		redirect: { name: 'checkout-resume' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/offerings/fundrise-ipo/preview',
		redirect: { name: 'offerings-ipo-landing' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/offerings/fundrise-ipo/background',
		redirect: { name: 'offerings-ipo-landing' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/offerings/fundrise-ipo/confirm-email',
		redirect: { name: 'offerings-ipo-landing' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/offerings/fundrise-ipo/bd67d8e4-b463-4f88-bcb1-e3137591e4c5',
		redirect: { name: 'offerings-ipo-landing' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/track-record',
		redirect: { name: 'client-returns' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/why-real-estate',
		redirect: { name: 'why-private-real-estate' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/real-estate-expertise',
		redirect: { name: 'home' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/investing-with-fundrise',
		redirect: { name: 'how-it-works' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/e-direct-investing',
		redirect: { name: 'how-it-works' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/logout',
		name: 'logout',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'logout-success' }
	},
	{
		path: '/authentication/login',
		name: 'authentication-login',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'login' }
	},
	{
		path: '/account/non-investor-dashboard/overview',
		redirect: { name: 'user-overview' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/real-estate-markets/:marketUrlSlug',
		redirect: { name: 'home' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/opportunity-fund',
		redirect: { name: 'view-opportunity-fund-details' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/account/upgrade',
		redirect: { name: 'account-overview' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/connect',
		redirect: { name: 'connect-api' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: '/questionnaire-select-plan',
		alias: ['/questionnaire/results-cards', '/questionnaire/results'],
		redirect: { name: 'checkout-resume' },
		meta: { minTokenScopeRequired: 'UNVERIFIED' }
	},
	{
		path: `/reits/checkout/preset-plan-confirmation`,
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		redirect: { name: 'checkout-resume' }
	},
	{
		path: `/reits/checkout/select-plan`,
		meta: { minTokenScopeRequired: 'UNVERIFIED' },
		redirect: { name: 'checkout-resume' }
	},
	{
		path: '/reits/checkout/start',
		redirect: { name: 'checkout-resume' },
		meta: { minTokenScopeRequired: 'UNVERIFIED' }
	},
	{
		path: '/questionnaire-pro',
		redirect: { name: 'home' },
		meta: { minTokenScopeRequired: 'UNVERIFIED' }
	},
	{
		path: `/investments/core-plans/compare`,
		name: 'compare-core-plans',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: {
			name: 'help-center-article',
			params: {
				articleId: '360032879791'
			}
		}
	},
	{
		path: '/account/custom-plans/templates',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		redirect: () => {
			if (investmentEntity.currentRiaPlanInvestmentStrategy === 'CUSTOM_PORTFOLIO') {
				return { name: 'custom-plans-start', query: { edit: 'true' }, replace: true };
			} else {
				return { name: 'custom-plans-start', replace: true };
			}
		}
	},
	{
		path: '/wallstreetjournal/**',
		redirect: { name: 'account-overview' },
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' }
	},
	{
		path: `/webview/track-record-annual-returns`,
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'home' }
	},
	{
		path: '/privacy-policy',
		name: 'privacy-policy',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: () => {
			redirect('/privacy-policy.html');
			return { name: 'website-document-view', params: { title: 'Privacy Policy' } };
		}
	}
];

export default redirectRoutes;
