import { AxiosRequestConfig, AxiosRequestInterceptorParams } from 'axios';
import { useResetPasswordStore } from '@stores/reset-password';

/**
 * Adds password reset key as a header.
 */
function passwordResetKeyHeader(config: AxiosRequestConfig): AxiosRequestConfig {
	const resetPasswordStore = useResetPasswordStore();
	const passwordResetKey = {
		'password-reset-key': resetPasswordStore.passwordResetKey
	};
	config.headers = { ...config.headers, ...passwordResetKey };
	return config;
}

export const passwordResetKeyHeaderInterceptor: AxiosRequestInterceptorParams = [
	(config) => passwordResetKeyHeader(config),
	(error) => Promise.reject(error)
];
