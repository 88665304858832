import { InvestmentEntity } from 'types/investment-entity';
import { user } from '@store/modules/user';

export const getUserEntityById = (investmentEntityId: string): InvestmentEntity | null => {
	if (!user.user?.entityDetails) {
		return null;
	}

	const selectedInvestmentEntity = user.user?.entityDetails?.investmentEntities.filter(
		(investmentEntity) => investmentEntity.investmentEntityId === investmentEntityId
	)[0];
	const defaultInvestmentEntity = user.user.entityDetails?.investmentEntities.filter(
		(investmentEntity) =>
			investmentEntity.investmentEntityId === user.user?.entityDetails?.defaultInvestmentEntityId
	)[0];

	return selectedInvestmentEntity ?? defaultInvestmentEntity;
};
