import { app } from '@store/modules/app';
import { investmentEntity } from '@store/modules/investment-entity';
import { NavigationGuard } from 'vue-router';
import { user } from '@store/modules/user';

export const checkAndSwitchToIraEntity: NavigationGuard = async (to, from, next) => {
	if (!user.hasIraEntity) {
		next({ name: 'account-overview', replace: true });
		app.ADD_TOAST({
			type: 'error',
			message: 'No IRA is available for rollover.'
		});
	} else if (investmentEntity.entityType !== 'IRA') {
		await user.updateSelectedEntity(user.iraEntities[0].investmentEntityId);
	} else {
		next();
	}
};
