import { EntityDetailResponse, ForgotPasswordEmailSubmission, ForgotPasswordMetaData } from 'types/forgot-password';
import { forgotPasswordApiRequest, oauthRequest } from '@utils/request';
import { TwoFactorCodeDeliveryMethod } from 'types/authentication';

export const getForgotPasswordMetaData = async (): Promise<ForgotPasswordMetaData> => {
	const response = await forgotPasswordApiRequest.get(`/forgot-password/forgot-password-metadata`);
	return response.data;
};

export const resetPasswordEmailRequest = async (submissionForm: ForgotPasswordEmailSubmission): Promise<void> => {
	await oauthRequest.post(`/identity/forgot-password/send-email`, {
		mixpanelId: submissionForm.mixpanelId,
		username: submissionForm.username
	});
};

export const resetUserPassword = async (newPassword: string, newPasswordConfirm: string): Promise<void> => {
	await forgotPasswordApiRequest.post(`/forgot-password/reset-password`, {
		newPassword,
		newPasswordConfirm
	});
};
export const verifyAdditionalEntityDetails = async (entityDetailResponse: EntityDetailResponse): Promise<void> => {
	await forgotPasswordApiRequest.post(`/forgot-password/entity-details/verify`, {
		...(entityDetailResponse.last4 && { last4: entityDetailResponse.last4 }),
		...(entityDetailResponse.year && { year: entityDetailResponse.year }),
		...(entityDetailResponse.zip && { zip: entityDetailResponse.zip })
	});
};

export const verifyAdditionalAuthQuestion = async (securityQuestionResponse: string): Promise<void> => {
	await forgotPasswordApiRequest.post(`/forgot-password/security-questions/verify`, {
		securityQuestionResponse
	});
};

export const sendForgotPasswordMobileCode = async (codeDeliveryMethod: TwoFactorCodeDeliveryMethod): Promise<void> => {
	await forgotPasswordApiRequest.post(`/forgot-password/mobile-code/send`, {
		codeDeliveryMethod
	});
};

export const submitForgotPasswordAuthCode = async (
	authCode: string,
	codeDeliveryMethod: TwoFactorCodeDeliveryMethod
): Promise<void> => {
	await forgotPasswordApiRequest.post(`/forgot-password/mobile-code/verify`, {
		authCode,
		codeDeliveryMethod
	});
};
