import { FundriseRouteConfig } from 'vue-router';
import { useAccreditationStore } from '@stores/accreditation';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const accreditationRoutes: FundriseRouteConfig = {
	path: `/account/accreditation/verify`,
	name: 'accreditation',
	meta: { minTokenScopeRequired: 'FULL_ACCESS' },
	component: () => import('@views/accreditation/accreditation-verify-start.vue'),
	redirect: { name: 'accreditation-verification-methods' },
	children: [
		{
			path: `method`,
			name: 'accreditation-verification-methods',
			meta: { step: 1, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () => import('@views/accreditation/accreditation-verification-methods.vue')
		},
		{
			path: `type`,
			name: 'accreditation-document-type',
			meta: { step: 2, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () => import('@views/accreditation/accreditation-document-type.vue')
		},
		{
			path: `upload`,
			name: 'accreditation-document-upload',
			meta: { step: 3, minTokenScopeRequired: 'FULL_ACCESS' },
			beforeEnter: async (to, from, next) => {
				const accreditationStore = useAccreditationStore();
				if (accreditationStore.verificationType === null) {
					next({ name: 'accreditation-document-type' });
				} else {
					next();
				}
			},
			component: () => import('@views/accreditation/accreditation-document-upload.vue')
		},
		{
			path: `success`,
			name: 'accreditation-upload-success',
			meta: { step: 4, minTokenScopeRequired: 'FULL_ACCESS' },
			component: () => import('@views/accreditation/accreditation-upload-success.vue')
		}
	]
};

export default accreditationRoutes;
