<template>
	<div :class="['text-align-center width-100', props.classes]">
		<div class="body-sm display-flex mt-100 mt-150-md" aria-hidden="true">
			<span v-for="label in visibleReactionLabels" :key="label" class="visible-reaction-label flex-1">{{
				label
			}}</span>
		</div>
		<div class="display-flex justify-content-space-between mt-50">
			<div v-for="(reaction, index) in reactions" :key="reaction">
				<div :class="['radio-checkbox-item radio-checkbox-item-inline']">
					<label>
						<input
							v-model="v$.activeResponse.$model"
							type="radio"
							:value="reaction"
							:data-test="`micro-feedback-reaction-${index}`"
						/>
						<span class="show-for-sr">{{ getReactionLabel(index) }}</span>
						<span class="mask"></span>
					</label>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { computed, PropType, reactive, watch } from 'vue';
import { MicroFeedbackReactionType } from 'types/micro-feedback';
import useVuelidate from '@vuelidate/core';

interface Emits {
	(e: 'reaction-selected', value: MicroFeedbackReactionType): void;
}

const emits = defineEmits<Emits>();

const props = defineProps({
	classes: {
		type: String as PropType<string>,
		default: 'mt-150'
	},
	selectedResponse: {
		type: String as PropType<MicroFeedbackReactionType>,
		default: undefined
	},
	reactionLabels: {
		type: Array as PropType<Array<string>>,
		default: () => ['Unsatisfied', 'Somewhat unsatisfied', 'Neutral', 'Somewhat satisfied', 'Satisfied'],
		validator: (value: Array<string>) => value.length === 5
	}
});

const reactions: Array<MicroFeedbackReactionType> = [
	'UNSATISFIED',
	'SOMEWHAT_UNSATISFIED',
	'NEUTRAL',
	'SOMEWHAT_SATISFIED',
	'SATISFIED'
];

const rules = {
	activeResponse: {}
};

const state: { activeResponse: MicroFeedbackReactionType | null } = reactive({
	activeResponse: null
});

const v$ = useVuelidate(rules, state);

const visibleReactionLabels = computed((): Array<string> => {
	return props.reactionLabels.filter((_reactionLabel, index) => index % 2 === 0);
});

watch(
	() => state.activeResponse,
	(activeResponse: MicroFeedbackReactionType | null) => {
		if (activeResponse) {
			emits('reaction-selected', activeResponse);
		}
	}
);

function getReactionLabel(index: number): string {
	return props.reactionLabels[index];
}

function setup(): void {
	state.activeResponse = props.selectedResponse ?? null;
}

setup();
</script>

<style lang="scss" scoped>
.radio-checkbox-item {
	margin-top: 0;
}

.visible-reaction-label {
	&:first-of-type {
		text-align: left;
	}

	&:last-of-type {
		text-align: right;
	}
}
</style>
