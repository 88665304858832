/**
 * Serializes an object of query parameters into a URL query string.
 *
 * This function takes an object where keys represent query parameter names
 * and values represent the corresponding values. It transforms this object into a query string
 * suitable for use in URLs. Array values are serialized by appending multiple key-value pairs for each item in the array.
 *
 * @template T - The type of the query parameters object.
 * @param {T} params - The query parameters object to be serialized.
 *
 * @returns {string} The serialized query string to be appended to the URL.
 */
export function serializeQueryParams<T>(params: T): string {
	const queryParams = new URLSearchParams();

	for (const key in params) {
		if (params && params.hasOwnProperty(key)) {
			const value = params[key as keyof T];
			if (Array.isArray(value)) {
				value.forEach((v) => queryParams.append(key, v));
			} else {
				queryParams.append(key, value as string);
			}
		}
	}

	return queryParams.toString();
}
