import { Address, TaxInfo } from 'types/form';
import { apiRequest, apiSecureRequest, investmentEntityRequest } from '@utils/request';
import { EmailSubscriptionDetails, UpdateEmailGroupSubscription } from 'types/email';
import { PhoneData, User, UserSignupForm } from 'types/user';
import { AxiosResponse } from 'axios';
import { IndividualAccountContactDetails } from 'types/checkout';
import { OauthTokenResponse } from 'types/security';
import { setCookie } from '@utils/web-storage';
import { UpdateSsnType } from 'types/account';

export const createUser = async (userSignupform: UserSignupForm): Promise<OauthTokenResponse> => {
	const response: AxiosResponse = await apiRequest.post(`/user`, userSignupform);

	setCookie('userLoggedIn', 'true', response.data.accessToken.refresh_expires_in);
	return response.data.accessToken;
};

export const getUser = async (): Promise<User> => {
	const response: AxiosResponse = await apiSecureRequest.get(`/user`);
	const userData: User = response.data;
	return userData;
};

export const getEmailVerificationStatus = async (): Promise<boolean> => {
	const response: AxiosResponse = await apiSecureRequest.get(`user/email-verified`);
	return response.data.emailVerified;
};

export const setUserPhoneNumber = async (phoneNumber: string): Promise<void> => {
	await apiSecureRequest.post(`user/phone-number`, {
		phoneNumber
	});
};

export const getPhoneData = async (): Promise<PhoneData> => {
	const response: AxiosResponse = await apiSecureRequest.get(`user/phone-numbers`);
	return response.data;
};

export const getAddress = async (): Promise<Address | undefined> => {
	const response: AxiosResponse = await investmentEntityRequest.get(`/current-address`, {
		suppressToast: (resp: AxiosResponse) => resp.status === 404
	});
	return response.data;
};

export const getUserAddress = async (): Promise<Address | undefined> => {
	const response: AxiosResponse = await apiSecureRequest.get(`user/current-address`, {
		suppressToast: (resp: AxiosResponse) => resp.status === 404
	});
	return response.data;
};

export const setUserFirstAndLastNames = async (firstName: string, lastName: string): Promise<void> => {
	await apiSecureRequest.post(`/user/set-name`, {
		firstName,
		lastName
	});
};

export const setUserCountry = async (selections: {
	citizenshipCountryCode: string;
	residenceCountryCode: string;
}): Promise<void> => {
	await apiSecureRequest.post(`/user/set-country`, selections);
};

export const setUserSsn = async (ssn: string): Promise<UpdateSsnType> => {
	const response = await apiSecureRequest.post('/user/set-ssn', { ssn });
	const hasSubmittedSameSsn = response.data.hasSubmittedSameSsn;
	return hasSubmittedSameSsn ? 'confirm' : 'update';
};

export const submitMobileVerificationCode = async (phoneNumberId: string, verificationCode: string): Promise<void> => {
	await apiSecureRequest.post(`/account/security/mobile-number`, { authCode: verificationCode, phoneNumberId });
};

export const sendMobileVerificationCode = async (phoneNumber: string): Promise<void> => {
	await apiSecureRequest.post(`/account/security/mobile-number/send-code`, { newPhoneNumber: phoneNumber });
};

export const resendMobileVerificationCode = async (phoneNumber: string): Promise<void> => {
	await apiSecureRequest.post(`/account/security/mobile-number/resend-code`, { newPhoneNumber: phoneNumber });
};

export const getEmailSubscriptions = async (): Promise<EmailSubscriptionDetails> => {
	const response: AxiosResponse = await apiSecureRequest.get(`/notifications/email-subscriptions`);
	return response.data;
};

export const updateEmailSubscriptions = async (
	updateEmailGroupSubscriptions: Array<UpdateEmailGroupSubscription>
): Promise<void> => {
	await apiSecureRequest.post(`/notifications/email-subscriptions`, { updateEmailGroupSubscriptions });
};

export const updateMobileNumber = async (payload: { phoneNumberId: string }): Promise<void> => {
	await apiSecureRequest.post(`/account/security/mobile-number/`, payload);
};

export const updateContactDetails = async (payload: IndividualAccountContactDetails): Promise<void> => {
	await apiSecureRequest.post(`/user/contact-details`, payload, {
		suppressToast: () => payload.verifyAddress
	});
};

export const updateTaxDetails = async (payload: TaxInfo): Promise<void> => {
	await apiSecureRequest.post('/user/tax-details', payload);
};
