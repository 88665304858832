import { apiRequest, apiSecureRequest, investmentEntityRequest } from '@utils/request';
import {
	DtcParticipant,
	IraAccountDetails,
	IraAccountType,
	IraAddFundsExistingOrderGroupParams,
	IraAddFundsNewContributionOrderGroupParams,
	IraAddFundsTransferOrderGroupParams,
	IraApiFundingType,
	IraCompleteRolloverOrderGroupParams,
	IraContribution,
	IraData,
	IraDocumentSubmission,
	IraDocumentType,
	IraEntityInfo,
	IraFeesAndContributionLimits,
	IraFeeWaiverQualifications,
	IraFundingType,
	IraIncompleteOrderInfo,
	IraInvestmentLimit,
	IraManualVerification,
	IraOnboardingMetadata,
	IraOtpDeliveryType,
	IraPersonalInformation,
	IraRolloverInfo,
	IraRolloverTypeWithLabel,
	IraSubmitRolloverParams,
	IraTypeWithLabel,
	IraVerification,
	IraVerificationDocument,
	MillenniumVerificationType,
	RolloverMethod,
	RolloverType,
	SourceAccountTypeSubmission
} from 'types/ira';
import { AxiosResponse } from 'axios';
import { BankAccount } from 'types/payment';
import { CreateIra } from 'types/checkout';
import deepMerge from 'deepmerge';
import { formatBankAccounts } from '@api/bank-accounts';
import { PhoneNumber } from 'types/user';
import { RiaPlan } from 'types/plan';

export const createIra = async (createIraForm: CreateIra): Promise<IraEntityInfo> => {
	const response = await apiSecureRequest.post(`/user/ira/create`, {
		...createIraForm
	});

	return response.data;
};

export const getContributionLimits = async (
	reitOfferingVintageId: string | null = null
): Promise<Array<IraContribution>> => {
	const params = { reitOfferingVintageId };
	const response = reitOfferingVintageId
		? await investmentEntityRequest.get(`/ira/contribution/limits`, { params })
		: await investmentEntityRequest.get(`/ira/contribution/limits`);
	return response.data;
};

export const getInvestmentLimits = async (reitOfferingVintageId: string | null = null): Promise<IraInvestmentLimit> => {
	const params = { reitOfferingVintageId };
	const response = reitOfferingVintageId
		? await investmentEntityRequest.get(`/ira/investment-limits`, { params })
		: await investmentEntityRequest.get(`/ira/investment-limits`);
	return response.data;
};

export const getEntryData = async (): Promise<IraData> => {
	const response = await investmentEntityRequest.get(`/ira/onboarding`, {
		suppressToast: (resp: AxiosResponse) => resp.status === 403
	});

	const iraEntryData = response.data;

	if (!iraEntryData.personalInfo.mailingAddress) {
		iraEntryData.personalInfo.mailingAddress = null;
	}

	return iraEntryData;
};

export const resetIra = async (): Promise<IraData> => {
	const response = await investmentEntityRequest.post(`/ira/onboarding/reset`);
	const iraData = response.data;

	if (!iraData.personalInfo.mailingAddress) {
		iraData.personalInfo.mailingAddress = null;
	}

	iraData.onboardingStatus = 'ira-funding';

	return iraData;
};

export const getIncompleteOrder = async (): Promise<IraIncompleteOrderInfo> => {
	const response = await investmentEntityRequest.get(`/ira/incomplete-order`);
	return response.data;
};

export const getVerifyFlowInfo = async (): Promise<IraEntityInfo> => {
	const response = await investmentEntityRequest.get(`/ira/verify/start`);
	return response.data;
};

export async function getIraBankAccounts(): Promise<Array<BankAccount>> {
	const response = await investmentEntityRequest.get(`/ira/onboarding/bank-accounts`);

	return formatBankAccounts(response.data);
}

export const getMTCDocuments = async (): Promise<Array<IraDocumentType>> => {
	const response = await investmentEntityRequest.get(`/mtc/documents`);
	return response.data.documentTypes;
};

export const getMTCDocumentsForInvestment = async (orderGroupId: string): Promise<Array<IraDocumentType>> => {
	const response = await investmentEntityRequest.get(`/order-group/${orderGroupId}/mtc/documents`);

	return response.data.documentTypes;
};

export const getContactDocuments = async (): Promise<IraDocumentType> => {
	const response = await apiSecureRequest.get(`/mtc/documents/contact`);

	return response.data;
};

export const getSupportedSourceAccountTypes = async (): Promise<Array<IraTypeWithLabel>> => {
	const response = await apiSecureRequest.get(`/ira/transfer/supported-types`);

	return response.data;
};

export const getSupportedRolloverTypes = async (): Promise<Array<IraRolloverTypeWithLabel>> => {
	const response = await apiSecureRequest.get(`/ira/rollover/source-account-types`);

	return response.data;
};

export const getTargetAccountType = async (): Promise<IraTypeWithLabel> => {
	const response = await investmentEntityRequest.get(`/ira/onboarding/target-account-type`);

	return response.data;
};

export const getDTCParticipants = async (): Promise<Array<DtcParticipant>> => {
	const response = await apiSecureRequest.get(`/ira/transfer/dtc-participants`);

	return response.data;
};

export const completeIraRepeatInvestment = async (orderGroupId: string): Promise<void> => {
	await investmentEntityRequest.post(`ira/investment/order-group/${orderGroupId}/complete-repeat-investment`);
};

export const checkSourceEligibilityAddFunds = async (
	sourceAccountType: IraAccountType
): Promise<{ compatible: boolean; sourceAccountType: IraTypeWithLabel }> => {
	const response = await investmentEntityRequest.post(`/ira/transfer/check-eligibility`, {
		sourceAccountType
	});
	return response.data;
};

export const checkRolloverSourceEligibilityAddFunds = async (
	sourceAccountType: IraAccountType
): Promise<{ compatible: boolean; sourceAccountType: IraTypeWithLabel }> => {
	const response = await investmentEntityRequest.post(`/ira/rollover/check-eligibility`, {
		sourceAccountType
	});
	return response.data;
};

export const submitSourceAccountType = async (sourceAccountType: IraAccountType): Promise<IraTypeWithLabel> => {
	const response = await investmentEntityRequest.post(`/ira/onboarding/source-account-type`, {
		sourceAccountType
	});
	return response.data.sourceAccountType;
};

export const submitRolloverAccountType = async (rolloverType: RolloverType): Promise<IraOnboardingMetadata> => {
	const response = await investmentEntityRequest.post(`/ira/onboarding/rollover-type`, {
		rolloverType
	});
	return response.data;
};

export const submitRolloverMethod = async (rolloverMethod: RolloverMethod): Promise<void> => {
	await investmentEntityRequest.post(`/ira/onboarding/rollover-method`, {
		rolloverMethod
	});
};

export const submitContactDocument = async (contactDocumentId: string): Promise<void> => {
	await investmentEntityRequest.post(`/mtc/contact`, {
		contactDocumentId
	});
};

export const submitAddFundsOrderGroup = async (
	params:
		| IraAddFundsNewContributionOrderGroupParams
		| IraAddFundsExistingOrderGroupParams
		| IraAddFundsTransferOrderGroupParams
		| IraCompleteRolloverOrderGroupParams
): Promise<string> => {
	const response = await investmentEntityRequest.post(`/ira/investment/order-group`, {
		isIraOnboardingCheckout: false,
		...params
	});

	return response.data.orderGroupId;
};

export const submitOnboardingOrderGroup = async (): Promise<string> => {
	const response = await investmentEntityRequest.post(`/ira/investment/order-group`, {
		isIraOnboardingCheckout: true
	});

	return response.data.orderGroupId;
};

export const submitOnboardingRollover = async (): Promise<void> => {
	await investmentEntityRequest.post(`/ira/rollover/submit`, {
		isOnboardingRollover: true
	});
};

export const submitAddFundsRollover = async (params: IraSubmitRolloverParams): Promise<void> => {
	await investmentEntityRequest.post(`/ira/rollover/submit`, {
		isOnboardingRollover: false,
		...params
	});
};

export const getPendingRollover = async (): Promise<IraRolloverInfo> => {
	const response = await await investmentEntityRequest.get(`/ira/rollover`);
	return response.data;
};

export const getIncompleteRollover = async (): Promise<IraRolloverInfo> => {
	const response = await investmentEntityRequest.get(`/ira/rollover/checkout/start`);
	return response.data;
};

export const resubmitPendingRollover = async (): Promise<void> => {
	await investmentEntityRequest.post(`/ira/rollover/resubmit`);
};

export const sendMtcCode = async (otpDeliveryType: IraOtpDeliveryType): Promise<IraVerification> => {
	const response = await investmentEntityRequest.post(`/mtc/verification`, { otpDeliveryType });
	return response.data;
};

export const submitAmount = async (amount: string, taxYear: string): Promise<void> => {
	await investmentEntityRequest.post(`/ira/onboarding/contribution`, {
		selectedAmount: amount,
		selectedTaxYear: taxYear
	});
};

export const completeOnboarding = async (): Promise<void> => {
	await investmentEntityRequest.post(`/ira/onboarding/complete`);
};

export const submitMtcSignatures = async (
	orderGroupId: string,
	documentsToSubmit: Array<IraDocumentSubmission>
): Promise<void> => {
	await investmentEntityRequest.post(`/order-group/${orderGroupId}/mtc/signatures`, {
		documentSignatures: documentsToSubmit
	});
};

export const submitMtcRolloverSignatures = async (documentsToSubmit: Array<IraDocumentSubmission>): Promise<void> => {
	await investmentEntityRequest.post(`/mtc/rollover/signatures`, {
		documentSignatures: documentsToSubmit
	});
};

export const submitPersonalInfo = async (info: IraPersonalInformation): Promise<void> => {
	await investmentEntityRequest.post(`/ira/onboarding/personal-information`, info);
};

export const submitSelectedNumber = async (
	newPhoneNumberObj: Partial<Record<'newPhoneNumber' | 'phoneNumberId', string>>
): Promise<PhoneNumber> => {
	const response = await investmentEntityRequest.post(`/ira/onboarding/phone-number`, newPhoneNumberObj);
	return response.data;
};

export const submitPhoneVerification = async (authCode: string): Promise<IraVerification> => {
	const response = await investmentEntityRequest.post(`/ira/onboarding/verify-phone-number`, { authCode });
	return response.data;
};

export const getFundingTypes = async (): Promise<Array<IraApiFundingType>> => {
	const response = await investmentEntityRequest.get(`/ira/funding-types`);
	return response.data;
};

export const submitFundingType = async (fundingType: IraFundingType): Promise<void> => {
	await investmentEntityRequest.post(`/ira/onboarding/funding-type`, { fundingType });
};

export const submitAccountType = async (iraType: IraAccountType): Promise<IraTypeWithLabel> => {
	const response = await investmentEntityRequest.post(`/ira/onboarding/type`, { iraType });
	return response.data.accountType;
};

export const submitDripPreference = async (dripEnabled: boolean): Promise<void> => {
	await investmentEntityRequest.post(`/ira/onboarding/drip-preference`, { dripEnabled });
};

export const submitPaymentMethod = async (selectedPaymentOptionId: string): Promise<void> => {
	await investmentEntityRequest.post(`/ira/onboarding/select-bank-account`, { selectedPaymentOptionId });
};

export const submitAcknowledgedFees = async (acknowledgedFees: boolean): Promise<void> => {
	await investmentEntityRequest.post(`/ira/onboarding/acknowledge-fees`, { acknowledgedFees });
};

export const submitAcknowledgedRollover = async (acknowledgedRollover: boolean): Promise<void> => {
	await investmentEntityRequest.post(`/mtc/rollover/acknowledge`, { acknowledgedRollover });
};

export const submitSimpleIraFirstContributionDate = async (
	simpleIraFirstContributionDate: string
): Promise<boolean> => {
	const response = await investmentEntityRequest.post(`/ira/onboarding/simple-ira/first-contribution-date`, {
		simpleIraFirstContributionDate
	});
	return response.data.simpleIraFirstContributionDateValid;
};

export const submitRolloverCheckDate = async (indirectRolloverChequeDate: string): Promise<boolean> => {
	const response = await investmentEntityRequest.post(`/ira/onboarding/indirect-rollover-cheque-date`, {
		indirectRolloverChequeDate
	});
	return response.data.indirectRolloverChequeDateValid;
};

export const checkSimpleIraFirstContributionDateAddFunds = async (
	simpleIraFirstContributionDate: string
): Promise<boolean> => {
	const response = await apiSecureRequest.post(`/ira/transfer/simple-ira/check-eligibility`, {
		simpleIraFirstContributionDate
	});
	return response.data.simpleIraFirstContributionDateValid;
};

export const checkRolloverCheckDate = async (indirectRolloverChequeDate: string): Promise<boolean> => {
	const response = await apiSecureRequest.post(`/ira/rollover/indirect/check-eligibility`, {
		indirectRolloverChequeDate
	});
	return response.data.indirectRolloverChequeDateValid;
};

export const submitPlan = async (riaPlanId: string): Promise<RiaPlan> => {
	const response = await investmentEntityRequest.post(`/ira/onboarding/ria-plan`, { riaPlanId });
	return response.data;
};

export const submitEmailRequest = async (initialContributionDate: string): Promise<void> => {
	await investmentEntityRequest.post(`/ira/transfer/schedule-reminder`, { initialContributionDate });
};

export const submitTransferAccountInfo = async (info: SourceAccountTypeSubmission): Promise<void> => {
	const infoCopy = deepMerge(info, {});
	if (info.sourceDtcParticipant) {
		infoCopy.sourceDtcParticipantId = info.sourceDtcParticipant.dtcParticipantId;
	}

	await investmentEntityRequest.post(`/ira/onboarding/source-account-information`, { ...infoCopy });
};

export const submitTransferAmount = async (selectedAmount: string): Promise<void> => {
	await investmentEntityRequest.post(`/ira/onboarding/transfer/amount`, { selectedAmount });
};

export const getFeeSchedule = async (): Promise<ArrayBuffer> => {
	const response = await apiSecureRequest.get(`/mtc/fee-schedule`, {
		responseType: 'arraybuffer'
	});
	return response.data;
};

export const getIraInfoForRepeatInvestment = async (): Promise<IraEntityInfo> => {
	const response = await investmentEntityRequest.get(`/ira/add-funds/start`, {
		suppressToast: (resp: AxiosResponse) => resp.status === 403
	});
	return response.data;
};

export const getVerificationManual = async (): Promise<IraManualVerification> => {
	const response = await investmentEntityRequest.get('/mtc/verification/manual');
	return response.data;
};

export const uploadVerificationManual = async (uploadForm: {
	[verificationType in MillenniumVerificationType]?: IraVerificationDocument | null;
}): Promise<IraVerification> => {
	const response = await investmentEntityRequest.post('/mtc/verification/manual/upload', uploadForm);
	return response.data;
};

export const getIraFeeWaiverQualificationData = async (orderAmount: string): Promise<IraFeeWaiverQualifications> => {
	const params = { orderAmount };
	const response = await investmentEntityRequest.get(`/ira/fee-waiver-qualifications`, { params });
	return response.data;
};

export const getIraFundingType = async (): Promise<IraFundingType | null> => {
	const response = await investmentEntityRequest.get(`/ira/onboarding/funding-type`);
	if (response.data.fundingType) {
		return response.data.fundingType;
	} else {
		return null;
	}
};

export const getIraAccountDetails = async (): Promise<IraAccountDetails> => {
	const response = await investmentEntityRequest.get(`/ira`);
	return response.data;
};

export const getIraFeesAndContributionLimits = async (): Promise<IraFeesAndContributionLimits> => {
	const response = await apiRequest.get('/ira/fees-and-contribution-limits');
	return response.data;
};
