import { createIsbot, isbot } from 'isbot';
import { app } from '@store/modules/app';

export const userAgentIsBot = (userAgent = window.navigator.userAgent): boolean => {
	const isIeBot = createIsbot(/MSIE (\d+\.\d+);.*Trident\/(\d+\.\d+)/);
	return checkThirdParty(userAgent) || isbot(userAgent) || isIeBot(userAgent) || checkAzureBot(userAgent);
};

const userAgentIsThirdParty = createIsbot(/(Windows|Linux).*Chrome\/(108|91|86)/);
const userAgentIsThirdPartyRecentVersion = createIsbot(/(Windows|Linux).*Chrome\/(129)/);

export const checkAzureBot = (userAgent = window.navigator.userAgent): boolean => {
	const azureViewportHeight = 960;
	const azureViewportWidth = 1280;
	const isAzureViewport = window.innerWidth === azureViewportWidth && window.innerHeight === azureViewportHeight;
	const azureBot = createIsbot(/(Windows).*Chrome\/(125)/);

	return azureBot(userAgent) && isAzureViewport;
};

export const checkThirdParty = (userAgent = window.navigator.userAgent): boolean => {
	const thirdPartyViewportHeight = 600;
	const thirdPartyViewportWidth = 800;
	const thirdPartyRoutes = ['/forbidden', '/login', '/account/overview', '/account/transactions', '/logout-success'];
	const route: boolean = thirdPartyRoutes.some((tpRoute) => tpRoute === window.location.pathname);
	const isThirdPartyViewport =
		window.innerWidth === thirdPartyViewportWidth && window.innerHeight === thirdPartyViewportHeight;
	const isThirdPartyUserAgentLegacyBrowsers = userAgentIsThirdParty(userAgent) && route;
	const isThirdPartyUserAgentRecentVersion =
		userAgentIsThirdPartyRecentVersion(userAgent) && route && isThirdPartyViewport;
	const isThirdPartyUserAgent = isThirdPartyUserAgentLegacyBrowsers || isThirdPartyUserAgentRecentVersion;

	return isThirdPartyUserAgent || app.isThirdPartyLogin;
};
