<template>
	<BaseLink
		:link="{
			router: title.router,
			params: {
				...title.params
			},
			state: {
				fromRoute: currentRoute
			}
		}"
		:classes="newsFeedCardClasses"
		data-test="news-feed-item"
		click-text="Newsfeed Item"
		mixpanel-element="Module"
		:mixpanel-target="mxpViewTitle"
	>
		<article ref="target" class="news-feed-card-img-container display-flex position-relative">
			<div class="news-feed-card-img" aria-label="figure" :style="`background-image: url(${thumbnailUrl});`" />
			<div class="news-feed-card-content p-100 flex-1">
				<div class="mb-100 mt-25 display-flex align-items-flex-start justify-content-space-between">
					<Tag :text="item.summary" :background="isPinnedUpdate ? 'white' : 'default'" size="xs" />
					<div class="display-flex">
						<div
							v-if="isPinnedUpdate"
							class="news-feed-card-pinned-flag text-color-white position-absolute"
							data-test="pinned-icon"
						>
							<BaseSvgIcon class="text-color-white" name="star" height="22" width="22" />
						</div>
					</div>
				</div>
				<h6
					:class="[
						'heading-6 dark mt-200 display-block ellipsis news-feed-card-title',
						{ 'news-feed-card-pinned-hover': isPinnedUpdate },
						{ 'news-feed-card-title-dividend': isDividend }
					]"
					>{{ title.text }}
					<span v-if="props.item.bodyText" class="news-feed-card-title-dividend-amount"
						>: + {{ props.item.bodyText }}</span
					>
				</h6>
				<p
					v-if="shouldShowSubtitle"
					class="news-feed-card-subtitle mt-50 text-color-content-primary"
					data-test="subtitle"
				>
					{{ subtitle }}
				</p>
				<component
					:is="componentFromUpdateType"
					v-if="componentFromUpdateType"
					:item="item"
					data-test="update-type-content"
				/>
				<p class="news-feed-card-cta mt-100 text-align-right">
					<span :class="['cta-link--rust', { 'news-feed-card-text-color-blue': isPinnedUpdate }]">{{
						ctaText
					}}</span>
				</p>
			</div>
		</article>
	</BaseLink>
</template>

<script lang="ts">
export default {
	name: 'NewsFeedCard'
};
</script>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { appCloudfrontPath } from '@constants';
import { Link } from 'types/layout';
import NewsFeedCardContentAsset from '@components/account/news-feed-card-content-asset.vue';
import NewsFeedCardContentDividend from '@components/account/news-feed-card-content-dividend.vue';
import NewsFeedCardContentFirstInvestment from '@components/account/news-feed-card-content-first-investment.vue';
import { NewsFeedItem } from 'types/account';
import Tag from '@components/tags/tag-component.vue';
import { trackMixpanelScrollIntoView } from '@utils/analytics';
import { useRoute } from 'vue-router';
import type { Component as ComponentType } from 'vue';

interface Props {
	item: NewsFeedItem;
	isPinnedUpdate?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	isPinnedUpdate: false
});

const route = useRoute();

const target = ref<HTMLElement | null>(null);

const newsFeedCardClasses = computed((): string => {
	const base = 'news-feed-card mb-200 text-align-left width-100';
	const bgColorClass = props.isPinnedUpdate ? 'bg-color-info-tint' : 'bg-color-white';

	return `${base} ${bgColorClass}`;
});

const ctaText = computed((): string => {
	if (isFirstInvestment.value) {
		return 'View your portfolio';
	}

	if (isDividend.value) {
		return 'View transactions';
	}

	if (storyOnly.value) {
		return 'Watch story';
	}

	return 'Read more';
});

const isDividend = computed((): boolean => {
	switch (props.item.type) {
		case 'DIVIDEND_DISBURSEMENT':
		case 'DIVIDEND_REINVESTMENT':
		case 'REIT_DIVIDEND':
			return true;
		default:
			return false;
	}
});

const isAssetUpdate = computed((): boolean => {
	switch (props.item.type) {
		case 'INVESTOR_UPDATE_ASSET_ACQUISITION':
		case 'INVESTOR_UPDATE_ASSET_EXIT':
		case 'INVESTOR_UPDATE_ASSET_OTHER':
			return true;
		default:
			return false;
	}
});

const isFirstInvestment = computed((): boolean => {
	return props.item.type === 'FIRST_INVESTMENT_SNAPSHOT';
});

const shouldShowAssetDetails = computed((): boolean => {
	return !!props.item.associatedAsset?.realEstateAndPrivateCreditFields?.strategy?.name;
});

const componentFromUpdateType = computed((): ComponentType | null => {
	if (isAssetUpdate.value && shouldShowAssetDetails.value) {
		return NewsFeedCardContentAsset;
	} else if (isDividend.value) {
		return NewsFeedCardContentDividend;
	} else if (isFirstInvestment.value) {
		return NewsFeedCardContentFirstInvestment;
	} else {
		return null;
	}
});

const subtitle = computed((): string => {
	if (isFirstInvestment.value) {
		return "Here's a snapshot of your portfolio on day one.";
	}

	if (isDividend.value) {
		return '';
	}

	return props.item?.associatedInvestorUpdate?.subtitle ?? '';
});

const thumbnailUrl = computed((): string => {
	if (isFirstInvestment.value) {
		return props.item.associatedFirstInvestmentSnapshot?.thumbnailUrl ?? '';
	}

	if (isDividend.value) {
		return `${appCloudfrontPath}/images/dashboard/news-feed-dividend.jpg`;
	}

	return props.item?.associatedInvestorUpdate?.thumbnailUrl ?? '';
});

const title = computed((): Link => {
	if (isFirstInvestment.value) {
		return {
			text: 'Welcome to Fundrise',
			router: 'account-portfolio'
		};
	}

	if (isDividend.value) {
		return {
			text: props.item.bodyTitle,
			router: 'account-transactions'
		};
	}

	return {
		text: props.item?.associatedInvestorUpdate?.title ?? '',
		router: 'investor-update',
		params: { investorUpdateId: props.item.associatedInvestorUpdate?.id }
	};
});

const mxpViewTitle = computed((): string => {
	// This value create consistency with mobile teams.
	if (isFirstInvestment.value) {
		return subtitle.value;
	}

	// Send bodyTitle consistently - display title changes depending on screen size.
	if (isDividend.value) {
		return props.item.bodyTitle;
	}

	return title.value.text ?? '';
});

const hasStory = computed((): boolean => {
	return !!props.item.story;
});

const shouldShowSubtitle = computed((): boolean => {
	return !!subtitle.value && !hasStory.value;
});

const currentRoute = computed((): string => {
	return route.path;
});

const storyOnly = computed((): boolean => {
	return props.item.storyOnly;
});

onMounted(() => {
	if (target.value) {
		trackMixpanelScrollIntoView(target.value, {
			'View Title': mxpViewTitle.value,
			'View Content': 'Newsfeed',
			'View ID': props.item.type
		});
	}
});
</script>
<style lang="scss">
@use '../../styles/constants/config.scss' as *;

.news-feed-card {
	&:not(:last-of-type) {
		margin-bottom: 2rem;
	}

	display: block;
	border-radius: $module-border-radius;
	border: solid 1px token('bg-tertiary');
	color: token('content-primary');
	overflow: hidden;
	container-type: inline-size;

	&:hover {
		color: token('content-primary');
	}

	&-bg-greige {
		background-color: #e7e9e9;
	}

	&-content {
		min-width: 0;
		position: relative;
	}
	&-img {
		background-size: cover;
		background-position: center;
	}
	&-pinned-hover:hover {
		color: slate(80);
	}
	&-pinned-flag {
		top: 0;
		right: 0;
		background-color: slate(80);
		border-radius: 0 0 4px 4px;
		padding: 15px 4px 8px 4px;
		margin: 0 20px 20px 7px;
	}
	&-subtitle {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}
	&-title-dividend-amount {
		display: inline-block;
	}
	&-text-color-blue {
		color: slate(80);
		&:hover {
			color: slate(90);
		}
	}

	@container (max-width: 549px) {
		&-img {
			min-height: 225px;
			max-height: 225px;
		}
		&-img-container {
			flex-direction: column;
		}
	}

	@container (min-width: 550px) {
		&-content {
			min-height: 250px;
			max-height: 250px;
		}
		&-cta {
			bottom: 16px;
			right: 16px;
			position: absolute;
		}
		&-img {
			min-width: 272px;
			max-width: 272px;
		}
		&-subtitle {
			-webkit-line-clamp: 2;
		}
		&-title-dividend-amount {
			display: none;
		}
	}

	@container (min-width: 768px) {
		&-content {
			min-height: 275px;
			max-height: 275px;
		}
		&-img {
			min-width: 320px;
			max-width: 320px;
		}
	}

	@container (min-width: 1200px) {
		&-content {
			min-height: 325px;
			max-height: 325px;
		}
		&-img {
			min-width: 325px;
			max-width: 325px;
		}
	}
}
</style>
