import { AxiosRequestConfig, AxiosRequestInterceptorParams } from 'axios';

/**
 * Adds a header which allows us to specify a LaunchDarkly segment, which is used to override individual LaunchDarkly test configurations.
 * This can be used in development and omega only, by setting the `VITE_LD_USER_HEADER` environment variable to the segment name, e.g. web-e2e-control or web-e2e-variant
 */
function ldUserHeader(config: AxiosRequestConfig): AxiosRequestConfig {
	if (
		(import.meta.env.DEV || import.meta.env.MODE === 'omega') &&
		import.meta.env.VITE_LD_USER_HEADER &&
		config.headers
	) {
		config.headers['ld-user'] = import.meta.env.VITE_LD_USER_HEADER;
	}
	return config;
}

export const ldUserHeaderInterceptor: AxiosRequestInterceptorParams = [
	(config) => ldUserHeader(config),
	(error) => Promise.reject(error)
];
