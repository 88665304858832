import 'intersection-observer';
import { Directive, DirectiveBinding } from 'vue';

export const reveal: Directive = {
	beforeMount: function (el: HTMLElement, binding: DirectiveBinding) {
		let scrollObserver: IntersectionObserver | null = null;

		function animateIntersect(entries: Array<IntersectionObserverEntry>): void {
			entries.forEach((entry) => {
				if (scrollObserver) {
					const animationClass = binding.arg ? binding.arg + '-active' : 'fade-in-active';
					entry.target.classList.add('intersect-reveal-staged');
					entry.target.classList.add(animationClass);

					if (entry.intersectionRatio > scrollObserver.thresholds[0]) {
						entry.target.classList.add('intersect-reveal');

						scrollObserver.unobserve(entry.target);

						entry.target.addEventListener('animationend', () => {
							entry.target.classList.remove(
								'intersect-reveal-staged',
								'intersect-reveal',
								animationClass
							);
						});
					}
				}
			});
		}

		scrollObserver = new IntersectionObserver(animateIntersect, {
			threshold: 0.25
		});
		scrollObserver.observe(el);
	}
};
export default reveal;
