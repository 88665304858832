<template>
	<button ref="sButton" class="button" :class="buttonClasses" type="submit" @click="onClick()">
		<slot> Submit </slot>
	</button>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { MixpanelContext } from 'types/analytics';
import { trackMixpanelClick } from '@utils/analytics';
import { useAnalyticsProperties } from '@utils/composables/use-analytics-properties';

interface Props {
	classes?: string;
	isWorking?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	v$?: any;
	clickText?: string;
	mixpanelCustomProperties?: MixpanelContext;
}
const props = withDefaults(defineProps<Props>(), {
	classes: 'button-primary',
	isWorking: undefined,
	v$: undefined,
	clickText: undefined,
	mixpanelCustomProperties: undefined
});

interface Emits {
	(e: 'sb-click'): void;
}
const emit = defineEmits<Emits>();
const { getActionProperties } = useAnalyticsProperties();

const sButton = ref<HTMLElement | null>(null);

const buttonClasses = computed((): string => {
	if (props.isWorking) {
		return `${props.classes} active button-loading`;
	}

	if (props.v$.$invalid) {
		return `${props.classes} disabled`;
	}

	return `${props.classes}`;
});

function onClick(): void {
	if (!props.isWorking) {
		if (sButton.value) {
			trackMixpanelClick(
				getActionProperties({
					'Action Element': 'Submit Button',
					'Action Target': props.clickText,
					element: sButton.value,
					customProperties: { ...props.mixpanelCustomProperties }
				})
			);
		}

		if (!props.v$.$invalid) {
			emit('sb-click');
		} else {
			props.v$.$touch();
		}
	}
}
</script>
