import { appStoreVisitRedirect } from '@api/mobile-redirect';
import { flattenQueryParam } from '@utils/request';
import { FundriseRouteConfig } from 'vue-router';
import { isIphone } from '@utils/client';
import { MobileRedirectParams } from 'types/mobile-redirects';

const mobileRedirectRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/play-store-redirect',
		name: 'play-store-redirect',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to) => {
			const params: MobileRedirectParams = {
				utmCampaign: flattenQueryParam(to.query.utm_campaign) ?? null,
				utmContent: flattenQueryParam(to.query.utm_content) ?? null,
				utmMedium: flattenQueryParam(to.query.utm_medium) ?? null,
				utmSource: flattenQueryParam(to.query.utm_source) ?? null,
				distinctId: flattenQueryParam(to.query.distinct_id) ?? null,
				clickId: flattenQueryParam(to.query.clickid) ?? null,
				appType: 'GOOGLE_PLAY'
			};

			const mobileRedirectLink = await appStoreVisitRedirect(params);
			window.location.href = mobileRedirectLink.appRedirect;
		},
		// revisit using redirect
		component: () => import('@views/marketing-site/home-view.vue')
	},
	{
		path: '/app-store-redirect',
		name: 'app-store-redirect',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to) => {
			const params: MobileRedirectParams = {
				utmCampaign: flattenQueryParam(to.query.utm_campaign) ?? null,
				utmContent: flattenQueryParam(to.query.utm_content) ?? null,
				utmMedium: flattenQueryParam(to.query.utm_medium) ?? null,
				utmSource: flattenQueryParam(to.query.utm_source) ?? null,
				distinctId: flattenQueryParam(to.query.distinct_id) ?? null,
				clickId: flattenQueryParam(to.query.clickid) ?? null,
				appType: 'APPLE_APP_STORE'
			};

			const mobileRedirectLink = await appStoreVisitRedirect(params);
			window.location.href = mobileRedirectLink.appRedirect;
		},
		// revisit using redirect
		component: () => import('@views/marketing-site/home-view.vue')
	},
	{
		path: '/invest-now',
		name: 'invest-now',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: (to) => {
			if (isIphone()) {
				return { name: 'app-store-redirect', query: to.query };
			} else {
				return { name: 'start-investing', query: to.query };
			}
		}
	}
];

export default mobileRedirectRoutes;
