import { apiRequest, investmentEntityRequest } from '@utils/request';
import { Fund, PaginatedFunds } from 'types/funds';
import { isAdvisor, isAuthenticated } from '@utils/composables';
import { FundQueryParams } from 'types/sanity/proxy';
import { serializeQueryParams } from '@utils/sanity/proxy-query-params-serializer';

/**
 * Fetches funds based on the provided query parameters.
 *
 * @param {FundQueryParams} [params] - Optional query parameters to filter and customize the fund retrieval.
 *
 * All parameters are optional, and the request returns all funds if no parameters are supplied.
 *
 * By supplying the correct `include` query param, you can retrieve
 * information that is omitted by default.
 *
 * For more information, see the API overview documentation:
 * [Sanity Asset REIT migration - API overview](https://fundrise.atlassian.net/wiki/spaces/P/pages/3431038986/Sanity+Asset+REIT+migration+-+API+overview)
 * [API Query Parameter Overview](https://fundrise.atlassian.net/wiki/spaces/P/pages/3655925776/API+Query+Parameter+Overview#Fund-Query-Params)
 */
export async function getFunds(params?: FundQueryParams): Promise<PaginatedFunds> {
	let response;

	const payload = {
		params,
		paramsSerializer: serializeQueryParams
	};

	if (isAuthenticated.value && !isAdvisor.value) {
		response = await investmentEntityRequest.get('/funds', payload);
	} else {
		response = await apiRequest.get('/funds', payload);
	}

	return response.data;
}

/**
 * Get a single fund by ID
 */
export async function getFund(id: string, params?: Omit<FundQueryParams, 'fundriseId'>): Promise<Fund | null> {
	const funds = await getFunds({
		...params,
		fundriseId: [id],
		pageSize: 1,
		pageNumber: 1
	});

	return funds.results[0] ?? null;
}
