<template>
	<span>
		<span
			v-if="props.placehold"
			class="placeholder-inline__inner"
			:class="{ 'placeholder-inline__inner--reversed': props.theme === 'dark' }"
			:style="{ 'padding-left': props.width }"
		>
		</span>
		<slot v-else />
	</span>
</template>
<script setup lang="ts">
interface Props {
	placehold?: boolean;
	theme?: string;
	width?: string;
}

const props = withDefaults(defineProps<Props>(), {
	placehold: true,
	theme: 'light',
	width: '3em'
});
</script>
