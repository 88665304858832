import { computed, ref } from 'vue';
import {
	CustomAllocationOfferingResponse,
	CustomAllocationPlanRequest,
	CustomAllocationPlanResponse,
	CustomAllocationResponse,
	CustomAllocationType,
	CustomPlansRoute,
	CustomPlansSettingsRoute
} from 'types/custom-plans';
import {
	getCustomAllocationPlan,
	saveCustomAllocationPlan as saveAllocationPlan,
	saveAllocationType
} from '@api/custom-allocation-plan';
import { app } from '@store/modules/app';
import { customPlanFundDetailMapper } from '@utils/sanity/fund-detail-mapper';
import { defineStore } from 'pinia';
import { FundCategory } from 'types/funds';
import { getFunds } from '@sanity/proxy/funds';
import { investmentEntity } from '@store/modules/investment-entity';
import { K1Acknowledgement } from 'types/form';
import { mapCustomPlanAllocations } from '@utils/custom-plans';
import { user } from '@store/modules/user';

export const useCustomPlansStore = defineStore('customPlans', () => {
	const acknowledgedK1Disclosure = ref<K1Acknowledgement>({ acknowledged: false });
	const hasAcknowledgedContingencyPlan = ref(false);
	const hasSubmittedPlan = ref(false);
	const isEditMode = ref(false);
	const isFlowStarted = ref(false);

	const customAllocationPlan = ref<CustomAllocationPlanResponse | null>(null);
	const offeringsList = ref<Array<CustomAllocationOfferingResponse>>([]);
	const offeringCategories = ref<Array<FundCategory>>([]);

	const customAllocationSelections = ref<Array<CustomAllocationResponse>>([]);
	const customAllocationType = ref<CustomAllocationType | null>(null);

	const requiresContingencyAcknowledgement = computed((): boolean => {
		const containsFundRequiringAcknowledgement = customAllocationSelections.value
			.filter((selection) => selection.weight)
			.map((selection) => offeringsList.value.find((offering) => offering.reitId === selection.reitId))
			.some((selection) => selection?.requiresContingencyPlan);
		return containsFundRequiringAcknowledgement && !hasAcknowledgedContingencyPlan.value;
	});

	const hasCustomAllocationSelections = computed((): boolean => {
		return customAllocationSelections.value.length > 0;
	});

	const saveCustomAllocationPlanPayload = computed((): CustomAllocationPlanRequest => {
		const allocations = customAllocationSelections.value
			.filter((c) => c.weight)
			.map((c) => ({
				reitId: c.reitId,
				weight: +c.weight
			}));

		return { allocations, allocationType: customAllocationType.value as CustomAllocationType };
	});

	async function initializeEditMode(): Promise<void> {
		if (!isEditMode.value || customAllocationSelections.value.length) return;

		if (!customAllocationPlan.value) {
			await fetchAndStoreCustomAllocationPlan();
		}

		await fetchAndStoreOfferings();
		const allocationType = customAllocationPlan.value?.allocationType as CustomAllocationType;

		customAllocationSelections.value = mapCustomPlanAllocations(
			customAllocationPlan.value?.allocations ?? [],
			offeringsList.value
		);
		customAllocationType.value = allocationType;
	}

	async function fetchAndStoreCustomAllocationPlan(force = false): Promise<void> {
		if (!customAllocationPlan.value || force) {
			const data = await getCustomAllocationPlan();
			const sortedAllocations = [...data.allocations].sort((a, b) => Number(b.weight) - Number(a.weight));
			const plan = { ...data, allocations: sortedAllocations };
			customAllocationPlan.value = plan;
		}
	}

	async function fetchAndStoreOfferings(): Promise<void> {
		if (!offeringsList.value.length || !offeringCategories.value.length) {
			const fundsData = await getFunds({ context: 'CUSTOM_OFFERINGS' });
			const fundCategories: Array<FundCategory> = [];
			fundsData.results.forEach((fund) => {
				if (fund.category && !fundCategories.find((category) => category._id === fund.category?._id)) {
					fundCategories.push(fund.category);
				}
			});

			fundCategories.sort((a, b) => (a.priority && b.priority ? parseInt(a.priority) - parseInt(b.priority) : 0));
			offeringCategories.value = fundCategories;

			const offeringsData = fundsData.results.map((fund) => customPlanFundDetailMapper(fund));
			offeringsList.value = offeringsData;
		}
	}

	async function storeCustomAllocationSelections(
		selections: Array<CustomAllocationResponse>
	): Promise<CustomPlansRoute> {
		customAllocationSelections.value = selections;
		hasAcknowledgedContingencyPlan.value = false;

		return 'custom-plans-backup-plan';
	}

	async function acknowledgeContingencyPlan(): Promise<CustomPlansRoute> {
		hasAcknowledgedContingencyPlan.value = true;
		return 'custom-plans-allocation-type';
	}

	async function storeCustomAllocationType(allocationType: CustomAllocationType): Promise<CustomPlansRoute> {
		customAllocationType.value = allocationType;
		return 'custom-plans-review';
	}

	async function saveCustomAllocationPlan(): Promise<CustomPlansRoute | CustomPlansSettingsRoute> {
		const payload = saveCustomAllocationPlanPayload;

		const data = await saveAllocationPlan(payload.value);
		customAllocationPlan.value = data;

		hasSubmittedPlan.value = true;

		if (investmentEntity.currentRiaPlanInvestmentStrategy !== 'CUSTOM_PORTFOLIO') {
			await user.refreshUser();
		}

		if (isEditMode.value) {
			app.ADD_TOAST({
				type: 'success',
				message: 'Your custom plan has been saved.'
			});

			return 'settings-investment-plan-view';
		} else {
			return 'custom-plans-success';
		}
	}

	async function updateCustomAllocationType(allocationType: CustomAllocationType): Promise<void> {
		const newPlan = await saveAllocationType(allocationType);
		customAllocationPlan.value = newPlan;
	}

	function $reset(): void {
		offeringsList.value = [];
		offeringCategories.value = [];
		customAllocationSelections.value = [];
		hasSubmittedPlan.value = false;
		hasAcknowledgedContingencyPlan.value = false;
		isEditMode.value = false;
		isFlowStarted.value = false;
		customAllocationPlan.value = null;
		customAllocationType.value = null;
	}

	return {
		acknowledgedK1Disclosure,
		hasAcknowledgedContingencyPlan,
		hasSubmittedPlan,
		isEditMode,
		isFlowStarted,
		customAllocationPlan,
		offeringsList,
		offeringCategories,
		customAllocationSelections,
		customAllocationType,
		requiresContingencyAcknowledgement,
		hasCustomAllocationSelections,
		initializeEditMode,
		fetchAndStoreCustomAllocationPlan,
		fetchAndStoreOfferings,
		storeCustomAllocationSelections,
		acknowledgeContingencyPlan,
		storeCustomAllocationType,
		saveCustomAllocationPlan,
		updateCustomAllocationType,
		$reset
	};
});
