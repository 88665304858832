<template>
	<a ref="bLinkB" :class="classes" @click="handleTracking($event)"><slot /></a>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { trackMixpanelClick } from '@utils/analytics';

interface Props {
	classes?: string;
	clickText?: string;
}

interface Emits {
	(e: 'click', value: Event): void;
}

const props = withDefaults(defineProps<Props>(), {
	classes: undefined,
	clickText: undefined
});

const emits = defineEmits<Emits>();

const bLinkB = ref<HTMLElement | null>(null);

function handleTracking($event: Event): void {
	const clickText = props.clickText;

	if (bLinkB.value) {
		trackMixpanelClick({
			'Action Element': 'Base Link Button',
			'Action Target': clickText,
			children: bLinkB.value.children,
			element: bLinkB.value
		});
		emits('click', $event);
	}
}
</script>
