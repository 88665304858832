<template>
	<div
		ref="toastRef"
		:class="{
			'toast--active': active,
			'toast--dismissed': dismissed,
			'toast--error': toast.type === 'error',
			'toast--success': toast.type === 'success',
			'toast--info': toast.type === 'info',
			'toast--warning': toast.type === 'warning'
		}"
		class="toast body-sm-smo display-flex"
		:data-test="`toast-${toast.type}`"
		@mouseover="clearDismissalTimer"
		@mouseout="setDismissalTimer(1000)"
		@click="trackClick"
	>
		<BaseSvgIcon :name="toast.type" height="24" width="24" class="toast__icon icon--24x24" />
		<div data-test="toast-message" class="mr-200" v-html="toast.message" />
		<BaseLinkButton class="toast__close line-height-100" data-test="close-toast" @click="dismissToast">
			<BaseSvgIcon name="close" height="16" width="16" class="icon--16x16" />
		</BaseLinkButton>
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { Toast } from 'types/layout';
import { trackMixpanelClick } from '@utils/analytics';

interface Props {
	toast: Toast;
}

interface Emits {
	(e: 'dismiss', value: Toast): void;
}

const props = defineProps<Props>();

const emits = defineEmits<Emits>();

const toastRef = ref<HTMLElement | null>(null);

const dismissAnimationTime = 500;

const initialTimeActive = 3000;

const active = ref(false);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dismissalTimer = ref<any>();

const dismissed = ref(false);

const messageContainsLink = computed((): boolean => props.toast.message.indexOf('</a>') >= 0);

function clearDismissalTimer(): void {
	clearTimeout(dismissalTimer.value);
}

function dismissToast(): void {
	clearDismissalTimer();

	dismissed.value = true;
	setTimeout(() => {
		remove();
	}, dismissAnimationTime);
}

function remove(): void {
	emits('dismiss', props.toast);
}

function setDismissalTimer(timeToSet: number): void {
	if (!messageContainsLink.value) {
		dismissalTimer.value = setTimeout(() => {
			dismissToast();
		}, timeToSet);
	}
}

function trackClick(): void {
	if (toastRef.value) {
		trackMixpanelClick(
			{
				children: toastRef.value.children,
				customProperties: { clickText: props.toast.message }
			},
			true
		);
	}
}

function setup(): void {
	setTimeout(() => {
		active.value = true;
		setDismissalTimer(initialTimeActive);
	}, 30);
}

setup();
</script>
