import { apiRequest } from '@utils/request';
import { getDistinctId } from '@utils/analytics';

export async function recordLD(testName: string): Promise<void> {
	const mixpanelId = getDistinctId();

	await apiRequest.post('/ld-ab-test/record', {
		testName,
		mixpanelId
	});
}
