<template>
	<div :class="[`button-group ${props.classes}`, { 'button-group__centered': props.centered }]">
		<div v-if="hasTertiarySlot" class="button-group-tertiary-slot">
			<slot name="tertiary" />
		</div>
		<slot name="secondary" />
		<slot name="primary" />
	</div>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';

interface Props {
	classes?: string;
	centered?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	classes: '',
	centered: false
});

const slots = useSlots();

const hasTertiarySlot = computed((): boolean => !!slots.tertiary);
</script>

<style lang="scss">
@use '../styles/utilities/respond-to.scss' as *;

.button-group {
	display: flex;
	margin-top: 2rem;

	@include respond-to(smo) {
		flex-direction: column-reverse;

		:not(:last-child) {
			margin-top: 1rem;
		}
	}

	@include respond-to(md) {
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		:not(:last-child) {
			margin-right: 1rem;
		}

		&__centered {
			justify-content: center;
		}
	}

	.button-group-tertiary-slot {
		@include respond-to(smo) {
			margin: 1rem auto 0;
		}

		@include respond-to(md) {
			margin-right: auto;
		}
	}
}
</style>
