import { app } from '@store/modules/app';

export const customerBetaEnrollRouteGuard = async () => {
	const eligibilityTiers = await Promise.all([
		app.isUserInLDTestGroup({ featureFlag: 'customer-beta-enrollment-tier-1', testGroup: 'test-eligible' }),
		app.isUserInLDTestGroup({ featureFlag: 'customer-beta-enrollment-tier-2', testGroup: 'test-eligible' }),
		app.isUserInLDTestGroup({ featureFlag: 'customer-beta-enrollment-tier-3', testGroup: 'test-eligible' }),
		app.isUserInLDTestGroup({ featureFlag: 'customer-beta-enrollment-tier-4', testGroup: 'test-eligible' }),
		app.isUserInLDTestGroup({ featureFlag: 'customer-beta-enrollment-tier-5', testGroup: 'test-eligible' })
	]);

	if (eligibilityTiers.some(Boolean)) {
		return true;
	} else {
		return { name: 'account-overview', replace: true };
	}
};
