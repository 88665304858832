<template>
	<div>
		<div id="main-nav" class="main-nav" :class="{ sticky: styles.isSticky }">
			<div class="container display-flex justify-content-space-between zeta">
				<nav class="display-flex align-items-center">
					<BaseLink :link="logoLink">
						<img :src="styles.logo" alt="Fundrise logo" style="width: 137px" />
					</BaseLink>
					<slot name="left" />
				</nav>
				<div class="display-flex align-items-center">
					<AddFundsButton
						v-if="showAddFunds"
						:class="addFundsButtonStyles"
						:narrow-padding="isInInvitationNavTest"
						button-size="mini"
						data-context="Main Nav"
						data-test="add-funds-button"
					/>
					<slot name="right" />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'DesktopNavTemplate'
};
</script>

<script setup lang="ts">
import AddFundsButton from '@components/account/add-funds-button.vue';
import { app } from '@store/modules/app';
import { computed } from 'vue';
import { HeaderStyle } from 'types/header';
import { Link } from 'types/layout';

interface Props {
	styles: HeaderStyle;
	showAddFunds?: boolean;
}

withDefaults(defineProps<Props>(), {
	showAddFunds: false
});

const addFundsButtonStyles = computed((): string => {
	return `button button-primary mr-100`;
});
const isInInvitationNavTest = computed((): boolean => {
	return !!app.isInInvitationNavTest;
});

const logoLink: Link = { router: 'home' };
</script>
<style lang="scss" scoped>
.container {
	height: inherit;
}
</style>
