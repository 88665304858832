import { ErrorEvent, EventHint } from '@sentry/types';
import { isAdvisor, isWebview } from './composables';
import { isNavigationFailure, NavigationFailure, NavigationFailureType } from 'vue-router';
import { isSuppressedStatusCode, processResponseData } from './error-tracking';
import { app } from '@store/modules/app';
import { AxiosError } from 'axios';
import { getSessionReplayLink } from './datadog';
import { sanitizeNumbers } from '@filters/shared-filters';
import { userAgentIsBot } from './bot-detection';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterOriginalExceptionTokens(exception: any) {
	let updatedException = exception;
	if (typeof exception === 'string') {
		const bearerRegEx = /"Bearer([\s\S]+?)"/gi;
		updatedException = exception.replace(bearerRegEx, '"Bearer XXX"');
	}

	if (exception.config?.headers?.['authorization']) {
		updatedException.config.headers['authorization'] = 'XXX';
	}

	return updatedException;
}

function generateAxiosErrorFingerPrint(exception: AxiosError) {
	const newFingerprint = ['axios'];
	const sanitizedUrl = sanitizeNumbers(exception.config.url);

	if (sanitizedUrl) {
		// These urls frequently end in a random string, but we want to group them together under the main endpoint.
		if (sanitizedUrl?.includes('/promo/referrer-by-key')) {
			newFingerprint.push('/promo/referrer-by-key');
		} else {
			newFingerprint.push(sanitizedUrl);
		}
	}

	if (exception.response?.status) {
		newFingerprint.push(`${exception.response.status}`);
	}

	if (exception.message) {
		const sanitizedMessage = sanitizeNumbers(exception.response?.data?.errorDescription);
		newFingerprint.push(sanitizedMessage ?? exception.message);
	}

	return newFingerprint;
}

function setAxiosContext(event: ErrorEvent, exception: AxiosError) {
	const contexts = { ...event.contexts };
	contexts.Axios = {
		url: exception.response?.config?.url ? sanitizeNumbers(exception.response?.config.url) : undefined,
		data: processResponseData(exception.response?.data),
		status: exception.response?.status
	};

	return contexts;
}

function handleAxiosResponseError(event: ErrorEvent, exception: AxiosError) {
	const isSuppressedStatus = isSuppressedStatusCode(exception);
	if (isSuppressedStatus) {
		return null;
	}

	event.contexts = setAxiosContext(event, exception);
	event.fingerprint = generateAxiosErrorFingerPrint(exception);

	// Provides descriptive Sentry message instead of "Request failed with status code XXX"
	if (exception.response?.data?.errorDescription && event.exception?.values?.[0]) {
		event.exception.values[0].value = exception.response.data.errorDescription;
	}

	return event;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isRouterNavigationFailure(exception: any): exception is NavigationFailure {
	return (
		Object.values(NavigationFailureType).includes(exception.type) &&
		exception.hasOwnProperty('to') &&
		isNavigationFailure(exception)
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handleVueRouterError(event: ErrorEvent, exception: any) {
	const navError = exception as NavigationFailure;
	const errorTypeMap = {
		1: 'MATCHER_NOT_FOUND',
		2: 'NAVIGATION_GUARD_REDIRECT',
		4: 'NAVIGATION_ABORTED',
		8: 'NAVIGATION_CANCELLED',
		16: 'NAVIGATION_DUPLICATED'
	};
	const errorType = errorTypeMap[navError.type];
	const toPath = sanitizeNumbers(navError.to?.path) ?? 'unknown';
	const fromPath = sanitizeNumbers(navError.from?.path) ?? 'unknown';

	event.fingerprint = ['vue-router', errorType, toPath];
	event.contexts = { ...event.contexts, VueRouter: { fromPath, toPath, errorType, params: navError.to?.params } };
	if (event.exception?.values?.[0]) {
		event.exception.values[0].value = `Vue Router: ${errorTypeMap[navError.type]}: ${fromPath} -> ${toPath}`;
	}

	return event;
}

function addEventContext(event: ErrorEvent) {
	const contexts = { ...event.contexts };
	const sessionReplayLink = getSessionReplayLink();

	if (sessionReplayLink) {
		contexts.Datadog = { sessionReplayLink };
	}

	return contexts;
}

function addEventTags(event: ErrorEvent) {
	const tags = { ...event.tags };
	tags.isCorniceSubstituteUser = app.isCorniceSubstituteUser;
	tags.isMobileWebview = isWebview.value;
	tags.isAdvisor = isAdvisor.value;
	tags.hasReplay = !!getSessionReplayLink();
	tags.wafIsLoaded = app.wafIsLoaded;
	tags.isBot = userAgentIsBot();

	return tags;
}

export function sentryBeforeSendHandler(event: ErrorEvent, hint: EventHint) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const exception = hint?.originalException as any;
	const exceptionIsObject = exception === Object(exception);

	if (app.isThirdPartyLogin) {
		return null;
	}

	if (event.request?.headers?.['authorization']) {
		event.request.headers['authorization'] = 'XXX';
	}

	event.contexts = addEventContext(event);
	event.tags = addEventTags(event);

	if (exception) {
		hint.originalException = filterOriginalExceptionTokens(exception);
	}

	if (exceptionIsObject && exception.isAxiosError) {
		return handleAxiosResponseError(event, exception as AxiosError);
	}

	if (exceptionIsObject && isRouterNavigationFailure(exception)) {
		return handleVueRouterError(event, exception);
	}

	return event;
}
