import { Options, PositionObject } from 'highcharts';

export default class BaseChart {
	getOptions(): Options {
		const dataLabelColor = '#242628';
		const labelColor = 'hsl(210,5%,55%)';
		const lineColor = 'hsl(210,10%,85%)';

		return {
			chart: {
				backgroundColor: 'transparent',
				spacing: [8, 0, 0, 0],
				style: {
					fontFamily: '"Source Sans Pro","Helvetica Neue","Helvetica","Arial",sans-serif'
				}
			},
			credits: {
				enabled: false
			},
			lang: {
				thousandsSep: ','
			},
			legend: {
				enabled: false
			},
			plotOptions: {
				area: {
					threshold: 0
				},
				column: {
					color: 'hsl(210,100%,40%)',
					dataLabels: {
						inside: false,
						style: {
							color: '#242628',
							fontSize: '18px',
							fontWeight: 'normal'
						}
					}
				},
				line: {
					color: 'hsl(210,100%,40%)',
					marker: {
						symbol: 'circle'
					}
				},
				pie: {
					borderWidth: 3,
					borderColor: '#ffffff',
					dataLabels: {
						enabled: false
					},
					innerSize: 158,
					size: 194,
					slicedOffset: 0,
					states: {
						hover: {
							brightness: -0.1
						}
					}
				},
				series: {
					dataLabels: {
						style: {
							color: dataLabelColor
						}
					},
					states: {
						hover: {
							enabled: false
						}
					}
				},
				variablepie: {
					borderWidth: 3,
					borderColor: '#ffffff',
					dataLabels: {
						enabled: false
					},
					innerSize: 158,
					size: 194,
					slicedOffset: 0
				}
			},
			responsive: {
				rules: [
					{
						condition: {
							minWidth: 480
						},
						chartOptions: {
							tooltip: {
								positioner(labelWidth, labelHeight, point): PositionObject {
									return {
										x: point.plotX - labelWidth / 2,
										y: point.plotY - labelHeight - 16
									};
								}
							}
						}
					}
				]
			},
			title: {
				text: undefined
			},
			tooltip: {
				enabled: false,
				backgroundColor: '#ffffff',
				borderColor: undefined,
				borderWidth: 0,
				xDateFormat: '%b %e %Y',
				useHTML: true
			},
			xAxis: {
				labels: {
					style: {
						color: labelColor,
						fontSize: '12px'
					}
				},
				tickColor: lineColor,
				title: {
					text: null,
					style: {
						color: labelColor
					}
				},
				lineColor
			},
			yAxis: {
				gridLineColor: lineColor,
				labels: {
					style: {
						color: labelColor,
						fontSize: '12px'
					},
					x: -8
				},
				tickColor: lineColor,
				title: {
					text: null,
					style: {
						color: labelColor
					}
				},
				lineColor
			}
		};
	}
}
