<template>
	<div class="footer pt-300 pb-300">
		<div class="container">
			<div class="footer__links">
				<div class="grid-md gutters-300-md display-flex-md align-items-stretch-md">
					<div class="col-6-md">
						<ul class="line-height-200 mt-50">
							<li>
								<BaseLink :link="{ router: 'home' }" classes="display-inline-block">
									<img :src="footerLogo" alt="Fundrise logo" class="marketing-site-footer-logo" />
								</BaseLink>
							</li>
							<li class="mt-50 line-height-150">
								<BaseLink
									classes="white-hover-state"
									:link="{
										href: 'https://maps.google.com/maps?cid=16152291017447874787&hl=en',
										target: '_blank',
										rel: 'noopener noreferrer'
									}"
								>
									11 Dupont Circle NW, 9th Floor
									<br />Washington, DC 20036
								</BaseLink>
							</li>
							<li class="mt-150">
								<BaseLink :link="contactLink" classes="white-hover-state underline-hover" />
							</li>
							<li class="footer__social-media mt-100 display-none-smo">
								<ul class="display-flex">
									<li v-for="(link, id) in socialMediaLinks" :key="id">
										<BaseLink classes="footer__social-media-link mr-150" :link="link">
											<BaseSvgIcon :name="link.text" />
										</BaseLink>
									</li>
								</ul>
							</li>
						</ul>
					</div>

					<div class="col-6-md mt-200-smo">
						<div class="footer__links-section-header heading-6">Invest</div>
						<div class="display-flex-md flex-direction-row gap-100">
							<ul class="flex-1">
								<li v-for="(link, linkId) in investLinksCol1" :key="linkId" class="mt-100">
									<BaseLink :link="link" classes="white-hover-state underline-hover" />
								</li>
							</ul>
							<ul class="flex-1">
								<li v-for="(link, linkId) in investLinksCol2" :key="linkId" class="mt-100">
									<BaseLink :link="link" classes="white-hover-state underline-hover" />
								</li>
							</ul>
						</div>
					</div>
					<div class="col-4-md mt-200-smo">
						<div class="footer__links-section-header heading-6">{{ linkDetails[0].label }}</div>
						<ul>
							<li v-for="(link, linkId) in linkDetails[0].links" :key="linkId" class="mt-100">
								<BaseLink :link="link" classes="white-hover-state underline-hover" />
							</li>
						</ul>
					</div>
					<div class="col-3-md mt-200-smo">
						<div class="footer__links-section-header heading-6">{{ linkDetails[1].label }}</div>
						<ul>
							<li v-for="(link, linkId) in linkDetails[1].links" :key="linkId" class="mt-100">
								<BaseLink :link="link" classes="white-hover-state underline-hover" />
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="footer__social-media mt-250 display-none-md">
				<ul class="display-flex">
					<li v-for="(link, id) in socialMediaLinks" :key="id">
						<BaseLink :link="link" class="footer__social-media-link mr-100">
							<BaseSvgIcon :name="link.text" />
						</BaseLink>
					</li>
				</ul>
			</div>
			<div class="mt-250 display-none-md">
				<BaseLink :link="{ href: appStoreLink }">
					<img :src="appStoreLogo" alt="Apple App Store logo" class="app-logo" />
				</BaseLink>
				<div class="mt-250">
					<BaseLink :link="{ href: playStoreLink }">
						<img :src="googlePlayLogo" alt="Google Play Store logo" class="app-logo" />
					</BaseLink>
				</div>
			</div>
			<div class="mt-500 mt-250-smo body-xs muted">
				<slot name="additionalDisclaimer" />
				<p v-for="(footnote, id) in footnotes" :key="id" class="mb-100">
					<sup>{{ id + 1 }}</sup> <span v-html="footnote"></span>
				</p>
				<FooterDisclaimer :text-color-class="'muted'" :footer-full="true" :is-venture="isVenture" />
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { getAppleAppStoreLink, getGooglePlayStoreLink } from '@utils/analytics';
import { appCloudfrontPath } from '@constants';
import FooterDisclaimer from './footer-disclaimer.vue';
import { Link } from 'types/layout';

interface Props {
	footnotes?: Array<string> | null;
	isVenture?: boolean;
}
withDefaults(defineProps<Props>(), {
	footnotes: null,
	isVenture: false
});

const footerLogo = `${appCloudfrontPath}/interface/logo-glyph-white.svg`;

const appStoreLogo = `${appCloudfrontPath}/images/marketing-site/button-appstore-gray.svg`;

const googlePlayLogo = `${appCloudfrontPath}/images/marketing-site/button-googleplay-gray.svg`;

const appStoreLink = getAppleAppStoreLink('site_footer');
const playStoreLink = getGooglePlayStoreLink('site_footer');

const contactLink: Link = {
	text: 'Contact us',
	router: 'contact'
};

const linkDetails: Array<{ label: string; links: Array<Link> }> = [
	{
		label: 'Resources',
		links: [
			{
				text: 'Why Fundrise',
				router: 'why-fundrise'
			},
			{
				text: 'How it works',
				router: 'how-it-works'
			},
			{
				text: 'Help center',
				router: 'help-center-landing'
			},

			{
				text: 'Articles',
				router: 'blog-landing'
			}
		]
	},
	{
		label: 'Company',
		links: [
			{
				text: 'About us',
				router: 'about'
			},
			{
				text: 'Press',
				router: 'press'
			},
			{
				text: 'Careers',
				router: 'careers'
			}
		]
	}
];

const socialMediaLinks: Array<Link> = [
	{
		text: 'linkedin',
		href: 'https://www.linkedin.com/company/fundrise',
		target: '_blank',
		rel: 'noopener noreferrer'
	},
	{
		text: 'facebook',
		href: 'http://facebook.com/fundrise',
		target: '_blank',
		rel: 'noopener noreferrer'
	},
	{
		text: 'instagram',
		href: 'http://instagram.com/fundrise',
		target: '_blank',
		rel: 'noopener noreferrer'
	},
	{
		text: 'twitter',
		href: 'http://twitter.com/fundrise',
		target: '_blank',
		rel: 'noopener noreferrer'
	}
];

const investLinksCol1: Array<Link> = [
	{
		text: 'Real estate',
		router: 'real-estate-strategies'
	},
	{
		text: 'Private credit',
		router: 'private-credit'
	},
	{
		text: 'Venture',
		router: 'venture-capital'
	},
	{
		text: 'Retirement',
		router: 'ira-landing'
	}
];

const investLinksCol2: Array<Link> = [
	{
		text: 'Client returns',
		router: 'client-returns'
	},
	{
		text: 'For advisors',
		router: 'advisor-landing'
	},
	{
		text: 'Fundrise Connect API',
		router: 'connect-api'
	}
];
</script>

<style lang="scss">
.marketing-site-footer-logo {
	width: 60px;
	height: 40px;
}

.white-hover-state {
	color: #fff;

	&:hover,
	&:active {
		color: #fff;
	}

	&.underline-hover {
		&:hover,
		&:active {
			text-decoration: underline;
		}
	}
}
</style>
