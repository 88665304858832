// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

let trackingAdded = false;

export default function addTrackers() {
	if (trackingAdded) return;

	/* eslint-disable */

	// Facebook
	// prettier-ignore
	!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
		n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
		n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
		t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
		document,'script','//connect.facebook.net/en_US/fbevents.js');

	window.fbq('dataProcessingOptions', ['LDU'], 0, 0);
	window.fbq('init', '879623652131405');
	window.fbq('track', 'PageView');

	// https://podsights.com/install/?id=7b8316b549a94f6aad73ade1fbf71c55
	// prettier-ignore
	(function(w, d){
		const id='pdst-capture', n = 'script';
		if (!d.getElementById(id)) {
			w.pdst = w.pdst || function() {(w.pdst.q = w.pdst.q || []).push(arguments);};
			
			const e = d.createElement(n); e.id = id; e.async = 1;
			e.src = 'https://cdn.pdst.fm/ping.min.js';
			
			const s = d.getElementsByTagName(n)[0];
			s.parentNode.insertBefore(e, s);
		}
		w.pdst('conf', { key: '7b8316b549a94f6aad73ade1fbf71c55' });
		w.pdst('view');
	})(window, document);

	// LinkedIn
	const _linkedin_partner_id = '1417594';
	window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
	window._linkedin_data_partner_ids.push(_linkedin_partner_id);

	// prettier-ignore
	(function(){const s = document.getElementsByTagName("script")[0];
		const b = document.createElement("script");
		b.type = "text/javascript";b.async = true;
		b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
		s.parentNode.insertBefore(b, s);
	})();

	//Postie
	// prettier-ignore
	(function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
	p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)
	};p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
	n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,"script","//d18p8z0ptb8qab.cloudfront.net/lp.js","letterpress"));

	window.letterpress('newTracker', 'mycljcoll', 't.getletterpress.com', {
		appId: 'pomcpcir',
		discoverRootDomain: true,
		forceSecureTracker: '',
		contexts: {
			webPage: true,
			performanceTiming: true,
			gaCookies: true,
			geolocation: false
		}
	});

	window.letterpress('trackPageView');
	//END Postie

	// Bing
	// prettier-ignore
	(function(w,d,t,r,u){let f,n,i;w[u]=w[u]||[],f=function(){const o={ti:"5526767"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){const s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");

	// Impact Radius
	// prettier-ignore
	(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]= e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('//d.impactradius-event.com/A455081-da81-475c-8e6f-b5f26caeae371.js','script','ire',document,window);

	// Google Tag Manager
	// prettier-ignore
	(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
			new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','GTM-WGT3GR6');

	window.gtag = function (data) {
		dataLayer.push(data);
	};

	// Google Analytics
	// prettier-ignore
	(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
			(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
		m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
	}
	)(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

	ga('create', import.meta.env.VITE_GOOGLE_ANALYTICS_ID, 'auto');
	ga('set', 'anonymizeIp', true);

	/* eslint-enable */

	const queryParams = new URLSearchParams(window.location.search);
	const newLead = queryParams.get('new_lead');
	// Track new leads
	if (newLead) {
		// Bing
		window.uetq = window.uetq || [];
		window.uetq.push('event', 'Email Capture', {
			event_category: 'Lead',
			event_label: 'Landing Page',
			event_value: '1'
		});

		//Podsights
		window.pdst('lead', {
			value: 1.0,
			currency: 'USD'
		});

		// Facebook
		window.fbq('track', 'Lead');

		// Google Tag Manager
		window.gtag({ event: 'lead' });
	}

	trackingAdded = true;
}
