import * as Sentry from '@sentry/vue';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Address, TaxInfo } from 'types/form';
import {
	createUser,
	getPhoneData,
	getUser,
	getUserAddress,
	setUserCountry,
	setUserFirstAndLastNames,
	setUserPhoneNumber,
	updateContactDetails,
	updateTaxDetails
} from '@api/user';
import { getKnownLoginDevices, updateEmailAddress } from '@api/settings';
import { getOauthAuthorizations, revokeOauthAuthorization } from '@api/oauth-authorization';
import { identifyMixpanel, trackMixpanelEvent } from '@utils/analytics';
import { PhoneData, PhoneNumber, User, UserSignupForm } from 'types/user';
import { app } from '@store/modules/app';
import { datadogRum } from '@datadog/browser-rum';
import { getInvitationDetails } from '@api/referral';
import { getItemFromLocalStorage } from '@utils/web-storage';
import { getShareholderVoteDetails } from '@api/shareholder-vote';
import { getTwoFactorStatus } from '@api/login';
import { getUserEntityById } from '@utils/user';
import { IndividualAccountContactDetails } from 'types/checkout';
import { InvestmentEntity } from 'types/investment-entity';
import { investmentEntity } from '@store/modules/investment-entity';
import { InvitationDetailsResponse } from 'types/referral';
import { KnownDevice } from 'types/settings';
import { logError } from '@utils/error-tracking';
import { OAuthAuthorizationStatus } from 'types/oauth-authorization';
import { orderForm } from '@store/modules/order-form';
import { RouteLocationRaw } from 'vue-router';
import router from '@router';
import { ShareholderVoteDetail } from 'types/shareholder-vote';
import spacetime from 'spacetime';
import store from '..';
import { submitIndividualAccountTaxReportingDetails } from '@api/checkout';
import { SubscriptionStatus } from 'types/fundrise-pro';
import { TwoFactorType } from 'types/authentication';

export interface UserState {
	user: User;
	phoneData: PhoneData;
}
@Module({
	namespaced: true,
	name: 'user',
	store
})
class UserModule extends VuexModule {
	user: User | null = null;

	address: Address = {
		address1: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
		countryCode: ''
	};

	devices: Array<KnownDevice> = [];

	authorizations: Array<OAuthAuthorizationStatus> = [];

	invitationDetails: InvitationDetailsResponse | null = null;

	shareholderVoteDetail: ShareholderVoteDetail | null = null;

	phoneData: PhoneData = {
		twoFactorPhoneNumber: '',
		currentPhoneNumber: '',
		currentPhoneNumberValidated: false,
		allPhoneNumbers: []
	};
	twoFactorType: TwoFactorType | null = null;
	phoneNumberToVerify: string | null = null;

	get countriesSubmitted(): boolean {
		return !!(this.user?.citizenshipCountryCode && this.user.residenceCountryCode);
	}

	get twoFactorEnabled(): boolean {
		return !!this.twoFactorType && user.twoFactorType !== 'NONE';
	}

	get isExistingInvestor(): boolean {
		return !!this.user?.entityDetails?.firstInvestmentDate;
	}

	get hasAddress(): boolean {
		return !!this.address.address1;
	}

	get hasIraEntity(): boolean {
		return this.user?.entityDetails?.investmentEntities.some((entity) => entity.entityType === 'IRA') ?? false;
	}

	get hasFirstInvestment(): boolean {
		return !!this.user?.entityDetails?.firstInvestmentDate;
	}

	get isProOrPremium(): boolean {
		return this.isSubscriptionActive || this.isPremiumUser;
	}

	get isSubscriptionActive(): boolean {
		return !!this.user?.isSubscriptionActive;
	}

	get residenceValid(): boolean {
		return this.user?.residenceCountryCode === 'US';
	}

	get subscriptionStatus(): SubscriptionStatus {
		return this.user?.subscriptionStatus ?? 'INACTIVE';
	}

	get canCancelFundrisePro(): boolean {
		const cancellableStates: SubscriptionStatus[] = [
			'ACTIVE',
			'ACTIVE_PAST_DUE',
			'ACTIVE_TRIAL',
			'ACTIVE_LIFETIME'
		];

		return cancellableStates.includes(this.subscriptionStatus);
	}

	get hasActiveProStatus(): boolean {
		const activeStates: SubscriptionStatus[] = [
			'ACTIVE',
			'ACTIVE_CANCELLED',
			'ACTIVE_PAST_DUE',
			'ACTIVE_LIFETIME',
			'ACTIVE_TRIAL'
		];

		return activeStates.includes(this.subscriptionStatus);
	}

	get hasCancelledFundrisePro(): boolean {
		const cancelledStates = ['ACTIVE_CANCELLED', 'INACTIVE_CANCELLED'];

		return cancelledStates.includes(this.subscriptionStatus);
	}

	get hasFirstLastName(): boolean {
		return !!(this.user?.firstName && this.user?.lastName);
	}

	get hasDateOfBirth(): boolean {
		return !!this.user?.hasSubmittedDob;
	}

	get hasEmailAddress(): boolean {
		return !!this.user?.email;
	}

	get getDeviceById(): (deviceId: string) => KnownDevice | undefined {
		return (deviceId: string): KnownDevice | undefined => {
			return this.devices.find((device) => device.deviceId === deviceId);
		};
	}

	get ssnNeedsUpdating(): boolean {
		return this.user?.ssnNeedsUpdating || !this.user?.hasSubmittedSsn;
	}

	get iraEntities(): Array<InvestmentEntity> {
		return this.user?.entityDetails?.investmentEntities.filter((entity) => entity.entityType === 'IRA') ?? [];
	}

	get isEligibleForShareholderVote(): boolean {
		return this.user?.entityDetails?.investmentEntities.some((ie) => ie.eligibleForShareholderVote) ?? false;
	}

	get isPremiumUser(): boolean {
		return this.user?.isPremiumUser || false;
	}

	get hasAnyAdvisorRelationship(): boolean {
		return !!(
			this.user?.investmentAdvisorRelationshipDetailsResponse?.hasActiveRelationship ||
			this.user?.investmentAdvisorRelationshipDetailsResponse?.hasPendingRelationshipRequest
		);
	}

	@Action({ rawError: true })
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async createUser(payload: { userSignupForm: UserSignupForm }): Promise<any> {
		try {
			const response = await createUser(payload.userSignupForm);
			app.setUserAuthData(response);
			await this.getUser();
			return undefined;
		} catch (error) {
			return error;
		}
	}

	@Action({ rawError: true })
	async getUser(): Promise<void> {
		const updatedUser = await getUser();
		this.UPDATE_USER(updatedUser);

		if (!app.isReadOnlyAccess) {
			const mixpanelId = `${updatedUser.mixpanelId}`;
			await identifyMixpanel(mixpanelId);
		}

		Sentry.setUser({
			id: updatedUser.id
		});

		datadogRum.setUser({
			id: updatedUser.id,
			accountCreatedDate: spacetime(updatedUser.entityDetails?.firstInvestmentDate).format('iso-short'),
			isPro: user.isSubscriptionActive,
			proStatus: user.subscriptionStatus
		});

		if (!app.isAdvisor) {
			const entity = await this.getSelectedInvestmentEntity(updatedUser);
			if (entity) await investmentEntity.setInvestmentEntity(entity);
			await this.setInvitationDetails();
		}
	}

	@Action({ rawError: true })
	private async getSelectedInvestmentEntity(selectedUser: User): Promise<InvestmentEntity | null> {
		if (!selectedUser.entityDetails) {
			return null;
		}

		const lastSelectedEntityId = getItemFromLocalStorage('lastSelectedInvestmentEntity');
		return getUserEntityById(lastSelectedEntityId ?? '');
	}

	@Action({ rawError: true })
	private async userHasInvestmentEntity(investmentEntityId: string): Promise<boolean> {
		let hasInvestmentEntity =
			this.user?.entityDetails?.investmentEntities?.some(
				(entity) => entity.investmentEntityId === investmentEntityId
			) ?? false;

		if (!hasInvestmentEntity) {
			await this.getUser();

			hasInvestmentEntity =
				this.user?.entityDetails?.investmentEntities?.some(
					(entity) => entity.investmentEntityId === investmentEntityId
				) ?? false;
		}

		return hasInvestmentEntity;
	}

	@Action({ rawError: true })
	public async updateInvestmentEntityAndTrackEvents(investmentEntityId: string): Promise<void> {
		const entityIsValid = await this.userHasInvestmentEntity(investmentEntityId);
		const previousEntity = this.user?.entityDetails?.investmentEntities.find(
			(entity) => entity.investmentEntityId === investmentEntity.investmentEntityId
		);
		const newEntity = this.user?.entityDetails?.investmentEntities.find(
			(entity) => entity.investmentEntityId === investmentEntityId
		);

		if (entityIsValid && newEntity) {
			await investmentEntity.setInvestmentEntity(newEntity);

			trackMixpanelEvent('Selected Investment Entity Switched', {
				switchedFrom: previousEntity?.investmentEntityId,
				switchedTo: investmentEntityId,
				fromInvestmentEntityType: previousEntity?.entityType ?? '',
				toInvestmentEntityType: newEntity?.entityType
			});
		} else {
			const error = new Error('Cannot switch to entity not associated with user.');
			logError(error, 'info');
			return Promise.reject(error);
		}
	}

	@Action({ rawError: true })
	public async updateSelectedEntity(investmentEntityId: string): Promise<void> {
		await this.updateInvestmentEntityAndTrackEvents(investmentEntityId);
		router.go(0);
	}

	@Action({ rawError: true })
	public async updateSelectedEntityWithoutReload({
		investmentEntityId,
		nextRoute
	}: {
		investmentEntityId: string;
		nextRoute: { route: RouteLocationRaw; return?: boolean; next?: (to?: RouteLocationRaw) => void };
	}): Promise<RouteLocationRaw | undefined> {
		await this.updateInvestmentEntityAndTrackEvents(investmentEntityId);

		try {
			if (nextRoute.return) {
				return nextRoute.route;
			} else if (nextRoute.next) {
				nextRoute.next(nextRoute.route);
			} else {
				router.push(nextRoute.route);
			}
		} catch {}
	}

	@Action({ rawError: true })
	async refreshUser(): Promise<void> {
		await this.getUser();
		await investmentEntity.refreshPositionsAndPerformance();
	}

	@Action({ rawError: true })
	public async storeAddress(force = false): Promise<void> {
		if (!this.hasAddress || force) {
			const addressData = await getUserAddress();
			if (addressData) {
				this.UPDATE_ADDRESS(addressData);
			}
		}
	}

	@Action({ rawError: true })
	public async setInvitationDetails(): Promise<void> {
		const invitationDetailsData = await getInvitationDetails();
		this.UPDATE_INVITATION_DETAILS(invitationDetailsData);
	}

	@Action({ rawError: true })
	public async storeShareholderVoteDetails(): Promise<void> {
		const voteDetails = await getShareholderVoteDetails();
		this.UPDATE_OPEN_SHAREHOLDER_VOTE_DETAILS(voteDetails);
	}

	@Action({ rawError: true })
	public async storePhoneData(force = false): Promise<void> {
		if (!this.phoneData.currentPhoneNumber || force) {
			const phoneData = await getPhoneData();
			this.UPDATE_PHONE_DATA(phoneData);
		}
	}

	@Action({ rawError: true })
	public async setFirstAndLastNames(payload: { firstName: string; lastName: string }): Promise<void> {
		await setUserFirstAndLastNames(payload.firstName, payload.lastName);
		await this.getUser();
	}

	@Action({ rawError: true })
	public async setCountrySelections(payload: {
		citizenshipCountryCode: string;
		residenceCountryCode: string;
	}): Promise<void> {
		await setUserCountry({
			citizenshipCountryCode: payload.citizenshipCountryCode,
			residenceCountryCode: payload.residenceCountryCode
		});
		await this.getUser();
	}

	@Action({ rawError: true })
	public async storeDevices(): Promise<void> {
		if (this.devices.length === 0) {
			const devices = await getKnownLoginDevices();
			this.UPDATE_DEVICES(devices);
		}
	}

	@Action({ rawError: true })
	public clearDevices(): void {
		if (this.devices.length > 0) {
			this.UPDATE_DEVICES([]);
		}
	}

	@Action({ rawError: true })
	public async setUserPhoneNumber(payload: {
		phoneNumber: string;
		isPlaidLayer?: boolean;
	}): Promise<RouteLocationRaw> {
		await setUserPhoneNumber(payload.phoneNumber);
		orderForm.UPDATE_PHONE_NUMBER(payload.phoneNumber);
		return orderForm.completeStep(payload.isPlaidLayer ? 'PHONE_NUMBER_PLAID_LAYER_STEP' : 'PHONE_NUMBER_STEP');
	}

	@Action({ rawError: true })
	public async setUserContactDetails(payload: IndividualAccountContactDetails): Promise<RouteLocationRaw> {
		await updateContactDetails(payload);
		return orderForm.completeStep('CONTACT_INFORMATION_STEP');
	}

	@Action({ rawError: true })
	public async setUserTaxDetails(payload: TaxInfo): Promise<RouteLocationRaw> {
		await submitIndividualAccountTaxReportingDetails(payload);
		await this.getUser();
		return orderForm.completeStep();
	}

	@Action({ rawError: true })
	public async setUserTaxDetailsFromProfile(payload: TaxInfo): Promise<void> {
		await updateTaxDetails(payload);
		this.refreshUser();
	}

	@Action({ rawError: true })
	public async storeTwoFactorType(force = false): Promise<void> {
		if (!this.twoFactorType || force) {
			const twoFactorType = await getTwoFactorStatus();
			this.UPDATE_TWO_FACTOR_TYPE(twoFactorType);
		}
	}

	@Action({ rawError: true })
	public async updateEmailAddress(updatedEmail: string): Promise<void> {
		await updateEmailAddress(updatedEmail);
		await this.getUser();
	}

	@Action({ rawError: true })
	public async setPhoneNumberToVerify(phoneNumber: string | null): Promise<void> {
		this.UPDATE_PHONE_NUMBER_TO_VERIFY(phoneNumber);
	}

	@Action({ rawError: true })
	public async getAuthorizations(): Promise<void> {
		const authorizations = await getOauthAuthorizations();
		this.UPDATE_AUTHORIZATIONS(authorizations);
	}

	@Action({ rawError: true })
	public async revokeAuthorization(clientId: string): Promise<void> {
		await revokeOauthAuthorization(clientId);
		await this.getAuthorizations();
	}

	@Action({ rawError: true })
	public reset(): void {
		this.UPDATE_USER(null);
		this.UPDATE_ADDRESS({
			address1: '',
			address2: '',
			city: '',
			state: '',
			zip: '',
			countryCode: ''
		});
		this.UPDATE_DEVICES([]);
		this.UPDATE_INVITATION_DETAILS(null);
		this.UPDATE_OPEN_SHAREHOLDER_VOTE_DETAILS(null);
		this.UPDATE_PHONE_DATA({
			twoFactorPhoneNumber: '',
			currentPhoneNumber: '',
			currentPhoneNumberValidated: false,
			allPhoneNumbers: []
		});
		this.UPDATE_TWO_FACTOR_TYPE(null);
		this.UPDATE_PHONE_NUMBER_TO_VERIFY(null);
		this.UPDATE_AUTHORIZATIONS([]);
	}

	@Mutation
	public UPDATE_USER(updatedUser: User | null): void {
		this.user = updatedUser;
	}

	@Mutation
	public UPDATE_INVITATION_DETAILS(invitationDetails: InvitationDetailsResponse | null) {
		this.invitationDetails = invitationDetails;
	}

	@Mutation
	public UPDATE_OPEN_SHAREHOLDER_VOTE_DETAILS(voteDetail: ShareholderVoteDetail | null) {
		this.shareholderVoteDetail = voteDetail;
	}

	@Mutation
	public UPDATE_PHONE_DATA(data: PhoneData): void {
		this.phoneData = data;
	}

	@Mutation
	public ADD_PHONE_NUMBER(newPhone: PhoneNumber): void {
		if (!this.phoneData.allPhoneNumbers.some((existingPhone) => existingPhone.id === newPhone.id)) {
			this.phoneData.allPhoneNumbers.push(newPhone);
		}
	}

	@Mutation
	public UPDATE_ADDRESS(address: Address): void {
		this.address = address;
	}

	@Mutation
	public UPDATE_DEVICES(devices: Array<KnownDevice>): void {
		this.devices = devices;
	}

	@Mutation
	public UPDATE_TWO_FACTOR_TYPE(twoFactorType: TwoFactorType | null): void {
		this.twoFactorType = twoFactorType;
	}

	@Mutation
	public UPDATE_PHONE_NUMBER_TO_VERIFY(newPhoneNumber: string | null): void {
		this.phoneNumberToVerify = newPhoneNumber;
	}

	@Mutation
	public UPDATE_AUTHORIZATIONS(authorizations: Array<OAuthAuthorizationStatus>): void {
		this.authorizations = authorizations;
	}
}

if (!store.hasModule('user')) {
	store.registerModule('user', UserModule);
}

export const user = getModule(UserModule);
