import { AccountRequiredState } from 'types/account';
import { FundriseRouteConfig } from 'vue-router';
import { useAccountClosureStore } from '@stores/account-closure';
import { user } from '@store/modules/user';
import { verifyRequiredStates } from './account-state-guards';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const accountSecurityRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/account/security/mobile-number/login-verify`,
		name: 'login-mobile-verify',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await user.storePhoneData();
			next();
		},
		component: () => import('@views/account/login/login-mobile-verify.vue'),
		redirect: { name: 'login-mobile-verify-select' },
		children: [
			{
				path: `select`,
				name: 'login-mobile-verify-select',
				meta: { minTokenScopeRequired: 'FULL_ACCESS', additionalAllowedScopes: ['ADVISOR_ACCESS'] },
				beforeEnter: async (to, from, next) => {
					const requiredStates: Array<AccountRequiredState> = [
						'userPhoneNotVerified',
						'userHasCurrentPhone',
						'userIsNotRestrictedFromVisitingMobileVerifyFlow'
					];
					await verifyRequiredStates(next, requiredStates);
				},
				component: () => import('@views/account/login/login-mobile-verify-select.vue')
			},
			{
				path: `confirm`,
				name: 'login-mobile-verify-confirm',
				meta: { minTokenScopeRequired: 'FULL_ACCESS', additionalAllowedScopes: ['ADVISOR_ACCESS'] },
				beforeEnter: async (to, from, next) => {
					const requiredStates: Array<AccountRequiredState> = [
						'userPhoneNotVerified',
						'userHasCurrentPhone',
						'userHasCompletedSelectInCurrentSession'
					];
					await verifyRequiredStates(next, requiredStates);
				},
				component: () => import('@views/account/login/login-mobile-verify-confirm.vue')
			}
		]
	},
	{
		path: `/account/security/mobile-number/verify`,
		name: 'mobile-number-verify',
		meta: { minTokenScopeRequired: 'PRIVILEGED_ACCESS', additionalAllowedScopes: ['ADVISOR_PRIVILEGED_ACCESS'] },
		beforeEnter: async (to, from, next) => {
			if (!user.phoneNumberToVerify) {
				next({ name: 'profile-mobile-number-edit', replace: true });
			} else {
				next();
			}
		},
		component: () => import('@views/account/security/mobile-number-verify.vue')
	},
	{
		path: `/account/security/verify-password`,
		name: 'password-verify',
		meta: { minTokenScopeRequired: 'FULL_ACCESS', additionalAllowedScopes: ['ADVISOR_ACCESS'] },
		component: () => import('@views/account/security/password-verify.vue')
	},
	{
		path: '/account/security/closure',
		name: 'profile-account-closure',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		redirect: { name: 'profile-account-closure-start' },
		component: () => import('@views/account/security/account-closure.vue'),
		children: [
			{
				path: 'start',
				name: 'profile-account-closure-start',
				meta: { step: 1, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@components/account/closure/account-closure-start.vue')
			},
			{
				path: 'pro',
				name: 'profile-account-closure-pro',
				meta: { step: 2, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@components/account/closure/account-closure-pro.vue'),
				beforeEnter: async (to, from, next) => {
					const accountClosureStore = useAccountClosureStore();
					if (from.name === 'profile-account-closure-start' && !!accountClosureStore.flow) {
						next();
					} else {
						next({ name: 'profile-account-closure-start', replace: true });
					}
				}
			},
			{
				path: 'review',
				name: 'profile-account-closure-review',
				meta: { step: 3, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@components/account/closure/account-closure-review.vue'),
				beforeEnter: async (to, from, next) => {
					const accountClosureStore = useAccountClosureStore();
					if (
						(from.name === 'profile-account-closure-start' ||
							from.name === 'profile-account-closure-pro') &&
						!!accountClosureStore.flow
					) {
						next();
					} else {
						next({ name: 'profile-account-closure-start', replace: true });
					}
				}
			},
			{
				path: 'complete',
				name: 'profile-account-closure-complete',
				meta: { step: 4, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@components/account/closure/account-closure-complete.vue'),
				beforeEnter: async (to, from, next) => {
					const accountClosureStore = useAccountClosureStore();
					if (
						((from.name === 'profile-account-closure-review' ||
							from.name === 'profile-account-closure-start') &&
							!!accountClosureStore.flow) ||
						accountClosureStore.completionAction
					) {
						next();
					} else {
						next({ name: 'profile-account-closure-start', replace: true });
					}
				}
			}
		]
	}
];
export default accountSecurityRoutes;
