import { GlobalConfigOptions } from '@vue/test-utils/dist/config';
import type { App, DefineComponent } from 'vue';

type RegisterOptions = {
	app?: App<Element>;
	config?: GlobalConfigOptions;
};

export function registerBaseComponents({ app, config }: RegisterOptions): void {
	const baseComponents = import.meta.glob<DefineComponent<object, object, unknown>>('./_base-[\\w-]+.vue', {
		eager: true
	});

	for (const fileName in baseComponents) {
		if (fileName) {
			const componentConfig = baseComponents[fileName];

			// Get the PascalCase version of the component name
			const componentName = fileName
				// Remove the "./_" from the beginning
				.replace(/^\.\/_/, '')
				// Remove the file extension from the end
				.replace(/\.\w+$/, '')
				// Split up kebabs
				.split('-')
				// Upper case
				.map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
				// Concatenated
				.join('');

			if (app) {
				// Globally register the component in the Vue app instance
				app.component(componentName, componentConfig.default || componentConfig);
			} else if (config) {
				// Register the component in the test setup
				config.global.components[componentName] = componentConfig.default || componentConfig;
			}
		}
	}
}
