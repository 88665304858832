import {
	ActionContextKey,
	CustomPropertiesKey,
	ViewContentKey,
	ViewIdKey,
	ViewNameKey,
	ViewTitleKey
} from '@utils/symbols/analytics';
import {
	AnalyticsReactiveContext,
	MixpanelClickProperties,
	MixpanelContext,
	MixpanelReactiveActionProperties,
	MixpanelReactiveViewProperties
} from 'types/analytics';
import {
	findMixpanelContext,
	getActionTargetFromContext,
	getMixpanelViewId,
	getMixpanelViewName
} from '@utils/analytics';
import { inject, provide } from 'vue';

export function useAnalyticsProperties() {
	const injectedViewName = inject(ViewNameKey, undefined);
	const injectedViewTitle = inject(ViewTitleKey, undefined);
	const injectedViewContent = inject(ViewContentKey, undefined);
	const injectedViewId = inject(ViewIdKey, undefined);
	const injectedActionContext = inject(ActionContextKey, undefined);
	const injectedCustomProperties = inject(CustomPropertiesKey, undefined);

	function getViewName(): string {
		return injectedViewName?.value ?? getMixpanelViewName();
	}

	function getViewTitle(): string | undefined {
		return injectedViewTitle?.value;
	}

	function getViewContent(): string | undefined {
		return injectedViewContent?.value;
	}

	function getViewId(): string | undefined {
		return injectedViewId?.value ?? getMixpanelViewId() ?? undefined;
	}

	function getActionTarget(componentRef?: HTMLElement | null): string | undefined {
		return componentRef ? getActionTargetFromContext(componentRef) : undefined;
	}

	function getActionContext(componentRef?: HTMLElement | null): string | undefined {
		const actionContextfromContext = findMixpanelContext(componentRef);
		return injectedActionContext?.value ?? actionContextfromContext;
	}

	function getActionElement(componentRef?: HTMLElement | null): string | undefined {
		return componentRef ? componentRef.tagName.toLowerCase() : undefined;
	}

	function getInjectedCustomProperties(): MixpanelContext | undefined {
		return injectedCustomProperties?.value;
	}

	/**
	 * Constructs MixpanelClickProperties object. Priority is given to params,
	 * then injected values, and finally traversing the DOM.
	 * @param clickData - Anything passed directly to a property overrides any injected properties. It's recommend to pass `element` to get fallback values.
	 * @returns MixpanelClickProperties to be passed to `trackMixpanelClick`
	 */
	function getActionProperties(clickData: MixpanelClickProperties): MixpanelClickProperties {
		return {
			'Action Context': clickData['Action Context'] ?? getActionContext(clickData.element),
			'Action Element': clickData['Action Element'] ?? getActionElement(clickData.element),
			'Action Target': clickData['Action Target'] ?? getActionTarget(clickData.element),
			Link: clickData.Link,
			customProperties: {
				...getViewProperties(), // includes injected custom properties
				...clickData.customProperties
			}
		};
	}

	/**
	 * Constructs View properties. Priority is given to injected values.
	 * View Name and View ID fall back to their default handling.
	 * View Title and View Content return undefined if no injected value.
	 * @returns MixpanelContext to be passed to `customProperties` in `trackMixpanelClick`
	 */
	function getViewProperties(): MixpanelContext {
		return {
			'View Name': getViewName(),
			'View Title': getViewTitle(),
			'View Content': getViewContent(),
			'View ID': getViewId(),
			...getInjectedCustomProperties()
		};
	}

	/**
	 * Injects View properties onto **ALL** descendants of the current component.
	 * Note: Always call this in the top-level of `<script setup>`, not within a function.
	 * @param data - Pass full refs/computed properties
	 * @example
	 * const viewName = ref('Info Screen');
	 * const viewTitle = computed(() => questionInfoFramework.currentInfoScreenDetails?.mixpanelName);
	 * setViewProperties({ viewName, viewTitle });
	 */
	function setViewProperties(data: Partial<MixpanelReactiveViewProperties>): void {
		data.viewName && provide(ViewNameKey, data.viewName);
		data.viewTitle && provide(ViewTitleKey, data.viewTitle);
		data.viewContent && provide(ViewContentKey, data.viewContent);
		data.viewId && provide(ViewIdKey, data.viewId);
	}

	/**
	 * Injects Action properties onto **ALL** descendants of the current component.
	 * Note: Always call this in the top-level of `<script setup>`, not within a function.
	 * @param data - Pass full refs/computed properties
	 * @example
	 * const actionContext = ref('Funds Allocation Modal');
	 * setActionProperties({ actionContext });
	 */
	function setActionProperties(data: Partial<MixpanelReactiveActionProperties>): void {
		data.actionContext && provide(ActionContextKey, data.actionContext);
	}

	/**
	 * Injects custom properties onto **ALL** descendants of the current component.
	 * Note: Always call this in the top-level of `<script setup>`, not within a function.
	 * @param data - Pass full refs/computed properties
	 * @example
	 * const customProperties = ref({ Flow: 'Signup' });
	 * setCustomProperties(customProperties);
	 */
	function setCustomProperties(data: AnalyticsReactiveContext): void {
		provide(CustomPropertiesKey, data);
	}

	return {
		getActionProperties,
		setActionProperties,
		getViewProperties,
		setViewProperties,
		setCustomProperties
	};
}
