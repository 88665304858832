<template>
	<MobileNavTemplate :styles="styles">
		<template #flyout-content>
			<div class="mobile-nav__flyout-content pl-0 pr-0">
				<div v-if="isMobileOS" class="iphone-smart-banner-flyout px-100 py-150 bb">
					<div class="iphone-smart-banner-flyout__heading pb-100">
						<div>
							<img
								class="iphone-smart-banner__image height-100"
								:src="appCloudfrontPath + '/images/global/mobile-app-icon.svg'"
								alt=""
								role="presentation"
							/>
						</div>
						<div class="iphone-smart-banner-flyout__title">
							<div class="delta font-weight-bold">Fundrise</div>
							<RatingsStars
								class="review-source-stars"
								:stars-color="'#6B6A65'"
								:rating="mobileAppProps.ratingAverage"
							/>
							<div class="body-sm">
								{{ mobileAppProps.ratingAverage }}<span class="mx-25">|</span
								>{{ mobileAppProps.numberOfRatings }}
								ratings
							</div>
						</div>
					</div>
					<BaseLink
						:link="{ href: mobileAppProps.storeLink }"
						classes="button button-default button-block zeta"
					>
						Download the app
					</BaseLink>
				</div>
				<div class="display-flex gap-100 p-100 links-flex-container text-align-center zeta">
					<BaseLink
						data-test="get-started-button"
						classes="button button-outline"
						:link="{ router: 'get-started', text: 'Get started' }"
					/>
					<BaseLink classes="button button-outline" :link="{ href: '/login', text: 'Log in' }" />
				</div>
				<div v-for="(link, id) in marketingLinks" :key="id">
					<NavigationLinkMobile :link="link" />
				</div>
				<LearnLinksMobile />
			</div>
		</template>
	</MobileNavTemplate>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { getAppleAppStoreLink, getGooglePlayStoreLink } from '@utils/analytics';
import { isIphone as isIphoneClient, isMobileOS as isMobileOsClient } from '@utils/client';
import { appCloudfrontPath } from '@constants';
import { getReviewsData } from '@api/prismic';
import { HeaderStyle } from 'types/header';
import LearnLinksMobile from '@components/header/navigation/mobile/learn-links-mobile.vue';
import { marketingLinks } from '../links';
import MobileNavTemplate from '@components/header/navigation/mobile/mobile-nav-template.vue';
import NavigationLinkMobile from '@components/header/navigation/mobile/navigation-link-mobile.vue';
import RatingsStars from '@components/marketing-site/ratings-stars.vue';
interface Props {
	styles: HeaderStyle;
}
defineProps<Props>();

const appStoreLink = getAppleAppStoreLink('flyout_menu_smart_banner');
const playStoreLink = getGooglePlayStoreLink('flyout_menu_smart_banner');

const mobileAppProps = ref({
	ratingAverage: '',
	numberOfRatings: '',
	storeLink: ''
});

const isMobileOS = computed((): boolean => {
	return isMobileOsClient();
});

const isIphone = computed((): boolean => {
	return isIphoneClient();
});

async function setup(): Promise<void> {
	const reviewsData = await getReviewsData();

	mobileAppProps.value = {
		ratingAverage: isIphone.value ? reviewsData.iphone_app_rating : reviewsData.android_app_rating,
		numberOfRatings: isIphone.value
			? reviewsData.iphone_app_number_of_reviews
			: reviewsData.android_app_number_of_reviews,
		storeLink: isIphone.value ? appStoreLink : playStoreLink
	};
}
setup();
</script>
<style lang="scss">
.links-flex-container {
	& > .button.button-outline {
		flex: 1 1 0px;
	}
}
</style>
