<template>
	<div class="intercom-popup-bubble">
		<div class="display-flex align-items-center justify-content-space-between text-color-white bg-color-rust p-100">
			<span class="body-md font-weight-bold">{{ props.data.title }}</span>
			<BaseButton :no-style="true" @click="dismissPopup"
				><BaseSvgIcon name="close" width="20" height="20"
			/></BaseButton>
		</div>
		<div class="display-flex flex-direction-column gap-50 p-100 body-sm">
			<div class="display-flex flex-direction-column gap-100">
				<div v-for="(bodyText, i) in props.data.body" :key="i" v-html="bodyText"></div>
			</div>
			<div class="display-flex flex-direction-column gap-50">
				<BaseButton
					v-for="(cta, i) in props.data.ctas"
					:key="i"
					class="button button-outline button-block no-wrap"
					@click="handleCtaClick(cta.prepopulated_message)"
					>{{ cta.label }}</BaseButton
				>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { IntercomPopupContentSlice } from 'types/prismic';

interface Props {
	data: IntercomPopupContentSlice;
}

interface Emits {
	(e: 'popup-dismissed'): void;
	(e: 'cta-clicked', prepopulateMessage: string): void;
}

const props = defineProps<Props>();

const emits = defineEmits<Emits>();

function handleCtaClick(message: string): void {
	emits('cta-clicked', message);
}

function dismissPopup(): void {
	emits('popup-dismissed');
}
</script>
<style lang="scss" scoped>
.intercom-popup-bubble {
	border-radius: 8px;
	background: #ffffff;
	box-shadow: 0px 2px 10px 0px rgba(0, 47, 108, 0.08);
}
</style>
