import {
	RedemptionRequestAcknowledgements,
	RedemptionRequestMetadata,
	RedemptionRequestPreviewData,
	RedemptionSubmission,
	ReitRedemptionRequest
} from 'types/redemption-request';
import { investmentEntityRequest } from '@utils/request';

export const getReitRedemptionRequestMetadata = async (): Promise<RedemptionRequestMetadata> => {
	const response = await investmentEntityRequest.get(`/redemption-request/metadata`);
	return response.data;
};

export const getIpoRedemptionRequestMetadata = async (): Promise<RedemptionRequestMetadata> => {
	const response = await investmentEntityRequest.get(`/redemption-request/ipo/metadata`);
	return response.data;
};

export const getRedemptionPreview = async (
	requests: Array<ReitRedemptionRequest>
): Promise<RedemptionRequestPreviewData> => {
	const response = await investmentEntityRequest.post(`/redemption-request/redemption-request-preview`, { requests });
	return response.data;
};

export const getRedemptionRequestAcknowledgements = async (
	requests: Array<ReitRedemptionRequest>
): Promise<RedemptionRequestAcknowledgements> => {
	const response = await investmentEntityRequest.post(`/redemption-request/acknowledgements`, { requests });
	return response.data;
};

export const submitRequest = async (redemptionSubmission: RedemptionSubmission): Promise<string> => {
	const response = await investmentEntityRequest.post(`/redemption-request/submit-request`, {
		...redemptionSubmission
	});
	return response.data;
};
