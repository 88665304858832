import { useLoginStore } from '@stores/login';

export const validateRouteAndNavigate = async (
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	next: any,
	routeName: string
): Promise<void> => {
	const loginStore = useLoginStore();
	switch (routeName) {
		case 'login-two-factor-select':
			if (loginStore.twoFactorMetadata?.twoFactorType === 'TOTP') {
				next({ name: 'login-two-factor-confirm', replace: true });
			}
			break;
		case 'login-two-factor-confirm':
			if (loginStore.selectedTwoFactorCodeDeliveryMethod === 'NONE') {
				next({ name: 'login-two-factor-select' });
			}
			break;
		default:
			next({ name: 'home' });
			break;
	}
	await next();
};
