import { apiRequest, investmentEntityRequest } from '@utils/request';
import { IpoHypotheticalInvestment, IpoMetadata, IpoPriceData } from 'types/ipo';

export async function getIpoPriceHistory(): Promise<Array<IpoPriceData>> {
	const response = await apiRequest.get(`/offerings/fundrise-ipo/ipo-share-price-history`);
	return response.data;
}

export async function getIpoMetadata(): Promise<IpoMetadata> {
	const response = await investmentEntityRequest.get(`/ipo`);
	return response.data;
}

export async function getIpoHypotheticalInvestment(pendingInvestment: string): Promise<IpoHypotheticalInvestment> {
	const response = await investmentEntityRequest.get(`/ipo/investment-hypothetical-max/${pendingInvestment}`);
	return response.data;
}
