<template>
	<div
		class="site-header"
		:class="{ 'header--transparent-dark-text': isTransparentDarkText, sticky: isSticky }"
		@mouseover="isHovering = true"
		@mouseout="isHovering = false"
	>
		<div ref="placeholderElem" class="main-nav__placeholder" :class="{ hidden: transparentHeader }"></div>

		<component
			:is="navComponent"
			:styles="headerStyle"
			:is-transparent="transparentHeader"
			:is-transparent-dark-text="isTransparentDarkText"
			:is-sticky="isSticky"
			@remove-hover="isHovering = false"
		/>
		<div id="mobile-nav-placeholder" class="mobile-nav__placeholder" :class="{ hidden: transparentHeader }"></div>
	</div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { isAuthenticated, isDesktop } from '@utils/composables';
import { appCloudfrontPath } from '@constants';
import DesktopNav from '@components/header/navigation/desktop/desktop-nav.vue';
import DesktopNavAuth from '@components/header/navigation/desktop/desktop-nav-auth.vue';
import { HeaderStyle } from 'types/header';
import MobileNav from '@components/header/navigation/mobile/mobile-nav.vue';
import MobileNavAuth from '@components/header/navigation/mobile/mobile-nav-auth.vue';
import type { Component as ComponentType } from 'vue';

interface Props {
	transparentHeader?: boolean;
	transparentHeaderDarkText?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
	transparentHeader: false,
	transparentHeaderDarkText: false
});

const placeholderElem = ref<null | HTMLElement>(null);

const isHovering = ref(false);

const isScrolling = ref(false);

const stickyHeaderStyles: HeaderStyle = {
	logo: `${appCloudfrontPath}/interface/logo-hz-color.svg`,
	buttonClassLogin: 'button-outline',
	buttonClassGetStarted: 'button-default',
	isSticky: true
};

const defaultHeaderStyles = computed(
	(): HeaderStyle => ({
		logo: isTransparentDarkText.value
			? `${appCloudfrontPath}/interface/logo-hz-color.svg`
			: `${appCloudfrontPath}/interface/logo-hz-white.svg`,
		buttonClassLogin: isTransparentDarkText.value ? 'button-outline' : 'button-outline-reversed',
		buttonClassGetStarted: isTransparentDarkText.value ? 'button-default' : 'button-default-reversed',
		isSticky: false
	})
);

const isSticky = computed((): boolean => {
	return !props.transparentHeader || isScrolling.value || isHovering.value;
});

const isTransparentDarkText = computed((): boolean => props.transparentHeader && props.transparentHeaderDarkText);

const headerStyle = computed((): HeaderStyle => {
	return isSticky.value ? stickyHeaderStyles : defaultHeaderStyles.value;
});

function handleIntersect(entries: Array<IntersectionObserverEntry>): void {
	isScrolling.value = entries[0].intersectionRatio !== 1;
}

const navComponent = computed((): ComponentType => {
	if (isDesktop.value) {
		if (isAuthenticated.value) {
			return DesktopNavAuth;
		} else {
			return DesktopNav;
		}
	} else if (isAuthenticated.value) {
		return MobileNavAuth;
	} else {
		return MobileNav;
	}
});

const observer: IntersectionObserver = new IntersectionObserver(handleIntersect, { threshold: 1 });

onMounted((): void => {
	if (placeholderElem.value) {
		observer.observe(placeholderElem.value);
	}
});
</script>
