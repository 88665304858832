import { app } from '@store/modules/app';
import { FundriseRouteConfig } from 'vue-router';
import { includesRequiredQueryParams } from '@utils/request';
import { microFeedbackData } from '@constants/micro-feedback';
import { MicroFeedbackType } from 'types/micro-feedback';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const microFeedbackRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/micro-feedback',
		name: 'micro-feedback',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			const includesRequiredParams = includesRequiredQueryParams(['eventType'], to);
			if (includesRequiredParams) {
				next();
			} else {
				next({ name: 'account-overview', replace: true });
			}
		},
		component: () => import('@views/micro-feedback/micro-feedback.vue'),
		redirect: { name: 'micro-feedback-satisfaction-form' },
		children: [
			{
				path: 'submit',
				name: 'micro-feedback-submit',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/micro-feedback/micro-feedback-form-view.vue')
			},
			{
				path: 'satisfaction-feedback',
				name: 'micro-feedback-satisfaction-form',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				component: () => import('@views/micro-feedback/micro-feedback-satisfaction-form.vue')
			},
			{
				path: 'success',
				name: 'micro-feedback-success',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				component: () => import('@views/micro-feedback/micro-feedback-success.vue')
			}
		]
	},
	{
		path: '/account/micro-feedback',
		name: 'micro-feedback-old',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		redirect: (to) => {
			return { name: 'micro-feedback-submit', replace: true, query: to.query };
		}
	},
	{
		path: '/account/micro-feedback/redirect',
		name: 'micro-feedback-redirect',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		redirect: (to) => {
			const eventType = to.query.eventType as MicroFeedbackType;
			if (eventType) {
				app.ADD_TOAST({
					type: 'success',
					message: 'Thanks for sharing your feedback.'
				});
				return { name: microFeedbackData[eventType].redirectRoute, replace: true };
			} else {
				app.ADD_TOAST({
					type: 'success',
					message: 'Thanks for sharing your feedback.'
				});
				return { name: 'account-overview', replace: true };
			}
		}
	},
	{
		path: '/hpi-survey',
		name: 'hpi-survey',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/micro-feedback/micro-feedback.vue'),
		redirect: { name: 'hpi-survey-form' },
		children: [
			{
				path: 'feedback',
				name: 'hpi-survey-form',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				component: () => import('@views/micro-feedback/hpi-survey-form.vue')
			},
			{
				path: 'success',
				name: 'hpi-survey-success',
				meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
				component: () => import('@views/micro-feedback/micro-feedback-success.vue')
			}
		]
	}
];
export default microFeedbackRoutes;
