import { AccountClosureCompletionAction, AccountDeletionFlowEnum } from 'types/account/account-closure';
import { computed, ref } from 'vue';
import { getAccountClosureEligibility, submitAccountClosure } from '@api/account';
import { defineStore } from 'pinia';

const PRO_ACKNOWLEDGEMENT_FLOWS: Array<AccountDeletionFlowEnum> = ['PRO_LEAD_NO_SHARES', 'PRO_INVESTOR_NO_SHARES'];

export const useAccountClosureStore = defineStore('accountClosure', () => {
	const flow = ref<AccountDeletionFlowEnum | null>(null);
	const completionAction = ref<AccountClosureCompletionAction | null>(null);
	const repeatRequest = ref(false);

	const requiresProAcknowledgement = computed((): boolean => {
		return !!flow.value && PRO_ACKNOWLEDGEMENT_FLOWS.includes(flow.value);
	});

	async function getEligibility(): Promise<void> {
		const eligibility = await getAccountClosureEligibility();

		flow.value = eligibility.accountDeletionFlow;
		completionAction.value = eligibility.completionAction ?? null;
	}

	async function submit(): Promise<void> {
		const submitResponse = await submitAccountClosure();
		completionAction.value = submitResponse;
	}

	function $reset(): void {
		flow.value = null;
		completionAction.value = null;
		repeatRequest.value = false;
	}

	return {
		flow,
		completionAction,
		repeatRequest,
		requiresProAcknowledgement,
		getEligibility,
		submit,
		$reset
	};
});
