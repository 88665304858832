import { NavigationLink } from 'types/header';
import { User } from 'types/user';

export default class AdvisorAuthenticatedNav {
	public links!: Array<NavigationLink>;

	public fullName!: string;

	// Keeping these to avoid type errors in desktop-nav-auth.vue
	public multipleInvestmentEntities = false;
	public inviteLink = false;

	private setFullName(user: User): void {
		if (user && user.firstName && user.lastName) {
			this.fullName = user.firstName + ' ' + user.lastName;
		} else if (user && user.firstName) {
			this.fullName = user.firstName;
		} else {
			this.fullName = 'Your Account';
		}
	}

	private setLinks(): void {
		this.links = [
			{
				text: 'Clients',
				router: 'advisor-dashboard'
			},
			{
				text: 'Help center',
				router: 'help-center-landing'
			}
		];
	}

	public constructor(user: User | null) {
		if (!user) {
			return;
		}

		this.setFullName(user);
		this.setLinks();
	}
}
