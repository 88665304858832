<template>
	<StandardModal data-context="AccountValueReturnCalculationModal" @modal-close="close">
		<template #title> <div class="heading-5">About this section</div> </template>
		<template #content>
			<div class="mb-150">
				<p
					>Return calculations are performed using the Modified Dietz method, which is used to measure the
					performance of your account, portfolio, or security in the presence of inflows and outflows, such as
					contributions, distributions, transfers, or&nbsp;redemptions.</p
				>
				<p
					>The return calculation for a period includes dividends earned (whether paid or unpaid), plus
					realized and unrealized gains or losses as a result of any changes in the net asset value (NAV) of
					your shares, net of any advisory fees accrued or redemption penalties charged.
					<BaseLink :link="performanceDisclosure" classes="text-color-current-color text-decoration-underline"
						>Full&nbsp;disclosure</BaseLink
					>.</p
				>
				<p
					>The highlighted investor updates are presented as of the date published, and may or may not be
					reflected in the account value as of such date, if at&nbsp;all.
				</p>
			</div>
		</template>
		<template #buttons>
			<BaseButton classes="button button-default ml-100" @click="close">Got it</BaseButton>
		</template>
	</StandardModal>
</template>

<script setup lang="ts">
import { performanceDisclosure } from '@constants/links';
import StandardModal from '@components/modals/standard-modal.vue';
interface Emits {
	(e: 'modal-close'): void;
}
const emit = defineEmits<Emits>();

function close(): void {
	emit('modal-close');
}
</script>
