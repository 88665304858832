<template>
	<div class="sanity-rich-text-container">
		<PortableText :value="parsedBlocks" :components="components" />
	</div>
</template>

<script setup lang="ts">
import { computed, h, VNode } from 'vue';
import {
	PortableText,
	PortableTextComponentProps,
	PortableTextComponents,
	PortableTextMarkComponentProps
} from '@portabletext/vue';
import BaseLink from '@components/_base-link.vue';
import { Link } from 'types/layout';
import { SanityRichTextBlock } from 'types/sanity';
import type { PortableTextBlock, TypedObject } from '@portabletext/types';

interface Props {
	blocks: Array<SanityRichTextBlock>;
	isDarkMode?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	isDarkMode: false
});

interface LinkMark extends TypedObject {
	url: string;
	openInNewTab: boolean;
}

interface ListProps {
	value: {
		level: number;
	};
}

type OrderedListStyles = 'decimal' | 'lower-alpha' | 'lower-roman';
type UnorderedListStyles = 'disc' | 'circle' | 'square';

const parsedBlocks = computed((): Array<SanityRichTextBlock> => {
	return props.blocks.map((block) => {
		// If level is present on the block and is a string, convert it to a number
		if (block.level && typeof block.level === 'string') {
			return { ...block, level: parseInt(block.level, 10) };
		}
		return block;
	});
});

const components = computed((): PortableTextComponents => {
	return {
		marks: {
			/**
			 * Renders a link component
			 * @param {PortableTextMarkComponentProps<LinkMark>} value - The link mark props
			 * @param {{ slots: { default?: () => Array<VNode> } }} context - The context with slots
			 * @returns {VNode | null} - The rendered link component or null if value or slots.default is not provided
			 */
			link: (
				{ value }: PortableTextMarkComponentProps<LinkMark>,
				{ slots }: { slots: { default?: () => Array<VNode> } }
			) => {
				if (!value || !slots.default) {
					return null;
				} else {
					const link: Link = {
						href: value.url,
						target: value.openInNewTab ? '_blank' : '',
						text: slots.default()[0].children as string
					};
					const linkStyle = props.isDarkMode ? 'text-link-dark' : 'text-link';
					return h(BaseLink, { link, classes: linkStyle });
				}
			}
		},
		list: {
			bullet: createListComponent(getUnorderedListStyle, 'ul'),
			number: createListComponent(getOrderedListStyle, 'ol')
		},
		block: {
			h1: createBlockComponent('heading-1', 'h1'),
			h2: createBlockComponent('heading-2', 'h2'),
			h3: createBlockComponent('heading-3', 'h3'),
			h4: createBlockComponent('heading-4', 'h4'),
			h5: createBlockComponent('heading-5', 'h5'),
			h6: createBlockComponent('heading-6', 'h6'),
			subheading: createBlockComponent('subheading mb-100', 'div'),
			normal: createBlockComponent(),
			'body-lg': createBlockComponent('body-lg'),
			'body-md': createBlockComponent('body-md'),
			'body-sm': createBlockComponent('body-sm'),
			'body-xs': createBlockComponent('body-xs'),
			'label-md': createBlockComponent('label-md'),
			'label-sm': createBlockComponent('label-sm')
		}
	};
});

/**
 * Gets the ordered list style based on the level
 * @param {number} level - The list level
 * @returns {OrderedListStyles} - The ordered list style
 */
function getOrderedListStyle(level: number): OrderedListStyles {
	const styles: OrderedListStyles[] = ['decimal', 'lower-alpha', 'lower-roman'];
	return styles[(level - 1) % styles.length];
}

/**
 * Gets the unordered list style based on the level
 * @param {number} level - The list level
 * @returns {UnorderedListStyles} - The unordered list style
 */
function getUnorderedListStyle(level: number): UnorderedListStyles {
	const styles: UnorderedListStyles[] = ['disc', 'circle', 'square'];
	return styles[(level - 1) % styles.length];
}

/**
 * Creates a block component
 * @param {string} [className=''] - The class name to be added to the element
 * @param {string} [element='p'] - The HTML element to be used for the block
 * @returns {Function} - A function that returns a VNode
 */
function createBlockComponent(
	className = '',
	element = 'p'
): (
	value: PortableTextComponentProps<PortableTextBlock>,
	context: { slots: { default?: () => Array<VNode> } }
) => VNode | null {
	return (
		_value: PortableTextComponentProps<PortableTextBlock>,
		{ slots }: { slots: { default?: () => Array<VNode> } }
	): VNode | null => {
		const classProps: Record<string, unknown> = {};
		if (className) {
			classProps.class = className;
		}

		const slotContent = slots.default?.();
		if (!slotContent || slotContent.every((vnode) => !vnode.children || vnode.children === '')) {
			return null;
		}
		return h(element, classProps, slotContent);
	};
}

/**
 * Creates a list component
 * @param {Function} getStyle - Function to get the list style
 * @param {'ul' | 'ol'} tag - The HTML tag to be used for the list
 * @returns {Function} - A function that returns a VNode
 */
function createListComponent(
	getStyle: (level: number) => string,
	tag: 'ul' | 'ol'
): (value: ListProps, context: { slots: { default?: () => Array<VNode> } }) => VNode {
	return (value: ListProps, { slots }: { slots: { default?: () => Array<VNode> } }): VNode => {
		const listStyle = getStyle(value.value.level);
		return h(tag, { class: tag, style: { listStyleType: listStyle } }, slots.default?.());
	};
}
</script>

<style lang="scss">
@use '../../styles/constants/colors.scss' as *;
.sanity-rich-text-container {
	ul li,
	ol li {
		margin-top: 0.5em;

		&:has(> p) {
			margin-top: 0;
		}

		& > p {
			margin-top: 0.5em;
		}
	}

	h1,
	h2,
	h3 {
		margin-bottom: 24px;
	}

	h4,
	h5,
	h6,
	.subheading {
		margin-bottom: 16px;
	}

	.text-link-dark {
		color: token('bg-secondary');
		text-decoration: underline;
	}

	p + h1,
	p + h2,
	p + h3 {
		margin-top: 24px;
	}

	p + h4,
	p + h5,
	p + h6,
	p + .subheading {
		margin-top: 16px;
	}
}
</style>
