import { KeyValue, Link, QuestionItem } from 'types/layout';
import {
	PartnerLogo,
	PremiumLandingFaq,
	PremiumLandingFundOpportunities,
	PremiumLandingFundOpportunity,
	PremiumLandingPage,
	PremiumLandingPageData,
	PremiumLandingStrategiesShowcase
} from 'types/prismic/premium-landing-page';
import { PrismicObject, PrismicWebLink } from 'types/prismic';
import { LandingPageHero } from 'types/prismic/landing-page';
import { serializePrismicContent } from './html-serializer';
import { StrategyShowcaseInfo } from 'types/real-estate-strategy';

export async function buildPremiumLandingPage(prismicData: PremiumLandingPageData): Promise<PremiumLandingPage | null> {
	if (prismicData) {
		return {
			cta: {
				heading: prismicData.cta_heading,
				summary: prismicData.cta_summary,
				link: prismicData.cta_link_url
					? {
							text: prismicData.cta_link_text || 'Get Started',
							href: prismicData.cta_link_url
						}
					: undefined
			},
			faqData: {
				faqsHeading: prismicData.faqs_heading,
				questionList: buildFaqsQuestionItemList(prismicData.faq_questions)
			},
			hero: {
				background_color: prismicData.hero_background_color,
				is_dark_theme: false,
				hero_heading: prismicData.hero_title,
				hero_description: prismicData.hero_description,
				show_lead_capture: true,
				lead_capture_heading: '',
				lead_capture_button_text: '',
				media: prismicData.hero_image,
				is_media_right: true
			} as LandingPageHero,
			investorRelationsData: {
				description: serializePrismicContent(prismicData.investor_relations_description).join(''),
				heading: prismicData.investor_relations_heading,
				image: prismicData.investor_relations_image
			},
			metaData: {
				page_summary: prismicData.page_summary,
				page_title: prismicData.page_title
			},
			opportunitiesData: {
				heading: prismicData.opportunities_heading,
				description: serializePrismicContent(prismicData.opportunities_description).join(''),
				fundOpportunities: buildFundOpportunties(prismicData.fund_opportunities)
			},
			partnersData: {
				logos: buildLogosList(prismicData.partner_logos),
				logosHeading: prismicData.partners_logos_heading,
				summary: serializePrismicContent(prismicData.partners_summary).join('')
			},
			performanceData: {
				heading: prismicData.performance_heading,
				link: {
					text: prismicData.performance_link_text,
					href: prismicData.performance_link,
					target: '_blank'
				},
				overline: prismicData.performance_overline,
				summary: serializePrismicContent(prismicData.performance_summary).join('')
			},
			premiumStrategies: {
				sectionDisclaimer: prismicData.strategies_section_disclaimer,
				sectionHeading: prismicData.strategies_title,
				sectionLink: {
					text: prismicData.strategies_section_link_text,
					href: prismicData.strategies_section_link,
					target: '_blank'
				},
				sectionSummary: prismicData.strategies_summary,
				strategies: buildStrategiesShowcase(prismicData.strategies_showcase)
			}
		};
	} else {
		return null;
	}
}

export function buildStrategiesShowcase(
	strategies: Array<PremiumLandingStrategiesShowcase>
): Array<StrategyShowcaseInfo> {
	const showcaseInfo: Array<StrategyShowcaseInfo> = [];
	strategies.forEach((strategy) => {
		const strategyStats: Array<KeyValue> = [];
		if (strategy?.stat_1) {
			strategyStats.push({
				key: strategy.stat_1.toString(),
				value: strategy.stat_1_label
			});
		}
		if (strategy?.stat_2) {
			strategyStats.push({
				key: strategy.stat_2.toString(),
				value: strategy.stat_2_label
			});
		}

		const images: Array<string> = [];
		if (strategy.image_1?.url) {
			images.push(strategy.image_1.url);
		}
		if (strategy.image_2?.url) {
			images.push(strategy.image_2.url);
		}
		if (strategy.image_3?.url) {
			images.push(strategy.image_3.url);
		}

		showcaseInfo.push({
			featuredImages: images,
			featuredImagesCaption: serializePrismicContent(strategy.images_caption).join(''),
			eyebrow: strategy.eyebrow,
			link: getStrategyLink(strategy),
			stats: strategyStats,
			summary: serializePrismicContent(strategy.content).join(''),
			title: strategy.heading
		});
	});

	return showcaseInfo;
}

function getStrategyLink(strategy: PremiumLandingStrategiesShowcase): Link | undefined {
	let strategylink = undefined;
	if (strategy.link.link_type === 'Document') {
		strategylink = strategy.link as PrismicObject;
		return {
			router: 'blog-post',
			params: { blogPostUrlSlug: strategylink.uid },
			text: strategy.link_text,
			target: '_blank'
		};
	} else if (strategy.link.link_type === 'Web') {
		strategylink = strategy.link as PrismicWebLink;
		return { target: strategylink.target, href: strategylink.url, text: strategy.link_text };
	}
	return strategylink;
}

function buildFaqsQuestionItemList(faqsList: Array<PremiumLandingFaq>): Array<QuestionItem> {
	const questionItemList: Array<QuestionItem> = [];
	faqsList.forEach((faq) => {
		questionItemList.push({
			answer: serializePrismicContent(faq.answer).join(''),
			question: faq.question
		});
	});

	return questionItemList;
}

function buildLogosList(images: Array<PartnerLogo>): Array<string> {
	return images.map((image) => image?.logo?.url);
}

function buildFundOpportunties(
	prismicOpportunities: Array<PremiumLandingFundOpportunities>
): Array<PremiumLandingFundOpportunity> {
	const fundOpportunities: Array<PremiumLandingFundOpportunity> = [];
	prismicOpportunities.forEach((opportunity) => {
		fundOpportunities.push({
			availability: opportunity.availability,
			cta: {
				text: opportunity.cta_text ?? 'Learn more',
				href: opportunity.cta_link ?? undefined,
				target: '_blank'
			},
			description: serializePrismicContent(opportunity.description).join(''),
			inceptionDate: opportunity.inception_date,
			isAccreditedOnly: opportunity.accredited_only,
			minimum: opportunity.account_minimum,
			title: opportunity.heading
		});
	});

	return fundOpportunities;
}
