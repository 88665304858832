<template>
	<BaseButton :disabled="props.disabled" :classes="buttonClasses" :aria-label="label">
		<BaseSvgIcon :name="props.icon" :dir="props.iconDir" />
	</BaseButton>
</template>

<script setup lang="ts">
import BaseButton from '@components/_base-button.vue';
import { computed } from 'vue';

interface Props {
	ariaLabel?: string;
	classes?: string;
	disabled?: boolean;
	icon: string;
	iconDir?: 'left' | 'up' | 'right' | 'down';
	elevation?: boolean;
	unstyled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	ariaLabel: undefined,
	classes: 'button button-icon-only',
	disabled: false,
	icon: '',
	iconDir: undefined,
	elevation: false,
	unstyled: false
});

const buttonClasses = computed(() => {
	if (props.unstyled) {
		return '';
	}
	return props.elevation ? `${props.classes} elevated` : props.classes;
});

const label = computed((): string => {
	return props.ariaLabel || `${props.icon || 'icon'} button`;
});
</script>
