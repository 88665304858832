import { AxiosRequestConfig, AxiosRequestInterceptorParams } from 'axios';
import { app } from '@store/modules/app';

/**
 * Adds a user’s access token to the authorization headers of a request.
 * It also cancels the request if someone using substitute user has read-only permissions and they attempt anything other than a GET request.
 * Required for authenticated endpoints.
 */
export function oAuthTokenHeader(config: AxiosRequestConfig): AxiosRequestConfig {
	const abortController = new AbortController();
	if (config.method !== 'get' && app.isReadOnlyAccess) {
		// Being able to pass "reason" only available in Node >= v17.2.0
		abortController.abort();

		app.ADD_TOAST({
			type: 'error',
			message: 'Access level is read only. This action requires full access.'
		});
	}

	if (config.headers) {
		config.headers.authorization = `Bearer ${app.oauthToken}`;
	}

	return {
		...config,
		signal: abortController.signal
	};
}

export const oAuthTokenHeaderInterceptor: AxiosRequestInterceptorParams = [
	(config) => oAuthTokenHeader(config),
	(error) => Promise.reject(error)
];
