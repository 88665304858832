import { computed, ref } from 'vue';
import { getInfoDetails, getQuestions, submitQuestionAnswers } from '@api/question-info-framework';
import { NextQuestionResponse, Question, QuestionAnswer, QuestionResponse } from 'types/question-framework';
import { defineStore } from 'pinia';
import { enumToKebab } from '@filters/shared-filters';
import { InfoDetails } from 'types/info-framework';

export const useQuestionInfoFrameworkStore = defineStore('questionInfoFramework', () => {
	const currentQuestionResponse = ref<QuestionResponse | null>(null);
	const currentInfoScreenDetails = ref<InfoDetails | null>(null);

	const currentQuestion = computed((): Question | null => {
		return currentQuestionResponse.value?.questions[0] ?? null;
	});

	async function getInfo(infoId?: string): Promise<string | undefined> {
		if (!!currentInfoScreenDetails.value && currentInfoScreenDetails.value.infoId === infoId) {
			return enumToKebab(infoId);
		}
		const infoDetails = await getInfoDetails(infoId);
		currentInfoScreenDetails.value = infoDetails;
		return enumToKebab(infoDetails.infoId);
	}

	async function getQuestion(questionName?: string): Promise<string | undefined> {
		if (!!currentQuestionResponse.value && currentQuestionResponse.value.questions[0].questionId === questionName) {
			return enumToKebab(questionName);
		} else {
			const question = await getQuestions(questionName);
			currentQuestionResponse.value = question;
			return enumToKebab(question.questions[0].questionId);
		}
	}

	async function submitAnswers(answer: QuestionAnswer): Promise<NextQuestionResponse | undefined> {
		const nextQuestion = await submitQuestionAnswers([answer]);
		return nextQuestion.nextQuestionId ? nextQuestion : undefined;
	}

	function $reset(): void {
		currentQuestionResponse.value = null;
		currentInfoScreenDetails.value = null;
	}

	return {
		currentQuestionResponse,
		currentInfoScreenDetails,
		currentQuestion,
		$reset,
		getInfo,
		getQuestion,
		submitAnswers
	};
});
