<template>
	<div class="modal-backdrop" :data-context="mixpanelContext">
		<div class="modal display-block" @click="close">
			<div class="modal-dialog">
				<div class="modal-container" style="transform: scale(1) !important">
					<slot> Override Me </slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'BaseModal'
};
</script>

<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, onUnmounted, ref } from 'vue';
import { trackMixpanelView } from '@utils/analytics';
import { useAnalyticsProperties } from '@utils/composables/use-analytics-properties';

interface Props {
	dataContext?: string;
	closeable?: boolean;
	suppressDefaultMxpEvent?: boolean;
}

interface Emits {
	(e: 'modal-close'): void;
}

const props = withDefaults(defineProps<Props>(), {
	dataContext: undefined,
	closeable: true,
	suppressDefaultMxpEvent: false
});

const emits = defineEmits<Emits>();

const { getViewProperties } = useAnalyticsProperties();

const currentInstance = getCurrentInstance();

const mainNav: HTMLElement | null = document.getElementById('main-nav');
const mainContent: HTMLElement | null = document.getElementById('main-content');
const footer: HTMLElement | null = document.getElementById('footer');

const body = ref<HTMLCollectionOf<HTMLBodyElement>>(document.getElementsByTagName('body'));

const mixpanelContext = computed((): string => props.dataContext ?? getModalName());

function getModalName(): string {
	let parentModalName = currentInstance?.proxy?.$options.name;
	let parentComponent = currentInstance?.proxy?.$parent;

	for (let i = 0; i < 3; i++) {
		if (parentComponent) {
			if (parentComponent.$options.name?.includes('Modal')) {
				parentModalName = parentComponent.$options.name;
			}
			parentComponent = parentComponent.$parent;
		}
	}

	return parentModalName ?? 'Modal';
}

function measureScrollbarWidth(): number {
	const measuringDiv = document.createElement('div');
	measuringDiv.style.width = '100px';
	measuringDiv.style.height = '100px';
	measuringDiv.style.overflow = 'scroll';
	measuringDiv.style.position = 'absolute';
	measuringDiv.style.top = '-9999px';
	document.body.appendChild(measuringDiv);
	const scrollbarWidth = measuringDiv.offsetWidth - measuringDiv.clientWidth;
	document.body.removeChild(measuringDiv);
	const scrollbarVisible = window.innerHeight < document.body.scrollHeight;
	return scrollbarVisible ? scrollbarWidth : 0;
}

function close(): void {
	if (props.closeable) {
		emits('modal-close');
	}
}

onMounted(() => {
	if (!props.suppressDefaultMxpEvent) {
		trackMixpanelView('Modal', { ...getViewProperties(), 'View Name': mixpanelContext.value });
	}
});

onUnmounted(() => {
	if (body.value) {
		body.value[0].style.overflowY = 'hidden';
	}

	// first timeout waits until modal fade animation finishes to remove scrollbar padding
	setTimeout(() => {
		if (footer) {
			footer.style.paddingRight = '';
		}
		if (mainContent) {
			mainContent.style.paddingRight = '';
			if (body.value) {
				body.value[0].style.overflowY = '';
			}
		}
		if (mainNav) {
			mainNav.style.paddingRight = '';
		}

		//second timeout waits until nav transition animation finishes to remove inline transition, otherwise nav bounces
		setTimeout(() => {
			if (mainNav) {
				mainNav.style.transition = '';
			}
		}, 300);
	}, 500);
});

function setup(): void {
	body.value = document.getElementsByTagName('body');
	const scrollbarWidth = measureScrollbarWidth() + 'px';

	if (mainContent) {
		mainContent.style.paddingRight = scrollbarWidth;
	}

	if (footer) {
		footer.style.paddingRight = scrollbarWidth;
	}

	if (mainNav) {
		mainNav.style.transition = 'none';
		mainNav.style.paddingRight = scrollbarWidth;
	}
}

setup();
</script>
