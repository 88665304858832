<template>
	<MobileNavAccordion class="bb">
		<template #toggle>Resources</template>
		<template #drawer>
			<div
				v-for="(section, sectionId) in learnLinks"
				:key="sectionId"
				class="learn-link-list pt-100 pb-50 px-100"
			>
				<ul>
					<li class="overline eta line-height-150">
						{{ section.dropdown }}
					</li>
					<li v-for="(link, linkId) in section.links" :key="linkId" class="py-50">
						<BaseLink :link="link.link" classes="mobile-nav__sub-item-link"> {{ link.link.text }}</BaseLink>
					</li>
				</ul>
			</div>

			<div class="mobile-nav__featured">
				<p class="overline eta line-height-150">Featured</p>
				<div class="display-flex mt-50">
					<div class="flex-1">
						<img :src="featuredLinkDetails.imageUrl" alt="" role="presentation" />
					</div>
					<div class="flex-1">
						<div class="display-flex flex-direction-column ml-100">
							<p class="epsilon line-height-150">{{ featuredLinkDetails.text }}</p>
							<BaseCtaLink
								class="body-sm mt-25 line-height-150"
								:link="featuredLinkDetails.cta"
								icon="arrow"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</MobileNavAccordion>
</template>

<script setup lang="ts">
import { appCloudfrontPath } from '@constants';
import { learnLinks } from '../links';
import { LearnSubmenuFeaturedLink } from 'types/header';
import MobileNavAccordion from '@components/header/navigation/mobile/mobile-nav-accordion.vue';

const featuredLinkDetails: LearnSubmenuFeaturedLink = {
	text: 'Investor letters',
	cta: { text: 'View all', router: 'blog-category', params: { categoryUrlSlug: 'letters-to-investors' } },
	imageUrl: `${appCloudfrontPath}/images/modules/spotlight/featured-resource.png`
};
</script>
<style lang="scss" scoped>
.learn-links-list:first-child {
	padding-top: 0;
}
</style>
