<template>
	<li class="kv-pair-flex">
		<div class="kv-key">
			<slot name="key">
				{{ keyLabel }}
			</slot>
		</div>
		<hr :class="condensed ? 'kv-rule--condensed' : 'kv-rule'" />
		<div class="kv-value">
			<slot name="value">
				{{ valueLabel }}
			</slot>
		</div>
	</li>
</template>

<script setup lang="ts">
interface Props {
	keyLabel?: string;
	valueLabel?: string;
	condensed?: boolean;
}

withDefaults(defineProps<Props>(), {
	keyLabel: '',
	valueLabel: '',
	condensed: false
});
</script>
