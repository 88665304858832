export const flagshipFundOfferingId = '24';
export const incomeFundOfferingId = '25';
export const growthTechReitId = '26';
export const intervalFundReitIds = [flagshipFundOfferingId, incomeFundOfferingId];
export const ipoReitId = '6';
export const ofundReitId = '10';
export const ocfReitId = '27';
export const mergedEfundReitIds = ['7', '9'];
export const fundriseEfundReitId = '8';
export const vantaCompanyId = '1';
export const canvaCompanyId = '19';
export const multipleLocationsLabel = 'Multiple locations';

export const disclaimerModalContent = [
	'<p>Past performance is not indicative of future results.</p>',
	'<p>The performance assumptions shown in each of these charts are for illustrative purposes only, should not be relied on for any other purpose, and are not intended to reflect the actual experience of any individual investor. These values may not accurately reflect the amount that might be paid for your shares in a market transaction or the amount you would receive pursuant to the redemption policy of this&nbsp;offering.</p>',
	'<h3 class="line-height-125 delta font-weight-bold heading-text-color mt-100">Value chart</h3>',
	'<p>This chart shows the hypothetical growth of an investment in this offering over the period indicated, assuming dividend reinvestment.</p>',
	'<h3 class="line-height-125 delta font-weight-bold heading-text-color mt-100">Net return chart</h3>',
	'<p>This chart shows the hypothetical returns on an investment in this offering over the period indicated, assuming dividend reinvestment.</p>',
	'<h3 class="line-height-125 delta font-weight-bold heading-text-color mt-100">Historical net return chart</h3>',
	'<p>This table shows the historical NAV, change in NAV, dividends paid, and overall return on a hypothetical investment over the period indicated.</p>'
];
