import { hasFacebookQuery, hasGoogleAnalytics, hasMixpanel, trackMixpanelView } from './analytics';

export const pageViewed = (suppressMxpEvent = false): void => {
	if (hasMixpanel() && !suppressMxpEvent) {
		trackMixpanelView('Page');
	}

	if (hasGoogleAnalytics()) {
		window.ga('send', 'pageview');
	}

	if (hasFacebookQuery()) {
		window.fbq('track', 'PageView');
	}
};
