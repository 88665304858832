import { FeatureEligibilityFeature } from 'types/investment-entity';
import { investmentEntity } from '@store/modules/investment-entity';
import { NavigationGuard } from 'vue-router';

export const proRouteGuard: (proFeature: FeatureEligibilityFeature) => NavigationGuard = (
	proFeature: FeatureEligibilityFeature
) => {
	return async (to, from, next) => {
		const featureEligibility = investmentEntity.featureEligibility[proFeature];

		if (featureEligibility === 'ELIGIBLE') {
			next();
		} else if (featureEligibility === 'INELIGIBLE_REQUIRES_PRO') {
			next({ name: 'checkout-fundrise-pro-join' });
		} else {
			next({ name: 'account-overview', replace: true });
		}
	};
};
