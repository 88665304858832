<template>
	<div>
		<div
			v-if="props.placehold"
			:class="['placeholder-block', props.classes, { 'placeholder-block__animated': props.useAnimation }]"
		>
			<div v-if="props.showErrorIcon" class="display-flex flex-direction-column gap-100 align-items-center">
				<BaseSvgIcon
					name="warning-filled"
					:height="iconSize"
					:width="iconSize"
					:class="[
						'placeholder-block__error-icon',
						{ 'placeholder-block_error-icon-small': props.errorIconSize === 'small' }
					]"
				/>
				<div v-if="props.errorMessage" class="display-flex flex-direction-column align-items-center gap-200">
					<div class="text-color-content-secondary" v-html="props.errorMessage" />
					<ModularDashboardRetryButton @retry-clicked="emits('retry-clicked')" />
				</div>
			</div>
		</div>
		<slot v-else />
	</div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import ModularDashboardRetryButton from '@components/account/modular-dashboard/modular-dashboard-retry-button.vue';

interface Props {
	placehold?: boolean;
	showErrorIcon?: boolean;
	errorIconSize?: 'small' | 'large';
	errorMessage?: string;
	classes?: string;
	useAnimation?: boolean;
}

interface Emits {
	(e: 'retry-clicked'): void;
}

const props = withDefaults(defineProps<Props>(), {
	placehold: true,
	showErrorIcon: false,
	errorIconSize: 'large',
	errorMessage: undefined,
	classes: '',
	useAnimation: false
});

const emits = defineEmits<Emits>();

const iconSize = computed((): string => (props.errorIconSize === 'large' ? '64' : '48'));
</script>
<style lang="scss" scoped>
@use '../../../styles/constants/_colors.scss' as *;
@use '../../../styles/utilities/respond-to.scss' as *;

.placeholder-block {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: token('bg-tertiary');
	min-height: 190px;
	height: 100%;

	@include respond-to(md) {
		min-width: 190px;
	}

	&__error-icon {
		color: gray(40);
	}

	&__animated {
		animation: placeholder 1.5s infinite linear;
	}
}

@keyframes placeholder {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.25;
	}

	100% {
		opacity: 1;
	}
}
</style>
