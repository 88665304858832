import spacetime, { Format, ParsableDate } from 'spacetime';
import { CustomSpacetimeFormat } from 'types/spacetime-formats';

export const determineFirstInvestment = (chosenDate: number, frequencySelection: string): string => {
	const now = spacetime.now();
	const firstDate = now.date(chosenDate);
	const firstDateMonthLater = firstDate.add(1, 'month');
	const dateFormat = CustomSpacetimeFormat.DAY_MONTH_DATE_ORDINAL_YEAR;
	let firstInvestmentDate = '';

	if (frequencySelection === 'SEMI_MONTHLY') {
		const secondDate = firstDate.add(14, 'days');

		if (firstDate.isAfter(now)) {
			firstInvestmentDate = firstDate.format(dateFormat);
		} else if (secondDate.isAfter(now)) {
			firstInvestmentDate = secondDate.format(dateFormat);
		} else {
			firstInvestmentDate = firstDateMonthLater.format(dateFormat);
		}
	} else if (firstDate.isAfter(now)) {
		firstInvestmentDate = firstDate.format(dateFormat);
	} else {
		firstInvestmentDate = firstDateMonthLater.format(dateFormat);
	}

	return firstInvestmentDate;
};

export const isValidAccountStatementDate = (yearMonthDay: string): boolean => {
	const date = spacetime(yearMonthDay);
	const isProvidedDateBeforeToday = date.isBefore(spacetime.now());
	const isProvidedDateEndOfMonth = date.isSame(date.endOf('month'), 'day');
	return isProvidedDateBeforeToday && isProvidedDateEndOfMonth;
};

export const isValidDateByFormat = (date: ParsableDate, format: Format): boolean => {
	return spacetime(date).isValid() && spacetime(date).format(format) === date;
};
