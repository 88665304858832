export const getItemFromLocalStorage = (key: string): string | null => {
	try {
		return localStorage.getItem(key);
	} catch (e) {
		return null;
	}
};

export const removeItemFromLocalStorage = (key: string): null => {
	try {
		localStorage.removeItem(key);
		return null;
	} catch (e) {
		return null;
	}
};

export const getItemAsBooleanFromLocalStorage = (key: string): boolean => {
	const item = getItemFromLocalStorage(key);
	const isFalse = item === null || item === 'false';
	return !isFalse;
};

export const setItemInLocalStorage = (key: string, value: string): boolean => {
	try {
		localStorage.setItem(key, value);
		return true;
	} catch (e) {
		return false;
	}
};

export const getItemFromSessionStorage = (key: string): string | null => {
	try {
		return sessionStorage.getItem(key);
	} catch (e) {
		return null;
	}
};

export const setItemInSessionStorage = (key: string, value: string): boolean => {
	try {
		sessionStorage.setItem(key, value);
		return true;
	} catch (e) {
		return false;
	}
};

export const removeItemFromSessionStorage = (key: string): null => {
	try {
		sessionStorage.removeItem(key);
		return null;
	} catch (e) {
		return null;
	}
};

export const getItemFromSessionStorageAsBoolean = (key: string): boolean => {
	const item = getItemFromSessionStorage(key);
	const isFalse = item === null || item === 'false';
	return !isFalse;
};

export const setCookie = (name: string, value: string, maxAge: string): void => {
	document.cookie = `${name}=${value};path=/;max-age=${maxAge}; Secure`;
};

export const checkCookieExists = (name: string): boolean => {
	return document.cookie.split(';').some((item) => item.trim().startsWith(`${name}=`)) ?? false;
};

export const getCookieValue = (name: string): string | null => {
	const value = `; ${document.cookie}`.split(`; ${name}=`).pop()?.split(';').shift();
	return value ?? null;
};

export const clearCookie = (name: string): void => {
	setCookie(name, '', '0');
};
