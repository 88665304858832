<template>
	<div ref="target" class="module-md dividends-module">
		<ModularDashboardPlaceholderLines
			v-if="isLoading || isError || showChips"
			:class="{ 'p-100': isLoading || isError }"
			:placehold="isLoading"
			:line-count="0"
			:show-header="true"
			:use-animation="isLoading"
		>
			<div v-if="isError" class="text-color-content-secondary" v-html="moduleLoadingErrorMessage" />
			<Chips
				v-else-if="showChips"
				v-model="selectedChip"
				class="module-section p-100 pb-50-smo"
				:chips="chips"
				:selected="selectedChip"
				:size="isMobile ? 'xs' : 'sm'"
				data-test="chip-select"
			/>
		</ModularDashboardPlaceholderLines>

		<div
			:class="[
				`module-section p-100
				dividends-module-content
				display-flex
				flex-direction-column-smo
				gap-150
				bt-0-smo`,
				{ 'bt-0-md loading': isLoading || isError }
			]"
		>
			<ModularDashboardPlaceholderLines
				class="flex-1"
				:placehold="isLoading || isError"
				:line-count="2"
				:show-header="true"
				:use-animation="isLoading"
			>
				<div class="body-sm">{{ dividendTitle }}</div>
				<div class="heading-4 mt-25">{{ dividendAmount }}</div>
				<transition mode="out-in" name="transition--fade">
					<div v-if="dividendCtaLink">
						<BaseCtaLink
							classes="body-sm font-weight-bold text-color-content-secondary"
							icon="caret"
							icon-dir="right"
							:link="dividendCtaLink"
							data-test="dividend-cta"
						/>
					</div>
				</transition>
			</ModularDashboardPlaceholderLines>
			<ModularDashboardPlaceholderLines
				class="flex-1"
				:placehold="isLoading || isError"
				:use-animation="isLoading"
			>
				<div class="display-flex align-items-center justify-content-space-between">
					<div class="display-flex align-items-center gap-50">
						<span class="badge badge-circle bg-color-slate-10">
							<BaseSvgIcon name="percentage" />
						</span>
						<div>
							<div class="body-sm">Annualized distribution rate</div>
							<div class="body-xs text-color-content-secondary"
								>{{ dividendTimePeriod }} - {{ fundName }}</div
							>
						</div>
					</div>
					<div class="font-weight-bold">{{ dividendAnnualizedDistributionRate }}</div>
				</div>
				<div class="display-flex align-items-center justify-content-space-between mt-100">
					<div class="display-flex align-items-center gap-50">
						<span class="badge badge-circle bg-color-slate-10">
							<BaseSvgIcon name="dollar-coin" />
						</span>
						<div class="body-sm">Total lifetime dividends</div>
					</div>
					<div class="font-weight-bold">{{ lifetimeDividendsAmount }}</div>
				</div>
			</ModularDashboardPlaceholderLines>
		</div>
		<div v-if="isError" class="pb-100 text-align-center">
			<ModularDashboardRetryButton @retry-clicked="loadModule()" />
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'DividendsModule'
};
</script>

<script setup lang="ts">
import { Chip, Link } from 'types/layout';
import { computed, onMounted, ref } from 'vue';
import { currency, percentage } from '@filters/shared-filters';
import { ModularDashboardGridContainer, ModularDashboardModule } from 'types/modular-dashboard';
import Chips from '@components/chips/chips-component.vue';
import { DividendsModuleData } from 'types/account';
import { isMobile } from '@utils/composables';
import { modularDashboard } from '@store/modules/modular-dashboard';
import ModularDashboardPlaceholderLines from '@components/account/modular-dashboard/modular-dashboard-placeholder-lines.vue';
import ModularDashboardRetryButton from '@components/account/modular-dashboard/modular-dashboard-retry-button.vue';
import { moduleLoadingErrorMessage } from '@constants/modular-dashboard';
import { trackMixpanelScrollIntoView } from '@utils/analytics';

interface Props {
	module: ModularDashboardModule;
	container: ModularDashboardGridContainer;
}

const props = defineProps<Props>();

const target = ref<HTMLElement | null>(null);

const isLoading = ref(true);
const isError = ref(false);

const selectedChip = ref<Chip>({ label: '', value: '' });

const moduleData = computed((): DividendsModuleData | null => modularDashboard.dividendsModuleData);

const chips = computed((): Array<Chip> => {
	return (
		moduleData.value?.dividends.map((d) => {
			const timePeriod = `Q${d.quarter} ${d.year}`;

			return {
				label: timePeriod,
				value: timePeriod
			};
		}) ?? []
	);
});

const selectedDividend = computed(() => {
	const index = chips.value.findIndex((chip: Chip) => chip.value === (selectedChip.value as Chip).value);
	return moduleData.value?.dividends[index];
});

const dividendAmount = computed((): string =>
	selectedDividend.value ? currency(selectedDividend.value.dividendAmount) : ''
);

const dividendTimePeriod = computed((): string =>
	selectedDividend.value ? `Q${selectedDividend.value.quarter} ${selectedDividend.value.year}` : ''
);

const dividendTitle = computed((): string =>
	selectedDividend.value
		? `${selectedDividend.value.pending ? 'Pending' : ''} ${dividendTimePeriod.value} net dividends`
		: ''
);

const dividendAnnualizedDistributionRate = computed((): string =>
	selectedDividend.value ? percentage(selectedDividend.value.annualizedDistributionRate, true, 2) : ''
);

const dividendCtaLink = computed((): Link | null => {
	if (
		selectedDividend.value &&
		!selectedDividend.value.pending &&
		!!selectedDividend.value.transactionId &&
		!!selectedDividend.value.transactionType
	) {
		return {
			text: 'View breakdown',
			router: 'account-transactions',
			query: {
				transactionId: selectedDividend.value.transactionId,
				transactionType: selectedDividend.value.transactionType
			}
		};
	}

	return null;
});

const fundName = computed((): string => moduleData.value?.fundName ?? '');

const lifetimeDividendsAmount = computed((): string => currency(moduleData.value?.lifetimeDividendsAmount ?? '0'));

const showChips = computed((): boolean => (moduleData.value?.dividends.length ?? 0) > 1);

onMounted(() => {
	if (target.value) {
		trackMixpanelScrollIntoView(target.value, {
			'View Title': props.container.title,
			'View Content': props.module.name,
			'View ID': props.module.name
		});
	}
});

async function loadModule(): Promise<void> {
	isLoading.value = true;
	isError.value = false;
	try {
		await modularDashboard.storeDividendsModuleData();
		const defaultIndex = +(moduleData.value?.defaultIndex ?? 0);
		selectedChip.value = chips.value[defaultIndex];
	} catch {
		isError.value = true;
	} finally {
		isLoading.value = false;
	}
}

async function setup(): Promise<void> {
	await loadModule();
}

setup();
</script>

<style lang="scss" scoped>
@use '../../../../../styles/constants/_colors.scss' as *;
@use '../../../../../styles/utilities/respond-to.scss' as *;

.badge {
	height: 32px;
	width: 32px;
}

.dividends-module {
	@include respond-to(md) {
		container-type: inline-size;

		@container (max-width: 600px) {
			.dividends-module-content {
				flex-direction: column;
			}
		}

		@container (max-width: 450px) {
			.dividends-module-content {
				border-top: 0;
			}

			:deep(.chip) {
				font-size: 0.75rem;
				padding: 5px 12px;
			}
		}

		@container (max-width: 320px) {
			:deep(.chips-container) {
				display: grid;
				gap: 0.5rem;
				grid-template-columns: repeat(2, max-content);
			}

			:deep(.chip) {
				font-size: 1rem;
				padding: 6px 16px;
			}
		}
	}

	@include respond-to(smo) {
		background: #fff;
		border-bottom: 1px solid gray(20);
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
	}

	.dividends-module-content.loading {
		padding-top: 0 !important;
	}
}
</style>
