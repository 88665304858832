import 'intersection-observer';
import { Directive, DirectiveBinding } from 'vue';
import { getScreenSize } from '@utils/client';

export const lazySrc: Directive = {
	beforeMount: function (el: HTMLElement, binding: DirectiveBinding) {
		let observer: IntersectionObserver | null = null;

		function loadImage(src: string, target: Element): void {
			const image = new Image();
			if (target.tagName !== 'IMG') {
				target.setAttribute('style', 'background: url(' + src + ') center center / cover no-repeat;');
			} else {
				target.setAttribute('src', src);
			}

			image.src = src;

			image.onload = function (): void {
				el.classList.add('in');
			};
		}

		function determineCorrectSrcAndLoadImage(src: string, target: Element): void {
			const sources = src.split(',');
			const sourceParsed = {
				sm: '',
				md: '',
				lg: '',
				default: ''
			};

			sources.forEach((source) => {
				source = source.trim();

				const lastThreeLetters = source.slice(-3);
				if (lastThreeLetters.indexOf('_sm') >= 0) {
					sourceParsed.sm = source.slice(0, -3);
				} else if (lastThreeLetters.indexOf('_md') >= 0) {
					sourceParsed.md = source.slice(0, -3);
				} else if (lastThreeLetters.indexOf('_lg') >= 0) {
					sourceParsed.lg = source.slice(0, -3);
				} else {
					sourceParsed.default = source;
				}
			});

			if (getScreenSize() === 'MOBILE') {
				loadImage(sourceParsed.sm || sourceParsed.default, target);
			} else if (getScreenSize() === 'DESKTOP') {
				loadImage(sourceParsed.lg || sourceParsed.md || sourceParsed.default, target);
			} else {
				loadImage(sourceParsed.md || sourceParsed.default, target);
			}
		}

		function loadOnIntersect(entries: Array<IntersectionObserverEntry>): void {
			entries.forEach((entry) => {
				if (observer) {
					const imgSrc: string = binding.value;
					if (imgSrc.indexOf(',') === -1) {
						loadImage(imgSrc, entry.target);
					} else {
						determineCorrectSrcAndLoadImage(imgSrc, entry.target);
					}

					observer.unobserve(entry.target);
				}
			});
		}

		el.classList.add('fade-in-active');
		observer = new IntersectionObserver(loadOnIntersect, {
			threshold: 0
		});
		observer.observe(el);
	}
};
export default lazySrc;
