<template>
	<li
		v-if="userNotifications.notifications.length > 0"
		ref="notificationDropdown"
		class="position-relative"
		@click.stop="toggleDropdown"
	>
		<BaseButton
			classes="main-nav__menu-item-link position-relative bell-icon"
			data-test="notification-dropdown-icon"
			data-dd-action-name="Notification Bell"
			click-text="Notification Bell"
			:mixpanel-custom-properties="mxpCustomProperties"
		>
			<BaseSvgIcon name="bell" style="height: 18px; width: 16px" />
			<CounterBadge
				v-if="numNewNotifications > 0"
				:text="numNewNotifications"
				class="position-absolute"
				theme="rust"
				style="top: 10px; right: 5px; width: 22px; height: 22px"
			/>
		</BaseButton>
		<span
			class="main-nav__sub-menu main-nav__sub-menu--notification-rollup"
			:class="{ 'main-nav__sub-menu--state-visible': dropdownExpanded }"
			@click.stop
		>
			<span class="display-block dark line-height-125 pl-100 pt-50">Notifications</span>
			<ul class="mt-50" style="max-height: 300px; overflow: auto">
				<NotificationDropdownItem
					v-for="(notification, index) in userNotifications.notifications"
					:key="index"
					:notification="notification"
				/>
			</ul>
		</span>
	</li>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { getCookieValue, setCookie } from '@utils/web-storage';
import CounterBadge from '@components/badges/counter-badge.vue';
import { investmentEntity } from '@store/modules/investment-entity';
import NotificationDropdownItem from '@components/header/navigation/notification-dropdown-item.vue';
import { NotificationResponse } from 'types/header';

interface Props {
	expanded: boolean;
}
const props = defineProps<Props>();
interface Emits {
	(e: 'expandNotificationDropdown', value: boolean): void;
}
const emit = defineEmits<Emits>();

const notificationDropdown = ref<null | HTMLElement>(null);

const lastViewedCookieKey = 'com.fundrise.notifications.lastViewed';

const lastViewCookie = ref(getCookieValue(lastViewedCookieKey));

const mxpCustomProperties = computed(() => ({
	'Has New Notifications': numNewNotifications.value > 0,
	'Click Type': props.expanded ? 'close' : 'open'
}));

const userNotifications = computed((): NotificationResponse => {
	return investmentEntity.notifications;
});

const numNewNotifications = computed((): number => {
	if (lastViewCookie.value && lastViewCookie.value > userNotifications.value.generatedTimestamp) {
		return 0;
	} else if (userNotifications.value.lastNotificationView) {
		return userNotifications.value.notifications.filter((notification) => {
			return notification.displayDateMillis >= userNotifications.value.lastNotificationView;
		}).length;
	} else {
		return userNotifications.value.notifications.length;
	}
});

const dropdownExpanded = computed((): boolean => {
	return props.expanded;
});

watch(
	() => dropdownExpanded.value,
	(expand: boolean) => {
		if (expand) {
			document.addEventListener('click', clickOutsideHandler);

			updateLastViewedNotification();
		} else {
			document.removeEventListener('click', clickOutsideHandler);
		}
	}
);

function clickOutsideHandler(event: MouseEvent): void {
	if (notificationDropdown.value && notificationDropdown.value.contains(event.target as Node)) {
		emit('expandNotificationDropdown', false);
	}
}

function toggleDropdown(): void {
	updateLastViewedNotification();
	emit('expandNotificationDropdown', !props.expanded);
}

async function updateLastViewedNotification(): Promise<void> {
	if (numNewNotifications.value > 0) {
		await investmentEntity.updateNotificationsView();

		setCookie(lastViewedCookieKey, Date.now().toString(), '34560000');
	}
}
</script>
<style lang="scss" scoped>
.bell-icon {
	top: 3px;
}
</style>
