import { DripStatus } from 'types/investment-entity';
import { investmentEntityRequest } from '@utils/request';

export const getDrip = async (): Promise<DripStatus> => {
	const response = await investmentEntityRequest.get(`/drip-preference`);
	return response.data.reinvestmentPreference;
};

export const updateDrip = async (reinvestmentPreference: DripStatus): Promise<void> => {
	await investmentEntityRequest.post(`/drip-preference`, {
		reinvestmentPreference
	});
};
