import {
	CustomAllocationOfferingResponse,
	CustomAllocationPlanResponseAllocations,
	CustomAllocationResponse
} from 'types/custom-plans';

export function mapCustomPlanAllocations(
	allocations: Array<CustomAllocationPlanResponseAllocations>,
	offerings: Array<CustomAllocationOfferingResponse>
): Array<CustomAllocationResponse> {
	return (
		(allocations
			.filter((allocation) => allocation.available)
			.map((allocation) => ({
				...offerings.find((offering) => offering.reitId === allocation.reitId),
				weight: +allocation.weight
			})) as Array<CustomAllocationResponse>) ?? []
	);
}
