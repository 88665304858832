import { app } from '@store/modules/app';
import { defineStore } from 'pinia';
import { IntercomContext } from 'types/intercom';
import { ref } from 'vue';

export const userIntercomStore = defineStore('intercom', () => {
	const enabled = ref<boolean | null>(null);
	const context = ref<IntercomContext | null>(null);

	async function storeFeatureEnabled(): Promise<void> {
		enabled.value = await app.isFeatureEnabled('intercom-chat-bot');
	}

	return {
		enabled,
		context,
		storeFeatureEnabled
	};
});
