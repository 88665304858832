const homeRedirects = {
	routeName: 'home',
	regExPaths: [
		'^/mission$',
		'^/historical-performance$',
		'^/fundrise-reviews-and-ratings$',
		'^/ratings-and-reviews$'
	].join('|')
};

export default homeRedirects;
