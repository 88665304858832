import { AxiosRequestConfig, AxiosRequestInterceptorParams } from 'axios';
import { v4 as uuidv4 } from 'uuid';

/**
 * Adds a UUID value as a correlation ID header. This is used on the API side for tracing.
 */
function correlationIdHeader(config: AxiosRequestConfig): AxiosRequestConfig {
	const xCorrelationId = {
		'X-Correlation-Id': uuidv4()
	};
	config.headers = { ...config.headers, ...xCorrelationId };
	return config;
}

export const correlationIdHeaderInterceptor: AxiosRequestInterceptorParams = [
	(config) => correlationIdHeader(config),
	(error) => Promise.reject(error)
];
