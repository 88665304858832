import { apiRequest, apiSecureRequest, investmentEntityRequest } from '@utils/request';
import {
	MicroFeedbackApiDropdownOption,
	MicroFeedbackDropdownOption,
	MicroFeedbackSatisfactionSubmission,
	MicroFeedbackSubmission,
	MicroFeedbackSubmittedFeedbackResponse,
	MicroFeedbackType,
	MicroFeedbackVotingSubmission
} from 'types/micro-feedback';

export const submitMicroFeedbackResponse = async (microFeedbackResponse: MicroFeedbackSubmission): Promise<void> => {
	await investmentEntityRequest.post(`/micro-feedback/submit`, microFeedbackResponse, {
		suppressToast: () => true
	});
};

export const getDropdownOptions = async (eventType: string): Promise<Array<MicroFeedbackDropdownOption>> => {
	const response = await apiSecureRequest.get(`/micro-feedback/event-type/${eventType}/options`);
	const apiOptions: Array<MicroFeedbackApiDropdownOption> = response.data;
	return apiOptions.map((dropdown) => {
		return {
			dropdownText: dropdown.dropdownText,
			id: dropdown.id
		};
	});
};

export const submitMicroFeedbackSatisfactionResponse = async (
	satisfactionForm: MicroFeedbackSatisfactionSubmission,
	suppressToast = true
): Promise<void> => {
	await apiRequest.post(`/micro-feedback/satisfaction/submit`, satisfactionForm, {
		suppressToast: () => suppressToast
	});
};

export const submitMicroFeedbackVotingResponse = async (votingForm: MicroFeedbackVotingSubmission): Promise<void> => {
	await investmentEntityRequest.post(`/micro-feedback/voting/submit`, votingForm, {
		suppressToast: () => true
	});
};

export const hasSubmittedMicrofeedback = async (
	microFeedbackType: MicroFeedbackType,
	associatedEventId: string
): Promise<boolean> => {
	const response = await apiSecureRequest.get(
		`/micro-feedback/event/${microFeedbackType}/associated-event/${associatedEventId}`,
		{
			suppressToast: () => true
		}
	);
	return (response.data as MicroFeedbackSubmittedFeedbackResponse).hasSubmittedFeedback;
};
