<template>
	<nav class="mobile-nav">
		<div class="mobile-nav__top-bar" :class="{ sticky: props.styles.isSticky }">
			<div
				class="mobile-nav__top-bar-inner container display-flex align-items-center justify-content-space-between"
			>
				<div @click="$emit('remove-hover')">
					<BaseLink :link="logoLink">
						<img :src="props.styles.logo" alt="Fundrise logo" style="width: 137px" />
					</BaseLink>
				</div>
				<div class="display-flex align-items-center">
					<AddFundsButton
						v-if="props.showAddFunds"
						button-size="mini"
						classes="button button-primary body-sm px-100 mr-100"
						data-context="Main Nav"
						data-test="add-funds-button"
					/>
					<BaseLinkButton
						data-test="mobile-menu-open"
						data-dd-action-name="mobile menu open"
						@click="toggleFlyout"
						><BaseSvgIcon class="mobile-hamburger-nav" name="hamburger-nav" style="margin: 0 auto"
					/></BaseLinkButton>
				</div>
			</div>
		</div>
		<div class="mobile-nav__flyout" :class="{ 'mobile-nav__flyout--state-active': flyoutOpened }">
			<div class="mobile-nav__flyout-header display-flex align-items-center justify-content-space-between">
				<div @click="toggleFlyout">
					<BaseLink :link="logoLink">
						<img
							:src="appCloudfrontPath + '/interface/logo-hz-color.svg'"
							alt="Fundrise logo"
							style="width: 137px"
						/>
					</BaseLink>
				</div>
				<BaseLinkButton
					classes="text-color-content-primary"
					data-dd-action-name="mobile menu close"
					@click="toggleFlyout"
				>
					<BaseSvgIcon class="close-icon" name="close" style="height: 16px; width: 16px" />
				</BaseLinkButton>
			</div>
			<slot name="flyout-content"></slot>
		</div>
		<div
			class="mobile-nav__flyout-dimmer"
			:class="{ 'mobile-nav__flyout-dimmer--state-active': flyoutOpened }"
			@click="toggleFlyout"
		></div>
	</nav>
</template>

<script lang="ts">
export default {
	name: 'MobileNavTemplate'
};
</script>

<script setup lang="ts">
import { computed, ref } from 'vue';
import AddFundsButton from '@components/account/add-funds-button.vue';
import { appCloudfrontPath } from '@constants';
import { HeaderStyle } from 'types/header';
import { isAuthenticated } from '@utils/composables';
import { Link } from 'types/layout';

interface Props {
	styles: HeaderStyle;
	showAddFunds?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	showAddFunds: false
});

interface Emits {
	(e: 'remove-hover'): void;
}
const emit = defineEmits<Emits>();

const flyoutOpened = ref(false);

const logoLink = computed((): Link => {
	return isAuthenticated.value ? { router: 'account-overview' } : { router: 'home' };
});

function toggleFlyout(): void {
	flyoutOpened.value = !flyoutOpened.value;

	if (!flyoutOpened.value) {
		emit('remove-hover');
	}
}
</script>
<style lang="scss">
.close-icon path[pid='0'] {
	stroke-width: 2px;
}
</style>
