import { defineStore } from 'pinia';
import { getTaxData } from '@api/tax';
import { ref } from 'vue';

export const useTaxStore = defineStore('tax', () => {
	const isReit1099Delivered = ref(false);
	const isEFundK1Delivered = ref(false);
	const isK3Delivered = ref(false);
	const willReceiveReit1099 = ref(false);
	const willReceiveEFundK1 = ref(false);
	const willReceiveK3 = ref(false);
	const currentTaxYear = ref('');

	async function storeTaxData(): Promise<void> {
		if (!currentTaxYear.value) {
			const taxData = await getTaxData();
			currentTaxYear.value = taxData.currentTaxYear;
			willReceiveEFundK1.value = taxData.willReceiveEFundK1;
			willReceiveK3.value = taxData.willReceiveK3;
			willReceiveReit1099.value = taxData.willReceiveReit1099;
			isEFundK1Delivered.value = taxData.isEFundK1Delivered;
			isK3Delivered.value = taxData.isK3Delivered;
			isReit1099Delivered.value = taxData.isReit1099Delivered;
		}
	}

	function $reset(): void {
		isReit1099Delivered.value = false;
		isEFundK1Delivered.value = false;
		isK3Delivered.value = false;
		willReceiveReit1099.value = false;
		willReceiveEFundK1.value = false;
		willReceiveK3.value = false;
		currentTaxYear.value = '';
	}

	return {
		isReit1099Delivered,
		isEFundK1Delivered,
		isK3Delivered,
		willReceiveReit1099,
		willReceiveEFundK1,
		willReceiveK3,
		currentTaxYear,
		storeTaxData,
		$reset
	};
});
