<template>
	<div>
		<BaseLinkButton :classes="buttonClasses" data-test="accordion-toggle" @click="toggle()">
			<slot name="toggle"></slot>
			<BaseSvgIcon class="accordion-icon" :name="accordionIcon" width="12" height="12" />
		</BaseLinkButton>
		<TransitionCollapse>
			<div v-if="open">
				<slot name="drawer"></slot>
			</div>
		</TransitionCollapse>
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import TransitionCollapse from '@components/layout/transition-collapse.vue';

interface Props {
	startOpened?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
	startOpened: false
});

const open = ref(false);

const accordionIcon = computed(() => {
	return open.value ? 'minus-sign' : 'plus-sign';
});

const buttonClasses = computed((): string => {
	const base = 'mobile-nav__item-link display-flex align-items-center justify-content-space-between bt';

	return open.value ? base + ' open' : base;
});

function setup(): void {
	open.value = props.startOpened;
}
setup();

function toggle(): void {
	open.value = !open.value;
}
</script>
<style lang="scss">
@use '../../../../styles/constants/colors.scss' as *;

.accordion-icon path[pid='0'] {
	stroke-width: 2px;
}

.mobile-nav__item-link.open {
	text-shadow: $nav-text-shadow;
}
</style>
