import { addScriptTag } from '@utils/dom';
import { app } from '@store/modules/app';

export const initializeUserway = async (): Promise<void> => {
	const userWayEnabled = await app.isFeatureEnabled('userway-accessibility-plugin');

	if (userWayEnabled) {
		const userwayId = 'userway';
		addScriptTag('https://cdn.userway.org/widget.js', userwayId);

		const userwayScript = document.getElementById(userwayId);
		if (userwayScript) {
			userwayScript.setAttribute('data-account', 'cVMkm5shQu');
		}
	}
};
