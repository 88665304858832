import { InvestmentEntity } from 'types/investment-entity';

export function displayAccountType(entity: InvestmentEntity): string {
	if (entity.isIraEntity) {
		if (entity.iraEntityMetadata.iraTypeLabel) {
			return entity.iraEntityMetadata.iraTypeLabel + ' IRA';
		}
		return 'Undesignated IRA';
	}
	return entity.entityTypeLabel ?? '';
}

export function canViewGrowthCalculator(investmentEntity: InvestmentEntity): boolean {
	return investmentEntity.entityType === 'INDIVIDUAL' || investmentEntity.entityType === 'JOINT_ACCOUNT';
}
