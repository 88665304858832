import { CheckoutAccountFormStatus, SignupStep } from 'types/checkout';
import { enumToKebab } from '@filters/shared-filters';
import { InvestmentEntityType } from 'types/investment-entity';
import { orderForm } from '@store/modules/order-form';
import { RouteLocationRaw } from 'vue-router';
import { signupStepToRouteMap } from '@constants/checkout';

export async function getNextRoute(currentStep?: SignupStep): Promise<RouteLocationRaw> {
	switch (currentStep?.signupStepType) {
		case 'INFO':
			if (currentStep.infoName) {
				const infoSlug = enumToKebab(currentStep.infoName);
				return { name: 'info-screen', params: { infoSlug } };
			} else {
				return { name: 'info-framework' };
			}
		case 'QUESTION':
			if (currentStep.questionName) {
				const questionId = enumToKebab(currentStep.questionName);
				return { name: 'question', params: { questionId } };
			} else {
				return { name: 'question-framework' };
			}
		case 'CUSTOM':
		default: {
			let route;
			if (currentStep?.signupStepName) {
				route = signupStepToRouteMap[currentStep.signupStepName];
				return {
					name: route
				};
			} else {
				return (route = await orderForm.goToNextStep());
			}
		}
	}
}

export const accountDetailsRoute = async (
	entityType: InvestmentEntityType,
	userRoute: CheckoutAccountFormStatus
): Promise<RouteLocationRaw> => {
	switch (entityType) {
		case 'INDIVIDUAL':
			await orderForm.getUserCheckoutDetailsData('CONTACT_INFORMATION_STEP');
			return { name: 'checkout-individual-account-details' };
		case 'JOINT_ACCOUNT':
			await orderForm.getUserCheckoutDetailsData('JOINT_INFORMATION_STEP');
			return { name: 'checkout-joint-account-details', query: { account: userRoute } };
		case 'LLC':
			await orderForm.getUserCheckoutDetailsData('ENTITY_INFORMATION_STEP');
			return {
				name: 'checkout-entity-account-details',
				query: { account: userRoute }
			};
		case 'TRUST':
			await orderForm.getUserCheckoutDetailsData('TRUST_INFORMATION_STEP');
			return { name: 'checkout-trust-account-details', query: { account: userRoute } };
		default:
			return { name: 'checkout-resume' };
	}
};
