import { DirectInvestmentEligibility, Fund, FundCategory, FundTypeEnum } from 'types/funds';
import { FundCategoryType, FundOverviewDto, OfferingAvailabilityStatus } from 'types/reit-element';
import { CustomAllocationOfferingResponse } from 'types/custom-plans';

/**
 * Maps a Fund model to a FundOverviewDto.
 * This function serves as an interim step for migrating data from the old fund model structure
 * to the new Fund structure powered by sanity, eventually we will do a refactor to update all
 * Fund component to consume the new Fund model.
 *
 * @param {Fund} fund - The fund model containing detailed fund data.
 * @returns {FundOverviewDto} - A mapped object containing the essential fields for fund details components/views.
 */
export function fundDetailMapper(fund: Fund): FundOverviewDto {
	return {
		id: fund.fundriseId,
		assetClass: fund.assetClass.enumValue,
		currentVintageId: fund.directInvestmentDetails?.reitOfferingVintageId ?? '',
		fundName: fund.name,
		fundType: fund.assetClass.label,
		summary: fund.fundSummary ?? '',
		marketingDisplayFundAum: fund.fundAumValue ?? '',
		marketingDisplayFundAumLabel: fund.fundAum ?? '',
		investmentOpenForUser: false,
		offeringOpen: fund.directInvestmentDetails?.currentVintageSaleIsAllowed ?? false,
		accreditedOnly: fund.directInvestmentDetails?.accreditedOnly ?? false,
		directInvestmentEligibility: fund.directInvestmentDetails
			?.directInvestmentEligibility as DirectInvestmentEligibility,
		inceptionDate: fund.inceptionDate ?? '',
		fundObjective: fund.objective?.name ?? '',
		fundCategory: fund.category?.name.toUpperCase() as FundCategoryType,
		offeringAvailabilityStatus: fund.offeringAvailabilityStatus as OfferingAvailabilityStatus,
		nextAvailableWindow: fund.reservationWindow?.reservationWindow.name ?? ''
	};
}

export function customPlanFundDetailMapper(fund: Fund): CustomAllocationOfferingResponse {
	return {
		assetType: fund.assetClass.label,
		defaultAllocation: fund.defaultAllocation,
		directInvestmentEligibility: fund.directInvestmentDetails
			?.directInvestmentEligibility as DirectInvestmentEligibility,
		fundCategory: fund.category as FundCategory,
		fundObjective: fund.objective?.name ?? '',
		inceptionDate: fund.inceptionDate ?? '',
		offeringOpen: fund.directInvestmentDetails?.currentVintageSaleIsAllowed ?? false,
		accreditedOnly: fund.directInvestmentDetails?.accreditedOnly ?? false,
		marketingDisplayFundAum: fund.fundAumValue ?? '',
		marketingDisplayFundAumLabel: fund.fundAum ?? '',
		reitId: fund.fundriseId,
		reitName: fund.name,
		reitType: fund.type?.name as FundTypeEnum,
		requiresContingencyPlan: fund.requiresContingencyPlan,
		requiresK1Acknowledgment: fund.requiresK1Acknowledgment,
		summary: fund.fundSummary ?? '',
		offeringAvailabilityStatus: fund.offeringAvailabilityStatus as OfferingAvailabilityStatus
	};
}
