import { Link } from 'types/layout';

export const contactOurTeamLink: Link = {
	text: 'contact our team',
	router: 'contact'
};

export const contactSupportLink: Link = {
	text: 'Contact support',
	router: 'contact'
};

export const backToDashboardLink: Link = {
	text: 'Back to dashboard',
	router: 'account-overview'
};

export const documentsLink: Link = {
	text: 'investment documents',
	router: 'account-documents'
};

export const investmentsEmailLink: Link = {
	text: 'investments@fundrise.com',
	href: 'mailto:investments@fundrise.com',
	target: '_blank'
};

export const irTeamCalendlyLink: Link = {
	href: 'https://calendly.com/fundrise-investor-relations/investor-relations-support-call',
	target: '_blank'
};

export const performanceDisclosure: Link = {
	text: 'full disclosure',
	router: 'disclosures',
	hash: '#performance-disclosure',
	target: '_blank'
};

export const privacyPolicyDocLink: Link = {
	href: `${import.meta.env.VITE_BASE_PATH}/privacy-policy.html`,
	params: { title: 'Privacy Policy' },
	target: '_blank',
	text: 'Privacy Policy'
};

export const supportEmailLink: Link = {
	text: 'support@fundrise.com',
	href: 'mailto:support@fundrise.com'
};

export const settingsInvestmentPlanViewLink: Link = {
	text: 'investment plan settings',
	router: 'settings-investment-plan-view'
};

export const termsOfServiceDocLink: Link = {
	router: 'website-document-view',
	params: { title: 'Terms of Service' },
	target: '_blank',
	text: 'Terms of Service'
};

export const fundriseConnectDocsLink: Link = {
	href: 'https://connect.fundrise.com/',
	target: '_blank'
};

export const redemptionLearnMoreLink: Link = {
	router: 'help-center-article',
	text: 'Learn more',
	target: '_blank',
	params: {
		articleId: '360054384672'
	}
};

export const transactionsLink: Link = {
	text: 'transactions page',
	router: 'account-transactions'
};
