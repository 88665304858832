import { AddFundsRoute } from 'types/add-funds';

export const addFundsRouteOrder: Array<AddFundsRoute> = [
	'account-invest',
	'add-funds-ria-amount',
	'add-funds-ria-view-allocation',
	'add-funds-ria-review',
	'add-funds-ria-success'
];

export const minInvestmentAmount = 10;
