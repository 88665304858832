import { FundriseRouteConfig, NavigationGuard } from 'vue-router';
import { investmentEntity } from '@store/modules/investment-entity';
import { useCustomPlansStore } from '@stores/custom-plans';

export const customPlansGuard: NavigationGuard = async (to, from, next) => {
	if (investmentEntity.featureEligibility.customAllocation !== 'ELIGIBLE') {
		next({ name: 'account-overview', replace: true });
	} else {
		next();
	}
};

export const customPlansStartGuard: NavigationGuard = async (to, from, next) => {
	const customPlansStore = useCustomPlansStore();
	customPlansStore.isFlowStarted = true;

	if (investmentEntity.currentRiaPlanInvestmentStrategy === 'CUSTOM_PORTFOLIO') {
		if (to.query.edit) {
			customPlansStore.isEditMode = true;
			next({ name: 'custom-plans-build', replace: true });
		} else {
			next();
		}
	} else {
		next();
	}
};

export const customPlansBuildGuard: NavigationGuard = async (to, from, next) => {
	const customPlansStore = useCustomPlansStore();
	if (!customPlansStore.isFlowStarted) {
		next({ name: 'custom-plans-start', replace: true });
	} else {
		next();
	}
};

export const customPlansBackupPlanGuard: NavigationGuard = async (to, from, next) => {
	const customPlansStore = useCustomPlansStore();
	if (customPlansStore.hasCustomAllocationSelections) {
		if (customPlansStore.requiresContingencyAcknowledgement) {
			next();
		} else {
			// if there are no contingency options, skip the step
			next({ name: 'custom-plans-allocation-type', replace: true });
		}
	} else {
		next({ name: 'custom-plans-start', replace: true });
	}
};

export const customPlansAllocationTypeGuard: NavigationGuard = async (to, from, next) => {
	const customPlansStore = useCustomPlansStore();
	if (customPlansStore.hasCustomAllocationSelections) {
		if (!customPlansStore.requiresContingencyAcknowledgement) {
			next();
		} else {
			next({ name: 'custom-plans-backup-plan', replace: true });
		}
	} else {
		next({ name: 'custom-plans-start', replace: true });
	}
};

export const customPlansReviewGuard: NavigationGuard = async (to, from, next) => {
	const customPlansStore = useCustomPlansStore();
	if (customPlansStore.hasCustomAllocationSelections) {
		if (!customPlansStore.customAllocationType) {
			next({ name: 'custom-plans-allocation-type', replace: true });
		} else {
			next();
		}
	} else {
		next({ name: 'custom-plans-start', replace: true });
	}
};

export const customPlansSuccessGuard: NavigationGuard = async (to, from, next) => {
	const customPlansStore = useCustomPlansStore();
	if (customPlansStore.hasSubmittedPlan) {
		next();
	} else {
		next({ name: 'custom-plans-start', replace: true });
	}
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const customPlanRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/account/custom-plans`,
		name: 'custom-plans',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: customPlansGuard,
		component: () => import('@views/account/custom-plans/custom-plans.vue'),
		redirect: { name: 'custom-plans-start' },
		children: [
			{
				path: 'start',
				name: 'custom-plans-start',
				meta: { step: 1, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/account/custom-plans/custom-plans-start.vue'),
				beforeEnter: customPlansStartGuard
			},
			{
				path: 'build',
				name: 'custom-plans-build',
				meta: { step: 2, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/account/custom-plans/custom-plans-build.vue'),
				beforeEnter: customPlansBuildGuard
			},
			{
				path: 'backup-plan',
				name: 'custom-plans-backup-plan',
				meta: { step: 3, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/account/custom-plans/custom-plans-backup.vue'),
				beforeEnter: customPlansBackupPlanGuard
			},
			{
				path: 'allocation-type',
				name: 'custom-plans-allocation-type',
				meta: { step: 4, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/account/custom-plans/custom-plans-allocation-type.vue'),
				beforeEnter: customPlansAllocationTypeGuard
			},
			{
				path: 'review',
				name: 'custom-plans-review',
				meta: { step: 5, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/account/custom-plans/custom-plans-review.vue'),
				beforeEnter: customPlansReviewGuard
			},
			{
				path: 'success',
				name: 'custom-plans-success',
				meta: { step: 6, minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/account/custom-plans/custom-plans-success.vue'),
				beforeEnter: customPlansSuccessGuard
			}
		]
	}
];

export default customPlanRoutes;
