// API Methods - https://developers.intercom.com/installing-intercom/docs/intercom-javascript
// API Attributes & Objects - https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
import { app } from '@store/modules/app';
import { IntercomContext } from 'types/intercom';
import { investmentEntity } from '@store/modules/investment-entity';
import { trackMixpanelEventWithBeacon } from '@utils/analytics';
import { user } from '@store/modules/user';
import { userIntercomStore } from '@stores/intercom';

export const intercomEnabled = async (): Promise<boolean> => {
	const store = userIntercomStore();

	await store.storeFeatureEnabled();
	const isWebview = app.isMobileWebview;
	const isNonInvestor = app.isAuthenticated && !user.hasFirstInvestment;

	const enabled = !!store.enabled && !isWebview && !app.isAdvisor;

	if (isNonInvestor || store.context === 'SIGN_UP') {
		const isInTestGroup = await app.isUserInLDTestGroup({
			featureFlag: '2024-11-05-intercom-in-signup-a-b-test',
			testGroup: 'test-enabled-in-signup'
		});
		return enabled && isInTestGroup;
	} else {
		return enabled;
	}
};

export const registerIntercom = async (context: IntercomContext): Promise<void> => {
	const store = userIntercomStore();

	if (store.context === context) {
		return; // don't reinitialize if configuration hasn't changed
	}

	store.context = context;
	if (await intercomEnabled()) {
		window.Intercom('boot', {
			app_id: 'sj0c506e',
			hide_default_launcher: true,
			custom_launcher_selector: '#intercom-custom-launcher',
			alignment: 'right',
			vertical_padding: 64,
			horizontal_padding: 24,
			environment: import.meta.env.VITE_INTERCOM_ENV,
			isAuthenticated: app.isAuthenticated,
			user_id: user.user?.mixpanelId,
			fundrise_user_id: user.user?.id,
			zendeskId: user.user?.zendeskId,
			user_hash: user.user?.intercomIdentityVerification,
			// name: [user.user?.firstName, user.user?.lastName].join(' '),
			// email: user.user?.email,
			// phone: user.phoneData.currentPhoneNumber,
			context,
			investor: user.hasFirstInvestment,
			advisor: user.user?.isInvestmentAdvisor ?? false,
			accredited: investmentEntity.isVerifiedAccredited,
			pro: user.isSubscriptionActive,
			premium: user.isPremiumUser,
			subscriptionStatus: user.subscriptionStatus,
			created_at: user.user?.entityDetails?.firstInvestmentDate
		});

		window.Intercom('onShow', () => trackMixpanelEventWithBeacon('Intercom', { event: 'Show' }));
		window.Intercom('onHide', () => trackMixpanelEventWithBeacon('Intercom', { event: 'Hide' }));

		app.toggleIntercom(true);
	} else {
		await shutdownIntercom();
	}
};

export const shutdownIntercom = async (): Promise<void> => {
	window.Intercom('shutdown');
	app.toggleIntercom(false);
};

export const intercomShowNewMessage = (prepopulateMessage: string): void => {
	window.Intercom('showNewMessage', prepopulateMessage);
};
