import { flattenQueryParam, includesRequiredQueryParams } from '@utils/request';
import { AppRedirectType } from 'types/app-redirect';
import { FundriseRouteConfig } from 'vue-router';

const appRedirectRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/app-redirect',
		name: 'app-redirect',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const includesRequiredParams = includesRequiredQueryParams(['redirectPage'], to);
			if (includesRequiredParams) {
				const redirectPage = (flattenQueryParam(to.query.redirectPage) as AppRedirectType) ?? '';

				switch (redirectPage) {
					case 'SETTINGS':
						next({
							name: 'settings-account-information-redirect',
							replace: true
						});
						break;
					case 'DOCUMENTS':
						next({ name: 'account-documents', replace: true });
						break;
					default:
						next({ name: 'account-overview', replace: true });
						break;
				}
			} else {
				next({ name: '404' });
			}
		},
		redirect: (to) => {
			const includesRequiredParams = includesRequiredQueryParams(['redirectPage'], to);
			if (includesRequiredParams) {
				const redirectPage = (flattenQueryParam(to.query.redirectPage) as AppRedirectType) ?? '';

				switch (redirectPage) {
					case 'SETTINGS':
						return {
							name: 'settings-account-information-redirect',
							replace: true
						};
					case 'DOCUMENTS':
						return { name: 'account-documents', replace: true };
					default:
						return { name: 'account-overview', replace: true };
				}
			} else {
				return { name: '404' };
			}
		}
	}
];

export default appRedirectRoutes;
