<template>
	<ul v-if="investmentEntities.length > 1">
		<NavInvestmentEntityListItem
			v-for="investmentEntity in investmentEntities"
			:key="investmentEntity.investmentEntityId"
			:entity="investmentEntity"
		/>
	</ul>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { InvestmentEntity } from 'types/investment-entity';
import NavInvestmentEntityListItem from '@components/header/navigation/nav-investment-entity-list-item.vue';
import { user } from '@store/modules/user';

const investmentEntities = computed((): Array<InvestmentEntity> => {
	if (user.user?.entityDetails) {
		return user.user.entityDetails?.investmentEntities;
	}

	return [];
});
</script>
