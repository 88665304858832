<template>
	<BasicBanner v-if="showBanner" data-context="Service Titan iPO">
		<p class="mr-200"
			>{{ bannerText }} <BaseLink class="text-color-white text-decoration-underline" :link="learnMoreLink"
		/></p>
		<template #close-button><span></span></template>
	</BasicBanner>
</template>

<script setup lang="ts">
import BasicBanner from '@components/layout/basic-banner.vue';
import { computed } from 'vue';
import { isAuthenticated } from '@utils/composables';
import { Link } from 'types/layout';
const bannerText = 'Congratulations to ServiceTitan on their successful IPO.';

const learnMoreLink: Link = {
	text: 'Learn more',
	router: 'investor-update',
	params: { investorUpdateId: '1211' }
};

const showBanner = computed((): boolean => {
	return !isAuthenticated.value;
});
</script>
