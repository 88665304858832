import { FundriseRouteConfig, NavigationGuard } from 'vue-router';
import { user } from '@store/modules/user';

const shareholderVoteEligibilityCheck: NavigationGuard = async (to, from, next) => {
	if (user.isEligibleForShareholderVote) {
		next();
	} else {
		next({ name: 'account-overview', replace: true });
	}
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const shareholderVoteRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/shareholder-vote',
		name: 'shareholder-vote',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await user.refreshUser();
			await shareholderVoteEligibilityCheck(to, from, next);
		},
		component: () => import('@views/shareholder-vote/shareholder-vote.vue'),
		redirect: { name: 'shareholder-vote-by-account-and-fund' },
		children: [
			{
				path: 'vote-by-fund',
				name: 'shareholder-vote-by-account-and-fund',
				beforeEnter: shareholderVoteEligibilityCheck,
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/shareholder-vote/shareholder-vote-by-account-and-fund.vue')
			},
			{
				path: 'success',
				name: 'shareholder-vote-success',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/shareholder-vote/shareholder-vote-success.vue')
			},
			{
				path: 'learn-more',
				name: 'shareholder-vote-learn-more',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/shareholder-vote/shareholder-vote-learn-more.vue')
			}
		]
	},
	{
		path: '/webview/shareholder-vote',
		name: 'shareholder-vote-webview',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await user.refreshUser();
			next();
		},
		component: () => import('@views/webview/shareholder-vote/shareholder-vote.vue'),
		redirect: { name: 'shareholder-vote-by-account-and-fund-webview' },
		children: [
			{
				path: 'vote-by-fund',
				name: 'shareholder-vote-by-account-and-fund-webview',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/shareholder-vote/shareholder-vote-by-account-and-fund.vue')
			},
			{
				path: 'success',
				name: 'shareholder-vote-success-webview',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/shareholder-vote/shareholder-vote-success.vue')
			},
			{
				path: 'learn-more',
				name: 'shareholder-vote-learn-more-webview',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				component: () => import('@views/shareholder-vote/shareholder-vote-learn-more.vue')
			}
		]
	}
];

export default shareholderVoteRoutes;
