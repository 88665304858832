<template>
	<div :class="['micro-feedback-performance-context-module bg-color-greige', props.classes]">
		<Badge class="micro-feedback-performance-context-module-badge" background="pro" size="medium" text="BETA" />
		<p class="body-sm mt-0">
			<span class="mr-25"> This feature is being tested.</span>
			<BaseButton classes="cta-link--rust display-inline-flex align-items-center" @click="openMicroFeedbackModal">
				Give&nbsp;feedback <BaseSvgIcon class="ml-25" name="arrow" />
			</BaseButton>
		</p>
	</div>
</template>

<script setup lang="ts">
import { app } from '@store/modules/app';
import Badge from '@components/badges/badge-component.vue';
import MicroFeedbackPerformanceContextFormModal from '@components/micro-feedback/micro-feedback-performance-context-form-modal.vue';

interface Props {
	classes?: string;
}
const props = withDefaults(defineProps<Props>(), {
	classes: undefined
});

function openMicroFeedbackModal(): void {
	app.UPDATE_CURRENT_MODAL({
		modal: MicroFeedbackPerformanceContextFormModal
	});
}
</script>

<style lang="scss" scoped>
@use '../../styles/constants/colors' as *;

.micro-feedback-performance-context-module {
	border-radius: 0.5rem;
	border: 1px solid token('bg-tertiary');
	display: flex;
	gap: 10px;
	padding: 1rem;

	&-badge {
		margin-top: 2px;
	}
}
</style>
