import { AxiosResponse } from 'axios';
import { investmentEntityRequest } from '@utils/request';
import { NotificationResponse } from 'types/header';

export const getNotifications = async (): Promise<NotificationResponse> => {
	const response: AxiosResponse = await investmentEntityRequest.get(`/recent-notifications-detailed-dividends`);
	const notificationResponse: NotificationResponse = response.data;

	return notificationResponse;
};

export const updateNotifications = async (timestamp: object): Promise<void> => {
	await investmentEntityRequest.post(`/notifications`, timestamp);
};
