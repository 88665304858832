import { AccreditationVerificationType } from 'types/accreditation';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAccreditationStore = defineStore('accreditation', () => {
	const verificationType = ref<AccreditationVerificationType | null>(null);

	function $reset(): void {
		verificationType.value = null;
	}

	return {
		verificationType,
		$reset
	};
});
