<template>
	<div ref="target" class="module-md portfolio-overview-module">
		<div class="module-section portfolio-overview-module-section">
			<ModularDashboardPlaceholderLines
				:placehold="isLoading"
				:line-count="0"
				:show-header="true"
				:class="[{ 'mb-100': isLoading || isError }]"
				:use-animation="isLoading"
			>
				<div class="p-50">
					<div v-if="isError" class="text-color-content-secondary" v-html="moduleLoadingErrorMessage" />
					<div v-else class="display-flex align-items-center justify-content-space-between">
						<p class="body-md-md font-weight-bold-md heading-6-smo text-color-content-primary">Portfolio</p>
						<BaseLink :link="ctaLink">
							<BaseButtonIconOnly icon="arrow" />
						</BaseLink>
					</div>
				</div>
			</ModularDashboardPlaceholderLines>
			<ModularDashboardPlaceholderLines
				:placehold="isLoading || isError"
				:line-count="1"
				:section-count="4"
				:show-header="true"
				:class="[{ 'my-200': isLoading }]"
				:use-animation="isLoading"
			>
				<div v-if="isAccountFullyRedeemed">
					<div class="display-flex flex-direction-column align-items-center my-150">
						<BaseSvgIcon
							:fill="false"
							:stroke="false"
							height="100"
							width="100"
							name="hd-portfolio-wheel"
							class="icon"
						/>
						<p class="text-color-content-secondary body-sm">{{ accountStatusDescription }}</p>
						<AddFundsButton classes="button button-primary mt-100 width-100" />
					</div>
				</div>

				<div v-else class="display-flex flex-direction-column gap-50">
					<div v-for="(alloc, i) in investedAssetClasses" :key="`invested-asset-class-${i}`">
						<BaseLink
							:link="getAssetClassLink(alloc)"
							classes="display-flex gap-100 justify-content-space-between asset-class-card"
						>
							<div class="display-flex align-items-center gap-100">
								<SimplePercentageWheel
									:percentage="percentage(alloc.allocationPercentage)"
									chart-size="40"
								/>

								<div>
									<div class="body-md font-weight-bold text-color-content-primary">{{
										alloc.assetClass.label
									}}</div>
									<div class="body-sm text-color-content-primary"
										>{{ percentage(alloc.allocationPercentage, true) }} of Portfolio</div
									>
								</div>
							</div>
							<div class="display-flex align-items-center gap-50">
								<div
									v-if="!isPerformanceContextEnabled && alloc.allTimeNetReturnsPercentage"
									class="display-flex align-items-center mb-auto-md"
								>
									<span
										:class="['mr-50', getReturnIndicator(alloc.allTimeNetReturnsPercentage)]"
									></span>
									<p class="mt-0 font-weight-bold text-color-content-primary">{{
										getFormattedReturn(alloc.allTimeNetReturnsPercentage)
									}}</p>
								</div>
								<span
									v-if="isPerformanceContextEnabled || isMobile"
									class="text-color-content-secondary"
								>
									<BaseSvgIcon
										role="presentation"
										class="arrow"
										name="caret"
										dir="right"
										height="24"
										width="24"
									/>
								</span>
							</div>
						</BaseLink>
					</div>
					<ZeroPercentAssetClassCard
						v-for="(alloc, i) in ineligibleZeroPercentAssetClasses"
						:key="`ineligible-zero-percent-asset-class-${i}`"
						:allocation="alloc"
					/>
					<ZeroPercentAssetClassCard
						v-for="(alloc, i) in eligibleZeroPercentAssetClasses"
						:key="`eligible-zero-percent-asset-class-${i}`"
						:allocation="alloc"
					/>
				</div>
			</ModularDashboardPlaceholderLines>
			<ModularDashboardPlaceholderLines
				v-if="isLoading || isError"
				:placehold="isLoading"
				:line-count="0"
				:show-footer="true"
				class="mt-100 text-align-center"
				:use-animation="isLoading"
			>
				<ModularDashboardRetryButton @retry-clicked="loadModule()" />
			</ModularDashboardPlaceholderLines>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'PortfolioOverviewModule'
};
</script>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { enumToKebab, percentage } from '@filters/shared-filters';
import { formattedPerformanceValue, getPerformanceReturnIndicator } from '@utils/performance-portfolio';
import {
	ModularDashboardPortfolioOverview,
	ModularDashboardPortfolioOverviewModule
} from 'types/modular-dashboard/custom';
import AddFundsButton from '@components/account/add-funds-button.vue';
import big from 'big.js';
import { investmentEntity } from '@store/modules/investment-entity';
import { isMobile } from '@utils/composables';
import { Link } from 'types/layout';
import { modularDashboard } from '@store/modules/modular-dashboard';
import { ModularDashboardModule } from 'types/modular-dashboard';
import ModularDashboardPlaceholderLines from '@components/account/modular-dashboard/modular-dashboard-placeholder-lines.vue';
import ModularDashboardRetryButton from '@components/account/modular-dashboard/modular-dashboard-retry-button.vue';
import { moduleLoadingErrorMessage } from '@constants/modular-dashboard';
import SimplePercentageWheel from '@charts/simple-percentage-wheel.vue';
import { trackMixpanelScrollIntoView } from '@utils/analytics';
import ZeroPercentAssetClassCard from '@components/account/zero-percent-asset-class-card.vue';

interface Props {
	module: ModularDashboardModule;
}

const props = defineProps<Props>();

const target = ref<HTMLElement | null>(null);

const isLoading = ref(true);
const isError = ref(false);

const portfolioOverviewModule = computed((): ModularDashboardPortfolioOverview | null => {
	return modularDashboard.portfolioOverviewModule;
});

const portfolioAllocations = computed((): Array<ModularDashboardPortfolioOverviewModule> => {
	const formattedAllocations: Array<ModularDashboardPortfolioOverviewModule> = [];

	if (portfolioOverviewModule.value?.realEstateClass) {
		formattedAllocations.push({
			assetClass: portfolioOverviewModule.value.realEstateClass.assetClass,
			allocationPercentage: portfolioOverviewModule.value.realEstateClass.allocationPercentage,
			allocationAmount: portfolioOverviewModule.value.realEstateClass.allocationAmount,
			allTimeNetReturnsPercentage: portfolioOverviewModule.value.realEstateClass.returnPercentage,
			assetTypeDescription: portfolioOverviewModule.value.realEstateClass.assetTypeDescription,
			directInvestmentEligibility: portfolioOverviewModule.value.realEstateClass.directInvestmentEligibility,
			cumulativeReturnPercentage: portfolioOverviewModule.value.realEstateClass.cumulativeReturnPercentage,
			fundId: portfolioOverviewModule.value.realEstateClass.fundId
		});
	}

	if (portfolioOverviewModule.value?.privateCreditClass) {
		formattedAllocations.push({
			assetClass: portfolioOverviewModule.value.privateCreditClass.assetClass,
			allocationPercentage: portfolioOverviewModule.value.privateCreditClass.allocationPercentage,
			allocationAmount: portfolioOverviewModule.value.privateCreditClass.allocationAmount,
			allTimeNetReturnsPercentage: portfolioOverviewModule.value.privateCreditClass.returnPercentage,
			assetTypeDescription: portfolioOverviewModule.value.privateCreditClass.assetTypeDescription,
			directInvestmentEligibility: portfolioOverviewModule.value.privateCreditClass.directInvestmentEligibility,
			cumulativeReturnPercentage: portfolioOverviewModule.value.privateCreditClass.cumulativeReturnPercentage,
			fundId: portfolioOverviewModule.value.privateCreditClass.fundId
		});
	}

	if (portfolioOverviewModule.value?.ventureClass) {
		formattedAllocations.push({
			assetClass: portfolioOverviewModule.value.ventureClass.assetClass,
			allocationPercentage: portfolioOverviewModule.value.ventureClass.allocationPercentage,
			allocationAmount: portfolioOverviewModule.value.ventureClass.allocationAmount,
			allTimeNetReturnsPercentage: portfolioOverviewModule.value.ventureClass.returnPercentage,
			assetTypeDescription: portfolioOverviewModule.value.ventureClass.assetTypeDescription,
			directInvestmentEligibility: portfolioOverviewModule.value.ventureClass.directInvestmentEligibility,
			cumulativeReturnPercentage: portfolioOverviewModule.value.ventureClass.cumulativeReturnPercentage,
			fundId: portfolioOverviewModule.value.ventureClass.fundId
		});
	}

	if (portfolioOverviewModule.value?.otherClass) {
		formattedAllocations.push({
			assetClass: portfolioOverviewModule.value.otherClass.assetClass,
			allocationPercentage: portfolioOverviewModule.value.otherClass.allocationPercentage,
			allocationAmount: portfolioOverviewModule.value.otherClass.allocationAmount,
			allTimeNetReturnsPercentage: '',
			cumulativeReturnPercentage: ''
		});
	}

	return formattedAllocations.sort(
		(a: ModularDashboardPortfolioOverviewModule, b: ModularDashboardPortfolioOverviewModule) =>
			Number(b.allocationPercentage) - Number(a.allocationPercentage)
	);
});

const hasSelectedRiaPlan = computed((): boolean => !!investmentEntity.currentSelectedRiaPlanId);

const investedAssetClasses = computed((): Array<ModularDashboardPortfolioOverviewModule> => {
	return hasSelectedRiaPlan.value
		? portfolioAllocations.value.filter((assetClass) => hasAssetClass(assetClass.allocationAmount))
		: portfolioAllocations.value;
});

const ineligibleZeroPercentAssetClasses = computed((): Array<ModularDashboardPortfolioOverviewModule> => {
	return hasSelectedRiaPlan.value
		? portfolioAllocations.value.filter(
				(assetClass) =>
					!hasAssetClass(assetClass.allocationAmount) && assetClass.directInvestmentEligibility !== 'ELIGIBLE'
			)
		: [];
});

const eligibleZeroPercentAssetClasses = computed((): Array<ModularDashboardPortfolioOverviewModule> => {
	return hasSelectedRiaPlan.value
		? portfolioAllocations.value.filter(
				(assetClass) =>
					!hasAssetClass(assetClass.allocationAmount) && assetClass.directInvestmentEligibility === 'ELIGIBLE'
			)
		: [];
});

const isAccountFullyRedeemed = computed((): boolean => {
	return modularDashboard.modularDashboardMetadata?.settlementState === 'FULLY_REDEEMED';
});

const accountStatusDescription = computed((): string => {
	return modularDashboard.modularDashboardMetadata?.statusDescription ?? '';
});

const isPerformanceContextEnabled = computed((): boolean => modularDashboard.performanceContextEnabled);

const ctaLink = computed(
	(): Link =>
		isAccountFullyRedeemed.value
			? { router: 'account-portfolio' }
			: { router: 'account-portfolio', hash: '#portfolio-breakdown' }
);

onMounted(() => {
	if (target.value) {
		trackMixpanelScrollIntoView(target.value, {
			'View Title': 'Portfolio Overview',
			'View Content': props.module.name,
			'View ID': props.module.type
		});
	}
});

function hasAssetClass(allocationAmount: string): boolean {
	return big(allocationAmount || '0').gt('0');
}

function getAssetClassLink(allocation: ModularDashboardPortfolioOverviewModule): Link {
	return {
		router: 'account-portfolio-asset-class-detail',
		params: {
			assetClass: enumToKebab(allocation.assetClass.name)
		}
	};
}

function getFormattedReturn(returnPercentage: string): string {
	return formattedPerformanceValue(returnPercentage, 'percentage', true, true);
}

function getReturnIndicator(returnPercentage: string): string {
	return getPerformanceReturnIndicator(returnPercentage);
}

async function loadModule(): Promise<void> {
	isLoading.value = true;
	isError.value = false;
	try {
		await modularDashboard.storePortfolioOverviewModuleData();
	} catch {
		isError.value = true;
	} finally {
		isLoading.value = false;
	}
}

async function setup(): Promise<void> {
	await loadModule();
}

setup();
</script>

<style lang="scss">
@use '../../../../../styles/components/performance-portfolio';
@use '../../../../../styles/utilities/respond-to.scss' as *;
@use '../../../../../styles/constants/colors' as *;

.portfolio-overview-module {
	@include respond-to(smo) {
		background: #fff;
		border-bottom: 1px solid gray(20);
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
	}
}

.portfolio-overview-module-section {
	padding: 1rem;
	container-type: inline-size;

	@container (max-width: 260px) {
		.asset-class-card-content {
			align-items: flex-start;
			flex-direction: column;
			gap: 0;
		}

		.asset-class-label {
			font-size: 0.875rem;
		}
	}
}

.asset-class-card {
	padding: 8px;
	border: 1px solid transparent;

	&-content {
		display: flex;
		flex: 1;

		div:last-child {
			flex: 1;
			justify-content: flex-end;
		}
	}

	&:hover {
		color: token('content-primary');
		background-color: token('bg-secondary');
		border-color: gray(20);
		border-radius: 4px;
	}
}
</style>
