<template>
	<span>
		<BaseButton :classes="baseLinkButtonClasses">
			<span class="slide-underline">Resources</span>
			<BaseSvgIcon name="caret" width="20" height="20" dir="down" class="ml-25" />
		</BaseButton>
		<span class="main-nav__sub-menu display-flex">
			<div v-for="panel in learnLinks" :key="panel.dropdown" class="main-nav__sub-menu-panel">
				<ul>
					<li class="overline line-height-150 eta main-nav__sub-menu-item--panel">
						{{ panel.dropdown }}
					</li>
					<li v-for="(link, id) in panel.links" :key="id" class="main-nav__sub-menu-item--panel">
						<BaseLink
							:link="link.link"
							classes="main-nav__sub-menu-item-link line-height-150 slide-underline"
						>
							{{ link.link.text }}</BaseLink
						>
					</li>
				</ul>
			</div>
			<div class="main-nav__sub-menu-panel main-nav__sub-menu-panel--featured">
				<BaseLink :link="featuredLinkDetails.cta">
					<p class="overline eta main-nav__sub-menu-item--panel">Featured</p>
					<img :src="featuredLinkDetails.imageUrl" alt="" role="presentation" class="my-100" />
					<p class="epsilon dark mt-25 line-height-150">{{ featuredLinkDetails.text }}</p>
					<p class="cta-link--rust body-sm mt-25 line-height-150"
						>{{ featuredLinkDetails.cta.text }}<BaseSvgIcon class="ml-25" name="arrow" />
					</p>
				</BaseLink>
			</div>
		</span>
	</span>
</template>

<script setup lang="ts">
import { DropdownLinks, DropdownSubmenuLink, LearnSubmenuFeaturedLink } from 'types/header';
import { appCloudfrontPath } from '@constants';
import { computed } from 'vue';
import { learnLinks } from '../links';
import { useRoute } from 'vue-router';

const route = useRoute();

const featuredLinkDetails: LearnSubmenuFeaturedLink = {
	text: 'Letters to investors',
	cta: { text: 'View all', router: 'blog-category', params: { categoryUrlSlug: 'letters-to-investors' } },
	imageUrl: `${appCloudfrontPath}/images/modules/spotlight/featured-resource.png`
};

const hasActiveSubMenuItem = computed((): boolean => {
	return learnLinks.some((details: DropdownLinks) =>
		details.links.find(
			(dropdownSubmenuLink: DropdownSubmenuLink) => dropdownSubmenuLink.link.router === route?.name
		)
	);
});

const baseLinkButtonClasses = computed(() => {
	const base = 'link main-nav__menu-item-link main-nav__menu-item-link--has-dropdown-arrow';

	return hasActiveSubMenuItem.value ? base + ' router-link-active' : base;
});
</script>
