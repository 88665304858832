import { AccountInvestFlowType } from 'types/account/invest';
import { investmentEntity } from '@store/modules/investment-entity';
import { Link } from 'types/layout';
import { MixpanelContext } from 'types/analytics';
import { RouteLocationRaw } from 'vue-router';

export function getInvestFlowLink(
	flowType: AccountInvestFlowType,
	text?: string,
	queries?: { offeringId?: string; backRoute?: string; cohortId?: string }
): Link {
	const route = {
		router: 'account-invest-flow',
		text,
		params: { flowType },
		query: {}
	};

	if (queries?.offeringId) {
		route.query = { offeringId: queries.offeringId };
	}
	if (queries?.backRoute) {
		route.query = { ...route.query, backRoute: queries.backRoute };
	}
	if (queries?.cohortId) {
		route.query = { ...route.query, cohortId: queries.cohortId };
	}

	return route;
}

export function getInvestFlowRouteLocation(
	flowType: AccountInvestFlowType,
	queries?: { offeringId?: string; backRoute?: string; cohortId?: string }
): RouteLocationRaw {
	const route = {
		name: 'account-invest-flow',
		params: { flowType },
		query: {}
	};

	if (queries?.offeringId) {
		route.query = { offeringId: queries.offeringId };
	}
	if (queries?.backRoute) {
		route.query = { ...route.query, backRoute: queries.backRoute };
	}
	if (queries?.cohortId) {
		route.query = { ...route.query, cohortId: queries.cohortId };
	}

	return route;
}

export function getInvestFlowMixpanelCustomProperties(
	flowType: AccountInvestFlowType,
	offeringId?: string,
	cohortId?: string
): MixpanelContext {
	let flowTypeLabel = '';

	switch (flowType) {
		case 'plan':
			flowTypeLabel = 'Plan';
			break;
		case 'ipo':
			flowTypeLabel = 'iPO';
			break;
		case 'fund':
		case 'window-reservation':
			flowTypeLabel = 'Fund';
			break;
		case 'new-investor-offering':
			flowTypeLabel = 'Post Signup X-sell';
			break;
	}

	let customProperties: MixpanelContext = {
		Flow: `Invest - ${flowTypeLabel}`
	};

	if (flowType === 'plan') {
		customProperties = {
			...customProperties,
			'Plan Name': investmentEntity.riaPlan?.name
		};
	} else {
		customProperties = {
			...customProperties,
			'Offering ID': offeringId
		};

		if (cohortId) {
			customProperties = {
				...customProperties,
				'Cohort ID': cohortId
			};
		}
	}

	return customProperties;
}
