export const addScriptTag = (url: string, id: string, onLoadCallback?: () => void): void => {
	let script = document.querySelector<HTMLScriptElement>(`script[src="${url}"]`);

	if (!script) {
		script = document.createElement('script');
		script.id = id;
		script.src = url;
		script.async = true;
		script.defer = true;
		document.head.appendChild(script);

		if (onLoadCallback) {
			script.addEventListener('load', onLoadCallback, { once: true });
		}
	} else if (onLoadCallback) {
		onLoadCallback();
	}
};

export const addLinkTag = (url: string, id: string, onLoadCallback?: () => void): void => {
	let link = document.querySelector<HTMLLinkElement>(`link[href="${url}"]`);

	if (!link) {
		link = document.createElement('link');
		link.id = id;
		link.href = url;
		link.type = 'text/css';
		link.rel = 'stylesheet';
		document.getElementsByTagName('head')[0].appendChild(link);
		if (onLoadCallback) {
			link.addEventListener('load', onLoadCallback, { once: true });
		}
	} else if (onLoadCallback) {
		onLoadCallback();
	}
};

export const removeScriptTag = (id: string): void => {
	const elem = document.getElementById(id);
	if (elem) {
		elem.remove();
	}
};
