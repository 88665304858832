<template>
	<div class="position-relative">
		<div
			v-if="props.loading"
			class="loader alpha coterminous fade in"
			:class="{ 'loader--gray': props.theme === 'light' }"
			style="margin: auto"
		></div>
		<slot v-else />
	</div>
</template>

<script setup lang="ts">
interface Props {
	theme?: 'light' | 'dark';
	loading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	theme: 'light',
	loading: true
});
</script>
