import { AccountValueItem, Performance } from 'types/performance';
import { constructUrlWithQueryParams, investmentEntityApsRequest, investmentEntityRequest } from '@utils/request';
import {
	CurrentPrincipal,
	IpoInvestmentPosition,
	IpoPositionDetail,
	Positions,
	ReitPositionDetail
} from 'types/portfolio';
import { ModularNewsFeedResponse, NewsFeedByPageNumber, NewsFeedResponse } from 'types/account';
import { RiaPlan, SignupRiaPlanListResponse } from 'types/plan';
import { AxiosResponse } from 'axios';
import { CustomSpacetimeFormat } from 'types/spacetime-formats';
import { formatPerformanceResponseData } from '@utils/account-performance-table-formatter';
import spacetime from 'spacetime';

export async function getAccountValueOverTimeData(): Promise<Array<AccountValueItem>> {
	const response = await investmentEntityApsRequest.get(`/performance/account-value-over-time`);
	return response.data;
}

export async function getPerformanceData(): Promise<Performance> {
	const response = await investmentEntityApsRequest.get(`/performance`);
	const data: Performance = response.data;
	return formatPerformanceResponseData(data);
}

export async function getPositionsTable(): Promise<Positions> {
	const response = await investmentEntityApsRequest.get(
		`/portfolio/positions-table?separateGrowthEquityPositions=true`
	);
	return response.data;
}

export async function getNewsFeedByPageNumber(params: NewsFeedByPageNumber): Promise<ModularNewsFeedResponse> {
	const urlWithParams = constructUrlWithQueryParams('/news-feed/page-detailed-dividends', params);
	const response = await investmentEntityRequest.get(urlWithParams);
	const availableFilters = response.data.meta.availableFilters ?? [];
	const offset = Number(response.data?.meta?.nextPage?.offset ?? 0);
	const totalNumber = response.data?.meta?.totalNumber ?? '0';

	return {
		items: response.data.data,
		availableFilters,
		offset,
		totalNumber
	};
}

export async function getNewsFeedFirstPage(filters: Record<string, unknown> = {}): Promise<NewsFeedResponse> {
	const urlWithParams = constructUrlWithQueryParams('/news-feed/page-detailed-dividends', filters);
	const response = await investmentEntityRequest.get(urlWithParams);
	const nextPage = response.data?.meta?.nextPage?.pageUrl ?? '';

	return {
		items: response.data.data,
		nextPage: nextPage.substring(nextPage.indexOf('/news-feed'))
	};
}

export async function getNewsFeedNextPage(nextPageUrl: string): Promise<NewsFeedResponse> {
	if (nextPageUrl) {
		const response = await investmentEntityRequest.get(nextPageUrl);
		const nextPage = response.data?.meta?.nextPage?.pageUrl ?? '';

		return {
			items: response.data.data,
			nextPage: nextPage.substring(nextPage.indexOf('/news-feed'))
		};
	} else {
		return {
			items: [],
			nextPage: ''
		};
	}
}

export async function getPublicNewsFeedByPageNumber(params: NewsFeedByPageNumber): Promise<ModularNewsFeedResponse> {
	const urlWithParams = constructUrlWithQueryParams('/news-feed/public', params);
	const response = await investmentEntityRequest.get(urlWithParams);
	const offset = Number(response.data?.meta?.nextPage?.offset ?? 0);
	const availableFilters = response.data.meta.availableFilters ?? [];
	const totalNumber = response.data?.meta?.totalNumber ?? '0';

	return {
		items: response.data.data,
		availableFilters,
		offset,
		totalNumber
	};
}

export async function getReitPositionDetail(reitId: string): Promise<ReitPositionDetail> {
	const response = await investmentEntityApsRequest.get(`/reit/${reitId}/position`);
	return response.data;
}

export async function getIpoPositionDetail(): Promise<IpoPositionDetail> {
	const response = await investmentEntityRequest.get(`/ipo/position`);
	response.data.ipoInvestmentPositions.forEach((ipoInvestmentPosition: IpoInvestmentPosition) => {
		ipoInvestmentPosition.investmentDate = spacetime(ipoInvestmentPosition.investmentDate).format(
			CustomSpacetimeFormat.MONTH_SHORT_DATE_YEAR
		);
	});
	return response.data;
}

export async function getCurrentRiaPlan(): Promise<RiaPlan> {
	const response = await investmentEntityRequest.get(`/ria-plan`, {
		suppressToast: (resp: AxiosResponse) => resp.status === 404
	});
	return response.data;
}

export async function getAvailableSignupPlansV2(filter?: 'recommended'): Promise<SignupRiaPlanListResponse> {
	const endpoint = '/signup-ria-plans-v2';
	let response;

	if (filter) {
		response = await investmentEntityRequest.get(endpoint, {
			params: {
				filter
			}
		});
	} else {
		response = await investmentEntityRequest.get(endpoint);
	}

	return response.data;
}

export async function updateRiaPlan(planId: string): Promise<void> {
	await investmentEntityRequest.post(`/ria-plan/${planId}/update`);
}

export async function getCurrentPrincipal(): Promise<CurrentPrincipal> {
	const response = await investmentEntityApsRequest.get(`/principal`);
	return response.data;
}

export async function clearPlanAndProSelections(): Promise<void> {
	await investmentEntityRequest.post(`/signup-checkout/clear-selections`);
}

export async function syncSignupFlow(): Promise<void> {
	await investmentEntityRequest.put('/sync-signup-flow');
}
