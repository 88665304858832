export const iraOnboardingIncomplete = '100100';

export const missingAccountNumber = '100101';

export const iraAddFundsDisabled = '100102';

export const iraAddFundsDisabledForUser = '100103';

export const invalidCheckoutState = '100602';

export const invalidIraType = '100104';

export const iraNotReadyToInvest = '100105';

export const iraOwnerNotUsResident = '100106';

export const noActiveBackupWithholdings = '100200';

export const pendingInvestmentEntityDetailsUpdate = '100201';

export const invalidStripePaymentCard = '100300';

export const forgotPasswordTooManyUsernameGuesses = '100400';

export const forgotPasswordInvalidPasswordResetKey = '100403';

export const forgotPasswordTooManyAuthAnswerAttempts = '100404';

export const forgotPasswordUserIsMalicious = '100405';

export const windowReservationCapacityFull = '100600';

export const windowReservationCapacityExceeded = '100601';

export const customerBetaAlreadyEnrolled = '100610';
