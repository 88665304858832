import { AxiosRequestConfig, AxiosRequestInterceptorParams } from 'axios';

/**
 * Adds a header which allows us to always send cookies/credentials to unauthenticated API endpoints.
 * It resolves issues that occur when a WAF token isn’t sent properly with the request.
 */
async function forceCorsGetRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig<unknown>> {
	if (config.method === 'get') {
		if (config.headers) {
			config.headers['x-force-cors'] = 'true';
		} else {
			config.headers = {
				'x-force-cors': 'true'
			};
		}
	}
	return config;
}

export const forceCorsGetRequestInterceptor: AxiosRequestInterceptorParams = [(config) => forceCorsGetRequest(config)];
