<template>
	<div class="placeholder">
		<h3 v-if="title" class="placeholder-title">{{ title }}</h3>
		<div class="placeholder-text">
			<slot name="message">
				{{ message }}
			</slot>
		</div>
		<div v-if="link" class="placeholder-cta">
			<slot name="cta">
				<BaseLink classes="zeta-smo button button-outline" :link="link" />
			</slot>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Link } from 'types/layout';

interface Props {
	title?: string;
	message?: string;
	link?: Link | null;
}

withDefaults(defineProps<Props>(), {
	title: '',
	message: '',
	link: null
});
</script>
