import { completeUpgrade, getRiaPlanMarketingInfo, getRiaPlansByEntityV2 } from '@api/ria';
import { computed, ref } from 'vue';
import { RiaPlan, RiaPlanMarketingStrategy } from 'types/plan';
import { defineStore } from 'pinia';
import { DripStatuses } from 'types/investment-entity';
import { RiaUpgradePreference } from 'types/ria';
import { user } from '@store/modules/user';

export const useRiaUpgradeStore = defineStore('riaUpgrade', () => {
	const hasSubmitted = ref(false);
	const marketingContent = ref<Array<RiaPlanMarketingStrategy>>([]);
	const reinvestmentPreference = ref<DripStatuses>(DripStatuses.CASH_DISTRIBUTION);
	const riaPlanId = ref('');
	const riaPlans = ref<Array<RiaPlan>>([]);

	const selectedPlan = computed((): RiaPlan | null => {
		return riaPlans.value.find((r) => r.id === riaPlanId.value) ?? null;
	});

	async function selectPlanId(planId: string): Promise<string> {
		riaPlanId.value = planId;

		return 'ria-upgrade-confirm-investment-plan';
	}

	async function getRiaPlans(): Promise<Array<RiaPlan>> {
		if (!riaPlans.value.length) {
			const plans = await getRiaPlansByEntityV2();
			riaPlans.value = plans;
		}

		return riaPlans.value;
	}

	async function getMarketingContent(): Promise<Array<RiaPlanMarketingStrategy>> {
		if (!marketingContent.value.length) {
			const content = await getRiaPlanMarketingInfo();
			marketingContent.value = content;
		}

		return marketingContent.value;
	}

	async function submit(preference: DripStatuses): Promise<string> {
		reinvestmentPreference.value = preference;
		hasSubmitted.value = true;

		const riaUpgradePreference: RiaUpgradePreference = {
			riaPlanId: riaPlanId.value,
			reinvestmentPreference: reinvestmentPreference.value
		};

		await completeUpgrade(riaUpgradePreference);
		await user.refreshUser();

		return 'ria-upgrade-success';
	}

	function $reset(): void {
		hasSubmitted.value = false;
		marketingContent.value = [];
		reinvestmentPreference.value = DripStatuses.CASH_DISTRIBUTION;
		riaPlanId.value = '';
		riaPlans.value = [];
	}

	return {
		hasSubmitted,
		marketingContent,
		reinvestmentPreference,
		riaPlanId,
		riaPlans,
		selectedPlan,
		getMarketingContent,
		getRiaPlans,
		selectPlanId,
		submit,
		$reset
	};
});
