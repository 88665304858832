import spacetime, { Format, ParsableDate } from 'spacetime';
import { Address } from 'types/form';
import big from 'big.js';
import { CustomSpacetimeFormat } from 'types/spacetime-formats';
import { realEstateStrategyColors } from '@constants/real-estate-strategies';
import { RealEstateStrategySlug } from 'types/real-estate-strategy';

export function number(input: string | number | undefined | null, decimals = 0, addCommas = true): string {
	if (input !== null && input !== undefined && input !== '' && !isNaN(Number(input))) {
		const arr = big(input).toFixed(decimals).split('.');
		if (addCommas) {
			arr[0] = arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}
		return arr.join('.');
	}

	return '-';
}

export function percentage(
	input: string | number | undefined | null | big,
	hasSuffix = false,
	decimals = 1,
	noVal = '-',
	removeTrailingZeros = false
): string {
	if (input !== null && input !== undefined && input !== '' && !isNaN(Number(input))) {
		const suffix = hasSuffix ? '%' : '';
		let percentageValue = big(input).times(100).toFixed(decimals);
		if (removeTrailingZeros) {
			percentageValue = parseFloat(percentageValue).toString();
		}
		return percentageValue + suffix;
	}

	return noVal;
}

/**
 * Given a number representing the quantity of some entity, returns the correct singular or plural form of the entity
 * name. The plural form is defaulted to add "s" (ie, result -> results) or can be overridden for more
 * complex use cases (lady -> ladies)
 * @param count - Quantity of items (ie: list.length)
 * @param singular - Singular form of entity (ie: result)
 * @param plural - Optional plural form of entity (ie: results, ladies, oxen)
 */
export function pluralize(count: number, singular: string, plural = `${singular}s`): string {
	if (count === 1) {
		return singular;
	} else {
		return plural;
	}
}

/**
 * Given a list of values, returns the list as a human-readable of comma-separated values. Enforces the use of the oxford
 * comma.
 * @param items - List of values to humanize
 * @example ['A', 'B'] => 'A and B'
 * @example ['A', 'B', 'C'] => 'A, B, and C'
 */
export function listToString(items: Array<string>): string {
	if (!items.length) {
		return '';
	} else if (items.length <= 2) {
		return items.join(' and ');
	} else {
		return `${items.slice(0, items.length - 1).join(', ')}, and ${items[items.length - 1]}`;
	}
}

export function currency(input: string | number | big | null, decimals = 2, symbol = '$'): string {
	if (input !== null && input !== '' && !isNaN(Number(input))) {
		const inputWithCommas = new big(input).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

		if (inputWithCommas.charAt(0) === '-') {
			return '-' + symbol + inputWithCommas.slice(1);
		} else {
			return symbol + inputWithCommas;
		}
	}
	return '';
}

export function currencyElongated(input: string | number | null) {
	if (!input || isNaN(Number(input))) {
		return '';
	}

	const oneBillion = big(1.0e9);
	const oneMillion = big(1.0e6);
	const bigAbsInput = big(input).abs();

	if (bigAbsInput.gte(oneBillion)) {
		return currencyBillions(input);
	} else if (bigAbsInput.gte(oneMillion)) {
		return currencyMillions(input, false, '$', true, 0);
	} else {
		return currency(input, 0);
	}
}

export function currencyBillions(input: string | number | null) {
	if (input === null || input === '' || isNaN(Number(input))) {
		return '';
	}

	const bigInput = new big(input);
	const suffix = 'billion';
	const oneBillion = big(1.0e9);

	return `$${bigInput.div(oneBillion).toFixed(3)} ${suffix}`;
}

export function currencyMillions(
	input: string | number | null | undefined,
	condensed = false,
	symbol = '$',
	separator = false,
	precision = 1
): string {
	if (input && input !== null && input !== '' && !isNaN(Number(input))) {
		const bigInput = new big(input);
		const suffix = condensed ? 'M' : 'million';
		const oneMillion = big(1.0e6);
		const spacer = separator ? ' ' : '';
		return `${symbol}${bigInput.div(oneMillion).toFixed(precision)}${spacer}${suffix}`;
	}

	return '';
}

export function bytes(inputBytes: number, precision = 1): string {
	try {
		big(inputBytes);
		const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
		const bytesAsNumber = Math.floor(Math.log(inputBytes) / Math.log(1024));
		const reducedNumber = (inputBytes / Math.pow(1024, Math.floor(bytesAsNumber))).toFixed(precision);

		return `${reducedNumber} ${units[bytesAsNumber]}`;
	} catch {
		return '-';
	}
}

export function telephone(input: string): string {
	if (!input) {
		return '';
	}

	const value = input.toString().trim().replace(/^\+/, '');

	if (value.match(/\D/)) {
		return input;
	}

	let country, city, num;

	switch (value.length) {
		case 10:
			country = '';
			city = value.slice(0, 3);
			num = value.slice(3);
			break;
		case 11:
			country = value[0];
			city = value.slice(1, 4);
			num = value.slice(4);
			break;
		case 12:
			country = value.slice(0, 3);
			city = value.slice(3, 5);
			num = value.slice(5);
			break;
		default:
			return input;
	}

	if (country === '1') {
		country = '';
	}

	num = `${num.slice(0, 3)}-${num.slice(3)}`;

	return `${country} (${city}) ${num}`.trim();
}

export function enumToLabel(enumToConvert: string): string {
	const allLowersWithSpaces = enumToConvert.toLowerCase().split('_').join(' ');
	return allLowersWithSpaces.charAt(0).toUpperCase() + allLowersWithSpaces.slice(1);
}

export function labelToKebab(labelToConvert: string): string {
	if (!labelToConvert) return '';

	return labelToConvert.toLowerCase().split(' ').join('-');
}

export function enumToKebab(enumToConvert: string): string {
	return enumToConvert.toLowerCase().split('_').join('-');
}

export function kebabToEnum(kebabToConvert: string): string {
	return kebabToConvert.toUpperCase().split('-').join('_');
}

export function camelLettersToKebab(camelToConvert: string): string {
	if (!camelToConvert) return '';

	return camelToConvert
		.split(/(?=[A-Z])/)
		.join('-')
		.toLowerCase();
}

export function stringToShortenedCurrency(toShorten = '', decimals = 2, hasParenthesis = false): string {
	if (!toShorten) {
		return '';
	}

	const oneBillion = big(1.0e9);
	const oneMillion = big(1.0e6);
	const oneThousand = big(1.0e3);
	const toShortenBig = big(toShorten);

	const sign = toShortenBig.s === -1 ? '-' : '';

	const absNumberValue = toShortenBig.abs();
	let divisor = big(1);
	let suffix = '';

	if (absNumberValue.gte(oneBillion)) {
		divisor = oneBillion;
		suffix = 'B';
	} else if (absNumberValue.gte(oneMillion)) {
		divisor = oneMillion;
		suffix = 'M';
	} else if (absNumberValue.gte(oneThousand)) {
		divisor = oneThousand;
		suffix = 'k';
	}

	let formatted = `${absNumberValue.div(divisor).round(decimals)}`;

	if (formatted.replace('.', '').length < 3) {
		formatted = big(formatted).toFixed(decimals);
	}

	const formattedValue = formatted.substring(0, 4);
	const displayValue =
		formattedValue[formattedValue.length - 1] === '.'
			? formattedValue.substring(0, formattedValue.length - 1)
			: formattedValue;

	if (hasParenthesis) {
		if (sign === '-') {
			return `($${displayValue + suffix})`;
		}
		return `$${displayValue + suffix}`;
	}

	return `${sign}$${displayValue + suffix}`;
}

export function realEstateStrategyColor(strategySlug: RealEstateStrategySlug): string {
	const colorMap = realEstateStrategyColors;
	const filtered = colorMap.filter((strategy) => strategy.slug === strategySlug);
	return filtered.length > 0 ? filtered[0].color : '#909294';
}

export function date(dateString: ParsableDate, format: Format = CustomSpacetimeFormat.MONTH_SHORT_DATE_YEAR): string {
	if (!dateString) {
		return '';
	}

	return spacetime(dateString).format(format);
}

export function deorphanText(text: string): string {
	if (!text) return '';
	return text.split(' ').reduce((str, curr, i, arr) => [str, curr].join(i >= arr.length - 1 ? '&nbsp;' : ' '));
}

export function capitalize(value: string | null): string {
	if (!value) return '';
	const lowerCaseWord = value.toLowerCase();
	return lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1);
}

export function cityStateZip(address: Address): string {
	return `${address.city}, ${address.state} ${address.zip}`;
}

export function booleanLabel(value = false, truthyLabel = 'Enabled', falseyLabel = 'Disabled'): string {
	return value ? truthyLabel : falseyLabel;
}

export function kebabCaseToTitleCase(kebabCase: string): string {
	return kebabCase
		.split('-')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
}

export function sanitizeNumbers(text: string | undefined): string | undefined {
	if (!text) return undefined;
	return text.replace(/(\d[0-9,.]*\d|\d)[kMB]?/g, 'X');
}
