import { EmailEntryUnsubscribeForm, IterableUnsubscribeRequest, UnsubscribeEmailResponse } from 'types/email';
import { apiRequest } from '@utils/request';

export const getEmailGroupSubscriptions = async (unsubscribeKey: string): Promise<UnsubscribeEmailResponse> => {
	const response = await apiRequest.get(`/email/unsubscribe`, { params: { unsubscribeKey } });
	return response.data;
};

export const unsubscribeUserEmail = async (
	emailEntryUnsubscribeForm: EmailEntryUnsubscribeForm
): Promise<UnsubscribeEmailResponse> => {
	const response = await apiRequest.post(`/email/unsubscribe`, emailEntryUnsubscribeForm);
	return response.data;
};

export const unsubscribeUserIterableEmail = async (
	iterableUnsubscribeRequest: IterableUnsubscribeRequest
): Promise<UnsubscribeEmailResponse> => {
	const response = await apiRequest.post(`/email/iterableUnsubscribe`, iterableUnsubscribeRequest);
	return response.data;
};

export const unsubscribeInviteEmail = async (unsubscribeKey: string): Promise<void> => {
	await apiRequest.post(`/email/inviteEmailUnsubscribe`, { unsubscribeKey });
};
