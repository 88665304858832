<template>
	<span class="ratings-stars" :style="starsStyles" :aria-label="`${rating}/5 stars`"></span>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
	rating?: string;
	starsColor?: string;
	size?: string;
}

const props = withDefaults(defineProps<Props>(), {
	rating: '5',
	starsColor: '',
	size: undefined
});

const starsStyles = computed((): string => {
	let fontSize = '';
	if (props.size) {
		fontSize = `font-size: ${props.size}px;`;
	}
	if (props.starsColor) {
		const ratingsVar = `--rating: ${props.rating};`;
		const colorVar = `--star-background: ${props.starsColor};`;
		return ratingsVar + colorVar + fontSize;
	}
	return `--rating: ${props.rating}; ${fontSize}`;
});
</script>
