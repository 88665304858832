import {
	ActiveGoalChart,
	GoalIncomeSelectAmount,
	GoalRecalculateSuggestion,
	GoalResponse,
	GoalSelectAmount,
	GoalSuggestion,
	GoalTypeOptions,
	SaveInvestorGoal,
	UpdateInvestorGoal
} from 'types/investment-goals';
import { constructUrlWithQueryParams, investmentEntityRequest } from '@utils/request';

export const getCurrentGoal = async (): Promise<GoalResponse> => {
	const response = await investmentEntityRequest.get(`/goal`);
	return response.data;
};

export const saveCurrentGoal = async (investorGoal: SaveInvestorGoal): Promise<GoalResponse> => {
	let submitGoal: SaveInvestorGoal = investorGoal;
	if (investorGoal.goalType === 'INCOME') {
		submitGoal = {
			...submitGoal,
			targetValue: undefined
		};
	} else {
		submitGoal = {
			...submitGoal,
			targetIncome: undefined
		};
	}

	const response = await investmentEntityRequest.post(`/goal`, submitGoal);
	return response.data;
};

export const updateCurrentGoal = async (investorGoal: UpdateInvestorGoal): Promise<GoalResponse> => {
	const response = await investmentEntityRequest.post(`/goal/update`, investorGoal);
	return response.data;
};

export const saveOneClickGoal = async (): Promise<GoalResponse> => {
	const response = await investmentEntityRequest.post(`/one-click-goal`);
	return response.data;
};

export const getGoalSuggestion = async (params: Partial<SaveInvestorGoal>): Promise<GoalSuggestion> => {
	const urlWithParams = constructUrlWithQueryParams(`/goal/suggestion`, params);
	const response = await investmentEntityRequest.get(urlWithParams);
	return response.data;
};

export const getGoalAmountOptions = async (years: string): Promise<GoalSelectAmount> => {
	const response = await investmentEntityRequest.get(`/goal/amount-selects?years=${years}`);
	return response.data;
};

export const getGoalIncomeAmountSelect = async (years: string): Promise<GoalIncomeSelectAmount> => {
	const response = await investmentEntityRequest.get(`/goal/income-amount-select?years=${years}`);
	return response.data;
};

export const recalculateGoalSuggestion = async (
	params: Partial<GoalRecalculateSuggestion>
): Promise<GoalSuggestion> => {
	const urlWithParams = constructUrlWithQueryParams(`/goal/recalculate-suggestion`, params);
	const response = await investmentEntityRequest.get(urlWithParams, {
		suppressToast: () => true
	});
	return response.data;
};

export const getActiveGoalChart = async (): Promise<ActiveGoalChart> => {
	const response = await investmentEntityRequest.get(`/goal/chart`);
	return response.data;
};

export const deleteGoal = async (): Promise<void> => {
	await investmentEntityRequest.delete(`/goal/delete`);
};

export const getGoalTypes = async (): Promise<GoalTypeOptions> => {
	const response = await investmentEntityRequest.get('/goal/goal-types');
	return response.data;
};
