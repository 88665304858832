import { KeyValue, Link } from 'types/layout';
import { PrismicObject, PrismicWebLink } from 'types/prismic';
import {
	RealEstatePageData,
	RealEstatePagePrismicData,
	RealEstatePageStrategiesShowcase
} from 'types/prismic/real-estate-page';
import { serializePrismicContent } from './html-serializer';
import { StrategyShowcaseInfo } from 'types/real-estate-strategy';

export function buildRealEstatePage(prismicData: RealEstatePagePrismicData | undefined): RealEstatePageData | null {
	if (prismicData) {
		return {
			hero: {
				title: prismicData.hero_title,
				heading: prismicData.hero_heading,
				summary: prismicData.hero_summary
			},
			strategiesSection: {
				strategies: buildStrategiesShowcase(prismicData.strategies_showcase),
				sectionDisclaimer: serializePrismicContent(prismicData.strategies_section_disclaimer)
			},
			portfolioValue: {
				value: prismicData.portfolio_value,
				label: prismicData.portfolio_value_label
			},
			investorUpdates: {
				heading: prismicData.investor_updates_heading,
				investorUpdates: prismicData.investor_updates.map((wrapper) => wrapper.investor_update)
			}
		};
	} else {
		return null;
	}
}

function buildStrategiesShowcase(strategies: Array<RealEstatePageStrategiesShowcase>): Array<StrategyShowcaseInfo> {
	const showcaseInfo: Array<StrategyShowcaseInfo> = [];

	strategies.forEach((strategy) => {
		const strategyStats: Array<KeyValue> = [];
		if (strategy.stat_1) {
			strategyStats.push({
				key: strategy.stat_1.toString(),
				value: strategy.stat_1_label
			});
		}
		if (strategy.stat_2) {
			strategyStats.push({
				key: strategy.stat_2.toString(),
				value: strategy.stat_2_label
			});
		}

		const images: Array<string> = [strategy.image_1, strategy.image_2, strategy.image_3]
			.filter(Boolean)
			.map((image) => image.url);

		showcaseInfo.push({
			featuredImages: images,
			featuredImagesCaption: serializePrismicContent(strategy.images_caption).join(''),
			link: getStrategyLink(strategy),
			stats: strategyStats,
			summary: serializePrismicContent(strategy.content).join(''),
			title: strategy.heading,
			eyebrow: ''
		});
	});

	return showcaseInfo;
}

function getStrategyLink(strategy: RealEstatePageStrategiesShowcase): Link | undefined {
	if (strategy.link.link_type === 'Document') {
		const strategyLink = strategy.link as PrismicObject;
		return {
			router: 'blog-post',
			params: { blogPostUrlSlug: strategyLink.uid },
			text: strategy.link_text,
			target: '_blank'
		};
	} else if (strategy.link.link_type === 'Web') {
		const strategyLink = strategy.link as PrismicWebLink;
		return { target: strategyLink.target, href: strategyLink.url, text: strategy.link_text };
	}
	return undefined;
}
