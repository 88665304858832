import { AccountInvestFlowType, InvestMetadata } from 'types/account/invest';
import { CheckoutOrderGroupDetails, CrossSellResponse } from 'types/checkout';
import { createOrderGroup, placeOrder } from '@api/checkout';
import { getCrossSellEligibility, getOfferingReservationWindows } from '@api/add-funds';
import { defineStore } from 'pinia';
import { DocumentListItem } from 'types/documents';
import { getOrderMetadata } from '@api/invest';
import { investmentEntity } from '../store/modules/investment-entity';
import { K1Acknowledgement } from 'types/form';
import { OrderGroupResponse } from 'types/add-funds';
import { PaymentMethod } from 'types/payment';
import { ref } from 'vue';
import { ReservationWindow } from 'types/reit-element';

export const useInvestStore = defineStore('invest', () => {
	const accountInvestFlowType = ref<AccountInvestFlowType | null>(null);
	const acknowledgedK1Disclosure = ref<K1Acknowledgement>({ acknowledged: false });
	const amount = ref<string | null>(null);
	const amountAltText = ref<string | null>(null);
	const crossSellResponse = ref<CrossSellResponse | null>(null);
	const hasMultipleWindows = ref(false);
	const investMetaData = ref<InvestMetadata | null>(null);
	const investTabAddFunds = ref(false);
	const offeringSubmissionErrorCode = ref<string | null>(null);
	const orderComplete = ref(false);
	const orderGroupId = ref<string | null>(null);
	const orderGroupResponse = ref<OrderGroupResponse | null>(null);
	const reservationWindows = ref<Array<ReservationWindow>>([]);
	const selectedWindow = ref<ReservationWindow | null>(null);
	const paymentMethod = ref<PaymentMethod | null>(null);

	function $reset(): void {
		accountInvestFlowType.value = null;
		amount.value = null;
		amountAltText.value = null;
		investMetaData.value = null;
		investTabAddFunds.value = false;
		offeringSubmissionErrorCode.value = null;
		orderComplete.value = false;
		orderGroupId.value = null;
		orderGroupResponse.value = null;
	}

	function updateReservationWindow(data: {
		reservationWindows: Array<ReservationWindow>;
		cohortId: string | null;
	}): void {
		reservationWindows.value = data.reservationWindows;
		hasMultipleWindows.value =
			data.reservationWindows.filter((window) => window.status.name === 'AVAILABLE').length > 1;
		selectedWindow.value = data.cohortId
			? data.reservationWindows.find((window) => {
					return window.cohortId === data.cohortId;
				}) ?? null
			: null;
	}

	function resetReservationWindowData(): void {
		updateReservationWindow({ reservationWindows: [], cohortId: null });
	}

	async function fetchAndUpdateReservationWindow(data: {
		offeringId: string;
		cohortId: string | null;
	}): Promise<void> {
		const windows = await getOfferingReservationWindows(data.offeringId);
		updateReservationWindow({ reservationWindows: windows, cohortId: data.cohortId });
	}

	async function getInvestOrderMetadata(reitOfferingVintageId?: string, cohortId?: string): Promise<InvestMetadata> {
		const data = await getOrderMetadata(reitOfferingVintageId, cohortId);
		investMetaData.value = data;
		return data;
	}

	async function checkCrossSellEligibility(): Promise<void> {
		const response = await getCrossSellEligibility();
		crossSellResponse.value = response;
	}

	async function createInvestOrderGroup(
		orderDetails: CheckoutOrderGroupDetails,
		isPlanFlowType = false
	): Promise<string> {
		const order: CheckoutOrderGroupDetails = {
			amount: orderDetails.amount,
			paymentMethodId: orderDetails.paymentMethodId,
			paymentMethodType: orderDetails.paymentMethodType
		};

		if (isPlanFlowType) {
			order.riaPlanId = orderDetails.riaPlanId;
		} else {
			order.reitOfferingVintageId = orderDetails.reitOfferingVintageId;
		}

		if (orderDetails.cohortId) {
			order.cohortId = orderDetails.cohortId;
		}

		const orderGroup = await createOrderGroup(order);

		amount.value = orderDetails.amount;
		orderGroupId.value = orderGroup.orderGroupId ?? null;

		return 'account-invest-flow-review';
	}

	async function submitReview(data: {
		reitDocuments: Array<DocumentListItem>;
		suppressToast?: boolean;
	}): Promise<string> {
		const reitDocumentRevisionIds = data.reitDocuments.map((document) => document.documentRevisionId ?? '');
		const response = await placeOrder(
			orderGroupId.value ?? '',
			reitDocumentRevisionIds,
			null,
			null,
			data.suppressToast ?? false
		);

		orderGroupResponse.value = response;
		orderComplete.value = true;

		await investmentEntity.refreshPositionsAndPerformance();

		return 'account-invest-flow-success';
	}

	return {
		accountInvestFlowType,
		acknowledgedK1Disclosure,
		amount,
		amountAltText,
		crossSellResponse,
		hasMultipleWindows,
		investMetaData,
		investTabAddFunds,
		offeringSubmissionErrorCode,
		orderComplete,
		orderGroupId,
		orderGroupResponse,
		paymentMethod,
		reservationWindows,
		selectedWindow,
		$reset,
		checkCrossSellEligibility,
		createInvestOrderGroup,
		fetchAndUpdateReservationWindow,
		getInvestOrderMetadata,
		resetReservationWindowData,
		submitReview,
		updateReservationWindow
	};
});
