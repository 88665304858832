<template>
	<span>
		<span v-if="isSimpleHeaderLink">
			<BaseLink :link="link" classes="main-nav__menu-item-link">
				<span class="slide-underline">{{ (link as Link).text }}</span>
				<Badge
					v-if="isBadgeLink"
					class="ml-50"
					:text="(link as DropdownLinks).badgeText"
					background="slate"
					size="medium"
				/>
			</BaseLink>
		</span>
		<NavigationLinkSubmenu v-else :link="link as DropdownLinks" />
	</span>
</template>

<script setup lang="ts">
import { DropdownLinks, NavigationLink } from 'types/header';
import Badge from '@components/badges/badge-component.vue';
import { computed } from 'vue';
import { Link } from 'types/layout';
import NavigationLinkSubmenu from './navigation-link-submenu.vue';
interface Props {
	link: NavigationLink;
	isSticky?: boolean;
	isTransparentDarkText?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
	isSticky: false,
	isTransparentDarkText: false
});

const isSimpleHeaderLink = computed((): boolean => {
	return 'text' in props.link;
});

const isBadgeLink = computed((): boolean => {
	return 'badgeText' in props.link;
});
</script>
