import { canViewGrowthCalculator } from '@utils/investment-entity';
import { FundriseRouteConfig } from 'vue-router';
import { investmentEntity } from '@store/modules/investment-entity';

const growthCalculatorRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/growth-calculator',
		name: 'growth-calculator',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async () => {
			if (!canViewGrowthCalculator(investmentEntity)) {
				return { name: 'account-overview', replace: true };
			}
		},
		component: () => import('@views/growth-calculator/growth-calculator-wrapper.vue')
	}
];

export default growthCalculatorRoutes;
