<template>
	<BaseButton
		classes="performance-context-card-wrapper"
		:mixpanel-target="props.card.eventName"
		@click="openSummaryModal"
		@mouseenter="onHoverEnter"
		@mouseleave="onHoverLeave"
		@focus="onHoverEnter"
		@blur="onHoverLeave"
	>
		<div class="performance-context-card p-100" :class="{ active: props.isActive }" data-test="performance-card">
			<component :is="cardComponent.component" :card-data="cardData" />
		</div>
	</BaseButton>
</template>
<script setup lang="ts">
import { PerformanceContextCardData, PerformanceContextCardEventData } from 'types/performance-context';
import { app } from '@store/modules/app';
import ArticleCardComponent from '@components/account/performance-context/article-card.vue';
import CategoryCardComponent from '@components/account/performance-context/category-card.vue';
import { computed } from 'vue';
import { DynamicComponent } from 'types/vue';
import PerformanceCardComponent from '@components/account/performance-context/performance-card.vue';
import PerformanceContextSummaryModal from './performance-context-summary-modal.vue';

interface Props {
	card: PerformanceContextCardData;
	isActive?: boolean;
}

interface Emits {
	(e: 'hovered-card', value: string): void;
}

const props = withDefaults(defineProps<Props>(), {
	isActive: false
});

const emits = defineEmits<Emits>();

const cardData = computed((): PerformanceContextCardEventData => props.card.cardData);

const cardComponent = computed((): DynamicComponent => {
	switch (cardData.value.type) {
		case 'CATEGORY':
			return {
				component: CategoryCardComponent
			};
		case 'PERFORMANCE':
			return {
				component: PerformanceCardComponent
			};
		case 'ARTICLE':
		default:
			return {
				component: ArticleCardComponent
			};
	}
});

function openSummaryModal(): void {
	app.UPDATE_CURRENT_MODAL({
		modal: PerformanceContextSummaryModal,
		props: {
			card: props.card
		}
	});
}

function onHoverEnter(): void {
	emits('hovered-card', props.card.eventName);
}

function onHoverLeave(): void {
	emits('hovered-card', '');
}
</script>

<style lang="scss">
@use '../../../styles/constants/_colors.scss' as *;

.performance-context-card {
	position: relative;
	text-align: left;
	border: 1px solid transparent;
	border-radius: 8px;
	white-space: normal;
	height: 100%;

	> * {
		height: inherit;
	}

	.performance-context-card__header {
		line-height: 22px;
		color: gray(80);
	}

	&-wrapper {
		min-width: 264px;
		max-width: 264px;
	}

	&:hover,
	&.active {
		border: 1px solid gray(20);
		background-color: gray(5);
	}
}
</style>
