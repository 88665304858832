<template>
	<div ref="target" class="display-flex flex-direction-column position-relative height-100">
		<div class="card-media img-lazy-wrapper aspect-ratio-16-9">
			<span class="loader alpha"></span>
			<img :src="imageUrl" alt="" role="presentation" />
		</div>

		<BaseButton
			v-if="props.module.dismissible"
			:classes="`dismiss-button ${dismissButtonStyle}`"
			data-test="dismiss"
			:mixpanel-context="props.module.name"
			mixpanel-element="Module"
			mixpanel-target="Dismiss"
			@click.prevent="dismiss()"
		>
			<BaseSvgIcon name="close" />
		</BaseButton>

		<div class="card-content p-100">
			<div>
				<h4 class="line-clamp-2 subheading">{{ props.module.title }}</h4>
				<p class="line-clamp-4 mt-75">
					{{ props.module.subText }}
				</p>
			</div>

			<BaseCtaLink
				v-if="link"
				classes="align-self-flex-end"
				:link="link"
				:mixpanel-context="props.module.name"
				mixpanel-element="Module"
				:mixpanel-target="props.module.title"
			/>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'VerticalMediaCardModule'
};
</script>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { getLinkFromRoute } from '@utils/request';
import { Link } from 'types/layout';
import { ModularDashboardCardModule } from 'types/modular-dashboard/card-module';
import { ModularDashboardContainer } from 'types/modular-dashboard';
import { trackMixpanelScrollIntoView } from '@utils/analytics';
import { useRouter } from 'vue-router';

interface Props {
	module: ModularDashboardCardModule;
	container: ModularDashboardContainer;
}

interface Emits {
	(e: 'dismiss'): void;
}

const props = defineProps<Props>();

const router = useRouter();

const emits = defineEmits<Emits>();

const target = ref<HTMLElement | null>(null);

const dismissButtonStyle = computed((): string => {
	return props.module.dismissButtonStyle === 'LIGHT' ? 'text-color-white cta-link--rust' : 'dark cta-link';
});

const link = computed((): Link | undefined => {
	if (props.module.cta) {
		return {
			...getLinkFromRoute(router, props.module.cta.targetUrl ?? '', props.module.cta.text),
			target: props.module.cta.newTab ? '_blank' : '_self'
		};
	} else {
		return undefined;
	}
});

const imageUrl = computed((): string => {
	return `${props.module.imageUrl}`;
});

onMounted(() => {
	if (target.value) {
		trackMixpanelScrollIntoView(target.value, {
			'View Title': props.module.title,
			'View Content': props.container?.title,
			'View ID': props.module.name
		});
	}
});

function dismiss(): void {
	emits('dismiss');
}
</script>

<style lang="scss" scoped>
@use '../../../../styles/constants/_colors.scss' as *;

$border-radius: 8px;

.card {
	&-media {
		border-radius: $border-radius;

		img {
			border: 1px solid token('bg-tertiary');
			border-radius: $border-radius $border-radius 0 0;
			opacity: 1;
			object-fit: cover;
		}
	}

	&-content {
		border: 1px solid gray(20);
		border-radius: 0 0 $border-radius $border-radius;
		border-top: none;
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 1rem;
		justify-content: space-between;
		background-color: #fff;
	}
}

.dismiss-button {
	position: absolute;
	top: 0.5rem;
	right: 1rem;
}

.mt-75 {
	margin-top: 0.75rem;
}

.pt-75 {
	padding-top: 0.75rem;
}
</style>
