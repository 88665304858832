<template>
	<component :is="component.component" v-bind="component.props">
		{{ props.link?.text || props.text }}
		<BaseSvgIcon
			v-if="props.icon"
			class="icon"
			:name="props.icon"
			:width="props.iconSize"
			:height="props.iconSize"
			:dir="props.iconDir"
		/>
	</component>
</template>

<script setup lang="ts">
import BaseButton from '@components/_base-button.vue';
import BaseLink from '@components/_base-link.vue';
import { computed } from 'vue';
import { DynamicComponent } from 'types/vue';
import { Link } from 'types/layout';
import { MixpanelContext } from 'types/analytics';

interface Props {
	classes?: string;
	icon?: string;
	iconDir?: 'left' | 'up' | 'right' | 'down';
	iconSize?: string;
	isLeadingIcon?: boolean;
	isOrange?: boolean;
	link?: Link;
	text?: string;
	mixpanelElement?: string;
	mixpanelTarget?: string;
	mixpanelContext?: string;
	mixpanelCustomProperties?: MixpanelContext;
}

const props = withDefaults(defineProps<Props>(), {
	classes: '',
	icon: '',
	iconDir: undefined,
	iconSize: '',
	isLeadingIcon: false,
	isOrange: false,
	link: undefined,
	text: '',
	mixpanelElement: undefined,
	mixpanelTarget: undefined,
	mixpanelContext: undefined,
	mixpanelCustomProperties: undefined
});

const mixpanelProps = computed(
	(): Pick<Props, 'mixpanelElement' | 'mixpanelTarget' | 'mixpanelContext' | 'mixpanelCustomProperties'> => {
		return {
			mixpanelElement: props.mixpanelElement,
			mixpanelTarget: props.mixpanelTarget,
			mixpanelContext: props.mixpanelContext,
			mixpanelCustomProperties: props.mixpanelCustomProperties
		};
	}
);

const component = computed((): DynamicComponent => {
	const baseClasses = `display-inline-flex gap-50 align-items-center
		 ${props.isOrange ? 'cta-link' : 'cta-link--rust'} 
		 ${props.isLeadingIcon ? 'flex-direction-row-reverse justify-content-flex-end' : ''}`;

	return props.link
		? {
				component: BaseLink,
				props: { link: props.link, classes: `${props.classes} ${baseClasses}`, ...mixpanelProps.value }
			}
		: { component: BaseButton, props: { classes: `link ${props.classes} ${baseClasses}`, ...mixpanelProps.value } };
});
</script>
