import { apiRequest, apiSecureRequest } from '@utils/request';
import {
	InvestorReferralInvite,
	InvitationDetailsResponse,
	InvitationPromoRewardAmounts,
	InviteUser,
	ReferrerUser
} from 'types/referral';

export async function getInvitationDetails(): Promise<InvitationDetailsResponse> {
	return apiSecureRequest.get('/promo/user/invitation/details').then((response) => response.data);
}

export async function sendInviteEmails(inviteEmails: Array<InviteUser>): Promise<Array<InvestorReferralInvite>> {
	const response = await apiSecureRequest.post('/invite-email/new', inviteEmails);
	const sentInvites: Array<InvestorReferralInvite> = response.data.userEmailInvites;
	return sentInvites;
}

export async function getReferralByKey(referralKey: string): Promise<ReferrerUser> {
	const response = await apiRequest.get(`/promo/referrer-by-key/${referralKey}`);
	const referrerUser: ReferrerUser = response.data;
	return referrerUser;
}

export async function getInviteAmounts(): Promise<InvitationPromoRewardAmounts> {
	return apiSecureRequest.get('/promo/user/invitation/amounts').then((response) => response.data);
}
