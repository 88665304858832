import { FundriseRouteConfig, RouteLocationNormalized } from 'vue-router';
import { investmentEntity } from '@store/modules/investment-entity';
import { modularDashboard } from '@store/modules/modular-dashboard';
import { RiaUpgradeRoute } from 'types/ria';
import { riaUpgradeRouteOrder } from '@constants/ria-upgrade';
import { useRiaUpgradeStore } from '@stores/ria-upgrade';

const getFlowStepIndex = (name: RiaUpgradeRoute): number => {
	return riaUpgradeRouteOrder.indexOf(name) + 1;
};

const stepConstructor = (route: RouteLocationNormalized): number => {
	return getFlowStepIndex(route.name as RiaUpgradeRoute);
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const riaUpgradeRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/account/ria-upgrade`,
		name: 'ria-upgrade',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/account/ria-upgrade/ria-upgrade.vue'),
		beforeEnter: async (to, from, next) => {
			await modularDashboard.resetAll();
			if (!investmentEntity.isLegacyInvestorWithoutPlan) {
				next({ name: 'account-overview', replace: true });
			} else {
				next();
			}
		},
		redirect: { name: 'ria-upgrade-select-investment-plan' },
		children: [
			{
				path: 'select-investment-plan',
				name: 'ria-upgrade-select-investment-plan',
				meta: {
					step: stepConstructor,
					minTokenScopeRequired: 'FULL_ACCESS',
					suppressDefaultMxpViewEvent: true
				},
				component: () => import('@views/account/ria-upgrade/ria-upgrade-select-investment-plan.vue')
			},
			{
				path: 'confirm-investment-plan',
				name: 'ria-upgrade-confirm-investment-plan',
				meta: {
					step: stepConstructor,
					minTokenScopeRequired: 'FULL_ACCESS',
					suppressDefaultMxpViewEvent: true
				},
				beforeEnter: async (to, from, next) => {
					const riaUpgradeStore = useRiaUpgradeStore();
					if (!riaUpgradeStore.riaPlanId) {
						next({ name: 'ria-upgrade', replace: true });
					} else {
						next();
					}
				},
				component: () => import('@views/account/ria-upgrade/ria-upgrade-confirm-investment-plan.vue')
			},
			{
				path: 'agreements',
				name: 'ria-upgrade-agreements',
				meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const riaUpgradeStore = useRiaUpgradeStore();
					if (!riaUpgradeStore.riaPlanId) {
						next({ name: 'ria-upgrade-select-investment-plan', replace: true });
					} else {
						next();
					}
				},
				component: () => import('@views/account/ria-upgrade/ria-upgrade-agreements.vue')
			},
			{
				path: 'success',
				name: 'ria-upgrade-success',
				meta: { step: stepConstructor, minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: async (to, from, next) => {
					const riaUpgradeStore = useRiaUpgradeStore();
					if (!riaUpgradeStore.hasSubmitted) {
						next({ name: 'ria-upgrade-select-investment-plan', replace: true });
					} else {
						next();
					}
				},
				component: () => import('@views/account/ria-upgrade/ria-upgrade-success.vue')
			}
		]
	}
];

export default riaUpgradeRoutes;
