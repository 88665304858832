import { FundriseRouteConfig, RouteLocationRaw } from 'vue-router';
import {
	PrivateInvestorDocumentRedirectParams,
	PrivateInvestorDocumentType,
	PrivateUserDocumentRedirectParams,
	PrivateUserDocumentType
} from 'types/investor-documents';
import { privateInvestorDocumentViewRedirect, privateUserDocumentViewRedirect } from '@api/private-document-redirect';
import { app } from '@store/modules/app';
import { openNewTab } from '@utils/client';
import { triggerDownload } from '@utils/request';

const openPrivateDocument = async (redirectUrl: string, fromPath: string, next: (to?: RouteLocationRaw) => void) => {
	if (app.isMobileWebview) {
		triggerDownload(redirectUrl);
		next(fromPath);
	} else if (app.isCorniceSubstituteUser) {
		openNewTab(redirectUrl);
		next(fromPath);
	} else {
		window.location.href = redirectUrl;
	}
};

const privateDocumentRedirectRoutes: Array<FundriseRouteConfig> = [
	{
		path: '/shareholder-documents/:id/view',
		name: 'shareholder-document-view',
		meta: {
			minTokenScopeRequired: 'FULL_ACCESS',
			additionalAllowedScopes: ['ADVISOR_ACCESS', 'ADVISOR_PRIVILEGED_ACCESS', 'ADVISOR_READ_ACCESS']
		},
		beforeEnter: async (to, from, next) => {
			const params: PrivateInvestorDocumentRedirectParams = {
				documentId: to.params.id as string,
				documentType: 'SHAREHOLDER'
			};

			try {
				const redirectLink = await privateInvestorDocumentViewRedirect(params);
				await openPrivateDocument(redirectLink.presignedUrl, from.path, next);
			} catch {
				next({ name: 'account-overview', replace: true });
			}
		},
		// revisit using redirect
		component: () => import('@views/account/account-overview.vue')
	},
	{
		path: '/investor-verification-documents/:id/view',
		name: 'investor-verification-document-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const params: PrivateInvestorDocumentRedirectParams = {
				documentId: to.params.id as string,
				documentType: 'INVESTOR_VERIFICATION'
			};

			try {
				const redirectLink = await privateInvestorDocumentViewRedirect(params);
				await openPrivateDocument(redirectLink.presignedUrl, from.path, next);
			} catch {
				next({ name: 'account-overview', replace: true });
			}
		},
		// revisit using redirect
		component: () => import('@views/account/account-overview.vue')
	},
	{
		path: '/identification-documents/:id/view',
		name: 'identification-document-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const params: PrivateInvestorDocumentRedirectParams = {
				documentId: to.params.id as string,
				documentType: 'IDENTIFICATION'
			};

			try {
				const redirectLink = await privateInvestorDocumentViewRedirect(params);
				await openPrivateDocument(redirectLink.presignedUrl, from.path, next);
			} catch {
				next({ name: 'account-overview', replace: true });
			}
		},
		// revisit using redirect
		component: () => import('@views/account/account-overview.vue')
	},
	{
		path: '/private-investor-documents/:documentType/:documentId/view',
		name: 'private-investor-documents-redirect',
		meta: {
			minTokenScopeRequired: 'FULL_ACCESS',
			additionalAllowedScopes: ['ADVISOR_ACCESS', 'ADVISOR_PRIVILEGED_ACCESS', 'ADVISOR_READ_ACCESS']
		},
		beforeEnter: async (to, from, next) => {
			const params: PrivateInvestorDocumentRedirectParams = {
				documentId: to.params.documentId as string,
				documentType: to.params.documentType as PrivateInvestorDocumentType
			};

			try {
				const redirectLink = await privateInvestorDocumentViewRedirect(params);
				await openPrivateDocument(redirectLink.presignedUrl, from.path, next);
			} catch {
				next({ name: 'account-overview', replace: true });
			}
		},
		// revisit using redirect
		component: () => import('@views/account/account-overview.vue')
	},
	{
		path: '/private-user-documents/:documentType/:documentId/view',
		name: 'private-user-documents-redirect',
		meta: {
			minTokenScopeRequired: 'FULL_ACCESS',
			additionalAllowedScopes: ['ADVISOR_ACCESS', 'ADVISOR_PRIVILEGED_ACCESS', 'ADVISOR_READ_ACCESS']
		},
		beforeEnter: async (to, from, next) => {
			const params: PrivateUserDocumentRedirectParams = {
				documentId: to.params.documentId as string,
				documentType: to.params.documentType as PrivateUserDocumentType
			};

			try {
				const redirectLink = await privateUserDocumentViewRedirect(params);
				await openPrivateDocument(redirectLink.presignedUrl, from.path, next);
			} catch {
				next({ name: 'account-overview', replace: true });
			}
		},
		// revisit using redirect
		component: () => import('@views/account/account-overview.vue')
	}
];

export default privateDocumentRedirectRoutes;
