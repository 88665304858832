import { apiSecureRequest, investmentEntityRequest } from '@utils/request';
import { AutoInvestOptions, AutoInvestSchedule, RecurringInvestmentScheduleSelections } from 'types/auto-invest';
import { AxiosResponse } from 'axios';

export async function getRecurringInvestmentSchedule(): Promise<AutoInvestSchedule> {
	const response = await investmentEntityRequest.get(`/recurring-investment`, {
		suppressToast: (resp: AxiosResponse) => resp.status === 404
	});
	return response.data;
}

export async function getAgreements(): Promise<Array<string>> {
	const response = await apiSecureRequest.get(`/recurring-investment/acknowledgements`);
	return response.data.map((ack: string) =>
		ack.replace(
			'fundrise.com/oc',
			'<a class="text-link" href="https://fundrise.com/oc" target="_blank">fundrise.com/oc</a>'
		)
	);
}

export async function setRecurringInvestmentSchedule(
	autoInvestSchedule: AutoInvestSchedule
): Promise<AutoInvestSchedule> {
	const response = await investmentEntityRequest.post(`/recurring-investment`, {
		...autoInvestSchedule,
		paymentMethodId: autoInvestSchedule.bankAccount.id
	});

	return response.data;
}

export async function getFrequenciesAndAmounts(): Promise<AutoInvestOptions> {
	const response = await investmentEntityRequest.get(`/recurring-investment/get-frequencies-and-amounts`);
	return response.data;
}

export async function getCheckoutFrequenciesAndAmounts(investmentAmount: string): Promise<AutoInvestOptions> {
	const queryParams = `?investmentAmount=${investmentAmount}`;
	const response = await apiSecureRequest.get(`/recurring-investment/get-frequencies-and-amounts` + queryParams);
	return response.data;
}

export async function updateRecurringInvestmentSchedule(
	autoInvestSchedule: AutoInvestSchedule
): Promise<AutoInvestSchedule> {
	const response = await investmentEntityRequest.put(`/recurring-investment`, {
		...autoInvestSchedule,
		paymentMethodId: autoInvestSchedule.bankAccount.id
	});

	return response.data;
}

export async function deleteRecurringInvestmentSchedule(): Promise<void> {
	await investmentEntityRequest.delete(`/recurring-investment`);
}

export async function resumeRecurringInvestmentSchedule(): Promise<AutoInvestSchedule> {
	const response = await investmentEntityRequest.post(`/recurring-investment/resume`);

	return response.data;
}

export async function cacheRecurringInvestmentScheduleSelections(
	selections: RecurringInvestmentScheduleSelections
): Promise<void> {
	await investmentEntityRequest.post(`/recurring-investment/selections/cache`, selections);
}

export async function loadRecurringInvestmentScheduleSelections(): Promise<RecurringInvestmentScheduleSelections> {
	const response = await investmentEntityRequest.get(`/recurring-investment/selections`);
	return response.data;
}
