<template>
	<div
		:class="{
			container: addContainerClass
		}"
	>
		<div v-if="addTopBorder" class="bt"></div>
		<div class="pt-150 pb-150">
			<FooterDisclaimer />
		</div>
	</div>
</template>

<script setup lang="ts">
import FooterDisclaimer from './footer-disclaimer.vue';

interface Props {
	addContainerClass?: boolean;
	addTopBorder?: boolean;
}
withDefaults(defineProps<Props>(), {
	addContainerClass: true,
	addTopBorder: true
});
</script>
