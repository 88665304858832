<template>
	<div>
		<div v-if="props.placehold" class="placeholder-grid__inner">
			<div class="grid-md gutters-300-md">
				<div v-for="(col, colIndex) in colArray" :key="colIndex" :class="blockSize">
					<div
						v-for="(row, rowIndex) in rowArray"
						:key="rowIndex"
						:class="{ 'placeholder-grid__square--reversed': props.theme === 'dark' }"
						class="aspect-ratio-1-1 content-block placeholder-grid__square"
					/>
				</div>
			</div>
		</div>
		<slot v-else />
	</div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

interface Props {
	placehold?: boolean;
	theme?: string;
	rowCount?: number;
	colCount?: number;
}

const props = withDefaults(defineProps<Props>(), {
	placehold: true,
	theme: 'light',
	rowCount: 3,
	colCount: 4
});

const rowArray = computed((): Array<number> => new Array(props.rowCount));

const colArray = computed((): Array<number> => new Array(props.colCount));

const blockSize = computed((): string => {
	const width = Math.floor(12 / props.colCount);
	return `col-${width}-md`;
});
</script>
