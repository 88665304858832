<template>
	<div>
		<div class="progress mb-50 display-flex" :style="{ height: `${props.height}px` }">
			<div :class="progressBarClasses" :style="{ width: `${props.progressPercent}%` }">
				<span v-if="isAnimated" :class="['animation', props.barClass]" />
			</div>
		</div>
		<div class="display-flex justify-content-space-between align-items-center zeta">
			<slot name="left-side">
				<div>
					Progress to goal
					<BaseTooltip v-if="props.entityHasNonRiaShareholdings" :dark-mode="true">
						<p class="font-weight-normal">
							This figure represents the value of your portfolio excluding Fundrise Innovation Fund, iPO,
							Opportunity Fund or Short-Term Notes.
						</p>
					</BaseTooltip>
				</div>
			</slot>
			<slot name="right-side">
				<div>{{ props.progressPercent }}% ({{ props.total }})</div>
			</slot>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
	progressPercent: string;
	entityHasNonRiaShareholdings?: boolean;
	barClass: string;
	total?: string;
	height?: string;
	isAnimated?: boolean;
	isRounded?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	entityHasNonRiaShareholdings: false,
	total: '',
	height: '4',
	isAnimated: false,
	isRounded: true
});

const progressBarClasses = computed((): string => {
	let classes = 'progress__bar--pending';
	if (props.isRounded) {
		classes = `${classes} rounded-left rounded-right`;
	}
	if (props.isAnimated) {
		classes = `${classes} bg-color-none`;
	} else {
		classes = `${classes} ${props.barClass}`;
	}
	return classes;
});
</script>
<style lang="scss" scoped>
.bg-color-none {
	background-color: transparent;
}

.progress__bar--pending span {
	display: block;
	height: 100%;
}

.animation {
	animation: loadingAnimation 2s ease-in-out;
	animation-fill-mode: both;
}

@keyframes loadingAnimation {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
</style>
