import { computed, ref } from 'vue';
import { app } from '@store/modules/app';
import { defineStore } from 'pinia';
import { getItemFromLocalStorage } from '@utils/web-storage';
import { isWebview } from '@utils/composables';

export const useGoogleSignUpStore = defineStore('googleSignUp', () => {
	const featureEnabled = ref<boolean | null>(null);
	const libraryLoaded = ref(false);
	const darkMode = ref(false);

	const leadCaptured = computed((): boolean => {
		return !!getItemFromLocalStorage('emailcapture');
	});

	const isLoading = computed((): boolean => {
		return !libraryLoaded.value || featureEnabled.value === null;
	});

	const isEnabled = computed((): boolean => {
		return !!featureEnabled.value && libraryLoaded.value && !leadCaptured.value && !isWebview.value;
	});

	async function storeFeatureEnabled(): Promise<void> {
		const isUserInLDTestGroup = await app.isUserInLDTestGroup({
			featureFlag: '2024-08-15-sign-up-with-google-a-b-test',
			testGroup: 'test-with-google-signup'
		});

		featureEnabled.value = isUserInLDTestGroup;
	}

	function $reset(): void {
		featureEnabled.value = null;
		libraryLoaded.value = false;
		darkMode.value = false;
	}

	return {
		featureEnabled,
		libraryLoaded,
		darkMode,
		isLoading,
		isEnabled,
		storeFeatureEnabled,
		$reset
	};
});
