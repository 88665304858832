import {
	getForgotPasswordMetaData,
	sendForgotPasswordMobileCode,
	submitForgotPasswordAuthCode
} from '@api/forgot-password';
import { TwoFactorAuthCodeForm, TwoFactorCodeDeliveryMethod } from 'types/authentication';
import { defineStore } from 'pinia';
import { ForgotPasswordMetaData } from 'types/forgot-password';
import { ref } from 'vue';

export const useResetPasswordStore = defineStore('resetPassword', () => {
	const dataRetrieved = ref(false);
	const forgotPasswordMetaData = ref<ForgotPasswordMetaData | null>(null);
	const lastFourOnly = ref(false);
	const passwordResetKey = ref('');
	const passwordResetSessionUuid = ref('');
	const attemptsRemaining = ref('5');
	const selectedAuthCodeDeliveryMethod = ref<TwoFactorCodeDeliveryMethod>('NONE');
	const defaultAttemptsCount = ref('5');

	async function initiateResetPasswordFlow() {
		const forgotPasswordFlow = await getForgotPasswordMetaData();
		dataRetrieved.value = true;
		forgotPasswordMetaData.value = forgotPasswordFlow;

		if (forgotPasswordFlow.attemptsLeft) {
			attemptsRemaining.value = forgotPasswordFlow.attemptsLeft;
		}
	}

	async function sendMobileAuthCode(method: TwoFactorCodeDeliveryMethod) {
		selectedAuthCodeDeliveryMethod.value = method;
		await sendForgotPasswordMobileCode(method);
	}

	async function verifyMobileAuthCode(twoFactorAuthCodeForm: TwoFactorAuthCodeForm) {
		await submitForgotPasswordAuthCode(twoFactorAuthCodeForm.authCode, twoFactorAuthCodeForm.codeDeliveryMethod);
	}

	function $reset() {
		dataRetrieved.value = false;
		forgotPasswordMetaData.value = null;
		lastFourOnly.value = false;
		passwordResetKey.value = '';
		passwordResetSessionUuid.value = '';
		attemptsRemaining.value = '5';
		selectedAuthCodeDeliveryMethod.value = 'NONE';
	}

	return {
		// State
		dataRetrieved,
		defaultAttemptsCount,
		forgotPasswordMetaData,
		lastFourOnly,
		passwordResetKey,
		passwordResetSessionUuid,
		attemptsRemaining,
		selectedAuthCodeDeliveryMethod,
		// Actions
		initiateResetPasswordFlow,
		sendMobileAuthCode,
		verifyMobileAuthCode,
		$reset
	};
});
